import React from 'react'
import { ListItem, ListItemText } from '@mui/material'
import { listItemClasses } from '@mui/material/ListItem'
import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'

const StyledListItem = styled(ListItem)(({ theme }) => ({
  [`&.${listItemClasses.root}`]: {
    justifyContent: 'space-evenly',
    margin: '0',
    maxWidth: '150px',
    height: '50px',
  },
  [`&.${listItemClasses.root}.Mui-selected`]: {
    borderRadius: '5px',
    backgroundColor: theme.palette.primary.main,
  },
  ['&.Mui-selected .MuiListItemText-root .MuiTypography-root']: {
    color: theme.palette.primary.contrastText,
  },
}))

const NavMenuLink = (props) => {
  const { title, href, color, isExact } = props

  return (
    <StyledListItem
      color={color}
      key={title}
      button
      component={NavLink}
      to={href}
      activeClassName="Mui-selected"
      exact={isExact}
    >
      <ListItemText style={{ textAlign: 'center' }}>{title}</ListItemText>
    </StyledListItem>
  )
}

NavMenuLink.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.symbol,
  href: PropTypes.string,
  color: PropTypes.string,
  isExact: PropTypes.bool,
}

export default NavMenuLink
