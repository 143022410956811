import React from 'react'
import PropTypes from 'prop-types'
import { Checkbox, Radio, BlockEl } from 'components/atoms'
import { FormGroupCheck } from 'components/molecules'

export default function ListItems(props) {
  const {
    items,
    activeList,
    onHandleActiveList,
    modalType,
    onHandleCheck,
    onIsChecked,
    selectedItem,
    setSelectedItem,
    selectedItems,
    setDisabled,
    dataToRemove,
  } = props
  // useEffect(() => {}, [items])
  const count = (index) => {
    let count = 0
    if (Object.keys(selectedItems).length > 0 && selectedItems[index]) {
      count = selectedItems[index].length
    }
    return count
  }

  return (
    <div className="modal__contMain modal__contMain-flex">
      <div className="modal__contMainLeft">
        <ul className="modal__contMainLeftUl">
          {items.map((item, index) =>
            dataToRemove.length ? (
              Object.keys(dataToRemove).slice().includes(String(item.id)) &&
              item.children.length === dataToRemove[item.id].length ? null : (
                <BlockEl
                  component="li"
                  bec="modal__contMainLeftLi"
                  modifier={{ active: activeList === index }}
                  key={item.id}
                  onClick={() => onHandleActiveList(index)}
                >
                  {item.name}
                  <span className="modal__contMainLeftLi-num" style={{ display: count(item.id) > 0 ? 'flex' : 'none' }}>
                    {count(item.id)}
                  </span>
                </BlockEl>
              )
            ) : (
              <BlockEl
                component="li"
                bec="modal__contMainLeftLi"
                modifier={{ active: activeList === index }}
                key={item.id}
                onClick={() => onHandleActiveList(index)}
              >
                {item.name}
                <span className="modal__contMainLeftLi-num" style={{ display: count(item.id) > 0 ? 'flex' : 'none' }}>
                  {count(item.id)}
                </span>
              </BlockEl>
            )
          )}
        </ul>
      </div>
      <div className="modal__contMainRight">
        {items.map((item, index) => (
          <BlockEl key={index} bec="modal__contMainRightCont" modifier={{ active: activeList === index }}>
            <div className="modal__contMainRightInner">
              {'children' in item
                ? item.children.map((option, idx) =>
                    modalType == 'checklist' && option.id !== dataToRemove ? (
                      <FormGroupCheck.Item
                        key={idx}
                        Input={Checkbox}
                        inputProps={{
                          value: option.id,
                          onClick: onHandleCheck,
                          ['data-index']: item.id,
                          blockElClass: 'form__checkItemModal',
                          className: `${onIsChecked(item.id, option.id) ? 'checked' : ''}`,
                          defaultChecked: onIsChecked(item.id, option.id),
                        }}
                        label={option.name}
                        type="square"
                      />
                    ) : dataToRemove.length ? (
                      !(dataToRemove[item.id] || []).slice().includes(option.id) ? (
                        <FormGroupCheck.Item
                          key={idx}
                          Input={Radio}
                          inputProps={{
                            value: option.id,
                            onClick: () => {
                              setSelectedItem(option.id)
                              setDisabled(false)
                            },
                            blockElClass: `form__checkItemModal`,
                            className: `${selectedItem == option.id ? 'checked' : ''}`,
                            defaultChecked: selectedItem == option.id,
                          }}
                          label={option.name}
                          type="round"
                        />
                      ) : null
                    ) : modalType == 'radio' ? (
                      <FormGroupCheck.Item
                        key={idx}
                        Input={Radio}
                        inputProps={{
                          value: option.id,
                          onClick: () => {
                            setSelectedItem(option.id)
                            setDisabled(false)
                          },
                          blockElClass: `form__checkItemModal`,
                          className: `${selectedItem == option.id ? 'checked' : ''}`,
                          defaultChecked: selectedItem == option.id,
                        }}
                        label={option.name}
                        type="round"
                      />
                    ) : null
                  )
                : item.descendants.map((option, idx2) =>
                    modalType == 'checklist' && option.id !== dataToRemove ? (
                      <FormGroupCheck.Item
                        key={idx2}
                        Input={Checkbox}
                        inputProps={{
                          value: option.id,
                          onClick: onHandleCheck,
                          ['data-index']: item.id,
                          blockElClass: 'form__checkItemModal',
                          className: `${onIsChecked(item.id, option.id) ? 'checked' : ''}`,
                          defaultChecked: onIsChecked(item.id, option.id),
                        }}
                        label={option.name}
                        type="square"
                      />
                    ) : dataToRemove.length ? (
                      !(dataToRemove[item.id] || []).slice().includes(option.id) ? (
                        <FormGroupCheck.Item
                          key={idx2}
                          Input={Radio}
                          inputProps={{
                            value: option.id,
                            onClick: () => {
                              setSelectedItem(option.id)
                              setDisabled(false)
                            },
                            blockElClass: `form__checkItemModal`,
                            className: `${selectedItem == option.id ? 'checked' : ''}`,
                            defaultChecked: selectedItem == option.id,
                          }}
                          label={option.name}
                          type="round"
                        />
                      ) : null
                    ) : modalType == 'radio' ? (
                      <FormGroupCheck.Item
                        key={idx2}
                        Input={Radio}
                        inputProps={{
                          value: option.id,
                          onClick: () => {
                            setSelectedItem(option.id)
                            setDisabled(false)
                          },
                          blockElClass: `form__checkItemModal`,
                          className: `${selectedItem == option.id ? 'checked' : ''}`,
                          defaultChecked: selectedItem == option.id,
                        }}
                        label={option.name}
                        type="round"
                      />
                    ) : null
                  )}
            </div>
          </BlockEl>
        ))}
      </div>
    </div>
  )
}

ListItems.propTypes = {
  items: PropTypes.array.isRequired,
  activeList: PropTypes.number,
  onHandleActiveList: PropTypes.func,
  modalType: PropTypes.string,
  activeModal: PropTypes.string,
  selectedItems: PropTypes.array,
  onIsChecked: PropTypes.func,
  onHandleCheck: PropTypes.func,
  selectedItem: PropTypes.number,
  setSelectedItem: PropTypes.func,
  setDisabled: PropTypes.func,
  dataToRemove: PropTypes.any,
}
