export const jobPostingSearch = ['option1', 'option2', 'option3']
export const basicConditions = ['jobIndustry', 'availablePosition', 'workLocations']
export const employmentStatuses = ['fullTimeEmployee', 'contractEmployee', 'subContractEmployee']
export const noOfHires = [
  'person1',
  'person2',
  'person3',
  'person4',
  'person5to9',
  'person10to19',
  'person20to29',
  'person30',
]
export const desiredTimeToHires = [
  'recruitedAllyear',
  'withinAMonth1',
  'withinAMonth3',
  'withinHalfyear',
  'withinAYear',
]
export const companySizes = ['person10', 'person11to100', 'person101to500', 'person501to1000', 'person1001']
export const workingStyles = [
  'fixedWorkingHrs',
  'flexTimeSystem',
  'professionalBusinessDiscretion',
  'planningWorkTypeDiscretion',
  'modifiedWorkingHrsSystem',
  'shiftSystem',
  'others',
]
export const assumedGenders = ['noMatter', 'male', 'female']
export const noOfJobChanges = ['regardlessNoOfTime', 'specifyNoOfTime']

export const advertisingBudget = [
  'twentyYenOrLess',
  'twentyToFiftyYen',
  'fiftyOneToHundredYen',
  'hundredYenOrMore',
  'discussBudget',
]

export const yearEstablished = [
  'lessThanOneYear',
  'oneToNineYears',
  'tenToTwentyNineYears',
  'thirtyToFortyNineYears',
  'fiftyToNinetyNineYears',
  'overOneHundredYears',
]

export const postPeriod = ['within24Hours', 'within3Days', 'withinAWeek', 'withinAMonth', 'threeMonths']
