import React from 'react'

import PropTypes from 'prop-types'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Button } from 'components/atoms'
import { useInvalidFieldCount } from 'utils/hooks'

function Footer({ confirmation, onBack }) {
  const { t } = useTranslation()
  const {
    control,
    formState: { isSubmitting },
  } = useFormContext()

  const { count } = useInvalidFieldCount({ control, name: ['type', 'message'] })
  const invalid = count > 0

  return (
    <div className="ev__footer">
      {confirmation ? (
        <div className="ev__footerWrap">
          <div className="ev__footerText">{t('evaluationForm.confirmQuestion')}</div>
          <div className="ev__btns">
            <Button bec="ev__btn" onClick={onBack} modifier="edit">
              {t('common.edit')}
            </Button>
            <Button bec="ev__btn" type="submit">
              {t('evaluationForm.evaluate')}
            </Button>
          </div>
        </div>
      ) : (
        <div className="ev__btns">
          <Button type="submit" bec="ev__btn" disabled={invalid || isSubmitting}>
            {t('common.confirm')}
          </Button>
        </div>
      )}
    </div>
  )
}

Footer.propTypes = {
  confirmation: PropTypes.bool.isRequired,
  onBack: PropTypes.func.isRequired,
}

export default Footer
