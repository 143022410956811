import React from 'react'

import PropTypes from 'prop-types'
import clsx from 'clsx'

const withUnclickableDisabled = (Component) => {
  function WithUnclickableDisabled({ ...props }) {
    const handleOnClick = (event) => {
      event.preventDefault()
      if (props.disabled) return
      props.onClick(event)
    }

    return <Component {...props} onClick={handleOnClick} />
  }

  WithUnclickableDisabled.propTypes = {
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
  }

  return WithUnclickableDisabled
}

const PaginationItemDot = () => <span className="pagenation__dot">...</span>

const PaginationItemPrevious = ({ disabled = false, ...props }) => (
  <a {...props} className={clsx('pagenation__prev', { 'pagenation__prev-disabled': disabled })}></a>
)
PaginationItemPrevious.propTypes = {
  disabled: PropTypes.bool,
}

const PaginationItemNext = ({ disabled = false, ...props }) => (
  <a {...props} className={clsx('pagenation__next', { 'pagenation__next-disabled': disabled })}></a>
)
PaginationItemNext.propTypes = {
  disabled: PropTypes.bool,
}

const PaginationItemPage = ({ page, selected, ...props }) => (
  <a className={clsx('pagenation__num', { current: selected })} {...props}>
    {page}
  </a>
)

PaginationItemPage.propTypes = {
  page: PropTypes.number,
  selected: PropTypes.bool,
}

const Components = {
  previous: withUnclickableDisabled(PaginationItemPrevious),
  'start-ellipsis': withUnclickableDisabled(PaginationItemDot),
  'end-ellipsis': withUnclickableDisabled(PaginationItemDot),
  page: withUnclickableDisabled(PaginationItemPage),
  next: withUnclickableDisabled(PaginationItemNext),
}

function PaginationItem({ type, ...props }) {
  const Component = Components[type]

  return <Component {...props} />
}

PaginationItem.propTypes = {
  type: PropTypes.string.isRequired,
  page: PropTypes.number,
  onClick: PropTypes.func,
}

export default PaginationItem
