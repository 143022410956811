import React from 'react'
import { useFormContext } from 'react-hook-form'
import PropTypes from 'prop-types'
import { Button } from 'components'
import { ReactComponent as ArrowIcon } from 'assets/img/arrow.svg'
import { useTranslation } from 'react-i18next'

function Footer({ onBack, job_vacancy_data, onSubmit }) {
  const singleStep = job_vacancy_data?.job_vacancy_data?.singleStep
  const { t } = useTranslation('translation', { keyPrefix: 'jobPosting.step2' })
  const to_edit = location.pathname.includes('edit') ? 1 : 0
  const {
    formState: { isSubmitting },
  } = useFormContext()

  const goBack = (e) => {
    e.preventDefault()
    onBack()
  }

  return (
    <div className="main__footer">
      <div className="main__footerLeft">
        <a href="" onClick={goBack} className="main__footerBack">
          <span className="pc">前に</span>戻る
        </a>
      </div>
      <div className="main__footerRight">
        <Button
          onClick={onSubmit}
          id="footerBtn"
          className="main__footerBtn"
          style={{ display: 'flex' }}
          disabled={isSubmitting}
        >
          {to_edit === 1 ? (
            t('confirmBtn')
          ) : singleStep ? (
            t('confirmBtn')
          ) : (
            <>
              {' '}
              次へ
              <span className="main__footerBtnIcon">
                <ArrowIcon />
              </span>
            </>
          )}
        </Button>
      </div>
    </div>
  )
}

Footer.propTypes = {
  onBack: PropTypes.func,
  job_vacancy_data: PropTypes.any,
  onSubmit: PropTypes.func.isRequired,
}

export default Footer
