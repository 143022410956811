import Dialog from '@mui/material/Dialog'
import PropTypes from 'prop-types'
import React from 'react'
import InviteUserForm from '../InviteUserForm'

function InviteUserDialog(props) {
  return (
    <Dialog open={props.open} maxWidth="sm" fullWidth={true} onClose={props.onClose}>
      <InviteUserForm onCancel={props.onClose} onInviteSuccess={props.onInviteSuccess} />
    </Dialog>
  )
}

InviteUserDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onInviteSuccess: PropTypes.func,
}

export default InviteUserDialog
