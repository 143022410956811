import React from 'react'

import PropTypes from 'prop-types'
import clsx from 'clsx'
import presets from './presets'

function EvaluationNav({ classes, preset, items, active, onClick, profilePage }) {
  const theClasses = typeof classes === 'object' ? classes : presets[preset]

  const handleItemClick = (id) => (e) => {
    e.preventDefault()
    onClick(id)
  }

  return (
    <ul
      className={clsx({
        [theClasses.root]: !profilePage,
        [`evaluation__leftEvaHeader evaluation__leftEvaHeader-cm010`]: profilePage,
      })}
    >
      {items.map((item) => (
        <li key={item.id} className={theClasses.item}>
          <a
            href="#"
            onClick={handleItemClick(item.id)}
            className={clsx(theClasses.link, {
              [theClasses.active]: active === item.id,
              [`${theClasses.item}-disable`]: item.number === 0,
            })}
          >
            {item.icon ? <span className="evaluation__navSpanIcon">{item.icon}</span> : ''}
            {<span>{item.id === 'all' ? item.label + ' ' + item.number + ' 件' : item.number}</span>}
            {item.id && item.id != 'all' ? item.label : ''}
          </a>
        </li>
      ))}
    </ul>
  )
}

const IDType = PropTypes.oneOfType([PropTypes.string, PropTypes.number])

const ClassesType = PropTypes.shape({
  root: PropTypes.string.isRequired,
  item: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  active: PropTypes.string.isRequired,
  number: PropTypes.string,
})

const ItemShape = PropTypes.shape({
  id: IDType.isRequired,
  label: PropTypes.string.isRequired,
  icon: PropTypes.node,
  number: PropTypes.number,
})

EvaluationNav.propTypes = {
  preset: PropTypes.oneOf(Object.keys(presets)),
  classes: ClassesType,
  items: PropTypes.arrayOf(ItemShape.isRequired).isRequired,
  active: IDType,
  onClick: PropTypes.func,
  profilePage: PropTypes.bool,
}

EvaluationNav.defaultProps = {
  preset: 'evaluation',
}

export default EvaluationNav
