import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useFormContext } from 'react-hook-form'
import { Textarea } from 'components/atoms'
import { FormItemLabel, ErrorMessage } from 'components'
import { FormGroup, FormSupArea } from 'components/molecules'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { makeFormGroupStateGetter } from 'utils/helper'
import { fetchJobPosting } from 'services/jobPostings'
import { useParams } from 'react-router-dom'
import { selectJobPosting } from 'store/job-postings/selectors'

const RecommendationInfo = (props) => {
  const { editmode, rules, data, successRecommendationInfo, finalData, errors, candidateSelected, applicant } = props
  const { t } = useTranslation()
  const { id } = useParams()
  const dispatch = useDispatch()
  const { watch, register, getFieldState } = useFormContext()
  const testimonial = watch('testimonial')
  const special_note = watch('special_notes')
  const { byName } = makeFormGroupStateGetter(getFieldState)
  const jobInfo = useSelector(selectJobPosting)

  useEffect(() => {
    if (id) dispatch(fetchJobPosting(id))
  }, [id])

  return (
    <>
      <FormGroup
        modifier="mgb0 mgt2"
        className={editmode ? successRecommendationInfo : ''}
        {...byName('testimonial')}
        required={editmode}
      >
        <FormItemLabel label={t('fieldLabels.testimonial')} />
        {editmode &&
        (applicant?.testimonial === '' ||
          !finalData?.testimonial === '' ||
          applicant?.testimonial === null ||
          applicant?.testimonial === undefined) ? (
          <div className="form__textAreaWrap form__textAreaWrap-w600">
            <Textarea {...register('testimonial', rules.testimonial)} cols="30" rows="10" className="form__group-mgt" />
            <FormSupArea flex>
              <ErrorMessage name="testimonial" />
              <FormSupArea.InputSup end inputValue={testimonial} max={rules.testimonial.maxLength.value} />
            </FormSupArea>
          </div>
        ) : (
          <div className="recommendation__dd">
            <p className="recommendation__dt">{candidateSelected ? applicant?.testimonial : finalData?.testimonial}</p>
          </div>
        )}
      </FormGroup>

      <FormGroup modifier="mgb0 mgt2" error={!!errors?.special_notes}>
        <FormItemLabel label={t('fieldLabels.notices')} />
        {editmode ? (
          <>
            <div className="form__subLabel">
              <div className="title">
                {jobInfo?.company.company_name}
                {t('hints.entered')}{' '}
              </div>
              <div className="details">{jobInfo?.special_note}</div>
            </div>
            <div className="form__textAreaWrap form__textAreaWrap-w600">
              <Textarea
                {...register('special_notes', rules.special_notes)}
                defaultValue={data?.special_notes}
                cols="30"
                rows="10"
                className="form__inputTextArea form__group-mgt inputCalc boolCheck"
              />
              <FormSupArea modifier="flex">
                <ErrorMessage name="special_notes" />
                <FormSupArea.InputSup modifier="end" inputValue={special_note} max={3000} />
              </FormSupArea>
            </div>
          </>
        ) : (
          <dd className="recommendation__dd">
            <div className="recommendation__ddText">{props?.finalData?.special_notes}</div>
          </dd>
        )}
      </FormGroup>
    </>
  )
}

RecommendationInfo.propTypes = {
  applicant: PropTypes.object,
  editmode: PropTypes.bool,
  rules: PropTypes.object,
  data: PropTypes.object,
  successRecommendationInfo: PropTypes.string,
  handleOnChange: PropTypes.func,
  finalData: PropTypes.object,
  errors: PropTypes.object,
  methods: PropTypes.object,
  candidateSelected: PropTypes.bool,
}

export default RecommendationInfo
