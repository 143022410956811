import Http from 'utils/Http'
import { showLoader, hideLoader } from 'store/loader/actionCreators'
import { authRequestSignIn, authSignIn, authFailSignIn, setUser, signOut } from 'store/auth/actionCreators'
import { parseAxiosResponse } from 'utils/helper'
import { showNotification } from 'store/notification/actionCreators'
// import { selectAuthUser } from 'store/auth/selectors'

export function signInUser(credentials) {
  return async (dispatch) => {
    /**
     * The app state is updated to inform
     * that the API call is starting.
     */
    dispatch(authRequestSignIn())

    dispatch(showLoader())

    const config = {
      grant_type: 'password',
      client_id: process.env.REACT_APP_CLIENT_ID,
      client_secret: process.env.REACT_APP_CLIENT_SECRET,
    }

    const data = { ...credentials, ...config }

    try {
      const response = await parseAxiosResponse(Http.post('oauth/token', data))

      dispatch(authSignIn(response))
      await dispatch(getUser())
    } catch (error) {
      dispatch(authFailSignIn(error))
    } finally {
      dispatch(hideLoader())
    }
  }
}

export function signInUserViaGoogleId(googleID) {
  const data = {
    grant_type: 'social',
    client_id: process.env.REACT_APP_CLIENT_ID,
    client_secret: process.env.REACT_APP_CLIENT_SECRET,
    access_token: googleID,
    provider: 'google',
  }

  return async (dispatch) => {
    try {
      const response = await parseAxiosResponse(Http.post('oauth/token', data))

      dispatch(authSignIn(response))
      await dispatch(getUser())
    } catch (error) {
      dispatch(authFailSignIn(error))
    }
  }
}

export function signInUserViaUserID(userID) {
  const data = {
    grant_type: 'pseudo_login',
    client_id: process.env.REACT_APP_CLIENT_ID,
    client_secret: process.env.REACT_APP_CLIENT_SECRET,
    user_id: userID,
  }

  return async (dispatch) => {
    dispatch(authRequestSignIn())
    dispatch(showLoader())

    try {
      const response = await parseAxiosResponse(Http.post('auth/pseudo-login', data))

      dispatch(authSignIn(response))
      await dispatch(getUser())
    } catch (error) {
      dispatch(authFailSignIn(error))
    } finally {
      dispatch(hideLoader())
    }
  }
}

export function signiUsingGoogle() {
  return async (dispatch) => {
    const result = await parseAxiosResponse(Http.post('sign-in/google/create-url')).then(
      (data) => ({ data }),
      (error) => ({ error })
    )

    if (result.error) {
      dispatch(authFailSignIn(result.error))
    }

    if (result.data) {
      console.log('data', result.data)
    }

    return result
  }
}

export function signUpUser(formData) {
  return () => {
    return Http.post('register', { ...formData })
  }
}

export function activateUser(token) {
  return () => {
    return Http.post('activate', { token })
  }
}

export function sendForgotPasswordEmail(formData) {
  return () => {
    return Http.post('password/forgot', { ...formData })
  }
}

export function resetPassword(formData, isAuthenticated = false) {
  const endpoint = isAuthenticated ? 'user/password/reset' : 'password/reset'
  return () => {
    return Http.post(endpoint, { ...formData })
  }
}

export function getUser() {
  return (dispatch) => {
    return parseAxiosResponse(Http.get('profile')).then(
      (data) => dispatch(setUser(data)),
      () => dispatch(setUser(null))
    )
  }
}

export function signOutUser() {
  return async (dispatch) => {
    dispatch(showLoader())
    try {
      await Http.delete('oauth/token').catch(null)
      dispatch(signOut())
    } catch (error) {
      console.log({ error })
    } finally {
      dispatch(hideLoader())
    }
  }
}

export function googleRedirect(invitation = false) {
  return (dispatch) => {
    const googleAuthEndpoint = invitation ? 'invitation/google/oauth' : 'google/oauth'
    return Http.get(googleAuthEndpoint)
      .then((response) => {
        const { data } = response
        window.location.href = data.url
      })
      .catch((error) => {
        dispatch(
          showNotification(error.error || error.message || 'Unable to authenticate to google. Please try again.', {
            type: 'danger',
            autoClose: 20000,
          })
        )
      })
  }
}

export function googleCallback(params) {
  return async (dispatch) => {
    try {
      const response = await parseAxiosResponse(Http.get('google/callback', { params }))
      await dispatch(signInUserViaGoogleId(response.data.google_id))
    } catch (e) {
      dispatch(authFailSignIn(e))
    }
  }
}
