import React, { useState } from 'react'
import Modal from '../Modal'
import PropTypes from 'prop-types'
import { Button, Radio } from 'components/atoms'
import { useTranslation } from 'react-i18next'
import FormGroupCheck from '../FormGroupCheck'
import clsx from 'clsx'
import { EVALUATION_DISAPPROVAL_REASON } from 'utils/constants'

function EvaluationSubModal({ open, handleSubClose, handleNonApproved }) {
  const { t } = useTranslation()
  const [selectedReason, setSelectedReason] = useState(null)
  const options = [
    {
      id: EVALUATION_DISAPPROVAL_REASON.policyViolation,
      label: EVALUATION_DISAPPROVAL_REASON.policyViolation,
    },
    {
      id: EVALUATION_DISAPPROVAL_REASON.contentsCannotConfirm,
      label: EVALUATION_DISAPPROVAL_REASON.contentsCannotConfirm,
    },
    {
      id: EVALUATION_DISAPPROVAL_REASON.falseAccusation,
      label: EVALUATION_DISAPPROVAL_REASON.falseAccusation,
    },
    {
      id: EVALUATION_DISAPPROVAL_REASON.others,
      label: EVALUATION_DISAPPROVAL_REASON.others,
    },
  ]

  const handleClearSelection = () => {
    setSelectedReason(null)
  }

  const disable = selectedReason === null

  return (
    <Modal containerType="contF" containerClass="modal__adminEvaluation" onClose={handleSubClose} open={open}>
      <Modal.Header>
        <div className="modal__contTitleWrap">
          <Modal.Title modifier="mgr">{t('evaluation.reasonForDisapproval.title')}</Modal.Title>
        </div>
        <Modal.CloseButton onClick={handleSubClose} />
      </Modal.Header>
      <Modal.Body blockElClass="modal__choiceContInner choiceModal" style={{ paddingLeft: '10px', paddingTop: '10px' }}>
        <div className="modal__contMain">
          <div className="modal__contMainRightCont-nocalc" id="choiceModalInSp1">
            <div className="modal__contMainRightInner modal__contMainRightInner-pd0">
              <FormGroupCheck>
                {options?.map((option, index) => (
                  <FormGroupCheck.Item
                    modifier="mgb16"
                    key={index}
                    Input={Radio}
                    inputProps={{
                      onChange: () => setSelectedReason(option.id),
                      checked: selectedReason === option.id,
                      value: option.id,
                      className: 'form__checkItem2',
                    }}
                    type="round"
                    label={option.label}
                  />
                ))}
              </FormGroupCheck>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer blockElClass="modal__adContFooter">
        <Button
          type="button"
          modifier="mgr"
          className={clsx('btn__outlineDissable ', disable ? 'btn__outlineDissable-noActive' : '')}
          disabled={disable}
          onClick={handleClearSelection}
        >
          {t('jobPosting.search.modal.clear')}
        </Button>
        <Button
          disabled={disable}
          modifier="spW100"
          variant="yellowDessable"
          variantMod={{ active: !disable }}
          onClick={handleNonApproved(selectedReason)}
        >
          {t('jobPosting.search.modal.decide')}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

EvaluationSubModal.propTypes = {
  open: PropTypes.bool,
  handleSubClose: PropTypes.func,
  handleNonApproved: PropTypes.func,
}

export default EvaluationSubModal
