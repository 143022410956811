import React from 'react'
import { useFormContext } from 'react-hook-form'
import get from 'lodash/get'
import PropTypes from 'prop-types'
import { Button } from 'components'
import { useTranslation } from 'react-i18next'

const Footer = ({ mode }) => {
  const { t } = useTranslation()
  const {
    formState: { dirtyFields, errors, isSubmitting, touchedFields },
  } = useFormContext()

  const isStillInvalid = (field) => {
    const isDirty = get(dirtyFields, field, false)
    const isTouched = get(touchedFields, field, false)
    const invalid = !!get(errors, field, false)
    return !isDirty || ((isTouched || isDirty) && invalid)
  }

  const invalidCount = [
    isStillInvalid('fullNameCtr'),
    isStillInvalid('kanaNameCtr'),
    isStillInvalid('gender'),
    isStillInvalid('birthdayCtr'),
    isStillInvalid('prefecture'),
    isStillInvalid('recent_annual_salary'),
    isStillInvalid('desired_annual_salary'),
    isStillInvalid('work_history_cv'),
    isStillInvalid('resume'),
    isStillInvalid(mode === 'add' ? 'ad_person_incharge' : 'emergency_contact'),
    isStillInvalid('current_employment_statusCtr'),
    isStillInvalid('status'),
    isStillInvalid('testimonial'),
  ].reduce((accum, curr) => accum + (curr ? 1 : 0), 0)

  return (
    <footer className="footer footer-flex1">
      <div className="recommendation__footer">
        <p className="main__footerReq" id="footerReq" style={{ display: invalidCount > 0 ? 'inline-block' : 'none' }}>
          {invalidCount + t('candidate.invalidCountMsg')}
        </p>
        <Button
          type="submit"
          id="footerBtn"
          style={{ display: invalidCount === 0 ? 'flex' : 'none' }}
          disabled={isSubmitting}
          className="btn btn__shadowBtn btn__shadowBtn-wide recommendation__footerBtn main__footerBtn-disabled"
        >
          {t('candidate.submitPreviewlbl')}
        </Button>
      </div>
    </footer>
  )
}

Footer.propTypes = {
  mode: PropTypes.string,
}

export default Footer
