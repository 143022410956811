import React, { useMemo, useState } from 'react'

import dayjs from 'dayjs'
import PropTypes from 'prop-types'

import { BlockEl, Button } from 'components/atoms'
import StatusItem from './StatusItem'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { selectAuthUser } from 'store/auth/selectors'
import { accountType } from 'utils/helper'
import FinalProposedDateSelectModal from './FinalProposedDateSelectModal'

const ProposalPart = (props) => <BlockEl component="span" blockElClass="selectionModal__tabContProposal" {...props} />

const AppointmentItem = ({ message, referralProposal, buttonDisabled, loadLatest, ...others }) => {
  const { t } = useTranslation()
  const user = useSelector(selectAuthUser)
  const appointments = message.meta?.appointments || []
  const [open, setOpen] = useState(false)
  const isHR = accountType(user) === 'HR'
  const isAllowed = isHR && referralProposal.job_posting.user_id === user.id

  const grouped = useMemo(() => {
    const result = appointments
      .slice()
      .sort((a, b) => dayjs(a.start_datetime).diff(dayjs(b.start_datetime)))
      .reduce((acc, appointment) => {
        const date = dayjs(appointment.start_datetime).format('ll(dd)')
        const startTime = dayjs(appointment.start_datetime).format('HH:mm')
        const endTime = dayjs(appointment.end_datetime).format('HH:mm')

        if (!acc[date]) {
          acc[date] = {
            ...appointment,
            time: [],
          }
        }

        acc[date].time.push(`${startTime}~${endTime}`)

        return acc
      }, {})

    return result
  }, [appointments])

  const openModal = () => {
    setOpen(true)
  }

  const closeModal = () => {
    setOpen(false)
  }

  const handleOnSubmitted = () => {
    setOpen(false)
    loadLatest()
  }

  const modalAppointments = useMemo(
    () => appointments.map((appointment) => ({ id: appointment.appointment_schedule_id, ...appointment })),
    [appointments]
  )

  return (
    <>
      <StatusItem {...others} message={message} />

      {Object.keys(grouped).map((key) => (
        <div key={grouped[key].appointment_schedule_id} className="selectionModal__tabContProposaWrap">
          <ProposalPart component="p">
            <ProposalPart modifier="date" noBlockEl>
              {key}
            </ProposalPart>
            <ProposalPart modifier="time" noBlockEl>
              {grouped[key].time.join(', ')}
            </ProposalPart>
          </ProposalPart>
        </div>
      ))}

      {isAllowed && !buttonDisabled && (
        <>
          <div className="selectionModal__tabContSetDate">
            <Button onClick={openModal} blockElClass="selectionModal__tabContSetDateButton" disabled={buttonDisabled}>
              {t('appointment.selectADate')}
            </Button>
          </div>

          <FinalProposedDateSelectModal
            open={open}
            onClose={closeModal}
            onSubmitted={handleOnSubmitted}
            items={modalAppointments}
          />
        </>
      )}
    </>
  )
}

AppointmentItem.propTypes = {
  message: PropTypes.object,
  referralProposal: PropTypes.object.isRequired,
  buttonDisabled: PropTypes.bool.isRequired,
  loadLatest: PropTypes.func.isRequired,
}

export default AppointmentItem
