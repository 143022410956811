import Echo from 'laravel-echo'
import Pusher from 'pusher-js'
import { createContext, useContext } from 'react'
import Http from 'utils/Http'

window.Pusher = Pusher

const EchoInstance = new Echo({
  broadcaster: 'pusher',
  key: process.env.REACT_APP_PUSHER_APP_KEY,
  cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
  forceTLS: true,
  authorizer: (channel) => {
    return {
      authorize: (socketId, callback) => {
        // Inject headers
        Http.defaults.headers.common['X-Socket-ID'] = socketId

        Http.post('/broadcasting/auth', {
          socket_id: socketId,
          channel_name: channel.name,
        })
          .then((response) => {
            callback(false, response.data)
          })
          .catch((error) => {
            callback(true, error)
          })
      },
    }
  },
})

const EchoContext = createContext(EchoInstance)
const EchoContextProvider = EchoContext.Provider

const useEcho = () => {
  const Echo = useContext(EchoContext)

  return Echo
}

export { EchoContextProvider, useEcho, EchoInstance }
