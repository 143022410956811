import React, { useEffect, useState } from 'react'
import { getWorkPoints } from 'services/workPoints'
import { useDispatch, useSelector } from 'react-redux'
import { Checkbox } from 'components/atoms'
import { FormGroupCheck } from 'components/molecules'
import { useController } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

const WorkingPoints = () => {
  const dispatch = useDispatch()
  const { field } = useController({ name: 'working_points' })
  const { t } = useTranslation('translation', { keyPrefix: 'jobPosting.step3' })
  const items = useSelector((state) => state.workpoints.items)
  const workingState = useSelector((state) => state.workpoints.status)
  const [workingPoints, setWorkingPoints] = useState(false)

  const workplaceEnvironment = items.find((item) => item.name === t('workEnvironment'))?.descendants || []
  const workingStyle = items.find((item) => item.name === t('howToWork'))?.descendants || []
  const workExperience = items.find((item) => item.name === t('workExperience'))?.descendants || []

  useEffect(() => {
    dispatch(getWorkPoints())
  }, [])

  const selected = field.value

  useEffect(() => {
    if (workingState === 'fulfilled') {
      setWorkingPoints(true)
    }
  }, [workingState])

  const handleChange = (workingPoint) => () => {
    const exists = selected.includes(workingPoint.id)
    let tmp = selected.slice()

    tmp = exists ? tmp.filter((id) => id !== workingPoint.id) : tmp.concat(workingPoint.id)

    field.onChange(tmp)
  }

  return (
    <>
      <div className="form__groupCheckArea">
        <p className="form__inLabel">{t('workEnvironment')}</p>
        <FormGroupCheck modifier="flex">
          {workplaceEnvironment?.map((working_points, index) => (
            <FormGroupCheck.Item
              modifier={{ w50: index % 2 === 0, w502: index % 2 !== 0, sp100: true }}
              key={index}
              Input={Checkbox}
              inputProps={{
                ...field,
                onChange: handleChange(working_points),
                value: working_points.id,
                checked: selected.includes(working_points.id),
              }}
              type="square"
              label={working_points?.name}
            />
          ))}
        </FormGroupCheck>
      </div>
      <div className="form__groupCheckArea">
        <p className="form__inLabel">{t('howToWork')}</p>
        <FormGroupCheck modifier="flex">
          {workingStyle?.map((working_points, index) => (
            <FormGroupCheck.Item
              modifier={{ w50: index % 2 === 0, w502: index % 2 !== 0, sp100: true }}
              key={index}
              Input={Checkbox}
              inputProps={{
                ...field,
                onChange: handleChange(working_points),
                value: working_points?.id,
                checked: selected.includes(working_points?.id),
              }}
              type="square"
              label={working_points?.name}
            />
          ))}
        </FormGroupCheck>
      </div>
      <div className="form__groupCheckArea">
        <p className="form__inLabel">{t('workExperience')}</p>
        <FormGroupCheck modifier="flex">
          {workingPoints &&
            workExperience?.map((working_points, index) => (
              <FormGroupCheck.Item
                modifier={{ w50: index % 2 === 0, w502: index % 2 !== 0, sp100: true }}
                key={index}
                Input={Checkbox}
                inputProps={{
                  ...field,
                  onChange: handleChange(working_points),
                  value: working_points?.id,
                  checked: selected.includes(working_points?.id),
                }}
                type="square"
                label={working_points?.name}
              />
            ))}
        </FormGroupCheck>
      </div>
    </>
  )
}

export default WorkingPoints
