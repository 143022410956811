import React from 'react'

import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { NewlineText } from 'components/atoms'

function CompanyDetails({ company }) {
  const { t } = useTranslation()

  const concatIndustries = (company?.industries || []).map(({ name }) => name).join('\n')

  return (
    <section>
      <h2 className="cm__title2 cm__title2-mgt">{t('userProfile.basicInformation.companyInfoTitleLabel')}</h2>
      <dl className="cm__mainDl">
        <dt className="cm__mainDt">{t('userProfile.basicInformation.companyNameLabel')}</dt>
        <dd className="cm__mainDd">{company.company_name}</dd>
        <dt className="cm__mainDt">{t('userProfile.basicInformation.companyAddressLabel')}</dt>
        <dd className="cm__mainDd">{company.address}</dd>
        <dt className="cm__mainDt">{t('userProfile.basicInformation.companyIndustryLabel')}</dt>
        <dd className="cm__mainDd">
          <NewlineText text={concatIndustries} />
        </dd>
        {company.website_url ? (
          <dt className="cm__mainDt">{t('userProfile.basicInformation.companyHPLabel')}</dt>
        ) : null}
        {company.website_url ? (
          <dd className="cm__mainDd">
            <a href={company.website_url} className="cm__mainDdLink">
              {company.website_url}
            </a>
          </dd>
        ) : null}
        <dt className="cm__mainDt">{t('userProfile.basicInformation.companyIPOLabel')}</dt>
        <dd className="cm__mainDd">{company.ipo}</dd>
        <dt className="cm__mainDt">{t('userProfile.basicInformation.companyRepresentativeNameLabel')}</dt>
        <dd className="cm__mainDd">
          {company.last_name} {company.first_name}
        </dd>
        <dt className="cm__mainDt">{t('userProfile.basicInformation.companyYearOfEstablishmentLabel')}</dt>
        <dd className="cm__mainDd">
          {company.year_established}
          {t('userProfile.basicInformation.companyYearOfEstablishmentUnit')}
        </dd>
        <dt className="cm__mainDt">{t('userProfile.basicInformation.companyClosingMonthLabel')}</dt>
        <dd className="cm__mainDd">
          {company.account_settlement_month}
          {t('userProfile.basicInformation.companyClosingMonthUnit')}
        </dd>
        <dt className="cm__mainDt">{t('userProfile.basicInformation.companyNumberOfEmployeesLabel')}</dt>
        <dd className="cm__mainDd">
          {company.employee_count}
          {t('userProfile.basicInformation.companyNumberOfEmployeesUnit')}
        </dd>
        {company.description ? (
          <dt className="cm__mainDt">{t('userProfile.basicInformation.companyProfileLabel')}</dt>
        ) : null}
        {company.description ? <dd className="cm__mainDd">{company.description}</dd> : null}
        {company.overview ? (
          <dt className="cm__mainDt">{t('userProfile.basicInformation.companyBusinessContentLabel')}</dt>
        ) : null}
        {company.overview ? <dd className="cm__mainDd">{company.overview}</dd> : null}
      </dl>
    </section>
  )
}

CompanyDetails.propTypes = {
  company: PropTypes.object.isRequired,
}

export default CompanyDetails
