import React from 'react'

import PropTypes from 'prop-types'

import BlockEl from '../BlockEl'

const Radio = React.forwardRef(({ blockElClass = 'form__checkItem', ...props }, ref) => {
  return <BlockEl {...props} componentRef={ref} blockElClass={blockElClass} component="input" type="checkbox" />
})

Radio.displayName = 'Checkbox'

Radio.propTypes = {
  ...BlockEl.propTypes,
  blockElClass: PropTypes.string,
}

export default Radio
