import React, { useEffect } from 'react'

import { useSelector, useDispatch } from 'react-redux'

import { hideNotification } from 'store/notification/actionCreators'
import { Notification } from 'components/atoms'

function AlertContainer() {
  const { show, autoClose, ...notiProps } = useSelector((state) => state.notification)
  const dispatch = useDispatch()

  useEffect(() => {
    if (show) {
      const num = setTimeout(() => {
        dispatch(hideNotification())
      }, autoClose)

      return () => {
        clearTimeout(num)
      }
    }
  }, [show, autoClose])

  // Hide on click
  const handleClick = () => {
    dispatch(hideNotification())
  }

  return <div className="aleart__container">{show && <Notification onClick={handleClick} {...notiProps} />}</div>
}

export default AlertContainer
