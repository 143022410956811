import { useSelector } from 'react-redux'
import get from 'lodash/get'

import { selectAuthUser } from 'store/auth/selectors'
import { selectRefPropSlideoutItem } from 'store/referral-proposals/selectors'
import { accountType } from 'utils/helper'

export default function useRefPropPermission({ referralProposal, user } = {}) {
  const storeRefProp = useSelector(selectRefPropSlideoutItem)
  const storeUser = useSelector(selectAuthUser)
  const theRefProp = referralProposal ?? storeRefProp
  const theUser = user ?? storeUser
  const userType = accountType(theUser)

  const canChangeStatus =
    userType === 'HR' ? get(theRefProp, 'job_posting.user_id') === get(theUser, 'id') : get(theRefProp, 'referred_by')
  const canSendMessage =
    userType === 'HR'
      ? get(theRefProp, 'job_posting.user_id') === get(theUser, 'id')
      : get(theRefProp, 'referred_by') === get(theUser, 'id')

  return { canChangeStatus, canSendMessage }
}
