import React from 'react'

import PropTypes from 'prop-types'
import castArray from 'lodash/castArray'
import { useTranslation } from 'react-i18next'

import { BlockEl, DateText } from 'components/atoms'
import { PROPOSAL_SIDE_STATUS } from 'utils/constants'
import SituationMark from './SituationMark'
import { useDispatch, useSelector } from 'react-redux'
import { createReferralProposalUsers } from 'services/referralProposals'
import { selectAuthUser } from 'store/auth/selectors'

const statusNames = Object.keys(PROPOSAL_SIDE_STATUS).reduce(
  (accum, key) => ({
    ...accum,
    ...castArray(PROPOSAL_SIDE_STATUS[key]).reduce((accumJ, curr) => ({ ...accumJ, [curr]: key }), {}),
  }),
  {}
)

function ListItem({ item, onClick, isAD2 }) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const getStatusName = (item) => t(`suggestion.${statusNames[item.proposal_status_id]}`)
  const isUnread = !item.read
  const user = useSelector(selectAuthUser)

  const handleClick = () => {
    !isAD2 ? dispatch(createReferralProposalUsers(item.id, user.id)) : null
    onClick && onClick(item)
  }

  const companyName = isAD2 ? item.job_posting?.company_name : item.referrer.company.company_name

  return (
    <BlockEl onClick={handleClick} component="li" bec="selection__mainLi" modifier={{ white: isUnread }}>
      <BlockEl bec="selection__mainOwner">{companyName}</BlockEl>
      <BlockEl bec="selection__mainPosition" modifier={{ hr: !isAD2 }}>
        <span className="selection__mainLi-gray selection-dpb">{item.job_posting.available_position?.name}</span>
        {item.job_posting.job_title}
      </BlockEl>
      {isAD2 && <BlockEl bec="selection__mainPartner">{item.job_posting?.user?.full_name}</BlockEl>}
      <BlockEl bec="selection__mainStatus">
        {getStatusName(item)}
        <span className="selection__mainLi-gray selection-dpb">
          <DateText format="YYYY/MM/DD (ddd)" date={item.latest_status_transaction.created_at} />
        </span>
      </BlockEl>

      <SituationMark item={item} />
    </BlockEl>
  )
}

ListItem.propTypes = {
  item: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  isAD2: PropTypes.bool.isRequired,
}

export default ListItem
