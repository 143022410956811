import { AppBar, Toolbar, Typography } from '@mui/material'
import React from 'react'

const Footer = () => {
  return (
    <AppBar position="relative" sx={{ top: 'auto', bottom: 0 }}>
      <Toolbar style={{ justifyContent: 'center' }}>
        <Typography variant="body1" color="inherit">
          Copyright ID. Touch{'&'}Links Inc. All rights reserved.
        </Typography>
      </Toolbar>
    </AppBar>
  )
}
export default Footer
