import * as types from './actionTypes'
import defaults from 'lodash/defaults'

/**
 * @typedef {Object} ShowNotificationOpts
 * @property {string} type - The type of notification. (success, danger)
 * @property {number} autoClose
 */

/**
 *
 * @param {string} message
 * @param {ShowNotificationOpts} opts
 * @returns
 */
export function showNotification(message, opts) {
  return {
    type: types.SHOW_NOTIFICATION,
    payload: defaults({ message }, opts, {
      type: 'success',
      autoClose: 5000,
      showButton: false,
    }),
  }
}

export function hideNotification() {
  return {
    type: types.HIDE_NOTIFICATION,
  }
}
