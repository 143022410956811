import produce from 'immer'
import { getAboutTheMedium } from 'services/aboutTheMedium'

const initialState = {
  items: [],
  fetching: false,
}

export default produce((draft, { type, payload }) => {
  switch (type) {
    case getAboutTheMedium.pending:
      draft.fetching = true
      break
    case getAboutTheMedium.fulfilled:
      draft.items = payload.data
      draft.fetching = false
      break
    case getAboutTheMedium.failure:
      draft.fetching = false
      break
    default:
      return draft
  }
}, initialState)
