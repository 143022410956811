import React, { lazy, Suspense } from 'react'
import { Route } from 'react-router-dom'
import PropTypes from 'prop-types'

import preview from 'layouts/preview'
import { Loader } from 'components'

function Preview(props) {
  const { component, layout, ...rest } = props
  const Layout = layout ? lazy(() => import(`../layouts/${layout}`)) : preview
  const Component = lazy(() => import(`../${component}`))
  const renderLoader = Loader

  return (
    <Route
      {...rest}
      render={(props) => (
        <Suspense fallback={renderLoader()}>
          <Layout>
            <Component {...props} />
          </Layout>
        </Suspense>
      )}
    />
  )
}

Preview.propTypes = {
  component: PropTypes.any,
  layout: PropTypes.any,
  location: PropTypes.object,
}

export default Preview
