import fileDownload from 'js-file-download'
import papaparse from 'papaparse'
import { useCallback } from 'react'

export default function useCSVExporter({ items, parseItem, filename }) {
  const download = useCallback(() => {
    const csvStr = papaparse.unparse(items.map(parseItem))
    const blob = new Blob([csvStr], { type: 'text/csv' })

    fileDownload(blob, filename || 'export.csv')
  }, [parseItem, items, filename])

  return { download }
}
