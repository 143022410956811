import React, { useState } from 'react'

import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import CalendarIcon from 'assets/img/cal-messageicon.svg'
import CalendarIconGray from 'assets/img/cal-icon-grey.svg'
import WithAvatarItem from './WithAvatarItem'
import { Button, NewlineText, Tooltip } from 'components'
import { selectAuthUser } from 'store/auth/selectors'
import { accountType } from 'utils/helper'
import { cancelAppointment, fetchLatestAppointmentSchedules } from 'services/appointments'
import { showNotification } from 'store/notification/actionCreators'
import FinalProposedDateSelectModal from './FinalProposedDateSelectModal'

function Cancelled({ message, startDate, endDate }) {
  const { t } = useTranslation()

  return (
    <div className="selectionModal__tabContCanceled">
      <div className="selectionModal__tabContCanHeader">
        <div className="selectionModal__tabContCanImg">
          <img src={CalendarIconGray} alt="" width="24" height="24" />
        </div>
        <div className="selectionModal__tabContCanText">
          <NewlineText
            text={t('message.cancelledScheduleText', {
              status: message.meta.status_key,
            })}
          />
        </div>
      </div>
      <div className="selectionModal__tabContCanWhen">
        <div className="selectionModal__tabContCanDate">{startDate.format('ll(dd)')}</div>
        <div className="selectionModal__tabContCanTime">
          {startDate.format('HH:mm')}~{endDate.format('HH:mm')}
        </div>
      </div>
    </div>
  )
}

Cancelled.propTypes = {
  message: PropTypes.object.isRequired,
  startDate: PropTypes.object.isRequired,
  endDate: PropTypes.object.isRequired,
}

function Confirmed({ message, startDate, endDate, disabled: propDisabled, onEditClick, onCancelled }) {
  const { t } = useTranslation()
  const [showTooltip, setShowTooltip] = useState(false)
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)

  const handCancel = () => {
    setShowTooltip(true)
  }
  const handEdit = () => onEditClick()

  const handleCloseTooltip = () => {
    setShowTooltip(false)
  }

  const handleConfirmCancel = () => {
    setLoading(true)
    dispatch(cancelAppointment(message.meta.appointment_schedule_id)).then(({ error }) => {
      if (error) {
        const message = error.message || error.error || 'Unknown Error'
        dispatch(showNotification(message, { type: 'danger' }))
        setLoading(false)
        return
      }

      setShowTooltip(false)
      onCancelled()
    })
  }

  const disabled = loading || propDisabled

  return (
    <div className="selectionModal__tabContCheckDate">
      <div className="selectionModal__tabContCheckHeader">
        <div className="selectionModal__tabContCheckImg">
          <img src={CalendarIcon} alt="" width="24" height="24" />
        </div>
        <div className="selectionModal__tabContCheckText">
          <NewlineText
            text={t('message.selectedScheduleText', {
              status: message.meta.status_key,
            })}
          />
        </div>
      </div>
      <div className="selectionModal__tabContCheckMain">
        <div className="selectionModal__tabContCheckMainDate">{startDate.format('ll(dd)')}</div>
        <div className="selectionModal__tabContCheckMainTime">
          {startDate.format('HH:mm')}~{endDate.format('HH:mm')}
        </div>
      </div>
      <div className="selectionModal__tabContCheckBtns">
        <Button
          blockElClass="selectionModal__tabContCheckBtn"
          modifier={{ cancel: true }}
          disabled={disabled}
          onClick={handCancel}
        >
          {t('common.cancelLower')}
        </Button>
        {/* TODO: Find a way to close the tooltip when clicking outside of it */}
        {showTooltip && (
          <Tooltip bec="selectionModal__tabContCancel" open={true} onClose={handleCloseTooltip}>
            <Tooltip.Text bec="selectionModal__tabContCancelText">{t('message.cancelScheduleText')}</Tooltip.Text>
            <Tooltip.Link
              bec="selectionModal__tabContCancelBtn"
              component="button"
              disabled={loading}
              onClick={handleConfirmCancel}
            >
              {t('common.cancelLower')}
            </Tooltip.Link>
          </Tooltip>
        )}

        <Button
          blockElClass="selectionModal__tabContCheckBtn"
          modifier={{ edit: true }}
          disabled={disabled}
          onClick={handEdit}
        >
          {t('common.edit')}
        </Button>
      </div>
    </div>
  )
}

Confirmed.propTypes = {
  message: PropTypes.object.isRequired,
  startDate: PropTypes.object.isRequired,
  endDate: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  onEditClick: PropTypes.func.isRequired,
  onCancelled: PropTypes.func.isRequired,
}

function ScheduleItem({ message, buttonDisabled, referralProposal, loadLatest }) {
  const { t } = useTranslation()
  const user = useSelector(selectAuthUser)
  const dispatch = useDispatch()
  const startDate = dayjs(message.meta.start_datetime)
  const endDate = dayjs(message.meta.end_datetime)
  const [open, setOpen] = useState(false)
  const [opening, setOpening] = useState(false)
  const [items, setItems] = useState([])
  const appointmendScheduleId = message.meta.appointment_schedule_id

  const disabled = buttonDisabled || accountType(user) !== 'HR' || message.meta.status === 'cancelled'

  const isCancelled = message.meta.state === 'cancelled'

  const openModal = () => {
    setOpening(true)
    dispatch(fetchLatestAppointmentSchedules(referralProposal.id))
      .then(({ error, data }) => {
        if (error) {
          dispatch(showNotification(error.message || error.error || 'Unknown Error', { type: 'danger' }))
          return
        }

        setItems(data.data)
        setOpen(true)
      })
      .finally(() => setOpening(false))
  }

  const closeModal = () => {
    setOpen(false)
  }

  const handleOnSubmitted = () => {
    setOpen(false)
    loadLatest()
  }

  return (
    <>
      <WithAvatarItem message={message}>
        {isCancelled ? (
          <Cancelled message={message} startDate={startDate} endDate={endDate} disabled={disabled} />
        ) : (
          <Confirmed
            message={message}
            startDate={startDate}
            endDate={endDate}
            disabled={disabled || opening}
            onEditClick={openModal}
            onCancelled={loadLatest}
          />
        )}
      </WithAvatarItem>
      {!buttonDisabled && isCancelled && (
        <div className="selectionModal__tabContSetDate">
          <Button onClick={openModal} disabled={opening} bec="selectionModal__tabContSetDateButton">
            {t('appointment.selectADate')}
          </Button>
        </div>
      )}
      <FinalProposedDateSelectModal
        open={open}
        onClose={closeModal}
        onSubmitted={handleOnSubmitted}
        items={items}
        selected={isCancelled ? undefined : appointmendScheduleId}
      />
    </>
  )
}

ScheduleItem.propTypes = {
  message: PropTypes.object.isRequired,
  referralProposal: PropTypes.object.isRequired,
  buttonDisabled: PropTypes.bool.isRequired,
  loadLatest: PropTypes.func.isRequired,
}

export default ScheduleItem
