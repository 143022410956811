// import React, { useEffect, useState } from 'react'
import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { CustomSelect } from 'components/molecules'

function AccountPermissionSelect(props) {
  const { t } = useTranslation()

  const options = [
    { id: 'parent', label: t('userPermission.parent') },
    { id: 'child', label: t('userPermission.child') },
  ]

  return (
    <CustomSelect
      placeholder={t('userAccount.selectPlaceHolder')}
      options={options}
      onChange={props.onChange}
      selected={props.value}
    />
  )
}

AccountPermissionSelect.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
}

export default AccountPermissionSelect
