import React, { useState } from 'react'

import dayjs from 'dayjs'
import PropTypes from 'prop-types'
import find from 'lodash/find'

import { Radio } from 'components/atoms'
import { FormGroupCheck, Modal } from 'components/molecules'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { chooseAppointment } from 'services/appointments'
import { showNotification } from 'store/notification/actionCreators'

const FinalProposedDateSelectModal = ({ open, onClose, onSubmitted, selected: initialSelected, items }) => {
  const { t } = useTranslation()
  const [selected, setSelected] = useState(null)
  const [submitting, setSubmitting] = useState(false)
  const dispatch = useDispatch()
  const handleClose = () => {
    setSelected(null)
    onClose()
  }

  const submit = async () => {
    setSubmitting(true)
    const item = find(items, { id: selected })

    const { error } = await dispatch(chooseAppointment(item.id))

    if (error) {
      const message = error?.error || error?.message
      dispatch(showNotification(message, { type: 'danger' }))
      setSubmitting(false)

      return
    }

    setSubmitting(false)
    onSubmitted()
  }

  const disabled = submitting || selected === null

  return (
    <Modal open={open} containerType="contE" onClose={handleClose}>
      <Modal.Header>
        <Modal.Title>{t('appointment.modal.title')}</Modal.Title>
        <Modal.CloseButton onClick={handleClose} />
      </Modal.Header>
      <div className="modal__scheduleConfArea">
        <FormGroupCheck component="ul" blockElClass="modal__scheduleConf">
          {items.map((item) => (
            <FormGroupCheck.Item
              key={item.id}
              component="li"
              labelProps={{
                blockElClass: 'modal__labelRadio',
                renderText: () => (
                  <>
                    <p className="modal__scheduleConfDay">{dayjs(item.start_datetime).format('ll(dd)')}</p>
                    <p className="modal__scheduleConfTime">
                      {dayjs(item.start_datetime).format('HH:mm')}~{dayjs(item.end_datetime).format('HH:mm')}
                    </p>
                  </>
                ),
              }}
              inputProps={{
                checked: selected === item.id,
                blockElClass: 'modal__inputRadio',
                onChange: () => {
                  setSelected(item.id)
                },
                disabled: initialSelected === item.id,
              }}
              blockElClass="modal__scheduleConfItem"
              type="round"
              Input={Radio}
            />
          ))}
        </FormGroupCheck>
      </div>
      <Modal.BtnArea modifier="fe">
        <Modal.Button onClick={handleClose} modifier="a">
          {t('common.cancel')}
        </Modal.Button>
        <Modal.Button onClick={submit} modifier={{ c: true, 'c-active': !disabled }} disabled={disabled}>
          {t('common.decide')}
        </Modal.Button>
      </Modal.BtnArea>
    </Modal>
  )
}

FinalProposedDateSelectModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired,
  onSubmitted: PropTypes.func.isRequired,
  selected: PropTypes.number,
}

export default FinalProposedDateSelectModal
