import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { getApplicantRecommendations } from 'services/applicantRecommendations'
import { useDispatch } from 'react-redux'
import CandidateRecommendationsList from './CandidateRecommendationsList'

const CandidateRecommendationTab = ({ applicantId }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [joined, setJoins] = useState([])
  const [declines, setDeclines] = useState([])
  const [selections, setSelections] = useState([])
  const [offers, setOffers] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    getData()
  }, [])

  const getData = () => {
    setLoading(true)
    dispatch(getApplicantRecommendations(applicantId)).then((res) => {
      const { data } = res.data
      setJoins(data?.joined)
      setSelections(data?.selection)
      setOffers(data?.offers)
      setDeclines([...data?.declined, ...data?.rejected])
      setLoading(false)
    })
  }

  return (
    <div className="candidate__modalTabCont candidate__modalTabCont-top candidate__modalTabCont-active" id="tabCont2">
      <CandidateRecommendationsList
        loading={loading}
        title={t('selection.sideMenu.joined_the_company')}
        recommendations={joined}
        modifier="top"
      />
      <CandidateRecommendationsList
        loading={loading}
        title={t('selection.sideMenu.unofficial_decision')}
        recommendations={offers}
      />
      <CandidateRecommendationsList
        loading={loading}
        title={t('selection.sideMenu.under_selection')}
        recommendations={selections}
      />
      <CandidateRecommendationsList
        loading={loading}
        title={t('selection.sideMenu.decline_or_sendoff')}
        recommendations={declines}
      />
    </div>
  )
}

CandidateRecommendationTab.propTypes = {
  applicantId: PropTypes.number,
}

export default CandidateRecommendationTab
