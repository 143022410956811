import React from 'react'

import PropTypes from 'prop-types'
import { SELECTION_STATUSES } from 'utils/constants'
import SelectionMessageForm from '../SelectionMessageForm'
import { useTranslation } from 'react-i18next'
import { useRefPropPermission } from 'utils/hooks'

const disabledAppointmentStatuses = [
  SELECTION_STATUSES.recommend,
  SELECTION_STATUSES.documentScreening,
  SELECTION_STATUSES.joined,
  SELECTION_STATUSES.rejected,
  SELECTION_STATUSES.declined,
]

const SelectionInputArea = React.forwardRef((props, ref) => {
  const { threadId, recommendation, show, send, loadLatest, setProposalIsUpdated } = props
  const { t } = useTranslation('translation', { keyPrefix: 'selection.modal' })
  const { canSendMessage } = useRefPropPermission()
  const applicationStatus = recommendation?.application_status_id
  const isMessageDisabled = [
    SELECTION_STATUSES.rejected,
    SELECTION_STATUSES.declined,
    SELECTION_STATUSES.joined,
  ].includes(applicationStatus)
  const disableApppointment = disabledAppointmentStatuses.includes(applicationStatus)
  const noThread = typeof threadId === 'undefined'
  const showUnauthorized = !canSendMessage || noThread
  const showResume = !showUnauthorized && canSendMessage && isMessageDisabled
  const isCompleted = applicationStatus === SELECTION_STATUSES.joined

  const message = (() => {
    if (isCompleted) return t('selectionCompleted')

    if (showResume) return t('resumeMsg')

    if (showUnauthorized) return t('unauthorizedMsg')
  })()

  return (
    <div ref={ref} style={{ display: show ? 'block' : 'none' }} className="selectionModal__inputArea">
      {canSendMessage && !isMessageDisabled && !noThread ? (
        <SelectionMessageForm
          disableApppointment={disableApppointment}
          threadId={recommendation?.chat_thread?.id}
          send={send}
          loadLatest={loadLatest}
          recommendation={recommendation}
          setProposalIsUpdated={setProposalIsUpdated}
        />
      ) : (
        <p className="selectionModal__inputAreaText">{message}</p>
      )}
    </div>
  )
})

SelectionInputArea.displayName = 'SelectionInputArea'

SelectionInputArea.propTypes = {
  threadId: PropTypes.number,
  recommendation: PropTypes.object,
  user: PropTypes.object,
  show: PropTypes.bool,
  send: PropTypes.func.isRequired,
  loadLatest: PropTypes.func.isRequired,
  setProposalIsUpdated: PropTypes.func,
}

export default SelectionInputArea
