import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { ErrorMessage, FormGroup, FormSupArea } from 'components/molecules'
import { Input } from 'components/atoms'

export default function FormFields() {
  const { t } = useTranslation()
  const {
    formState: { errors, dirtyFields },
    register,
    watch,
  } = useFormContext()

  const rules = {
    searchCriteria: {
      required: t('validation.required'),
      minLength: {
        value: 1,
        message: t('validation.minLength', { field: t('jobPosting.search.modal.saveCriteriaTitle'), length: 1 }),
      },
      maxLength: {
        value: 100,
        message: t('validation.maxLength', { field: t('jobPosting.search.modal.saveCriteriaTitle'), length: 100 }),
      },
    },
  }

  const searchCriteria = watch('searchCriteria')
  return (
    <>
      <FormGroup
        success={!errors?.searchCriteria && dirtyFields.searchCriteria}
        error={!!errors.searchCriteria}
        modifier="mgb0 mgt2"
        required
      >
        <Input
          className="form__group-mgt form__inputText-w100 inputExp inputCalc boolCheck"
          {...register('searchCriteria', rules.searchCriteria)}
        />

        <FormSupArea modifier="flex">
          <ErrorMessage name="searchCriteria" />
          <FormSupArea.InputSup modifier="end" inputValue={searchCriteria} max={rules.searchCriteria.maxLength.value} />
        </FormSupArea>
      </FormGroup>
    </>
  )
}
