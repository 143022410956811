import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { calculateModifiers } from 'utils/helper'

function ButtonModal({ className, children, modifier, ...props }) {
  const theMods = calculateModifiers('popModal', modifier)

  return (
    <p className={clsx(className, 'popModal', theMods)} {...props}>
      {children}
    </p>
  )
}

ButtonModal.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  modifier: PropTypes.string,
}

export default ButtonModal
