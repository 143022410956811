import React from 'react'

import { useTranslation } from 'react-i18next'
import { useFormContext } from 'react-hook-form'
import { useSelector } from 'react-redux'

import { selectTopics } from 'store/topics/selectors'
import { NewlineText } from 'components/atoms'

export default function Confirmation() {
  const topics = useSelector(selectTopics)
  const { t } = useTranslation()
  const { watch } = useFormContext()

  const question = watch('question')
  const topic = watch('topic')

  const topicText = topics.find(({ id }) => +id === +topic)?.topic

  return (
    <dl className="modal__contFormDl">
      <dt className="modal__contFormDt">{t('qAndA.topicField')}</dt>
      <dd className="modal__contFormDd">{topicText}</dd>
      <dt className="modal__contFormDt">{t('qAndA.questionField')}</dt>
      <dd className="modal__contFormDd">
        <p className="modal__contFormDdText">
          <NewlineText text={question} />
        </p>
      </dd>
    </dl>
  )
}
