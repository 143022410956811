import get from 'lodash/get'
import cloneDeep from 'lodash/cloneDeep'
import curry from 'lodash/curry'
import { initialThreadState } from './reducer'

const getThread = (threadId, state) => get(state.chatThreads.threads, [threadId], cloneDeep(initialThreadState))

const getThreadItem = (threadId, state) => getThread(threadId, state)

export const selectThreadItems = curry((threadId, state) => getThreadItem(threadId, state)?.items ?? [], 2)

export const selectThreadFetching = curry((threadId, state) => getThreadItem(threadId, state)?.fetching ?? false, 2)

export const selectThreadSending = curry((threadId, state) => getThreadItem(threadId, state)?.sending ?? false, 2)

export const selectThreadPagination = curry(
  (threadId, state) =>
    getThreadItem(threadId, state)?.pagination ?? {
      page: 1,
      limit: 10,
      hasMore: true,
    },
  2
)

export const selectThreadError = curry((threadId, state) => getThreadItem(threadId, state)?.error ?? null, 2)

export const selectThreadCanLoadMore = curry((threadId, state) => selectThreadPagination(threadId, state)?.hasMore)
