export const INIT_THREAD = 'INIT_THREAD'

export const LOAD_THREAD_MESSAGES_REQUEST = 'LOAD_THREAD_MESSAGES_REQUEST'
export const LOAD_THREAD_MESSAGES_SUCCESS = 'LOAD_THREAD_MESSAGES_SUCCESS'
export const LOAD_THREAD_MESSAGES_FAILURE = 'LOAD_THREAD_MESSAGES_FAILURE'

export const CREATE_NEW_MESSAGE_REQUEST = 'CREATE_NEW_MESSAGE_REQUEST'
export const CREATE_NEW_MESSAGE_SUCCESS = 'CREATE_NEW_MESSAGE_SUCCESS'
export const CREATE_NEW_MESSAGE_FAILURE = 'CREATE_NEW_MESSAGE_FAILURE'

export const ADD_MESSAGE = 'ADD_MESSAGE'

export const LOAD_MORE_THREAD_MESSAGES_REQUEST = 'LOAD_MORE_THREAD_MESSAGES_REQUEST'
export const LOAD_MORE_THREAD_MESSAGES_SUCCESS = 'LOAD_MORE_THREAD_MESSAGES_SUCCESS'
export const LOAD_MORE_THREAD_MESSAGES_FAILURE = 'LOAD_MORE_THREAD_MESSAGES_FAILURE'
