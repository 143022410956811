import React from 'react'

import PropTypes from 'prop-types'
import clsx from 'clsx'
import noop from 'lodash/noop'

import { calculateModifiers } from 'utils/helper'
import { Avatar, Textarea } from 'components/atoms'
import NewlineText from 'components/atoms/NewlineText/NewlineText'
import AvatarIcon from 'assets/img/Avatar@3x 3.svg'

const BoxHeader = ({ children }) => <div className="qa__liBoxHeader">{children}</div>

BoxHeader.propTypes = {
  children: PropTypes.node,
}

const BoxNameArea = ({ avatar, name }) => (
  <div className="qa__liBoxNameArea">
    <Avatar
      bec="qa__liBoxImgArea"
      modifier="mgr12"
      src={avatar}
      imgProps={{ bec: 'qa__liBoxImg' }}
      defaultSrc={AvatarIcon}
    />
    <p className="qa__liBoxName">{name}</p>
  </div>
)

BoxNameArea.propTypes = {
  avatar: PropTypes.string,
  name: PropTypes.string.isRequired,
}

const BoxCatArea = ({ topic }) => (
  <div className="qa__liBoxCatArea">
    <div className="qa__liBoxCat">{topic}</div>
  </div>
)

BoxCatArea.propTypes = {
  topic: PropTypes.string.isRequired,
}

const BoxTextArea = ({ text, editing, editText, onEditChange }) => {
  return (
    <div className="qa__liBoxTextArea">
      {editing ? (
        <Textarea value={editText} onChange={onEditChange} autoHeight row={1} />
      ) : (
        <p className="qa__liBoxText">
          <NewlineText text={text} />
        </p>
      )}
    </div>
  )
}

BoxTextArea.propTypes = {
  text: PropTypes.string,
  editing: PropTypes.bool,
  editText: PropTypes.string,
  onEditChange: PropTypes.func,
}

const BoxSupArea = ({ date, showReply, onReplyClick = noop, replyText }) => (
  <div className="qa__liBoxSupArea">
    <div className="qa__liBoxDate">{date}</div>
    {showReply && (
      <p className="qa__liBoxReply" onClick={onReplyClick}>
        {replyText}
      </p>
    )}
  </div>
)

BoxSupArea.propTypes = {
  date: PropTypes.string,
  showReply: PropTypes.bool,
  onReplyClick: PropTypes.func,
  replyText: PropTypes.string,
}

const BoxIconArea = ({ icons, onClick }) => {
  const handleClick = (icon) => () => {
    onClick(icon)
  }

  return (
    <div className="qa__liBoxIconArea">
      {icons.map((icon, i) => (
        <p key={i} className="qa__liBoxIconWrap">
          <img src={icon} onClick={handleClick(icon)} className="qa__liBoxIcon" width="17" height="17" />
        </p>
      ))}
    </div>
  )
}

BoxIconArea.propTypes = {
  icons: PropTypes.arrayOf(PropTypes.string).isRequired,
  onClick: PropTypes.func,
}

const BoxFooter = ({ children, replyText }) => (
  <div className="qa__liBoxFooter qa__liBoxFooter-space-between">
    {replyText && <p className="qa__liBoxFooterRes">{replyText}</p>}
    {children}
  </div>
)

BoxFooter.propTypes = {
  children: PropTypes.node,
  replyText: PropTypes.string,
}

const Box = ({ modifier, children }) => (
  <div className={clsx('qa__liBox', calculateModifiers('qa__liBox', modifier))}>{children}</div>
)

Box.propTypes = {
  children: PropTypes.node,
  modifier: PropTypes.string,
}

Box.Header = BoxHeader
Box.NameArea = BoxNameArea
Box.IconArea = BoxIconArea
Box.CatArea = BoxCatArea
Box.TextArea = BoxTextArea
Box.SupArea = BoxSupArea
Box.Footer = BoxFooter

export default Box
