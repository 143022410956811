import React from 'react'

import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import uniqBy from 'lodash/uniqBy'

import { ListTruncate } from 'components/atoms'

function WorkLocations({ workLocations = [] }) {
  const { t } = useTranslation('translation', { keyPrefix: 'common' })

  const prefectures = uniqBy(
    workLocations.map((item) => item.prefecture),
    'id'
  ).map((item) => item.prefecture)

  return (
    <ListTruncate items={prefectures} max={2} separator="、" truncate={(count) => t('remainPrefecture', { count })} />
  )
}

WorkLocations.propTypes = {
  workLocations: PropTypes.array,
}

export default WorkLocations
