import BaseSelect from '@mui/material/Select'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import React from 'react'
import PropTypes from 'prop-types'
import FormHelperText from '@mui/material/FormHelperText'
import Box from '@mui/material/Box'
import Chip, { chipClasses } from '@mui/material/Chip'
import OutlinedInput from '@mui/material/OutlinedInput'
import CancelIcon from '@mui/icons-material/Cancel'
import { styled } from '@mui/material/styles'

const StyledChip = styled(Chip)(() => ({
  [`&.${chipClasses.sizeSmall}`]: {
    height: '20px',
  },
}))

function SimpleSelect(props) {
  return (
    <BaseSelect {...props}>
      {props.options.map(({ value, label }) => (
        <MenuItem value={value} key={value}>
          {label}
        </MenuItem>
      ))}
    </BaseSelect>
  )
}

function MultiSelect(props) {
  const handleDelete = (e, value) => {
    e.target.value = props.value.filter((i) => i !== value)
    props.onChange(e)
  }

  return (
    <BaseSelect
      {...props}
      input={<OutlinedInput label={props.label} />}
      renderValue={(selected) => (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
          {selected.map((value) => (
            <StyledChip
              key={value}
              label={props.options.find((opt) => opt.value === value)?.label}
              clickable
              deleteIcon={<CancelIcon onMouseDown={(event) => event.stopPropagation()} />}
              onDelete={(e) => handleDelete(e, value)}
            />
          ))}
        </Box>
      )}
    >
      {props.options.map(({ value, label }) => (
        <MenuItem value={value} key={value}>
          {label}
        </MenuItem>
      ))}
    </BaseSelect>
  )
}

function Select({ formControlProps, helperText, error, ...props }) {
  return (
    <FormControl {...formControlProps} error={error}>
      <InputLabel id={props.labelId}>{props.label}</InputLabel>
      {props.multiple ? <MultiSelect {...props} /> : <SimpleSelect {...props} />}
      {error && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  )
}

const valueType = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.number,
  PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
])

Select.propTypes = {
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: valueType,
      label: PropTypes.string.isRequired,
    })
  ),
  value: valueType,
  label: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  multiple: PropTypes.bool,
  formControlProps: PropTypes.object,
}

MultiSelect.propTypes = {
  ...Select.propTypes,
  labelWidth: PropTypes.any,
}

SimpleSelect.propTypes = {
  ...Select.propTypes,
}

Select.defaultProps = {
  multiple: false,
  error: false,
}

export default Select
