import React from 'react'

import PropTypes from 'prop-types'

import BlockEl from '../BlockEl'

const Input = React.forwardRef(
  ({ blockElClass = 'form__inputText', accept, onClick: onClickProp, forgetful = false, type, ...props }, ref) => {
    const acceptValue = Array.isArray(accept) ? accept.join(',') : accept

    const onClick = (e) => {
      if (forgetful && type === 'file') {
        e.target.value = null
      }

      onClickProp && onClickProp(e)
    }

    return (
      <BlockEl
        {...props}
        type={type}
        onClick={onClick}
        accept={acceptValue}
        blockElClass={blockElClass}
        component="input"
        componentRef={ref}
      />
    )
  }
)

Input.displayName = 'Input'

Input.propTypes = {
  ...BlockEl.propTypes,
  accept: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  blockElClass: PropTypes.string,
  forgetful: PropTypes.bool,
}

export default Input
