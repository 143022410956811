import React from 'react'

import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { ErrorMessage, FormGroup, FormGroupCheck, FormSupArea } from 'components/molecules'
import { Label, Radio, Textarea } from 'components/atoms'
import { selectTopics } from 'store/topics/selectors'

export default function FormFields() {
  const topics = useSelector(selectTopics)
  const { t } = useTranslation()
  const {
    formState: { errors, dirtyFields },
    register,
  } = useFormContext()

  const rules = {
    topic: {
      required: t('validation.required'),
    },
    question: {
      required: t('validation.required'),
      maxLength: { value: 2000, message: t('validation.maxLength', { length: 2000 }) },
    },
  }

  return (
    <>
      <FormGroup success={!errors.topic && dirtyFields.topic} modifier="mgb0" required>
        <Label>{t('qAndA.topicField')}</Label>
        <FormGroupCheck modifier="flex">
          {topics.map((topic, index) => (
            <FormGroupCheck.Item
              key={index}
              Input={Radio}
              inputProps={{ ...register('topic', rules.topic), value: topic.id }}
              modifier="w50"
              type="square"
              label={topic.topic}
            />
          ))}
        </FormGroupCheck>
      </FormGroup>

      <Controller
        name="question"
        rules={rules.question}
        render={({ field, fieldState: { invalid, isDirty, isTouched } }) => (
          <FormGroup modifier="mgb0 mgt2" success={!invalid && (isDirty || isTouched)} error={invalid} required>
            <Label>{t('qAndA.questionField')}</Label>

            <Textarea {...field} className="form__group-mgt" />

            <FormSupArea modifier="flex">
              <ErrorMessage name="question" />
              <FormSupArea.InputSup modifier="end" inputValue={field.value} max={rules.question.maxLength.value} />
            </FormSupArea>
          </FormGroup>
        )}
      />
    </>
  )
}
