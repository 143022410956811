import React from 'react'

import { useTranslation } from 'react-i18next'
import { Controller, useFormContext } from 'react-hook-form'
import PropTypes from 'prop-types'

import SatisfactionIcon from 'assets/img/satisfaction-icon.svg'
import CuriousIcon from 'assets/img/curious-icon.svg'
import HintIcon from 'assets/img/hint.svg'
import { Radio, Textarea, FormSupArea, FormGroupCheck, FormGroup, ErrorMessage } from 'components'
import { makeFormGroupStateGetter } from 'utils/helper'

function FormFields({ rules }) {
  const { t } = useTranslation()
  const { register, getFieldState } = useFormContext()

  const { fromFieldState } = makeFormGroupStateGetter(getFieldState)

  return (
    <>
      <div className="ev__contHeader">{t('evaluationForm.headerInstruction')}</div>
      <div className="ev__type">
        <div className="ev__typeHead">{t('evaluationForm.typeLabel')}</div>
        <FormGroupCheck component="ul" bec="rv__typeUl">
          <FormGroupCheck.Item
            label={t('common.like')}
            bec="ev__typeLi"
            component="li"
            inputProps={{ ...register('type', rules.type), value: 'like', bec: 'ev__typeInput' }}
            labelProps={{
              blockElClass: 'ev__typeLabel',
              start: <img className="ev__typeIcon" src={SatisfactionIcon} alt="" width="24" height="24" />,
              renderText: ({ text }) => <span className="ev__typeText">{text}</span>,
            }}
            type="round"
            Input={Radio}
          />
          <FormGroupCheck.Item
            label={t('common.dislike')}
            bec="ev__typeLi"
            component="li"
            inputProps={{ ...register('type', rules.type), value: 'dislike', bec: 'ev__typeInput' }}
            labelProps={{
              blockElClass: 'ev__typeLabel',
              start: <img className="ev__typeIcon" src={CuriousIcon} alt="" width="24" height="24" />,
              renderText: ({ text }) => <span className="ev__typeText">{text}</span>,
            }}
            type="round"
            Input={Radio}
          />
        </FormGroupCheck>
      </div>
      <div className="ev__comment">
        <div className="ev__commentHead">{t('evaluationForm.contentLabel')}</div>
        <div className="ev__commentArea">
          <Controller
            name="message"
            rules={rules.message}
            render={({ field, fieldState }) => (
              <FormGroup error={fromFieldState(fieldState).error} modifier="mgb0">
                <Textarea {...field} />
                <FormSupArea flex>
                  <ErrorMessage name="message" />
                  <FormSupArea.InputSup end max={rules.message.maxLength.value} inputValue={field.value} />
                </FormSupArea>
              </FormGroup>
            )}
          />

          <div className="ev__commentHint-hide">
            <div className="ev__commentHintHead">
              <div className="ev__commentHintHeadImg">
                <img src={HintIcon} alt="" />
              </div>
              {/* TODO: I think this text is subject to change. Change when final */}
              <div className="ev__commentHintHeadText">入力のヒント</div>
            </div>
            <div className="ev__commentHintBody">
              {/* TODO: I think this text is subject to change. Change when final */}
              <p className="ev__commentHintP">
                入力のヒントが入ります。
                <br />
                入力のヒントが入ります。入力のヒントが入ります。入力のヒントが入ります。入力のヒントが入ります。入力のヒントが入ります。入力のヒントが入ります。
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

FormFields.propTypes = {
  rules: PropTypes.shape({
    type: PropTypes.object,
    message: PropTypes.object,
  }),
}

export default FormFields
