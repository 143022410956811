import React from 'react'
import PropTypes from 'prop-types'
import { useFormContext } from 'react-hook-form'
import { ReactComponent as ArrowSvg } from 'assets/img/arrow.svg'
import { useTranslation } from 'react-i18next'
import { useInvalidFieldCount } from 'utils/hooks'
import { Button } from 'components/atoms'

const fields = [
  'nationality',
  'english_level',
  'chinese_level',
  'changed_job_count_opt',
  'education_background',
  'min_acceptable_age',
  'max_acceptable_age',
  'gender',
  'skills_experience',
  'other_info_working_conditions',
]

const Footer = (props) => {
  const { onBack, jobVacancyData, onSubmit, type } = props
  const singleStep = jobVacancyData?.singleStep
  const {
    formState: { isSubmitting },
    control,
  } = useFormContext()

  const { t: mainT } = useTranslation('translation')
  const t = (key, ...args) => mainT(`jobPosting.common.${key}`, ...args)

  const { count } = useInvalidFieldCount({
    control,
    name: fields,
    isRequired: (field) => field !== 'other_info_working_conditions',
  })
  const unfilledCount = type === 'referral' ? count : count - 1

  const handleBack = (e) => {
    e.preventDefault()
    onBack()
  }

  return (
    <div className="main__footer">
      <div className="main__footerLeft">
        <a href="" onClick={handleBack} className="main__footerBack">
          <span className="pc">{t('inFront')}</span>
          {t('return')}
        </a>
      </div>
      <div className="main__footerRight">
        {unfilledCount !== 0 && (
          <p className="main__footerReq" id="footerReq">
            {mainT('validation.invalidInputsCount', {
              count: unfilledCount,
            })}
          </p>
        )}
        {unfilledCount === 0 && (
          <Button
            onClick={onSubmit}
            id="footerBtn"
            blockElClass="main__footerBtn"
            style={{ display: 'flex' }}
            disabled={isSubmitting || unfilledCount !== 0}
          >
            {singleStep ? (
              t('confirmBtn')
            ) : (
              <>
                {' '}
                次へ
                <ArrowSvg className="main__footerBtnIcon" />
              </>
            )}
          </Button>
        )}
      </div>
    </div>
  )
}

Footer.propTypes = {
  onBack: PropTypes.func.isRequired,
  jobVacancyData: PropTypes.any,
  onSubmit: PropTypes.func.isRequired,
  type: PropTypes.string,
}

export default Footer
