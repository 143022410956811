import * as types from './actionTypes'

export function createJobFilterRequest(search_criteria) {
  return {
    type: types.CREATE_JOB_FILTER_REQUEST,
    payload: { search_criteria },
  }
}

export function createJobFilterSuccess(data) {
  return {
    type: types.CREATE_JOB_FILTER_SUCCESS,
    payload: { data },
  }
}

export function createJobFilterFailure(error) {
  return {
    type: types.CREATE_JOB_FILTER_FAILURE,
    payload: { error },
  }
}

export const actionAddJobFilterCriteria = (patch) => {
  return {
    type: types.ADD_FILTER_CRITERIA,
    payload: patch,
  }
}

export const actionSetEditMode = (payload) => {
  return {
    type: types.SET_EDIT_MODE,
    payload,
  }
}
