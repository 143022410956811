import produce from 'immer'
import findIndex from 'lodash/findIndex'
import { fetchJobPostingTabStats } from 'services/jobPostings'
import {
  createQuestion,
  deleteAnswer,
  deleteQuestion,
  fetchQuestions,
  focusQuestion,
  markAllAnswersAsRead,
  sendQuestionReply,
  updateAnswer,
  updateQuestion,
} from 'services/questionAndAnswers'
import { AUTH_SIGNOUT } from 'store/auth/actionTypes'
import * as ActionTypes from './actionTypes'

const initialState = {
  listState: 'pending',
  listError: null,
  items: [],
  params: {
    limit: 10,
    page: 1,
    job_posting_id: null,
    topic: [],
    company_id: null,
    answer_state: null,
    with: [],
    user_id: null,
  },
  focusQuestion: {
    item: null,
    state: 'pending',
  },
  pagination: {
    total: 0,
    currentPage: 1,
    lastPage: 1,
  },
  saveState: 'pending',
  saveError: null,
  answeredStats: {
    all: 0,
    answered: 0,
    unanswered: 0,
  },
  deleting: false,
  editQuestion: null,
}

const reducer = produce((draft, { type, payload, meta }) => {
  switch (type) {
    case createQuestion.pending:
    case updateQuestion.pending:
    case sendQuestionReply.pending:
      draft.saveState = 'pending'
      break
    case createQuestion.fulfilled:
      draft.saveState = 'fulfilled'
      break
    case createQuestion.failure:
    case sendQuestionReply.failure:
    case updateQuestion.failure:
      draft.saveState = 'failure'
      draft.saveError = payload?.message
      break
    case updateQuestion.fulfilled: {
      draft.saveState = 'fulfilled'
      prepareStateWithFocus(draft, () => {
        const itemId = meta.args[0]
        const itemsIndex = findIndex(draft.items, { id: itemId })

        draft.items[itemsIndex] = Object.assign(draft.items[itemsIndex], payload.data)

        // Remove from list if not the same topic anymore
        if (draft.params.topic.length > 0 && !draft.params.topic.includes(draft.items[itemsIndex].topic.id)) {
          removeQuestion(itemId, draft)
        }

        return true
      })

      break
    }
    case sendQuestionReply.fulfilled: {
      draft.saveState = 'fulfilled'
      prepareStateWithFocus(draft, () => {
        const itemsIndex = draft.items.findIndex((item) => item.id === meta.args[0])
        draft.items[itemsIndex].answers.push(payload.data)
        draft.items[itemsIndex].number_of_replies++
      })
      break
    }
    case ActionTypes.UPDATE_QUESTION_PARAMS:
      draft.params = Object.assign(draft.params, payload)
      break
    case ActionTypes.RESET_QUESTION_PARAMS:
      draft.params = Object.assign({}, initialState.params, payload)
      break
    case fetchQuestions.pending:
      draft.listState = 'pending'
      break
    case fetchQuestions.fulfilled: {
      draft.listState = 'fulfilled'
      draft.items = payload.data
      draft.pagination = Object.assign(draft.pagination, {
        total: +payload.meta.total,
        lastPage: +payload.meta.lastPage,
        currentPage: +payload.meta.currentPage,
      })

      if ('answeredStats' in payload.meta) {
        draft.answeredStats = Object.assign(draft.answeredStats, payload.meta.answeredStats)
      }

      const focusIndex = findIndex(draft.items, { id: draft.focusQuestion.item?.id })

      // Update the focused question
      if (focusIndex !== -1) {
        draft.focusQuestion.item = Object.assign(draft.focusQuestion.item, draft.items[focusIndex])
      }

      break
    }
    case fetchQuestions.failure:
      draft.listState = 'failure'
      draft.listError = payload.message
      break

    case deleteQuestion.pending:
      draft.deleting = true
      break
    case deleteQuestion.fulfilled:
      draft.deleting = false
      removeQuestion(meta.args[0], draft)
      break
    case deleteQuestion.failure:
      draft.deleting = false
      break

    case deleteAnswer.pending:
      draft.deleting = true
      break
    case deleteAnswer.fulfilled: {
      draft.deleting = false
      prepareStateWithFocus(draft, () => {
        const [itemIndex, answerIndex] = findIndexByAnswerId(meta.args[0], draft.items)

        if (itemIndex === -1) return

        draft.items[itemIndex].answers.splice(answerIndex, 1)
        draft.items[itemIndex].number_of_replies--
      })
      break
    }
    case deleteAnswer.failure:
      draft.deleting = false
      break

    case updateAnswer.pending: {
      draft.savingState = 'pending'
      prepareStateWithFocus(draft, () => {
        updateAnswerItem(meta.args[0], { answer: meta.args[1] }, draft)
      })
      break
    }
    case updateAnswer.fulfilled: {
      draft.savingState = 'fulfilled'
      prepareStateWithFocus(draft, () => {
        updateAnswerItem(meta.args[0], payload.data, draft)
      })
      break
    }
    case updateAnswer.failure: {
      draft.savingState = 'failure'
      prepareStateWithFocus(draft, () => {
        rollbackAnswerItem(meta.args[0], draft)
      })
      break
    }
    case ActionTypes.EDIT_QUESTION: {
      const item = draft.items.find((item) => item.id === payload)
      draft.editQuestion = item
        ? {
            id: item.id,
            topic: item.topic.id,
            question: item.question,
          }
        : null
      break
    }
    case focusQuestion.pending:
      draft.focusQuestion.state = 'pending'
      break
    case focusQuestion.fulfilled:
      draft.focusQuestion.state = 'fulfilled'
      draft.focusQuestion.item = payload.data
      break
    case ActionTypes.UNFOCUS_QUESTION:
      draft.focusQuestion.state = 'fulfilled'
      draft.focusQuestion.item = null
      break
    case focusQuestion.failure:
      draft.focusQuestion.state = 'failure'
      break
    case markAllAnswersAsRead.fulfilled:
      prepareStateWithFocus(draft, () => {
        if (payload.data === null) return

        const itemIndex = findIndex(draft.items, { id: meta.args[0] })

        payload.data.forEach((answer) => {
          const answerIndex = findIndex(draft.items[itemIndex].answers, { id: answer.id })
          if (answerIndex !== -1) {
            draft.items[itemIndex].answers[answerIndex] = Object.assign(
              draft.items[itemIndex].answers[answerIndex],
              answer
            )
          } else {
            draft.items.push(answer)
          }
        })
      })
      break
    case fetchJobPostingTabStats.fulfilled:
      if (payload.tab !== 'qanda') draft.pagination.total = payload.stats.qanda
      break
    case AUTH_SIGNOUT:
      return initialState
    default:
      return draft
  }
}, initialState)

function findIndexByAnswerId(id, items) {
  let answerIndex = -1
  const itemIndex = items.findIndex((item) =>
    item.answers.find((answer, index) => {
      const match = answer.id === id
      answerIndex = match ? index : answerIndex
      return match
    })
  )

  return [itemIndex, answerIndex]
}

function updateAnswerItem(answerId, updates, state) {
  const [itemIndex, answerIndex] = findIndexByAnswerId(answerId, state.items)

  // Exit early as we could not find the answer
  if (answerIndex === -1) return

  const answer = state.items[itemIndex].answers[answerIndex]
  answer.beforeValue = answer.answer
  answer.answer = updates.answer
}

function rollbackAnswerItem(answerId, state) {
  const [itemIndex, answerIndex] = findIndexByAnswerId(answerId, state.items)

  // Exit early as we could not find the answer
  if (answerIndex === -1) return

  const answer = state.items[itemIndex].answers[answerIndex]
  answer.answer = answer.beforeValue
}

function prepareStateWithFocus(draft, cb) {
  const hasFocus = !!draft.focusQuestion.item
  let focusIndex = -1

  if (hasFocus) {
    focusIndex = findIndex(draft.items, { id: draft.focusQuestion.item.id })
    // Add it to the list if it's not there
    if (focusIndex === -1) draft.items.push(draft.focusQuestion.item)
    // Set the focus index so we can update them both
    else draft.items[focusIndex] = draft.focusQuestion.item
  }

  const retain = !!cb(hasFocus, focusIndex)

  // Remove the focused index from list
  if (hasFocus && focusIndex === -1 && !retain) {
    draft.items.pop()
  }
}

function removeQuestion(id, draft) {
  const index = findIndex(draft.items, { id })
  if (index === -1) return

  draft.items.splice(index, 1)
  draft.pagination.total--

  // Remove focus item
  if (draft.focusQuestion.item?.id === id) {
    draft.focusQuestion.item = null
  }
}

export default reducer
