import React, { useState } from 'react'

import PropTypes from 'prop-types'
import get from 'lodash/get'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import dayjs from 'dayjs'
import { useDispatch } from 'react-redux'

import { inAccountType } from 'utils/helper'
import CompanyImg from 'assets/img/Frame 9058 1.svg'
import GarbageIcon2 from 'assets/img/garbage-icon2.svg'
import AvatarIcon from 'assets/img/Avatar@3x 3.svg'
import PenIcon from 'assets/img/pen-icon.svg'
import CheckmarkIcon from 'assets/img/checkmark-icon.svg'
import CloseIcon from 'assets/img/close-icon.svg'
import { ACCOUNT_TYPES, DATE_FORMAT } from 'utils/constants'
import QandABox from './Box'
import { updateAnswer } from 'services/questionAndAnswers'
import { showNotification } from 'store/notification/actionCreators'

const QuestionAndAnswerThreadChildItem = React.forwardRef((props, ref) => {
  const { onDelete, answer, user, showReply, onReplyClick, disabled = false } = props
  const dispatch = useDispatch()
  const isFromHR = inAccountType(answer.user, ACCOUNT_TYPES.hr)
  const isOwn = get(answer, 'user.id') === get(user, 'id')
  const sameCompany = get(answer, 'user.company.id') === get(user, 'company.id')
  const date = dayjs(answer.created_at).format(DATE_FORMAT)
  const name = get(answer, isFromHR && !sameCompany ? 'user.company.company_name' : 'user.full_name')
  const avatar = sameCompany ? answer.user?.avatar : isFromHR ? CompanyImg : AvatarIcon
  const [answerText, setAnswerText] = useState('')
  const [editing, setEditing] = useState(false)
  const { t } = useTranslation()

  const handleIconClick = (icon) => {
    if (disabled) return
    switch (icon) {
      case GarbageIcon2:
        return onDelete(answer.id)
      case PenIcon:
        setEditing(true)
        setAnswerText(answer.answer)
        return
      case CloseIcon:
        setEditing(false)
        setAnswerText('')
        return
      case CheckmarkIcon:
        setEditing(false)
        setAnswerText('')
        dispatch(updateAnswer(answer.id, answerText)).then((res) => {
          const { error } = res
          if (error) {
            const msg = error.error || error.message || 'Unknown Error'
            dispatch(showNotification(msg, { type: 'danger' }))
          }
        })
        return
      default:
        return
    }
  }

  const handleEditChange = (e) => {
    setAnswerText(e.target.value)
  }

  const displayName = !sameCompany && !isFromHR ? t('qAndA.agent') : name

  return (
    <li className="qa__childLi" ref={ref}>
      <QandABox modifier={clsx({ blue: isFromHR })}>
        <QandABox.Header>
          <QandABox.NameArea avatar={avatar} name={displayName} />

          {isOwn && (
            <QandABox.IconArea
              icons={editing ? [CloseIcon, CheckmarkIcon] : [GarbageIcon2, PenIcon]}
              onClick={handleIconClick}
            />
          )}
        </QandABox.Header>

        <QandABox.TextArea
          text={answer.answer}
          editing={editing}
          editText={answerText}
          onEditChange={handleEditChange}
        />

        <QandABox.SupArea
          date={t('qAndA.date', { date })}
          showReply={showReply}
          onReplyClick={onReplyClick}
          replyText={t('qAndA.seeOthers_one')}
        />
      </QandABox>
    </li>
  )
})

QuestionAndAnswerThreadChildItem.displayName = 'QuestionAndAnswerThreadChildItem'

QuestionAndAnswerThreadChildItem.propTypes = {
  answer: PropTypes.object,
  user: PropTypes.object,
  onDelete: PropTypes.func.isRequired,
  showReply: PropTypes.bool.isRequired,
  onReplyClick: PropTypes.func,
  disabled: PropTypes.bool,
}

export default QuestionAndAnswerThreadChildItem
