import React from 'react'
import PropTypes from 'prop-types'
import CandidateBasicProfile from './CandidateBasicProfile'
import CandidateMemo from './CandidateMemo'
import CandidateTestimonial from './CandidateTestimonial'

const CandidateBasicInfoTab = ({ open, row }) => {
  return (
    <div className="candidate__modalTabCont candidate__modalTabCont-active candidate__modalTabCont-top" id="tabCont1">
      <CandidateBasicProfile
        currentEmploymentStatus={row.current_employment_status}
        desiredAnnualIncome={row.desired_annual_salary}
        gender={row.gender}
        recentAnnualIncome={row.recent_annual_salary}
        situation={row.status}
        dateOfBirth={row.birthday}
        age={row.age}
        stateOrProvinceOfResidence={row.prefecture.prefecture}
      />
      <CandidateMemo open={open} applicant_id={row.id} />
      <CandidateTestimonial testimonial={row.testimonial} />
    </div>
  )
}

CandidateBasicInfoTab.propTypes = {
  row: PropTypes.object,
  open: PropTypes.bool,
}

export default CandidateBasicInfoTab
