import React, { useEffect } from 'react'
import { FormGroup } from 'components/molecules'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { selectAuthUser } from 'store/auth/selectors'
import { inAccountType } from 'utils/helper'
import PropTypes from 'prop-types'
import partition from 'lodash/partition'
import { useParams } from 'react-router-dom'
import { selectUserById } from 'store/users/reducer'
import { fetchUserProfile } from 'services/userLogs'
import CompanyDetails from '../CompanyDetails'
import IndustryOccupationCard from '../IndustryOccupationCard'

const BasicInformation = () => {
  const { t } = useTranslation()
  const currentUser = useSelector(selectAuthUser)
  const isAD = inAccountType(currentUser, 'AD1') || inAccountType(currentUser, 'AD2')
  const [bestOccupations, specializedOccupations] = partition(currentUser.occupations || [], ['most_good', true])
  const [bestIndustries, specializedIndustries] = partition(currentUser.industries || [], ['most_good', true])
  const { id } = useParams()
  const dispatch = useDispatch()
  const selectedUser = useSelector((state) => selectUserById(state, id) ?? {})

  useEffect(() => {
    id && dispatch(fetchUserProfile(id))
  }, [id])

  if (id) {
    const isSelectedAD = inAccountType(selectedUser, 'AD1') || inAccountType(selectedUser, 'AD2')
    const [userBestOccupations, userSpecializedOccupations] = partition(selectedUser.occupations || [], [
      'most_good',
      true,
    ])
    const [userBestIndustries, userSpecializedIndustries] = partition(selectedUser.industries || [], [
      'most_good',
      true,
    ])

    return (
      <FormGroup className="form__inner form__inner-sppdt24">
        <h1 className="cm__title2">{t('userProfile.basicInformation.tabTitleLabel')}</h1>
        {isSelectedAD && (
          <IndustryOccupationCard
            bestOccupations={userBestOccupations}
            bestIndustries={userBestIndustries}
            specializedOccupations={userSpecializedOccupations}
            specializedIndustries={userSpecializedIndustries}
          />
        )}
        <FormGroup className="form_group form__group-mgb0 cm__mainTextArea">
          <p className="cm__mainText">{selectedUser.self_introduction}</p>
        </FormGroup>
        {selectedUser.company && <CompanyDetails company={selectedUser.company} />}
      </FormGroup>
    )
  } else {
    return (
      <FormGroup className="form__inner form__inner-sppdt24">
        <h1 className="cm__title2">{t('userProfile.basicInformation.tabTitleLabel')}</h1>
        {isAD && (
          <IndustryOccupationCard
            bestOccupations={bestOccupations}
            bestIndustries={bestIndustries}
            specializedOccupations={specializedOccupations}
            specializedIndustries={specializedIndustries}
          />
        )}
        <FormGroup className="form_group form__group-mgb0 cm__mainTextArea">
          <p className="cm__mainText">{currentUser.self_introduction}</p>
        </FormGroup>
        {currentUser.company && <CompanyDetails company={currentUser.company} />}
      </FormGroup>
    )
  }
}

BasicInformation.propTypes = {
  userId: PropTypes.number,
}

export default BasicInformation
