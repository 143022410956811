import React, { useEffect, useState } from 'react'
import Form3 from './Step3Form'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { selectJobPostingForm, selectJobPostingProposalForm } from 'store/job-posting-form/selectors'
import { getJobPostingDataFromDraft, getJobPostingProposalDataFromDraft } from 'services/jobPostingForm'
import PropTypes from 'prop-types'

const Step3Form = (props) => {
  const { type } = props
  const location = useLocation()
  const [showTip, setShowTip] = useState(false)
  const { t } = useTranslation('translation', { keyPrefix: 'jobPosting.step3' })
  const handleShowTip = (state) => {
    setShowTip(state)
  }

  const initialData =
    type === 'referral' ? useSelector(selectJobPostingForm) : useSelector(selectJobPostingProposalForm)
  const dispatch = useDispatch()
  const [hasFetched, setHasFetched] = useState(false)
  const theInitialData = hasFetched ? initialData : {}

  useEffect(() => {
    type === 'referral' ? dispatch(getJobPostingDataFromDraft()) : dispatch(getJobPostingProposalDataFromDraft())
    setHasFetched(true)
  }, [])

  return (
    <main className="main">
      <div className="main__formArea">
        <Form3 handleShowTip={handleShowTip} initialData={theInitialData} jobVacancyData={location.state} type={type} />
      </div>
      <div className="comment">
        {showTip && (
          <div className="comment__inner" id="exp3" style={{ display: 'block' }}>
            <p className="comment__title">{t('inputTips')}</p>
            <p className="comment__text">
              {t('hint')}
              <br />
              {t('example')}
              <br />
              {t('presence')}
              <br />
              {t('hireDate')}
              <br />
              {t('interview')}
              <br />
              {t('day')}
              <br />
              {t('driversLicense')}
            </p>
          </div>
        )}
      </div>
    </main>
  )
}

Step3Form.propTypes = {
  type: PropTypes.string.isRequired,
}

export default Step3Form
