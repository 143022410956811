import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import AccountManagementEmptyList from './AccountManagementEmptyList'
import AccountInvitationManagementCard from '../AccountInvitationManagementCard'

function AccountInvitationManagementList(props) {
  const { t } = useTranslation()

  const {
    users,
    count,
    countOnLoad,
    openInvitationModal,
    handleUserStatusOnClick,
    handleUserOnClick,
    openUserStatus,
    clickedUser,
  } = props

  return (
    <>
      {users?.length === 0 ? (
        <div className="invited__mainWrap">
          <div className="invited__header">
            {count !== 0 && (
              <p className="invited__title">
                {t('accountList.headerCountTitle')}
                <span className="invited__mainTitle-num">{count}</span>
                {t('accountList.countSuffix')}
              </p>
            )}

            <AccountManagementEmptyList countOnLoad={countOnLoad} openInvitationModal={openInvitationModal} />
          </div>
        </div>
      ) : (
        <AccountInvitationManagementCard
          users={users}
          handleUserStatusOnClick={handleUserStatusOnClick}
          handleUserOnClick={handleUserOnClick}
          openUserStatus={openUserStatus}
          clickedUser={clickedUser}
        />
      )}
    </>
  )
}

AccountInvitationManagementList.propTypes = {
  users: PropTypes.array.isRequired,
  count: PropTypes.number,
  countOnLoad: PropTypes.number,
  openInvitationModal: PropTypes.func,
  handleUserStatusOnClick: PropTypes.func,
  openUserStatus: PropTypes.bool,
  handleUserOnClick: PropTypes.func,
  clickedUser: PropTypes.object,
}

export default AccountInvitationManagementList
