import { useSelector, useDispatch } from 'react-redux'

import noop from 'lodash/noop'

import { changeSearchCriteria, searchUser } from 'services/users'
import { selectUserSearchParams } from 'store/users/selectors'

export default function useUserTableList() {
  const dispatch = useDispatch()
  // List details
  const list = useSelector((state) => state.users.list)
  const totalCount = useSelector((state) => state.users.listMeta.totalCount)
  const lastPage = useSelector((state) => state.users.listMeta.lastPage)
  const searchParams = useSelector(selectUserSearchParams)
  const isLoading = useSelector((state) => state.loader)
  const changeAndSearch = (criterias) => {
    dispatch(changeSearchCriteria(criterias))
    dispatch(searchUser())
  }

  const handlePageChange = (newPage) => {
    changeAndSearch({ page: newPage })
  }

  const handlePageSizeChange = (pageSize) => {
    // Since it's a change in page size, let's reset page at first page.
    changeAndSearch({ page: 1, limit: pageSize })
  }

  const handleSort = (sort, sortBy) => {
    changeAndSearch({ sortBy, sort })
  }

  return {
    data: list,
    pageSize: searchParams.limit,
    totalCount,
    lastPage,
    sort: searchParams.sort,
    sortBy: searchParams.sortBy,
    currentPage: searchParams.page,
    handlePageChange,
    handlePageSizeChange,
    isLoading,
    handleSort,
    handleEdit: noop,
    showCheckbox: false,
  }
}
