import { useEcho } from 'config/echo'
import { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import {
  createNewMessage,
  loadLatestMessages,
  loadMessage,
  loadMoreThreadMsesages,
  loadThreadMessages,
} from 'services/chatThreads'
import { actionInitThread } from 'store/chat-threads/actionCreators'

export default function useThread({ threadId }) {
  const Echo = useEcho()
  const dispatch = useDispatch()

  useEffect(() => {
    const channel = `chat.${threadId}`
    const invalidThreadId = typeof threadId === 'undefined' || threadId === null

    if (!invalidThreadId) {
      Echo.private(channel).listen('NewMessage', addMessage).listen('UpdateMessage', addMessage)
      dispatch(actionInitThread(threadId))
      dispatch(loadThreadMessages(threadId))
    }

    return () => {
      !invalidThreadId && Echo.leave(channel)
    }
  }, [Echo, threadId])

  const send = useCallback(
    ({ message, attachments }) => dispatch(createNewMessage(threadId, { message, attachments })),
    [threadId]
  )

  const addMessage = (payload) => {
    dispatch(loadMessage({ threadId, id: payload.message_id }))
  }

  const loadMore = useCallback(() => dispatch(loadMoreThreadMsesages(threadId)), [threadId])

  const loadLatest = useCallback(() => dispatch(loadLatestMessages(threadId)), [threadId])

  return { send, loadMore, threadId, loadLatest }
}
