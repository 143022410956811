import React from 'react'
import PropTypes from 'prop-types'
import { BlockEl } from 'components'

const TitleArea = ({ children }) => <div className="adminEvaluation__mainTitleArea">{children}</div>

TitleArea.propTypes = {
  children: PropTypes.node.isRequired,
}

const MainTitle = ({ title, resultCount, unit }) => (
  <h3 className="adminEvaluation__mainTitle">
    {title}
    <span className="adminEvaluation__mainTitle-num">{resultCount}</span>
    <span className="adminEvaluation__mainTitle-unit">{unit}</span>
  </h3>
)

MainTitle.propTypes = {
  resultCount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  title: PropTypes.string.isRequired,
  unit: PropTypes.string.isRequired,
}

const Filter = ({ children }) => <ul className="evaluation__leftEvaHeader">{children}</ul>
Filter.propTypes = {
  children: PropTypes.node.isRequired,
}

const FilterItem = ({ children, onFilterItemClick, active = false }) => (
  <li className="evaluation__leftEvaHeaderItem">
    <BlockEl
      component="a"
      blockElClass="evaluation__leftEvaHeaderLink"
      modifier={[{ active }]}
      href=""
      onClick={onFilterItemClick}
    >
      {children}
    </BlockEl>
  </li>
)

FilterItem.propTypes = {
  children: PropTypes.node.isRequired,
  active: PropTypes.bool,
  onFilterItemClick: PropTypes.func,
}

TitleArea.MainTitle = MainTitle
TitleArea.Filter = Filter
TitleArea.FilterItem = FilterItem

export default TitleArea
