import React, { memo } from 'react'

import PropTypes from 'prop-types'

import ListItem from './ListItem'
import Header from './Header'
import { inAccountType } from 'utils/helper'
import { useSelector } from 'react-redux'
import { selectAuthUser } from 'store/auth/selectors'

function SuggestionManagementList({ items, onItemClick }) {
  const user = useSelector(selectAuthUser)
  const isAD2 = inAccountType(user, 'AD2')

  return (
    <ul className="selection__mainUl">
      <Header isAD2={isAD2} />
      {items.map((item) => (
        <ListItem isAD2={isAD2} onClick={onItemClick} key={item.id} item={item} />
      ))}
    </ul>
  )
}

SuggestionManagementList.propTypes = {
  items: PropTypes.array.isRequired,
  onItemClick: PropTypes.func,
}

export default memo(SuggestionManagementList)
