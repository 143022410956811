import * as ActionTypes from './actionTypes'

export function getReferralListSuccess(data) {
  return {
    type: ActionTypes.GET_REFERRAL_PROPOSALS_LIST_SUCCESS,
    payload: { data },
  }
}

//Referrals List
export function getReferralListRequest(fullname) {
  return {
    type: ActionTypes.GET_REFERRAL_PROPOSALS_LIST_REQUEST,
    payload: { fullname },
  }
}

export function getReferralListFailure(error) {
  return {
    type: ActionTypes.GET_REFERRAL_PROPOSALS_LIST_FAILURE,
    payload: { error },
  }
}
