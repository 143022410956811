import React, { useEffect, useState } from 'react'

import clsx from 'clsx'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import BackArrow from 'assets/img/back-arrow.svg'
import Close from 'assets/img/close.svg'
import FormFields from './FormFields'
import Footer from './Footer'
import Confirmation from './Confirmation'
import { actionCloseEvaluationForm } from 'store/evaluation-form/actionCreators'
import { createEvaluation } from 'services/evaluation'
import { showNotification } from 'store/notification/actionCreators'
import { EVALUATION_STATUSES } from 'utils/constants'

const defaultValues = {
  type: '',
  message: '',
}

function prepareData(data, extra = {}) {
  const { type, message } = data

  return {
    ...extra,
    like: type === 'like' ? 1 : 0,
    dislike: type === 'dislike' ? 1 : 0,
    message,
    status: EVALUATION_STATUSES.notYetApproved,
  }
}

function EvaluationScreen() {
  const methods = useForm({ defaultValues, mode: 'all' })
  const { handleSubmit } = methods
  const [confirmation, setConfirmation] = useState(false)
  const { t } = useTranslation('translation')
  const dispatch = useDispatch()
  const { open, data: storeData } = useSelector((state) => state.evaluationForm)

  const onSubmit = async (data) => {
    if (!confirmation) {
      setConfirmation(true)
      return
    }

    const { error } = await dispatch(createEvaluation(prepareData(data, storeData)))

    if (error) {
      const msg = error.code === 422 ? 'Bad Request' : error.message || 'Unknown Error'

      dispatch(showNotification(msg, { type: 'danger' }))
      return
    }

    dispatch(actionCloseEvaluationForm(true))
  }

  const handleBackToEdit = () => {
    setConfirmation(false)
  }

  const handleBackIconClick = async () => {
    if (confirmation) return setConfirmation(false)

    dispatch(actionCloseEvaluationForm(false))
  }

  const rules = {
    type: {
      required: t('validation.required'),
    },
    message: {
      required: t('validation.required'),
      maxLength: {
        value: 3000,
        message: t('validation.maxLength', { length: 3000 }),
      },
    },
  }

  useEffect(() => {
    if (!open) {
      methods.reset(defaultValues)
    }
  }, [open])

  return (
    <form className={clsx({ active: open })} onSubmit={handleSubmit(onSubmit)} id="evaluation">
      <FormProvider {...methods}>
        <div
          className={clsx({ ev1: true, evComfirm: confirmation, evInput: !confirmation })}
          style={{ display: open ? 'block' : 'none' }}
        >
          <div className="ev__header">
            <div className="ev__headerWrap">
              <div onClick={handleBackIconClick} className="ev__arrowImg">
                <img src={BackArrow} alt="" width="16.67" height="16.67" />
              </div>
              <div className="ev__headerTitle">{t('evaluationForm.headerTitle')}</div>
            </div>
            <div className="ev__headerWrap">
              <div className="ev__crossImg">
                <img src={Close} alt="" width="13" height="13" />
              </div>
            </div>
          </div>
          <div className="ev__contArea">{confirmation ? <Confirmation /> : <FormFields rules={rules} />}</div>
          <Footer confirmation={confirmation} onBack={handleBackToEdit} />
        </div>
      </FormProvider>
    </form>
  )
}

export default EvaluationScreen
