import produce from 'immer'
import { fetchTopics } from 'services/topics'

const initialState = {
  state: 'pending',
  items: [],
  error: null,
}

const reducer = produce((draft, { type, payload }) => {
  switch (type) {
    case fetchTopics.pending:
      draft.state = 'pending'
      return draft
    case fetchTopics.fulfilled:
      draft.state = 'fulfilled'
      draft.items = payload.data
      return draft
    case fetchTopics.failure:
      draft.state = 'failure'
      draft.error = payload?.message
      return draft
  }
}, initialState)

export default reducer
