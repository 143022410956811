import {
  loadCompanySelectFailure,
  loadCompanySelectRequest,
  loadCompanySelectSuccess,
  updateCompanyRequest,
  updateCompanySuccess,
  updateCompanyFailure,
  // actionSetJobPostingProposalForm,
  actionSyncCompanyCreate,
} from 'store/companies/actionCreator'
import { parseAxiosResponse } from 'utils/helper'
import Http from 'utils/Http'
import { createAsyncThunk } from 'store/helpers'

const SUCCESS = 200

export function getCompanySelect() {
  return (dispatch) => {
    dispatch(loadCompanySelectRequest())

    return parseAxiosResponse(Http.get('companies/select')).then(
      (data) => dispatch(loadCompanySelectSuccess(data)),
      (err) => dispatch(loadCompanySelectFailure(err))
    )
  }
}

export function updateCompany(id, formData) {
  return (dispatch) => {
    const data = new FormData()
    const appendIfExist = (fields) =>
      fields.forEach((field) => typeof formData[field] !== 'undefined' && data.append(field, formData[field]))
    if (formData.status) {
      data.append('status', formData.status)
    } else if (formData.edit) {
      data.append('address', formData.company_address)
      data.append('company_name', formData.company_name)
      data.append('telephone_number', formData.phone_number)
      data.append('ipo', formData.ipo)
      if (formData.contract_type === 'HR') {
        appendIfExist(['refund_policy'], formData.refund_policy)
      } else {
        appendIfExist(['type_kinds', 'contract_type'])
      }
    } else {
      appendIfExist([
        'first_name',
        'last_name',
        'year_established',
        'industries',
        'year_established',
        'account_settlement_month',
        'website_url',
        'employee_count',
        'overview',
        'description',
      ])
    }
    dispatch(updateCompanyRequest())
    data.append('_method', 'PUT')
    return Http.post(`/company_info/${id}`, data)
      .then((response) => {
        if (response.status === SUCCESS) {
          return dispatch(updateCompanySuccess(response.data))
        }
      })
      .catch((error) => {
        return dispatch(updateCompanyFailure(error.response.data.error))
      })
  }
}

export const fetchCompanies = createAsyncThunk('FETCH_COMPANIES', (getState) => {
  const { ...params } = getState().companies.params
  return parseAxiosResponse(Http.get(`/company_info`, { params }))
})

export const getCompanyDetails = createAsyncThunk('GET_COMPANY_DETAILS', (id) => {
  return parseAxiosResponse(Http.get(`/company_info/${id}`))
})

export const syncCompanyCreateToStore = (data) => {
  return (dispatch) => {
    dispatch(actionSyncCompanyCreate(data))
  }
}

export const saveCompanyCreate = (data = {}, type) => {
  return async (dispatch) => {
    dispatch(syncCompanyCreateToStore(data))

    if (type.adTab) {
      localStorage.setItem('company_ad', JSON.stringify(data))
    }

    if (type.hrTab) {
      localStorage.setItem('company_hr', JSON.stringify(data))
    }
  }
}

export const createCompany = createAsyncThunk('CREATE_COMPANY_CREATE', (data) => {
  return parseAxiosResponse(Http.post(`/company_info`, data))
})
