import React from 'react'
import PropTypes from 'prop-types'

function ArrowRight({ color }) {
  return (
    <svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 1L7 7L1 13" stroke={color} strokeWidth="1.5"></path>
    </svg>
  )
}

ArrowRight.propTypes = {
  color: PropTypes.string,
}

export default ArrowRight
