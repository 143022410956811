import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react'

import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import find from 'lodash/find'
import dayjs from 'dayjs'
import { Waypoint } from 'react-waypoint'

import { selectThreadCanLoadMore, selectThreadFetching, selectThreadItems } from 'store/chat-threads/selectors'
import { DATE_FORMAT, MESSAGE_TYPE } from 'utils/constants'
import StatusItem from './StatusItem'
import AppointmentItem from './AppointmentItem'
import RegularItem from './RegularItem'
import DateItem from './DateItem'
import LinkableItem from './LinkableItem'
import ScheduleItem from './ScheduleItem'
import EvaluationItem from './EvaluationItem'
import ProposalItem from './ProposalItem'

const Components = {
  date: DateItem,
  regular: RegularItem,
  evaluation: EvaluationItem,
  status: StatusItem,
  appointment: AppointmentItem,
  linkable: LinkableItem,
  schedule: ScheduleItem,
  proposal: ProposalItem,
}

const MessageItem = ({ type, ...props }) => {
  const Component = Components[type]

  return <Component {...props} />
}

MessageItem.propTypes = {
  type: PropTypes.string,
}

function MessageList(props) {
  const { threadId, loadMore, referralProposal, scrollToBottom, user, loadLatest } = props
  const items = useSelector(selectThreadItems(threadId))
  const fetching = useSelector(selectThreadFetching(threadId))
  const canLoadMore = useSelector(selectThreadCanLoadMore(threadId))
  const [hasTriggeredFetching, setHasTriggeredFetching] = useState(false)

  useEffect(() => {
    if (items.length === 0) return

    setHasTriggeredFetching(true)
  }, [hasTriggeredFetching, items])

  useLayoutEffect(() => {
    if (!hasTriggeredFetching) return

    scrollToBottom()
  }, [hasTriggeredFetching])

  const length = items.length

  const status = referralProposal.application_status_id

  const isOwn = user?.id === referralProposal?.referred_by

  const itemList = useMemo(
    () =>
      items.reduce((accum, curr, index) => {
        const tmpAccum = accum.slice()
        const before = items[index - 1]
        const hasBefore = !!before

        if (
          (hasBefore && dayjs(before.created_at).format(DATE_FORMAT) !== dayjs(curr.created_at).format(DATE_FORMAT)) ||
          !hasBefore
        ) {
          tmpAccum.push({
            key: dayjs(curr.created_at).format(DATE_FORMAT),
            type: 'date',
            date: dayjs(curr.created_at).format(DATE_FORMAT),
          })
        }

        const data = {
          key: curr.id,
          type: curr.type,
          message: curr,
          loadLatest,
        }

        const hasRecentMessageType = (type) => typeof find(items, { type }, index + 1) !== 'undefined'

        // Disable button if there are recent proposed dates,
        // change status, or schedule
        if (curr.type === MESSAGE_TYPE.appointment || curr.type === MESSAGE_TYPE.schedule) {
          const hasRecentAppointments = hasRecentMessageType(MESSAGE_TYPE.appointment)
          const hasRecentSchedule = hasRecentMessageType(MESSAGE_TYPE.schedule)
          const hasRecentStatus = hasRecentMessageType(MESSAGE_TYPE.status)

          data.buttonDisabled = hasRecentAppointments || hasRecentSchedule || hasRecentStatus
        }

        tmpAccum.push(data)

        if (curr.type === MESSAGE_TYPE.status && curr.meta?.show_proposal) {
          tmpAccum.push({
            key: 'business_proposal',
            type: 'proposal',
            message: curr,
          })
        }

        return tmpAccum
      }, []),
    [items, length, status, isOwn, loadLatest]
  )

  const handleLoadMore = () => {
    if (fetching || itemList.length === 0 || !canLoadMore) return
    loadMore()
  }

  const statusId =
    referralProposal?.type === 'referral'
      ? referralProposal.application_status_id
      : referralProposal?.proposal_status_id

  // Load recent messages when status has changed
  useEffect(() => {
    if (typeof referralProposal?.id === 'undefined' || !hasTriggeredFetching) return

    loadLatest()
  }, [referralProposal?.id, statusId])

  if (typeof referralProposal === 'undefined') return <></>

  return (
    <>
      <Waypoint onEnter={handleLoadMore} />
      {itemList.map((item) => (
        <MessageItem key={item.key} {...item} referralProposal={referralProposal} />
      ))}
    </>
  )
}

MessageList.propTypes = {
  threadId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  loadMore: PropTypes.func.isRequired,
  loadLatest: PropTypes.func.isRequired,
  referralProposal: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  scrollToBottom: PropTypes.func.isRequired,
}

export default MessageList
