import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import Avatar from '@mui/material/Avatar'
import { FormGroup } from 'components/molecules'

const UserProfileInfo = (props) => {
  const { t } = useTranslation()
  const { user, data, allIndustries, allOccupations } = props

  return (
    <main className="main cm cm-w">
      <div className="recommendation__inner">
        <dl className="recommendation__dl">
          <dt className="recommendation__dt">{t('userProfileInvitation.common.profileImageLabel')}</dt>
          <dd className="recommendation__dt">
            <FormGroup className="form__groupFlex form__group-mgt0 form__groupMultiCheck cm__pfImgArea form__group-mgb0">
              <Box className="cm__headerIconArea cm__headerIconArea-mgb16">
                <Avatar
                  className="cm__headerIcon"
                  sx={{ width: 112, height: 112 }}
                  src={
                    data.avatar ? URL.createObjectURL(new Blob([data.avatar], { type: 'images/jpeg' })) : user.avatar
                  }
                  alt="Avatar field"
                />
              </Box>
            </FormGroup>
          </dd>
          <dt className="recommendation__dt">{t('userProfileInvitation.common.companyNameLabel')}</dt>
          <dd className="recommendation__dd">{user.company.company_name}</dd>
          <dt className="recommendation__dt">{t('userProfileInvitation.common.accountTypeLabel')}</dt>
          <dd className="recommendation__dd">{user.groups[0].name}</dd>
          <dt className="recommendation__dt">{t('userProfileInvitation.common.fullNameLabel')}</dt>
          <dd className="recommendation__dd">
            {data.lastname} {data.firstname}
          </dd>
          <dt className="recommendation__dt">{t('userProfileInvitation.confirm.mostGoodIndustryLabel')}</dt>
          <dd className="recommendation__dd">
            {'・'}
            {allIndustries.filter((e) => e.id === data.most_good_industry)[0]
              ? allIndustries.filter((e) => e.id === data.most_good_industry)[0].name
              : null}
          </dd>
          <dt className="recommendation__dt">{t('userProfileInvitation.confirm.specialtyIndustryLabel')}</dt>
          <dd className="recommendation__dd">
            {data.specialty_industry.length > 0
              ? data.specialty_industry.map((item, index) =>
                  data.specialty_industry[index]
                    ? data.specialty_industry[index].map((option) =>
                        allIndustries.filter((e) => e.id === option)[0] ? (
                          <p key={option}>
                            {'・' + allIndustries.filter((e) => e.id === option)[0].name}
                            <br />
                          </p>
                        ) : null
                      )
                    : null
                )
              : null}
          </dd>
          <dt className="recommendation__dt">{t('userProfileInvitation.confirm.mostGoodJobLabel')}</dt>
          <dd className="recommendation__dd">
            {'・'}
            {allOccupations.filter((e) => e.id === data.most_good_job)[0]
              ? allOccupations.filter((e) => e.id === data.most_good_job)[0].name
              : null}
          </dd>
          <dt className="recommendation__dt">{t('userProfileInvitation.confirm.specialtyJobLabel')}</dt>
          <dd className="recommendation__dd">
            {data.specialty_job.length > 0
              ? data.specialty_job.map((item, index) =>
                  data.specialty_job[index]
                    ? data.specialty_job[index].map((option) =>
                        allOccupations.filter((e) => e.id === option)[0] ? (
                          <p key={option}>
                            {'・' + allOccupations.filter((e) => e.id === option)[0].name}
                            <br />
                          </p>
                        ) : null
                      )
                    : null
                )
              : null}
          </dd>
          <dt className="recommendation__dt">{t('userProfileInvitation.common.phoneNumberLabel')}</dt>
          <dd className="recommendation__dd">{data.contact_number}</dd>
          <dt className="recommendation__dt">
            {data.self_introduction ? t('userProfileInvitation.common.profileLabel') : null}
          </dt>
          <dd className="recommendation__dd">{data.self_introduction ? data.self_introduction : null}</dd>
        </dl>
      </div>
    </main>
  )
}

UserProfileInfo.propTypes = {
  user: PropTypes.object,
  data: PropTypes.object,
  allIndustries: PropTypes.array,
  allOccupations: PropTypes.array,
}

export default UserProfileInfo
