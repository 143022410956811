import React, { useEffect } from 'react'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { useSelector, useDispatch } from 'react-redux'

import { getUser } from 'services/auth'

const PREFIX = 'Main'

const classes = {
  root: `${PREFIX}-root`,
  shiftContent: `${PREFIX}-shiftContent`,
  content: `${PREFIX}-content`,
}

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    height: '100%',
    [theme.breakpoints.up('sm')]: {
      paddingTop: 0,
    },
  },

  [`& .${classes.content}`]: {
    height: '100%',
  },
}))

const Preview = (props) => {
  const { children } = props
  const dispatch = useDispatch()
  const auth = useSelector((state) => state.auth)

  useEffect(() => {
    if (!auth.user) {
      dispatch(getUser())
    }
  }, [dispatch, auth.user])

  return (
    <Root
      className={clsx({
        [classes.root]: true,
      })}
    >
      <main className={classes.content}>{children}</main>
    </Root>
  )
}

Preview.propTypes = {
  children: PropTypes.node,
}

export default Preview
