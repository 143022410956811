import React, { useEffect, useState } from 'react'
import { Textarea } from 'components/atoms'
import { ErrorMessage, FormGroup, FormSupArea } from 'components/molecules'
import Step2Comment from './Comment'
import Step2Footer from './Footer'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { useForm, FormProvider } from 'react-hook-form'
import StoreSync from '../StoreSync'
import lodashGet from 'lodash/get'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import { selectJobPostingForm } from 'store/job-posting-form/selectors'
import { getJobPostingDataFromDraft } from 'services/jobPostingForm'

export const initialValue = {
  difficulty_level: '',
  recent_job_info: '',
  interview_info: '',
  special_note: '',
}

export const step2Rules = ({ t }) => {
  return {
    difficulty_level: {
      maxLength: { value: 2000, message: t('maxLength', { length: 2000 }) },
    },
    recent_job_info: {
      maxLength: { value: 2000, message: t('maxLength', { length: 2000 }) },
    },
    interview_info: {
      maxLength: { value: 2000, message: t('maxLength', { length: 2000 }) },
    },
    special_note: {
      maxLength: { value: 2000, message: t('maxLength', { length: 2000 }) },
    },
  }
}

export const mapToStore = (formData) => {
  const get = (key) => lodashGet(formData, key)

  return {
    difficulty_level: get('difficulty_level'),
    recent_job_info: get('recent_job_info'),
    interview_info: get('interview_info'),
    special_note: get('special_note'),
  }
}
function Step2Form(job_vacancy_data) {
  const methods = useForm({ defaultValues: initialValue, mode: 'all' })
  const [isCommentExp1, setIsCommentExp1] = useState(false)
  const [isCommentExp2, setIsCommentExp2] = useState(false)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { t: tv } = useTranslation('translation', { keyPrefix: 'validation' })
  const history = useHistory()
  const job_posting_data = job_vacancy_data?.job_vacancy_data?.job_posting_data
  const singleStep = job_vacancy_data?.job_vacancy_data?.singleStep
  const multipleStep = job_vacancy_data?.job_vacancy_data?.multipleStep
  const [initted, setInitted] = useState(false)
  const initialData = useSelector(selectJobPostingForm)

  const {
    formState: { errors, dirtyFields },
    handleSubmit,
    watch,
    register,
    getValues,
    reset,
  } = methods

  const difficulty_level = watch('difficulty_level')
  const recent_job_info = watch('recent_job_info')
  const interview_info = watch('interview_info')
  const special_note = watch('special_note')
  const rules = step2Rules({ t: tv })
  const handleOnBack = () => {
    onSaveForm(getValues())
    mapToStore(getValues())

    history.push('/new-job-post/step1')
  }

  useEffect(() => {
    dispatch(getJobPostingDataFromDraft())
  }, [])

  const onSubmitData = async (data) => {
    if (singleStep) {
      onSaveForm(data)
      history.push({
        pathname: '/new-job-post/step-conf',
        state: { singleStep: true },
      })
    } else if (multipleStep) {
      onSaveForm(data)
      history.push({
        pathname: '/new-job-post/step3',
        state: { job_posting_data: job_posting_data, multipleStep: multipleStep },
      })
    } else {
      onSaveForm(data)
      history.push('/new-job-post/step3')
    }
  }

  const onSaveForm = (data) => {
    const localStorageData = JSON.parse(localStorage.getItem('job_posting') ?? '{}') || {}
    if (localStorageData !== {}) {
      localStorage.setItem('job_posting', JSON.stringify({ ...localStorageData, ...data }))
    } else {
      localStorage.setItem('job_posting', JSON.stringify({ ...localStorageData, ...data }))
    }
  }

  useEffect(() => {
    if (!isEmpty(initialData) && !initted) {
      setInitted(true)
      reset(
        {
          difficulty_level: initialData.difficulty_level,
          recent_job_info: initialData.recent_job_info,
          interview_info: initialData.interview_info,
          special_note: initialData.special_note,
        },
        { keepDefaultValues: true }
      )
    }
  }, [initialData])

  return (
    <main className="main">
      <div className="main__formArea">
        <FormProvider {...methods}>
          <form className="form" onSubmit={handleSubmit(onSubmitData)}>
            <div className="form__inner">
              <div className="form__group">
                <div className="form__groupWrap agent">
                  <label htmlFor="" className="form__label">
                    {t('jobPosting.step2.agentOnlyInforation')}
                  </label>
                  <p className="form__labelSup">{t('jobPosting.step2.agentSubInfo')}</p>
                  <FormGroup
                    success={!errors.difficulty_level && dirtyFields.difficulty_level}
                    error={!!errors?.difficulty_level}
                    modifier="mgb0 mgt2"
                  >
                    <label className="form__inLabel"> {t('jobPosting.step2.difficultyLabel')}</label>
                    <Textarea
                      cols="30"
                      rows="10"
                      className="form__inputTextArea form__group-mgt inputExp inputCalc"
                      {...register('difficulty_level', rules.difficulty_level)}
                      value={getValues('difficulty_level')}
                      onFocus={() => setIsCommentExp1(true)}
                      onBlur={() => setIsCommentExp1(false)}
                    />
                    <FormSupArea modifier="flex">
                      <ErrorMessage errors={methods.formState.errors} name="difficulty_level" />
                      <FormSupArea.InputSup modifier="end" inputValue={difficulty_level} max={2000} />
                    </FormSupArea>
                  </FormGroup>

                  <FormGroup
                    success={!errors.recent_job_info && dirtyFields.recent_job_info}
                    error={!!errors?.recent_job_info}
                    modifier="mgb0 mgt2"
                  >
                    <label className="form__inLabel"> {t('jobPosting.step2.recentJobInfolabel')}</label>
                    <Textarea
                      cols="30"
                      rows="10"
                      className="form__inputTextArea form__group-mgt inputExp inputCalc"
                      {...register('recent_job_info', rules.recent_job_info)}
                    />
                    <FormSupArea>
                      <ErrorMessage errors={methods.formState.errors} name="recent_job_info" />
                      <FormSupArea.InputSup modifier="end" inputValue={recent_job_info} max={2000} />
                    </FormSupArea>
                  </FormGroup>

                  <FormGroup
                    success={!errors.interview_info && dirtyFields.interview_info}
                    error={!!errors?.interview_info}
                    modifier="mgb0 mgt2"
                  >
                    <label className="form__inLabel"> {t('jobPosting.step2.interviewInfoLabel')}</label>
                    <Textarea
                      cols="30"
                      rows="10"
                      className="form__inputTextArea form__group-mgt inputExp inputCalc"
                      {...register('interview_info', rules.interview_info)}
                    />
                    <FormSupArea>
                      <ErrorMessage errors={methods.formState.errors} name="interview_info" />
                      <FormSupArea.InputSup modifier="end" inputValue={interview_info} max={2000} />
                    </FormSupArea>
                  </FormGroup>

                  <FormGroup
                    success={!errors.special_note && dirtyFields.special_note}
                    error={!!errors?.special_note}
                    modifier="mgb0 mgt2"
                  >
                    <label className="form__inLabel"> {t('jobPosting.step2.specialNote')}</label>
                    <Textarea
                      cols="30"
                      rows="10"
                      className="form__inputTextArea form__group-mgt inputExp inputCalc"
                      {...register('special_note', rules.special_note)}
                      onFocus={() => setIsCommentExp2(true)}
                      onBlur={() => setIsCommentExp2(false)}
                    />
                    <FormSupArea>
                      <ErrorMessage errors={methods.formState.errors} name="special_note" />
                      <FormSupArea.InputSup modifier="end" inputValue={special_note} max={2000} />
                    </FormSupArea>
                  </FormGroup>
                </div>
              </div>
            </div>
            <Step2Footer
              onSubmit={handleSubmit(onSubmitData)}
              onBack={handleOnBack}
              job_vacancy_data={job_vacancy_data}
            />

            <StoreSync defaultValues={initialValue} mapToStore={mapToStore} type="referral" />
          </form>
        </FormProvider>
      </div>
      <Step2Comment focusComment1={isCommentExp1} focusComment2={isCommentExp2} />
    </main>
  )
}

Step2Form.propTypes = {
  job_vacancy_data: PropTypes.any,
}

export default Step2Form
