import React from 'react'

import PropTypes from 'prop-types'
import FormGroupCheck from '../FormGroupCheck'
import { Checkbox } from 'components/atoms'

function CheckListV2({ items, selected, onChange }) {
  const onChangeHandler = onChange || (() => {})
  const handleOnChange = (e) => {
    const index = selected.indexOf(e.target.value)

    if (index === -1) {
      return onChangeHandler(selected.concat(e.target.value))
    }

    onChangeHandler(selected.filter((item) => item !== e.target.value))
  }

  const isSelected = (value) => selected.indexOf(value) !== -1

  return (
    <FormGroupCheck>
      {items.map(({ value, label }, index) => (
        <FormGroupCheck.Item
          label={label}
          key={index}
          type="square"
          inputProps={{
            onChange: handleOnChange,
            value,
            checked: isSelected(value),
          }}
          Input={Checkbox}
        />
      ))}
    </FormGroupCheck>
  )
}

CheckListV2.propTypes = {
  selected: PropTypes.array,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
  onChange: PropTypes.func,
}

export default CheckListV2
