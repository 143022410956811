import React, { useEffect, useRef, useState } from 'react'

import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import useResizeObserver from '@react-hook/resize-observer'
import { useDispatch, useSelector } from 'react-redux'
import findLastIndex from 'lodash/findLastIndex'

import QuestionAndAnswerReplyForm from '../QuestionAndAnswerReplyForm'
import QuestionAndAnswerThreadChildItem from './QuestionAndAnswerThreadChildItem'
import { markAllAnswersAsRead } from 'services/questionAndAnswers'
import { selectQandAFocusItem } from 'store/question-and-answers/selectors'
import { accountType } from 'utils/helper'

const QuestionAndAnswerThreadChild = (props) => {
  const {
    answers,
    isOwn,
    user,
    total,
    onDelete,
    questionId,
    inputRef,
    collapsed,
    setCollapsed,
    isParticipant,
    disabled = false,
  } = props
  const firstAnswerRef = useRef(null)
  const ulRef = useRef(null)
  const [height, setHeight] = useState(0)
  const { t } = useTranslation()
  const [hasMarkedAllAsRead, setHasMarkedAllAsRead] = useState(false)
  const dispatch = useDispatch()
  const focusItem = useSelector(selectQandAFocusItem)

  useEffect(() => {
    if (collapsed) {
      setHeight(firstAnswerRef.current?.offsetHeight)
    } else {
      setHeight(ulRef.current?.offsetHeight + 10)
    }
  }, [answers, collapsed])

  const onMenuClick = () => {
    setCollapsed((prev) => !prev)
  }

  useResizeObserver(firstAnswerRef, (result) => {
    setHeight(result.contentRect.height)
    if (collapsed) {
      setHeight(result.contentRect.height)
    } else {
      setHeight(ulRef.current?.offsetHeight + 10)
    }
  })

  useEffect(() => {
    if (!collapsed && !hasMarkedAllAsRead) {
      setHasMarkedAllAsRead(true)
      dispatch(markAllAnswersAsRead(questionId)).then((res) => {
        if (res.error) {
          // Revert so than the next time it is collapsed it will mark all as read again
          markAllAnswersAsRead(false)
        }
      })
    }
  }, [hasMarkedAllAsRead, collapsed, questionId])

  const isFocus = focusItem?.id === questionId

  useEffect(() => {
    // Automatically open the answers list
    isFocus && setCollapsed(false)
  }, [isFocus])

  const renderReplyIndex = isOwn ? findLastIndex(answers, (answer) => accountType(answer.user) === 'HR') : -1

  const handleReplyClick = () => {
    if (disabled) return
    setCollapsed(false)
    setTimeout(() => inputRef.current.focus(), collapsed ? 180 : 0)
  }

  useResizeObserver(inputRef, () => {
    if (collapsed) return

    setHeight(ulRef.current?.offsetHeight + 10)
  })

  return (
    <>
      <div className="qa__childUlWrap" style={{ height }}>
        <ul className="qa__childUl" ref={ulRef}>
          {answers.map((answer, index) => (
            <QuestionAndAnswerThreadChildItem
              ref={total > 2 ? firstAnswerRef : null}
              key={answer.id}
              answer={answer}
              user={user}
              onDelete={onDelete}
              showReply={isOwn && index === renderReplyIndex}
              onReplyClick={handleReplyClick}
              disabled={disabled}
            />
          ))}

          {isParticipant && (
            <li className="qa__childLi">
              <QuestionAndAnswerReplyForm inputRef={inputRef} questionId={questionId} user={user} disabled={disabled} />
            </li>
          )}
        </ul>
      </div>

      <div className="qa__childDropMenuWrap">
        {total > 2 && (
          <p className={clsx('qa__childDropMenu', { open: !collapsed })} onClick={onMenuClick}>
            {collapsed
              ? t(total > 2 ? 'qAndA.seeOthers' : 'qAndA.seeOthers_one', { count: total - 1 })
              : t('qAndA.close')}
          </p>
        )}
      </div>
    </>
  )
}

QuestionAndAnswerThreadChild.propTypes = {
  answers: PropTypes.array.isRequired,
  user: PropTypes.object,
  total: PropTypes.number,
  isOwn: PropTypes.bool,
  sameCompany: PropTypes.bool,
  onDelete: PropTypes.func.isRequired,
  questionId: PropTypes.number.isRequired,
  jobPostOwn: PropTypes.bool,
  inputRef: PropTypes.object,
  collapsed: PropTypes.bool,
  setCollapsed: PropTypes.func,
  isParticipant: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
}

export default QuestionAndAnswerThreadChild
