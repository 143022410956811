import React from 'react'

import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'

import { selectJobPosting } from 'store/job-postings/selectors'
import WorkPlaces from './WorkPlaces'
import { Button } from 'components/atoms'

function ApplicationRequirements({ accountTypeHR, editable = true }) {
  const job_posting = useSelector(selectJobPosting)
  const { t: tMain } = useTranslation('translation')
  const t = (key, ...args) => tMain(`jobVacancies.applicationRequirements.${key}`, ...args)
  const history = useHistory()
  const referralType = job_posting?.job_posting_type === 'referral'

  const handleClick = () => {
    if (!editable) return
    localStorage.setItem(
      job_posting.job_posting_type === 'referral' ? 'job_posting' : 'job_post_proposal',
      JSON.stringify(job_posting)
    )
    history.push({
      pathname: referralType ? '/new-job-post/step4/' : '/new-job-post-proposal/step3/',
      state: { job_posting_data: job_posting, multipleStep: true },
    })
  }

  return (
    <section className="jobvacancies__cont">
      <h3 className="jobvacancies__title jobvacancies__title-jcsb">
        {t('title')}
        {accountTypeHR && (
          <Button variant="text" onClick={handleClick} disabled={!editable}>
            {t('editButton')}
          </Button>
        )}
      </h3>
      <dl className="jobvacancies__dl">
        <dt className="jobvacancies__dt">{t('recruitmentType')}</dt>
        <dd className="jobvacancies__dd">{job_posting.available_position?.name} </dd>
        <dt className="jobvacancies__dt">{t('employmentStatus')}</dt>
        <dd className="jobvacancies__dd">{job_posting.employment_status}</dd>
        <dt className="jobvacancies__dt">{t('trialPeriod')}</dt>
        <dd className="jobvacancies__dd">
          {job_posting.trial_period === 0 ? t('none') : job_posting.trial_period + t('months')}
        </dd>
        <dt className="jobvacancies__dt">{t('workingConditionsDuringTheTrialPeriod')}</dt>
        <dd className="jobvacancies__dd">{job_posting.trial_period_working_conditions}</dd>
        <dt className="jobvacancies__dt">{t('assignedDepartment')}</dt>
        <dd className="jobvacancies__dd">{job_posting.assignment_department}</dd>
        <dt className="jobvacancies__dt">{t('assignedDepartmentDetails')}</dt>
        <dd className="jobvacancies__dd">{job_posting.assignment_department_details}</dd>
        <dt className="jobvacancies__dt">{t('businessContent')}</dt>
        <dd className="jobvacancies__dd">{job_posting.business_information_description}</dd>
        <dt className="jobvacancies__dt">{t('recruitmentBackground')}</dt>
        <dd className="jobvacancies__dd">{job_posting.recruitment_background}</dd>
        <dt className="jobvacancies__dt">{t('estimatedAnnualIncomeAtTheTimeOfJoiningTheCompany')}</dt>
        <dd className="jobvacancies__dd">
          {tMain('jobPosting.card.min_max_yen', {
            min: job_posting.min_estimated_annual_salary,
            max: job_posting.max_estimated_annual_salary,
          })}
        </dd>
        <dt className="jobvacancies__dt">{t('estimatedMonthlyIncome')}</dt>
        <dd className="jobvacancies__dd">
          {tMain('jobPosting.card.min_max_yen', {
            min: job_posting.min_estimated_monthly_salary,
            max: job_posting.max_estimated_monthly_salary,
          })}
        </dd>
        <dt className="jobvacancies__dt">{t('workLocation')}</dt>
        <dd className="jobvacancies__dd">
          <WorkPlaces workLocations={job_posting.work_locations} />
        </dd>
      </dl>
    </section>
  )
}

ApplicationRequirements.propTypes = {
  accountTypeHR: PropTypes.bool,
  editable: PropTypes.bool,
}

export default ApplicationRequirements
