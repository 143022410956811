import DialogTitle from '@mui/material/DialogTitle'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Alert from '@mui/material/Alert'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { inviteUser } from 'services/users'
import { syncErrorsToForm } from 'utils/helper'
import CompanySelect from '../CompanySelect'
import { AccountPermissionSelect } from 'components'

function InviteUserForm(props) {
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
    setError,
  } = useForm({
    defaultValues: {
      email: '',
      company_id: '',
      permission: '',
    },
  })
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [errorMsg, setErrorMsg] = useState(null)

  const handleSubmitCb = async (data) => {
    setErrorMsg(null)
    const { error, data: responseData } = await dispatch(inviteUser({ ...data }))

    if (error) {
      if (error?.code === 422) {
        syncErrorsToForm(error.error, setError)
      } else {
        setErrorMsg(error?.error || error?.message)
      }
    } else {
      props.onInviteSuccess(responseData)
    }
  }

  const validationRules = {
    email: {
      required: t('auth.required'),
      pattern: {
        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        message: t('auth.email'),
      },
    },
    compay_id: {
      required: t('auth.required'),
    },
    group_id: {
      required: t('auth.required'),
    },
  }

  return (
    <form onSubmit={handleSubmit(handleSubmitCb)} noValidate>
      <DialogTitle>{t('accountManagement.inviteDialogTitle')}</DialogTitle>

      <DialogContent dividers>
        {errorMsg && <Alert severity="error">{errorMsg}</Alert>}

        <Controller
          control={control}
          name="company_id"
          rules={validationRules.compay_id}
          render={({ field: { onChange, value }, fieldState: { invalid, error } }) => (
            <CompanySelect
              onChange={onChange}
              value={value}
              helperText={error?.message}
              error={invalid}
              formControlProps={{ margin: 'dense' }}
            />
          )}
        />

        <Controller
          control={control}
          name="permission"
          rules={validationRules.group_id}
          render={({ field: { onChange, value }, fieldState: { invalid, error } }) => (
            <AccountPermissionSelect
              onChange={onChange}
              value={value}
              helperText={error?.message}
              error={invalid}
              formControlProps={{ margin: 'dense' }}
            />
          )}
        />

        <Controller
          control={control}
          name="email"
          rules={validationRules.email}
          render={({ field: { onChange, value }, fieldState: { error, invalid } }) => (
            <TextField
              margin="dense"
              label={t('accountManagement.emailLabel')}
              type="email"
              fullWidth
              onChange={onChange}
              value={value}
              helperText={error?.message}
              error={invalid}
              sx={{ mb: 2 }}
            />
          )}
        />
      </DialogContent>

      <DialogActions sx={{ px: 3 }}>
        <Button onClick={props.onCancel} variant="outlined" disabled={isSubmitting}>
          {t('accountManagement.cancelButton')}
        </Button>
        <Button type="submit" variant="contained" color="primary" disabled={isSubmitting}>
          {t('accountManagement.inviteButton')}
        </Button>
      </DialogActions>
    </form>
  )
}

InviteUserForm.propTypes = {
  onCancel: PropTypes.func,
  onInviteSuccess: PropTypes.func,
}

export default InviteUserForm
