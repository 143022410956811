import React, { useEffect } from 'react'
import { getEducationalBackground } from 'services/educationalBackground'
import { useDispatch, useSelector } from 'react-redux'
import { FormGroupCheck } from 'components/molecules'
import { useController } from 'react-hook-form'
import { Radio } from 'components/atoms'
import PropTypes from 'prop-types'

const EducationalBackground = (props) => {
  const { rules } = props
  const dispatch = useDispatch()
  const { field } = useController({ name: 'education_background', rules })

  const educationalBackgroundState = useSelector((state) => state.educationalBackground.state)
  const items = useSelector((state) => state.educationalBackground.items)

  useEffect(() => {
    dispatch(getEducationalBackground()).then(() => {})
  }, [])
  const value = field?.value
  return (
    <FormGroupCheck modifier="flex">
      {educationalBackgroundState === 'fulfilled' &&
        items?.map((education, index) => (
          <FormGroupCheck.Item
            key={index}
            Input={Radio}
            inputProps={{
              ...field,
              onChange: () => {
                field.onChange([education.id])
              },
              checked: +value === education.id,
              value: education.id,
              className: 'form__checkItem2',
            }}
            modifier={{ w50: index % 2 === 0, w502: index % 2 !== 0, sp100: true }}
            type="round"
            label={education?.name}
          />
        ))}
    </FormGroupCheck>
  )
}

EducationalBackground.propTypes = {
  rules: PropTypes.object,
}

export default EducationalBackground
