import produce from 'immer'
import { fetchJobPostingTabStats } from 'services/jobPostings'
import { fetchOtherJobs } from 'services/otherJobs'
import * as ActionTypes from './actionTypes'

const initialState = {
  listState: 'pending',
  listError: null,
  items: [],
  params: {
    limit: 10,
    page: 1,
    job_posting_id: null,
    topic: [],
    with_answers: true,
  },
  pagination: {
    total: 0,
    currentPage: 1,
    lastPage: 1,
  },
  saveState: 'pending',
  saveError: null,
}

const reducer = produce((draft, { type, payload }) => {
  switch (type) {
    case ActionTypes.UPDATE_OTHER_JOBS:
      draft.params = { ...draft.params, ...payload }
      break
    case fetchOtherJobs.pending:
      draft.listState = 'pending'
      break
    case fetchOtherJobs.fulfilled:
      draft.listState = 'fulfilled'
      draft.items = payload.data
      draft.pagination = {
        total: +payload.meta.total,
        lastPage: +payload.meta.lastPage,
        currentPage: +payload.meta.currentPage,
      }
      break
    case fetchOtherJobs.failure:
      draft.listState = 'failure'
      draft.listError = payload.message
      break
    case fetchJobPostingTabStats.fulfilled:
      if (payload.tabs !== 'otherjobs') draft.pagination.total = payload.stats.otherjobs
      break
    default:
      return draft
  }
}, initialState)

export default reducer
