import produce from 'immer'

const initialValue = {
  header: {
    blockSize: 0,
    inlineSize: 0,
  },
}

const reducer = produce((draft, { type, payload }) => {
  switch (type) {
    case 'SET_HEADER_SIZE':
      draft.header.blockSize = payload.blockSize
      draft.header.inlineSize = payload.inlineSize
      break
    default:
      return draft
  }
}, initialValue)

export default reducer
