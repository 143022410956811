import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Stack from '@mui/material/Stack'
import React, { useEffect, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { selectAuthUser } from 'store/auth/selectors'
import { AvatarField, ButtonLink } from 'components'
import { updateProfile } from 'services/users'
import { syncErrorsToForm } from 'utils/helper'
import PropTypes from 'prop-types'
import { ADTYPES } from 'utils/constants'
import { useHistory } from 'react-router-dom'
import IndustryInputField from '../IndustryInputField'
import OccupationInputField from '../OccupationInputField'
import AvatarDefaultImg from 'assets/img/unsplash_8YG31Xn4dSw.png'

const makeValidation = (t) => ({
  lastname: {
    required: t('auth.required'),
  },
  firstname: {
    required: t('auth.required'),
  },
  email: {
    required: t('auth.required'),
  },
  contact_number: {
    required: t('auth.required'),
  },
  industries: {
    required: t('auth.required'),
  },
  occupations: {
    required: t('auth.required'),
  },
  avatar: {
    required: t('auth.required'),
  },
  self_introduction: {
    required: t('auth.required'),
    maxLength: {
      value: 2000,
      message: t('validation.maxLength', { length: 2000, field: t('userProfile.selfIntroLabel') }),
    },
  },
})

function EditProfileForm(props) {
  const userProfile = useSelector(selectAuthUser)
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()
  const [initted, setInitted] = useState(false)
  const {
    control,
    reset,
    formState: { isSubmitting },
    handleSubmit,
    setError,
  } = useForm({
    defaultValues: {
      lastname: '',
      firstname: '',
      email: '',
      contact_number: '',
      industries: [],
      occupations: [],
      self_introduction: '',
      avatar: '',
    },
  })
  const isParent = userProfile?.groups[0]?.is_parent
  const validation = useMemo(() => makeValidation(t), [t])
  const isADUser = (userProfile?.groups || []).reduce((truthy, group) => truthy || ADTYPES.includes(group.id), false)

  useEffect(() => {
    if (!initted && userProfile) {
      setInitted(true)
      reset({
        lastname: userProfile.lastname ?? '',
        firstname: userProfile.firstname ?? '',
        email: userProfile.email ?? '',
        contact_number: userProfile.contact_number ?? '',
        industries: userProfile.industries?.map(({ id }) => id) ?? [],
        occupations: userProfile.occupations?.map(({ id }) => id) ?? [],
        self_introduction: userProfile.self_introduction ?? '',
        avatar: userProfile.avatar ?? '',
      })
    }
  }, [userProfile, reset, initted])

  const handleSubmitCb = async (data) => {
    try {
      await dispatch(updateProfile(data))
      history.push('/profile')
    } catch (e) {
      if (e.code === 422) {
        syncErrorsToForm(e.error, setError)
      }
    }
  }

  const readonly = props.type === 'view'

  return (
    <form onSubmit={handleSubmit(handleSubmitCb)}>
      <Grid container columnSpacing={4} rowSpacing={2}>
        <Grid item xs={6}>
          <Controller
            control={control}
            rules={validation.lastname}
            name="lastname"
            render={({ field, fieldState }) => (
              <TextField
                margin="dense"
                label={t('userProfile.lastNameLabel')}
                onChange={field.onChange}
                value={field.value}
                helperText={fieldState.error?.message}
                error={fieldState.invalid}
                fullWidth
                disabled={readonly}
              />
            )}
          />
          <Controller
            control={control}
            rules={validation.firstname}
            name="firstname"
            render={({ field, fieldState }) => (
              <TextField
                margin="dense"
                label={t('userProfile.firstNameLabel')}
                onChange={field.onChange}
                value={field.value}
                helperText={fieldState.error?.message}
                error={fieldState.invalid}
                fullWidth
                disabled={readonly}
              />
            )}
          />
          <Controller
            control={control}
            rules={validation.email}
            name="email"
            render={({ field, fieldState }) => (
              <TextField
                margin="dense"
                label={t('accountManagement.emailLabel')}
                onChange={field.onChange}
                value={field.value}
                helperText={fieldState.error?.message}
                error={fieldState.invalid}
                fullWidth
                disabled={readonly}
              />
            )}
          />
          <Controller
            control={control}
            rules={validation.contact_number}
            name="contact_number"
            render={({ field, fieldState }) => (
              <TextField
                margin="dense"
                label={t('userProfile.contactNumberLabel')}
                onChange={field.onChange}
                value={field.value}
                helperText={fieldState.error?.message}
                error={fieldState.invalid}
                fullWidth
                disabled={readonly}
              />
            )}
          />
          {isParent && (
            <>
              <TextField
                margin="dense"
                label={t('userProfile.companyNameLabel')}
                value={userProfile?.company?.company_name}
                disabled={true}
                fullWidth
              />
              <TextField
                margin="dense"
                label={t('userProfile.accountTypeLabel')}
                value={isParent ? t('userPermission.parent') : t('userPermission.child')}
                disabled={true}
                fullWidth
              />
            </>
          )}
          {isADUser && (
            <>
              <Controller
                control={control}
                rules={validation.industries}
                name="industries"
                render={({ field, fieldState }) => (
                  <IndustryInputField
                    onChange={field.onChange}
                    helperText={fieldState.error?.message}
                    value={field.value}
                    error={fieldState.invalid}
                    disabled={readonly}
                  />
                )}
              />

              <Controller
                control={control}
                rules={validation.occupations}
                name="occupations"
                render={({ field, fieldState }) => (
                  <OccupationInputField
                    onChange={field.onChange}
                    helperText={fieldState.error?.message}
                    value={field.value}
                    error={fieldState.invalid}
                    disabled={readonly}
                  />
                )}
              />
            </>
          )}
          <Controller
            control={control}
            rules={validation.self_introduction}
            name="self_introduction"
            render={({ field, fieldState }) => (
              <TextField
                margin="dense"
                multiline
                minRows={4}
                label={t('userProfile.selfIntroLabel')}
                onChange={field.onChange}
                value={field.value}
                helperText={fieldState.error?.message}
                error={fieldState.invalid}
                fullWidth
                disabled={readonly}
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            control={control}
            rules={validation.avatar}
            name="avatar"
            render={({ field, fieldState }) => (
              <AvatarField
                label={t('userProfile.avatarLabel')}
                src={userProfile?.avatar}
                onChange={field.onChange}
                helperText={fieldState.error?.message}
                error={fieldState.invalid}
                disabled={readonly}
                hidePencil={readonly}
                defaultSrc={AvatarDefaultImg}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} container justifyContent="center">
          <Stack direction="row" spacing={2}>
            {props.type === 'edit' ? (
              <>
                <ButtonLink to="/profile" variant="outlined">
                  {t('accountManagement.cancelButton')}
                </ButtonLink>
                <Button type="submit" variant="contained" color="primary" disabled={isSubmitting}>
                  {t('userProfile.updateButton')}
                </Button>
              </>
            ) : (
              props.actionButtons
            )}
          </Stack>
        </Grid>
      </Grid>
    </form>
  )
}

EditProfileForm.propTypes = {
  type: PropTypes.oneOf(['view', 'edit']),
  actionButtons: PropTypes.node,
}

EditProfileForm.defaultProps = {
  type: 'edit',
}

export default EditProfileForm
