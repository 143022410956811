import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { BlockEl } from 'components/atoms'

function FormGroup(props) {
  const { children, required, success, error, modifier, className, blockElClass = 'form__group' } = props

  return (
    <BlockEl blockElClass={blockElClass} className={clsx({ required, success, error }, className)} modifier={modifier}>
      {children}
    </BlockEl>
  )
}

FormGroup.propTypes = {
  children: PropTypes.any,
  required: PropTypes.bool,
  success: PropTypes.bool,
  error: PropTypes.bool,
  // oneOf: mgb0
  modifier: PropTypes.any,
  className: PropTypes.string,
  blockElClass: PropTypes.string,
}

export default FormGroup
