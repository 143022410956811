import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectJobPosting } from '../../../../store/job-postings/selectors'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { fetchJobPosting } from 'services/jobPostings'
import { NewlineText } from 'components/atoms'

function CorporateInfoCard({ jobPostingId }) {
  const jobPosting = useSelector(selectJobPosting)
  const company = jobPosting?.company
  const dispatch = useDispatch()
  const { t } = useTranslation()

  useEffect(() => {
    if (jobPostingId) {
      dispatch(
        fetchJobPosting(jobPostingId, {
          with: 'company',
        })
      )
    }
  }, [jobPostingId])

  const concatIndustries = (jobPosting?.company?.industries || []).map(({ name }) => name).join('\n')

  return (
    <>
      {company && (
        <dl className="corporateinfo__dl">
          <dt className="corporateinfo__dt">{t('corporateInfo.companyName')}</dt>
          <dd className="corporateinfo__dd">{company.company_name}</dd>
          <dt className="corporateinfo__dt">{t('corporateInfo.companyAddress')}</dt>
          <dd className="corporateinfo__dd">{company.address}</dd>
          <dt className="corporateinfo__dt">{t('corporateInfo.phoneNum')}</dt>
          <dd className="corporateinfo__dd">{company.telephone_number}</dd>
          <dt className="corporateinfo__dt">{t('corporateInfo.initialPublicOffering')}</dt>
          <dd className="corporateinfo__dd">{company.ipo}</dd>
          <dt className="corporateinfo__dt">{t('corporateInfo.industry')}</dt>
          <dd className="corporateinfo__dd">
            <NewlineText text={concatIndustries} />
          </dd>
          <dt className="corporateinfo__dt">{t('corporateInfo.representativeName')}</dt>
          <dd className="corporateinfo__dd">{company.last_name + ' ' + company.first_name}</dd>
          <dt className="corporateinfo__dt">{t('corporateInfo.yrOfEstablishment')}</dt>
          <dd className="corporateinfo__dd">
            {t('corporateInfo.yrOfEstablishmentSuffix', { year: company.year_established })}
          </dd>
          <dt className="corporateinfo__dt">{t('corporateInfo.closingMonth')}</dt>
          <dd className="corporateinfo__dd">
            {t('corporateInfo.closingMonthVal', { months: company.account_settlement_month })}
          </dd>
          <dt className="corporateinfo__dt">{t('corporateInfo.companyHP')}</dt>
          <dd className="corporateinfo__dd">
            <a href={company.website_url} className="corporateinfo__ddLink" target="_blank" rel="noreferrer">
              {company.website_url}
            </a>
          </dd>
          <dt className="corporateinfo__dt">{t('corporateInfo.numOfEmployees')}</dt>
          <dd className="corporateinfo__dd">
            {t('corporateInfo.numOfEmployeesCount', { count: company.employee_count })}
          </dd>
          <dt className="corporateinfo__dt">{t('corporateInfo.companyProfile')}</dt>
          <dd className="corporateinfo__dd">{company.overview}</dd>
          <dt className="corporateinfo__dt">{t('corporateInfo.businessContent')}</dt>
          <dd className="corporateinfo__dd">{company.description}</dd>
        </dl>
      )}
    </>
  )
}

CorporateInfoCard.propTypes = {
  jobPostingId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default CorporateInfoCard
