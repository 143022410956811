import React from 'react'

import Grid from '@mui/material/Grid'
import { styled } from '@mui/material/styles'
import { Typography } from '@mui/material'
import PropTypes from 'prop-types'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import RotateRightIcon from '@mui/icons-material/RotateRight'
import PrintIcon from '@mui/icons-material/Print'
import Button from '@mui/material/Button'
import { saveAs } from 'file-saver'

const PREFIX = 'ApplicantFile'

const classes = {
  topMenu: `${PREFIX}-topMenu`,
  action_icons: `${PREFIX}-action_icons`,
  label: `${PREFIX}-label`,
  pageCount: `${PREFIX}-pageCount`,
}

const StyledRoot = styled(Grid)(({ theme }) => ({
  position: 'fixed',
  top: '0',
  left: '0',
  right: '0',
  backgroundColor: '#424242',
  color: 'white',
  fontWeight: 'bold',
  '& Typography': {
    color: 'white',
  },
  zIndex: 11,

  [`& .${classes.action_icons}`]: {
    justifyContent: 'right',
    textAlign: 'right',
    '& Button': {
      color: 'white',
      width: '1px',
    },
  },

  [`& .${classes.label}`]: {
    color: 'white',
    fontWeight: 'bold',
    display: 'inline-block',
    padding: theme.spacing(1, 0),
  },
  [`& .${classes.pageCount}`]: {
    color: 'white',
    textAlign: 'center',
    fontWeight: 'bold',
    padding: theme.spacing(1, 0),
  },
}))

const FilePreviewToolbar = (props) => {
  const { file, numPages, filePath, pageNumber, disabled } = props

  const refreshPage = () => {
    window.location.reload()
  }

  const printFile = () => {
    const blobURL = URL.createObjectURL(filePath)

    const iframe = document.createElement('iframe') //load content in an iframe to print later
    document.body.appendChild(iframe)

    iframe.style.display = 'none'
    iframe.src = blobURL
    iframe.onload = function () {
      setTimeout(function () {
        iframe.focus()
        iframe.contentWindow.print()
      }, 1)
    }
  }

  const handleDownload = () => {
    saveAs(filePath, 'JobPosting.pdf')
  }

  return (
    <StyledRoot container className={classes.root}>
      <Grid item sm={4}>
        {file ? <HighlightOffIcon fontSize="5px" /> : null}
        <Typography className={classes.label}>{file}</Typography>
      </Grid>
      <Grid item sm={4}>
        <Typography className={classes.pageCount}>{`${pageNumber} / ${numPages ?? '-'}`}</Typography>
      </Grid>
      <Grid item sm={4} className={classes.action_icons} title={'Refresh Page'}>
        <Button onClick={refreshPage}>
          <RotateRightIcon />
        </Button>
        <Button disabled={disabled} onClick={handleDownload} title={'Download'}>
          <FileDownloadIcon />
        </Button>
        <Button disabled={disabled} onClick={printFile} title={'Print'}>
          <PrintIcon />
        </Button>
      </Grid>
    </StyledRoot>
  )
}
FilePreviewToolbar.propTypes = {
  file: PropTypes.string,
  numPages: PropTypes.number,
  filePath: PropTypes.object,
  pdfPath: PropTypes.any,
  id: PropTypes.string,
  pageNumber: PropTypes.number,
  disabled: PropTypes.bool.isRequired,
}
export default FilePreviewToolbar
