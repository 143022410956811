import React from 'react'

import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import identity from 'lodash/identity'
import noop from 'lodash/noop'

import { BlockEl } from 'components/atoms'
import { FormGroupCheck } from 'components/molecules'

function SearchCondition({
  mb,
  label,
  getValue = identity,
  onChecklistChange = noop,
  onClick,
  selected = [],
  checklist = [],
}) {
  const { t } = useTranslation()

  const handleChange = (e) => {
    const val = getValue(e.target.value)

    if (isSelected(val)) {
      return onChecklistChange(selected.filter((item) => item !== val))
    }

    onChecklistChange(selected.concat(val))
  }

  const isSelected = (value) => {
    return selected.includes(getValue(value))
  }

  return (
    <BlockEl bec="search__conditon" modifier={{ mb }}>
      <div className="search__conditonTitleArea">
        <div className="search__conditonTitle">{label}</div>
        <div className="search__conditonBtn" onClick={onClick}>
          {t('jobPosting.search.select')}
        </div>
      </div>
      {checklist.map((item, i) => (
        <div className="search__conditonCheckItemWrap" key={i} style={{ display: 'block' }}>
          <h3 className="search__conditonCheckTitle">{item.label}</h3>
          <div className="search__conditonCheckArea">
            <FormGroupCheck className="search__conditonCheckItem">
              {item.children.map((child, j) => (
                <FormGroupCheck.Checkbox key={j} {...child} checked={isSelected(child.value)} onChange={handleChange} />
              ))}
            </FormGroupCheck>
          </div>
        </div>
      ))}
    </BlockEl>
  )
}

SearchCondition.propTypes = {
  label: PropTypes.string.isRequired,
  mb: PropTypes.bool,
  /**
   * Fires when select text is clicked.
   */
  onClick: PropTypes.func,
  /**
   * Fires when checklist has changed
   */
  onChecklistChange: PropTypes.func,
  /**
   * Get the real checklist item value
   */
  getValue: PropTypes.func,
  /**
   * An array of selected checklist
   */
  selected: PropTypes.array,
  /**
   * An array of checklist
   */
  checklist: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      children: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.any.isRequired,
          label: PropTypes.string.isRequired,
        }).isRequired
      ),
    })
  ),
}

export default SearchCondition
