import React, { useEffect } from 'react'
import { getEnglishLevel } from 'services/englishLevel'
import { useDispatch, useSelector } from 'react-redux'
import { FormGroupCheck } from 'components/molecules'
import { useController } from 'react-hook-form'
import { Radio } from 'components/atoms'
import PropTypes from 'prop-types'

const EnglishLevel = (props) => {
  const { rules } = props

  const dispatch = useDispatch()
  const { field } = useController({ name: 'english_level', rules })
  const englishLevelState = useSelector((state) => state.englishLevel.state)
  const items = useSelector((state) => state.englishLevel.items)

  useEffect(() => {
    dispatch(getEnglishLevel()).then(() => {})
  }, [])
  const value = field?.value
  return (
    <FormGroupCheck modifier="flex">
      {englishLevelState === 'fulfilled' &&
        items?.map((englishLevel, index) => (
          <FormGroupCheck.Item
            key={index}
            Input={Radio}
            inputProps={{
              ...field,
              onChange: () => {
                field.onChange([englishLevel.id])
              },
              checked: +value === englishLevel.id,
              value: englishLevel.id,
              className: 'form__checkItem2',
            }}
            modifier={{ w50: index % 2 === 0, w502: index % 2 !== 0, sp100: true }}
            type="round"
            label={englishLevel.name}
          />
        ))}
    </FormGroupCheck>
  )
}

EnglishLevel.propTypes = {
  rules: PropTypes.object,
}

export default EnglishLevel
