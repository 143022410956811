import React, { useEffect, useState } from 'react'
import qs from 'query-string'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useHistory, Link } from 'react-router-dom'
import { resetPassword, getUser } from 'services/auth'
import { Button, Input } from 'components/atoms'
import { ErrorMessage, FormGroup, FormSupArea } from 'components/molecules'
import { showNotification } from 'store/notification/actionCreators'
import { useAuth } from 'utils/hooks'
import {
  makeFormGroupStateGetter,
  isCompanyAccountNeedSetup,
  isUserAccountNeedSetup,
  isHrUserAccountNeedSetup,
} from 'utils/helper'

function ResetPasswordForm() {
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()
  const { user } = useAuth()
  const [success, setSuccess] = useState(false)
  const auth = useSelector((state) => state.auth)
  const isAuthenticated = auth.isAuthenticated

  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
    setError,
    getValues,
    setValue,
    getFieldState,
  } = useForm({
    defaultValues: { password: '', password_confirmation: '' },
    mode: 'all',
  })
  const { t } = useTranslation()

  useEffect(() => {
    const token = qs.parse(location.search)?.token

    // Redirect if not signed in and token is not set
    if (!token && !isAuthenticated) history.replace('/')

    setValue('token', token)
  }, [location.search])

  const validationRules = {
    password: {
      required: {
        value: String,
        message: t('auth.required'),
      },
      minLength: {
        value: 8,
        message: t('auth.password.strong'),
      },
      pattern: {
        // 8 Characters, 1 Uppercase, 1 Special Character
        value: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
        message: t('auth.password.strong'),
      },
    },
    password_confirmation: {
      // custom validation rule
      validate: (value) => {
        return value === getValues('password') || t('auth.password.confirm')
      },
    },
  }

  const handleError = (errorRes) => {
    const { code, error } = errorRes
    // handle API validation error
    if (code === 422 && error) {
      if ('password' in error) {
        setError('password', { message: error.password })
      }

      if ('token' in error) {
        dispatch(showNotification(error.token[0], { type: 'danger' }))
      }
    }
  }

  const handleSuccess = async () => {
    if (isAuthenticated) {
      await dispatch(getUser()).then(() => {
        const redirectTo = isCompanyAccountNeedSetup(user)
          ? '/invitation/company-account/create'
          : isUserAccountNeedSetup(user)
          ? '/invitation/user-profile/create'
          : isHrUserAccountNeedSetup(user)
          ? '/user/create'
          : ''
        history.push(redirectTo)
      })
    } else {
      setSuccess(true)
    }
  }

  const onSubmit = (data) => dispatch(resetPassword(data, isAuthenticated)).then(handleSuccess, handleError)

  const { byName } = makeFormGroupStateGetter(getFieldState)

  return (
    <div className="adminLogin__mainWrap">
      <div className="selection__mainTitleFlex">
        <h3 className="selection__mainTitle">{t('forgot.forgotPassword')}</h3>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        {success ? (
          <div>
            <p className="mgb">{t('reset.success')}</p>

            <Link to="/sign-in">{t('reset.clickToLogin')}</Link>
          </div>
        ) : (
          <>
            <div className="adminLogin__inputWrap">
              <p className="adminLogin__inputTtl">{t('forgot.enterPass')}</p>
              <Input type="password" {...register('password', validationRules.password)} />

              <FormGroup modifier="mgb0 mgt" {...byName('password')}>
                <FormSupArea>
                  <ErrorMessage name="password" errors={errors} />
                </FormSupArea>
              </FormGroup>

              <p className="adminLogin__inputTtl">{t('forgot.enterPassAgain')}</p>
              <Input type="password" {...register('password_confirmation', validationRules.password_confirmation)} />

              <FormGroup modifier="mgb0 mgt" {...byName('password_confirmation')}>
                <FormSupArea>
                  <ErrorMessage name="password_confirmation" errors={errors} />
                </FormSupArea>
              </FormGroup>
            </div>

            <div className="adminLogin__btnWrap">
              <Button type="submit" disabled={isSubmitting} variant={isSubmitting ? 'shadowDissable' : 'shadow'}>
                {t('forgot.setIt')}
              </Button>
            </div>
          </>
        )}
      </form>
    </div>
  )
}

export default ResetPasswordForm
