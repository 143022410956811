import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { FormProvider, Controller, useForm } from 'react-hook-form'
import { useHistory, useLocation, Link } from 'react-router-dom'
import logo from 'assets/img/logo.svg'
import { Input, Label, FormAlert, Textarea } from 'components/atoms'
import { useTranslation } from 'react-i18next'
import { FormGroup, FormSupArea, Header } from 'components/molecules'
import { AvatarField } from 'components'
import { isParent, makeFormGroupStateGetter } from 'utils/helper'
import Footer from './Footer'
import AvatarDefaultImg from 'assets/img/unsplash_8YG31Xn4dSw.png'

const defaultValues = {
  lastname: '',
  firstname: '',
  user_profile: '',
  avatar: AvatarDefaultImg,
}

const UserAccountForm = (props) => {
  const history = useHistory()
  const location = useLocation()
  const { t } = useTranslation()
  const { user, mode, hasData } = props
  const [initted, setInitted] = useState(false)
  const [title, setTitle] = useState('')
  const [isSourcePageEdit, setIsSourcePageEdit] = useState(false)

  const methods = useForm({ defaultValues, mode: 'all' })

  const {
    register,
    handleSubmit,
    reset,
    getFieldState,
    formState: { errors },
  } = methods

  const userType = isParent(user) ? t('userPermission.parent') : t('userPermission.child')

  const rules = {
    user_profile: {
      maxLength: { value: 5000, message: t('validation.maxLength', { length: 5000 }) },
    },
    firstname: {
      required: t('validation.required'),
    },
    lastname: {
      required: t('validation.required'),
    },
  }

  const { byName, and, fromFieldState } = makeFormGroupStateGetter(getFieldState)

  useEffect(() => {
    if (initted || !hasData) return

    const storedData = JSON.parse(localStorage.getItem('user_account_data') ?? '{}') || {}

    const newReset = {
      lastname: storedData?.lastname ?? defaultValues.lastname,
      firstname: storedData?.firstname ?? defaultValues.firstname,
      user_profile: storedData?.user_profile ?? defaultValues.user_profile,
      avatar:
        storedData?.avatar && mode === 'isEdit'
          ? storedData?.avatar
          : location?.state?.avatar
          ? location?.state?.avatar
          : defaultValues.avatar,
    }
    setInitted(true)
    reset(newReset, {
      keepDefaultValues: true,
      keepDirtyValues: false,
      keepValues: false,
    })
  }, [initted, hasData])

  useEffect(() => {
    if (location.pathname?.substring(0, 10) === '/user/edit') {
      setTitle(t('editUserAccount.title'))
      setIsSourcePageEdit(true)
    } else {
      setTitle(t('createUserAccount.title'))
    }
  }, [location])

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={handleSubmit(({ avatar = null, ...data }) => {
          const oldData = JSON.parse(localStorage.getItem('user_account_data') ?? '{}') || {}
          localStorage.setItem('user_account_data', JSON.stringify({ ...oldData, ...data }))
          history.push({
            state: {
              avatar: avatar,
              mode: mode,
              isSourcePageEdit: isSourcePageEdit,
            },
            pathname: '/user/confirm',
          })
        })}
      >
        <div>
          {mode === 'isEdit' ? (
            <header className="header header-spTop">
              <Link to="/profile" className="header__backBtn">
                {t('userProfile.toEdit.backButton')}
              </Link>
            </header>
          ) : (
            <Header className="header header-jc header-spTop">
              <div className="header__logoArea">
                <h1 className="header__logoWrap">
                  <img src={logo} alt="HuuNo" className="header__logo" width="72" height="25"></img>
                </h1>
              </div>
            </Header>
          )}

          <main className="main cm cm-pdb">
            <div className="cm__main">
              <div className="form__inner form__inner-sppd">
                <h1 className="cm__title">{title}</h1>
                <div className="form__group form__group-mgb0 form__group-spMgb16">
                  <Label>{t('createUserAccount.profileImage')}</Label>
                  <div className="form__groupFlex form__group-mgt form__groupMultiCheck cm__pfImgArea">
                    <Controller
                      name="avatar"
                      render={({ field }) => (
                        <AvatarField
                          src={field.value}
                          imgClass="cm__headerIconArea cm__headerIconArea-mgb16"
                          uploadLabel={t('createUserAccount.uploadLbl')}
                          onChange={field.onChange}
                          defaultSrc={defaultValues.avatar}
                        />
                      )}
                    />
                  </div>
                  <div className="form__supArea form__supArea-flex">
                    <p className="form__alert">{t('common.plsEnter')}</p>
                  </div>
                </div>
                <div className="form__group form__group-inSup2">
                  <Label modifier="mgt0">{t('createUserAccount.company')}</Label>
                  <p className="cm__formText">{user.company?.company_name}</p>
                </div>
                <div className="form__group form__group-inSup2">
                  <Label>{t('createUserAccount.accountType')}</Label>
                  <p className="cm__formText">{userType}</p>
                </div>
                {/** user fullname */}
                <FormGroup {...and(byName('firstname'), byName('lastname'))} modifier="mgb0 spMgb16" required>
                  <Label>{t('createUserAccount.fullName')}</Label>
                  <div className="form__groupFlex form__group-mgt form__groupMultiCheck">
                    <div>
                      <Input type="text" {...register('lastname', rules.lastname)} modifier="w96px mgr" />
                      <p className="form__inputSup2">{t('createUserAccount.lastName')}</p>
                    </div>
                    <div>
                      <Input type="text" {...register('firstname', rules.firstname)} modifier="w96px mgr" />
                      <p className="form__inputSup2">{t('createUserAccount.firstName')}</p>
                    </div>
                  </div>
                  <FormSupArea flex>
                    <FormAlert message={errors.firstname?.message || errors.lastname?.message} />
                  </FormSupArea>
                </FormGroup>
                {/** user profile */}
                <Controller
                  render={({ field, fieldState }) => (
                    <FormGroup modifier="mgb1" error={fromFieldState(fieldState).error}>
                      <Label>{t('createUserAccount.profile')}</Label>
                      <Textarea {...field} cols="30" rows="10" className="form__group-mgt" modifier="lh2em" />
                      <FormSupArea modifier="flex">
                        <FormSupArea.InputSup
                          modifier="end"
                          inputValue={field.value}
                          max={rules.user_profile.maxLength.value}
                        />
                      </FormSupArea>
                    </FormGroup>
                  )}
                  rules={rules.user_profile}
                  name="user_profile"
                />
              </div>
            </div>
          </main>
          <Footer />
        </div>
      </form>
    </FormProvider>
  )
}

UserAccountForm.propTypes = {
  user: PropTypes.object,
  mode: PropTypes.string,
  location: PropTypes.object,
  hasData: PropTypes.bool,
}

export default UserAccountForm
