import palette from '../palette'

export default {
  styleOverrides: {
    root: {
      '&$selected': {
        backgroundColor: palette.background.default,
      },
      '&$hover': {
        '&:hover': {
          backgroundColor: palette.background.default,
        },
      },
    },
  },
}
