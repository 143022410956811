import * as ActionTypes from './actionTypes'

export const actionSyncJobPostingForm = (payload) => ({ type: ActionTypes.SYNC_JOB_POSTING_FORM, payload })

export const actionSetJobPostingForm = (payload) => ({ type: ActionTypes.SET_JOB_POSTING_FORM, payload })

export const actionSyncJobPostingProposalForm = (payload) => ({
  type: ActionTypes.SYNC_JOB_POSTING_PROPOSAL_FORM,
  payload,
})

export const actionSetJobPostingProposalForm = (payload) => ({
  type: ActionTypes.SET_JOB_POSTING_PROPOSAL_FORM,
  payload,
})

export const actionResetJobPostingForm = () => ({ type: ActionTypes.RESET_JOB_POSTING_FORM })
