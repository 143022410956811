import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import find from 'lodash/find'
import { useDispatch, useSelector } from 'react-redux'
import { selectFlattenedIndustries } from 'store/industries/selectors'
import { getIndustries } from 'services/industries'
import { NewlineText } from 'components/atoms'
import { notEmpty } from 'utils/helper'

const CompanyAccountInfo = (props) => {
  const { t } = useTranslation()
  const { user, data } = props
  const dispatch = useDispatch()
  const allIndustries = useSelector(selectFlattenedIndustries)

  useEffect(() => {
    dispatch(getIndustries())
  }, [dispatch])

  const formIndustries = data?.industries || []
  const concatIndustries = formIndustries
    .map((id) => find(allIndustries, { id })?.name)
    .filter(notEmpty)
    .join('\n')

  return (
    <main className="invitation_main cm-pdb cm-confirm">
      <div className="recommendation__inner">
        <dl className="recommendation__dl">
          <dt className="recommendation__dt">{t('companyAccount.confirm.nameLabel')}</dt>
          <dd className="recommendation__dd">{user.company.company_name}</dd>
          <dt className="recommendation__dt">{t('companyAccount.common.numberLabel')}</dt>
          <dd className="recommendation__dd">{user.company.telephone_number}</dd>
          <dt className="recommendation__dt">{t('companyAccount.common.addressLabel')}</dt>
          <dd className="recommendation__dd">{user.company.address}</dd>
          <dt className="recommendation__dt">{t('companyAccount.common.initialPublicOfferingLabel')}</dt>
          <dd className="recommendation__dd">{user.company.ipo}</dd>
          <dt className="recommendation__dt">{t('companyAccount.common.respresentativeNameLabel')}</dt>
          <dd className="recommendation__dd">
            {data.lastname} {data.firstname}
          </dd>
          <dt className="recommendation__dt">{t('companyAccount.common.companyIndustryLabel')}</dt>
          <dd className="recommendation__dd">
            <NewlineText text={concatIndustries} />
          </dd>
          <dt className="recommendation__dt">{t('companyAccount.common.yearOfEstablishmentLabel')}</dt>
          <dd className="recommendation__dd">
            {data.year_of_establishment}
            {t('companyAccount.create.yearOfEstablishmentUnitLabel')}
          </dd>
          <dt className="recommendation__dt">{t('companyAccount.common.closingMonthLabel')}</dt>
          <dd className="recommendation__dd">
            {data.closing_month}
            {t('companyAccount.create.closingMonthUnitLabel')}
          </dd>
          <dt className="recommendation__dt">{data.hp ? t('companyAccount.common.companyHPLabel') : null}</dt>
          <dd className="recommendation__dd">{data.hp ? data.hp : null}</dd>
          <dt className="recommendation__dt">{t('companyAccount.common.numberOfEmployeesLabel')}</dt>
          <dd className="recommendation__dd">
            {data.number_of_employees}
            {t('companyAccount.create.man')}
          </dd>
          <dt className="recommendation__dt">
            {data.company_profile ? t('companyAccount.common.companyProfileLabel') : null}
          </dt>
          <dd className="recommendation__dd">{data.company_profile ? data.company_profile : null}</dd>
          <dt className="recommendation__dt">
            {data.business_content ? t('companyAccount.common.businessContentLabel') : null}
          </dt>
          <dd className="recommendation__dd">{data.business_content ? data.business_content : null}</dd>
        </dl>
      </div>
    </main>
  )
}

CompanyAccountInfo.propTypes = {
  user: PropTypes.object,
  data: PropTypes.object,
}

export default CompanyAccountInfo
