import React from 'react'
import PropTypes from 'prop-types'
import { useInvalidFieldCount } from 'utils/hooks'

function InvalidFieldCount({ render, ...props }) {
  const result = useInvalidFieldCount({ ...props })

  return <>{render(result)}</>
}

InvalidFieldCount.propTypes = {
  render: PropTypes.func.isRequired,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
}

export default InvalidFieldCount
