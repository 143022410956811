import React from 'react'

import PropTypes from 'prop-types'

import Modal from '../Modal/Modal'
import { Button } from 'components/atoms'

function ConfirmModalContent({ title, text, onClick: onButtonClick, buttons, disabled }) {
  const handleCancel = () => {
    onButtonClick({ type: 'cancel' })
  }

  const handleConfirm = (clickCb) => () => {
    const cb = () => {
      onButtonClick({ type: 'confirm' })
    }

    clickCb(cb)
  }

  const buttonList = buttons.map(({ onClick, text, type, props: btnProps = {} }) => ({
    ...{
      blockElClass: 'modal__btn',
      modifier: type === 'cancel' ? 'a' : '',
      ...btnProps,
      disabled: btnProps.disabled || disabled,
    },
    onClick: type === 'cancel' ? handleCancel : handleConfirm(onClick),
    text,
  }))

  return (
    <>
      <Modal.Title>{title}</Modal.Title>
      {text && (
        <div className="modal__textArea">
          <p className="modal__text">{text}</p>
        </div>
      )}

      <Modal.BtnArea modifier="fe">
        {buttonList.map((button, index) => (
          <Button key={index} {...button} />
        ))}
      </Modal.BtnArea>
    </>
  )
}

const ConfirmProp = PropTypes.shape({
  type: PropTypes.oneOf(['confirm']).isRequired,
  text: PropTypes.string,
})

const CancelProp = PropTypes.shape({
  type: PropTypes.oneOf(['cancel']).isRequired,
  text: PropTypes.string,
})

ConfirmModalContent.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
  buttonList: PropTypes.array,
  buttons: PropTypes.arrayOf(PropTypes.oneOfType([CancelProp, ConfirmProp])),
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}

export default ConfirmModalContent
