import React, { useEffect, useRef, useState } from 'react'
import Avatar from 'components/atoms/Avatar'
import FormHelperText from '@mui/material/FormHelperText'
import Input from 'components/atoms/Input'
import PropTypes from 'prop-types'
import { Label } from 'components/atoms'
import { FormGroup } from 'components/molecules'
export default function AvatarField(props) {
  const ref = useRef(null)
  const [internalValue, setInternalValue] = useState(null)
  const [hasSelected, setHasSelected] = useState(false)
  const [processing, setProcessing] = useState(false)

  const handleFileSelect = (e) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0]
      readFile(file)
      props.onChange(e.target.files[0])
    }
  }

  const readFile = (file) => {
    const reader = new FileReader()
    setProcessing(true)
    reader.onload = (e) => {
      setInternalValue(e.target.result)
      setHasSelected(true)
      setProcessing(false)
    }

    reader.readAsDataURL(file)
  }

  useEffect(() => {
    if (props.src instanceof File) {
      readFile(props.src)
    }
  }, [props.src])

  return (
    <FormGroup modifier="mgb0 spMgb16" className="form__group form__group">
      <Label>{props.label}</Label>
      <FormGroup className="form__groupFlex form__group-mgt form__groupMultiCheck cm__pfImgArea form__group-mgb0">
        <div className="cm__headerIconArea cm__headerIconArea-mgb16">
          <Avatar
            className="cm__headerIcon"
            imgProps={{ width: 112, height: 112, alt: 'Avatar field' }}
            src={hasSelected ? internalValue : processing ? null : props.src}
            defaultSrc={props.defaultSrc}
          />
        </div>
        {!props.hidePencil ? (
          <label className="form__fileLabel form__fileLabel-plus" htmlFor="file1">
            {props.uploadLabel}
            <Input
              sx={{ display: 'none' }}
              className="form__file form__file-dpn inputFile cmFileUp boolCheck"
              ref={ref}
              onChange={handleFileSelect}
              type="file"
              id="file1"
            />
          </label>
        ) : null}
      </FormGroup>
      {props.error && <FormHelperText>{props.helperText}</FormHelperText>}
    </FormGroup>
  )
}
AvatarField.propTypes = {
  onChange: PropTypes.func,
  src: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  defaultSrc: PropTypes.string,
  disabled: PropTypes.bool,
  helperText: PropTypes.string,
  error: PropTypes.bool,
  label: PropTypes.string,
  hidePencil: PropTypes.bool,
  uploadLabel: PropTypes.string,
}
