import isArray from 'lodash/isArray'
import { createAsyncThunk } from 'store/helpers'
import { parseAxiosResponse } from 'utils/helper'
import Http from 'utils/Http'

export const createQuestion = createAsyncThunk('CREATE_QUESTION', (data) => {
  return parseAxiosResponse(Http.post('/common/question_threads/add', data))
})

export const fetchQuestions = createAsyncThunk('FETCH_QUESTIONS', (getState) => {
  const { job_posting_id, ...params } = getState().qAndA.params

  params.topic = isArray(params.topic) ? JSON.stringify(params.topic) : params.topic
  // Convert to string
  params.with = params.with.length > 0 ? params.with.join(',') : null

  const url =
    job_posting_id === null ? `/common/question_threads` : `/common/job_postings/${job_posting_id}/question_and_answers`

  return parseAxiosResponse(Http.get(url, { params }))
})

export const fetchQuestioners = createAsyncThunk('FETCH_QUESTIONERS', () => {
  return parseAxiosResponse(Http.get('/common/question_threads/questioners'))
})

export const deleteQuestion = createAsyncThunk('DELETE_QUESTION', (id) => {
  return parseAxiosResponse(Http.delete(`/common/question_threads/${id}`))
})

export const deleteAnswer = createAsyncThunk('DELETE_ANSWER', (id) => {
  return parseAxiosResponse(Http.delete(`/common/question_answers/${id}`))
})

export const updateAnswer = createAsyncThunk('UPDATE_ANSWER', (id, answer) => {
  return parseAxiosResponse(Http.put(`/common/question_answers/update/${id}`, { answer }))
})

export const updateQuestion = createAsyncThunk('UPDATE_QUESTION', (id, updates) => {
  return parseAxiosResponse(Http.put(`/common/question_threads/update/${id}`, updates))
})

export const sendQuestionReply = createAsyncThunk('SEND_QUESTION_REPLY', (id, { answer, status = 'draft' }) => {
  return parseAxiosResponse(Http.post(`/common/question_answers/add`, { question_thread_id: id, answer, status }))
})

export const focusQuestion = createAsyncThunk('FOCUS_QUESTION', (id) => {
  return parseAxiosResponse(Http.get(`/common/question_threads/${id}`))
})

export const markAllAnswersAsRead = createAsyncThunk('MARK_ALL_ANSWER_AS_READ', (questionId) => {
  return parseAxiosResponse(Http.post(`/common/question_threads/${questionId}/answers`))
})
