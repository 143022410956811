import { loadWorkPointsFailure, loadWorkPointsRequest, loadWorkPointsSuccess } from 'store/working-points/actionCreator'
import { parseAxiosResponse } from 'utils/helper'
import Http from 'utils/Http'

export function getWorkPoints() {
  return (dispatch) => {
    loadWorkPointsRequest()
    return parseAxiosResponse(Http.get('common/working_points')).then((res) =>
      dispatch(loadWorkPointsSuccess(res), (err) => loadWorkPointsFailure(err))
    )
  }
}
