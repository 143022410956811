import React from 'react'

import PropTypes from 'prop-types'
import clsx from 'clsx'
import pick from 'lodash/pick'

import CheckIcon from 'assets/img/check-icon.svg'
import XIcon from 'assets/img/white-batu.svg'
import { BlockEl, DateText } from 'components/atoms'

const StepIDProp = PropTypes.oneOfType([PropTypes.string, PropTypes.number])

const icons = {
  success: CheckIcon,
  error: XIcon,
  normal: CheckIcon,
}

function StepperItem({
  label,
  type,
  date,
  isCurrent,
  dateFormat = 'MM/DD (dd)',
  filled = false,
  hasBorder = false,
  borderBlockElClass = 'selectionModal__stepBorder',
}) {
  const isSuccess = type === 'success'
  const isError = type === 'error'
  const isNormal = type === 'normal'
  const fcClass = clsx({ fc1: !isCurrent, fc2: isCurrent && isSuccess, fc3: isCurrent && isError })

  return (
    <>
      {hasBorder && <div className={clsx(borderBlockElClass, { mainBgColor: !isNormal || filled })}></div>}

      <div
        className={clsx('selectionModal__stepCircle', {
          mainBgColor: isSuccess && filled,
          mainBgColor2: isError && filled,
          mainBdColor: isSuccess,
          mainBdColor2: isError,
        })}
      >
        <img src={icons[type]} alt="" className="selectionModal__stepCheck" width="13" height="11" />
        <span className={clsx('selectionModal__stepSup', fcClass)}>{label}</span>
        {date && (
          <BlockEl blockElClass="selectionModal__stepDate" component="span" className={fcClass}>
            <DateText date={date} format={dateFormat} />
          </BlockEl>
        )}
      </div>
    </>
  )
}

StepperItem.propTypes = {
  id: StepIDProp.isRequired,
  label: PropTypes.string,
  date: PropTypes.string,
  dateFormat: PropTypes.string,
  type: PropTypes.oneOf(['normal', 'success', 'error']),
  filled: PropTypes.bool,
  hasBorder: PropTypes.bool,
  borderBlockElClass: PropTypes.string,
  isCurrent: PropTypes.bool.isRequired,
}

function Stepper(props) {
  const { steps = [], current } = props
  const stepsLength = steps.length
  const currentIndex = steps.findIndex(({ id }) => id === current)

  const stepList = steps.map((step, i) => ({
    ...step,
    type: step.type ?? (i <= currentIndex ? 'success' : 'normal'),
    hasBorder: i !== 0,
    filled: i < currentIndex || stepsLength === currentIndex + 1,
    isCurrent: currentIndex === i,
  }))

  return (
    <div className="selectionModal__step">
      {stepList.map((step) => (
        <StepperItem key={step.id} {...step} />
      ))}
    </div>
  )
}

Stepper.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape(pick(StepperItem.propTypes, ['id', 'label', 'date', 'dateFormat', 'type', 'borderBlockElClass']))
  ),
  current: StepIDProp,
}

export default Stepper
