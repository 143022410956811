import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Modal } from 'components'
import PropTypes from 'prop-types'
import { Button } from 'components/atoms'
import ListItems from '../ModalChecklist/ListItems'

export default function ModalChecklist(props) {
  const {
    open,
    onClose,
    title,
    modalType,
    activeModal,
    modalListItems,
    submitModal,
    data,
    dataToRemove = [],
    limit,
  } = props
  const { t } = useTranslation()
  const [selectedItems, setSelectedItems] = useState([])
  const [selectedItem, setSelectedItem] = useState(0)
  const selectedItemCount = selectedItems.reduce((total, current) => total + (current ? current.length : 0), 0)
  const [activeListKey, setActiveListKey] = useState(0)
  const [disabled, setDisabled] = useState(true)

  useEffect(() => {
    if (open) {
      const checklist = modalType == 'checklist' && data[activeModal].length > 0 ? data[activeModal] : []
      const radio = modalType == 'radio' && data[activeModal] ? data[activeModal] : 0

      setSelectedItems(checklist)
      setSelectedItem(radio)
      let activeParent = 0
      for (const items in modalListItems) {
        if (modalType == 'checklist') {
          if (
            (Object.keys(checklist).length || Object.keys(selectedItems).length) &&
            modalListItems[items].id == Object.keys(checklist)[0]
          ) {
            activeParent = Number(items)
          }
        } else {
          if (radio && modalListItems[items].children.filter((item) => item.id === radio).length) {
            activeParent = Number(items)
          }
        }
      }
      setActiveListKey(activeParent)
      if (activeListKey) {
        setDisabled(false)
      }
    }
  }, [open])

  const handleActiveLists = (index) => {
    setActiveListKey(index)
  }

  const onIsChecked = (parentIndex, childrenIndex) => {
    let isChecked = false
    if (Object.keys(selectedItems).length > 0 && selectedItems[parentIndex]) {
      isChecked = selectedItems[parentIndex].includes(childrenIndex)
    }
    return isChecked
  }

  const countAttached = (selected) => {
    let count = 0
    for (const items in selected) {
      if (selected[items]) {
        count = count + selected[items].length
      }
    }
    setDisabled(count === 0 || count > limit)
  }

  const onHandleCheck = (e) => {
    const parentIndex = e.target.dataset.index
    const childrenIndex = parseInt(e.target.value)
    const tempSelectedItems = selectedItems.slice()
    let temp = (selectedItems[parentIndex] || []).slice()
    if (temp.includes(childrenIndex)) {
      const insertIndex = temp.filter((item) => item !== childrenIndex)
      temp = insertIndex
    } else {
      temp = [...temp, childrenIndex]
    }
    tempSelectedItems[parentIndex] = temp
    setSelectedItems(tempSelectedItems)
    countAttached(tempSelectedItems)
  }

  const handleSubmit = () => {
    submitModal(modalType == 'checklist' ? selectedItems : selectedItem, activeModal)
  }

  const handleClear = () => {
    setSelectedItems([])
    setSelectedItem(0)
    setDisabled(true)
  }

  return (
    <Modal className="modal__contC-w474" containerType="adCont" onClose={onClose} open={open}>
      <div className="modal__choiceContInner1">
        <Modal.Header blockElClass="modal__contHeader" modifier="pd">
          <Modal.Title>{title}</Modal.Title>
          <Modal.CloseButton onClick={onClose} />
        </Modal.Header>
        <ListItems
          items={modalListItems}
          activeList={activeListKey}
          onHandleActiveList={handleActiveLists}
          modalType={modalType}
          activeModal={activeModal}
          selectedItems={selectedItems}
          onIsChecked={onIsChecked}
          onHandleCheck={onHandleCheck}
          selectedItem={selectedItem}
          setSelectedItem={setSelectedItem}
          setDisabled={setDisabled}
          dataToRemove={dataToRemove}
        />
        <Modal.Footer blockElClass="modal__adContFooter">
          <Button
            modifier="fs14 mgr"
            variant="outlineDissable"
            noVariantSuffix={true}
            variantMod={{ noActive: !(selectedItemCount > 0 || selectedItem) }}
            type="button"
            onClick={handleClear}
          >
            {t('userProfileInvitation.create.modal.clearSelection')}
          </Button>
          <Button
            modifier="fs14 mgr"
            variant="yellowDessable"
            noVariantSuffix={true}
            variantMod={{ active: (selectedItemCount > 0 && selectedItemCount <= limit) || selectedItem }}
            type="button"
            onClick={handleSubmit}
            disabled={disabled}
          >
            {t('userProfileInvitation.create.modal.decideSelected')}
          </Button>
        </Modal.Footer>
      </div>
    </Modal>
  )
}

ModalChecklist.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string,
  modalType: PropTypes.string,
  activeModal: PropTypes.string,
  modalListItems: PropTypes.array.isRequired,
  submitModal: PropTypes.func,
  data: PropTypes.object,
  dataToRemove: PropTypes.any,
  limit: PropTypes.number,
  flatten: PropTypes.bool,
}
