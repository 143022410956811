import { useEffect } from 'react'

/**
 * @typedef {Object} UseBodyScrollOpts
 * @property {bool} disable - Indicate whether the body scrolling should be disabled
 */

/**
 * Controls the body scroll.
 *
 * @param {UseBodyScrollOpts} opts
 */
export default function useBodyScroll(opts) {
  const { disable } = opts

  useEffect(() => {
    const body = document.body

    if (disable) {
      const sy = window.scrollY

      const timeout = setTimeout(() => {
        body.style.position = 'fixed'
        body.style.width = '100%'
        body.style.top = '-'.concat(sy, 'px')
        body.style.left = '0'
      }, 300)

      return () => {
        clearTimeout(timeout)
        body.style.position = ''
        body.style.width = ''
        body.style.top = ''
        body.style.left = ''
        body.style.overflow = ''
        scroll(0, sy)
      }
    }
  }, [disable])
}
