import React, { useEffect, useState } from 'react'
import { Button, Radio, Input } from 'components/atoms'
import { FormGroupCheck, FormSupArea, FormInterlock, FormGroup } from 'components/molecules'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import LinkPreview from 'components/molecules/LinkPreview'

const CandidatePortfolioInput = ({
  handleRadioClick,
  portfolioType,
  portfolioUrl,
  pdfToUpload,
  setPortfolioUrl,
  handleFileInput,
  handleRemoveFile,
  loading,
  checkIfFilled,
  handleSubmit,
}) => {
  const { t } = useTranslation()
  const [preview, setPreview] = useState(false)

  const handleOnBlurUrl = () => {
    setPreview(true)
  }

  const handleOnChangeUrl = (value) => {
    setPreview(false)
    setPortfolioUrl(value)
  }

  useEffect(() => {
    setPreview(false)
  }, [portfolioType])

  return (
    <div className="form__portfolioArea candidate__portfolioArea">
      <div className="form__groupCheckArea">
        <FormGroupCheck>
          <FormGroupCheck.Item
            Input={Radio}
            inputProps={{
              value: 'url',
              onClick: handleRadioClick,
              checked: portfolioType === 'url',
              readOnly: true,
            }}
            label={t('candidate.portfolioURL')}
            type="round"
          />
        </FormGroupCheck>

        <FormInterlock active={portfolioType === 'url'}>
          <FormGroup blockElClass="form__groupFlex" modifier="mgt">
            <Input
              modifier="w100"
              maxLength="300"
              value={portfolioUrl}
              onBlur={handleOnBlurUrl}
              onChange={(e) => handleOnChangeUrl(e.target.value)}
              className="inputCalc inputCalcParent inputCheck candidatePortCheck"
            />
          </FormGroup>
          <FormSupArea modifier="flex">
            <p className="form__sup form__sup-mgt0">{t('candidate.porfolioSuppLabel')}</p>
            <FormSupArea.InputSup modifier="num" inputValue={portfolioUrl} max={300} />
          </FormSupArea>
          {preview && portfolioUrl && <LinkPreview url={portfolioUrl} />}
        </FormInterlock>
        <FormSupArea modifier="flex">
          <p className="form__alert">{t('candidate.otherDocsLblMsg')}</p>
        </FormSupArea>
        <FormGroupCheck>
          <FormGroupCheck.Item
            Input={Radio}
            inputProps={{
              value: 'pdf',
              onClick: handleRadioClick,
              checked: portfolioType === 'pdf',
              readOnly: true,
            }}
            label={t('candidate.portfolioPDF')}
            type="round"
          />
        </FormGroupCheck>
        <FormInterlock active={portfolioType === 'pdf'}>
          <FormGroup blockElClass="form__groupFlex" modifier="mgt">
            {pdfToUpload === null ? (
              <label className="form__fileLabel form__fileLabel-plus">
                {t('candidate.enterPDF')}
                <Input
                  type="file"
                  accept="application/pdf"
                  className="form__file form__file-dpn inputFile"
                  onChange={handleFileInput}
                />
              </label>
            ) : (
              <p className="form__fileText">
                <span className="form__fileText-text">{pdfToUpload?.name}</span>
                <span className="form__fileTextDel" onClick={handleRemoveFile}></span>
              </p>
            )}
          </FormGroup>
          <FormSupArea modifier="flex">
            <p className="form__alert">{t('common.plsEnter')}</p>
          </FormSupArea>
        </FormInterlock>
      </div>
      <div className="form__portfolioFooter">
        <Button
          onClick={() => handleSubmit('add')}
          disabled={loading || !checkIfFilled()}
          variant={!loading && checkIfFilled() ? 'shadow' : 'yellowDessable'}
        >
          {t('candidateSlideout.add')}
        </Button>
      </div>
    </div>
  )
}

CandidatePortfolioInput.propTypes = {
  metadata: PropTypes.object,
  handleRadioClick: PropTypes.func,
  portfolioType: PropTypes.string,
  portfolioUrl: PropTypes.string,
  pdfToUpload: PropTypes.object,
  setPortfolioUrl: PropTypes.func,
  truncate: PropTypes.func,
  handleFileInput: PropTypes.func,
  handleRemoveFile: PropTypes.func,
  loading: PropTypes.bool,
  checkIfFilled: PropTypes.func,
  handleSubmit: PropTypes.func,
}

export default CandidatePortfolioInput
