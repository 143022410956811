import React, { useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import QuestionAndAnswerFormModal from '../QuestionAndAnswerFormModal'
import QuestionAndAnswerTopicsChecklist from '../QuestionAndAnswerTopicsChecklist'
import QuestionAndAnswerThread from '../QuestionAndAnswerThread'
import {
  actionEditQuestion,
  actionUnfocusQuestion,
  actionUpdateQuestionsParams,
} from 'store/question-and-answers/actionCreators'
import { fetchQuestions, focusQuestion } from 'services/questionAndAnswers'
import { selectQandATabTotal } from 'store/question-and-answers/selectors'
import { useQuery } from 'utils/hooks'
import { selectAuthUser } from 'store/auth/selectors'
import { accountType } from 'utils/helper'
import { USER_TYPE_KEYS } from 'utils/constants'
import { Button } from 'components/atoms'

const QuestionAndAnswerContainer = ({ side, main }) => (
  <div className="qa__cont">
    <div className="qa__side">
      <div className="qa__sideInner">{side}</div>
    </div>
    <div className="qa__main">{main}</div>
  </div>
)
QuestionAndAnswerContainer.propTypes = {
  side: PropTypes.any,
  main: PropTypes.any,
}

function QuestionAndAnswerSide() {
  const selected = useSelector((state) => state.qAndA.params.topic)
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const handleSelectedTopicChange = (newChanges) => {
    dispatch(actionUpdateQuestionsParams({ topic: newChanges, page: 1 }))
    dispatch(fetchQuestions())
  }

  return (
    <>
      <p className="qa__sideTitle">{t('qAndA.sideTitle')}</p>
      <QuestionAndAnswerTopicsChecklist selected={selected} onChange={handleSelectedTopicChange} />
    </>
  )
}

function QuestionAndAnswerTab({ jobPostingId, editable = true }) {
  const [open, setOpen] = useState(false)
  const { t } = useTranslation()
  const total = useSelector(selectQandATabTotal)
  const editQuestion = useSelector((state) => state.qAndA.editQuestion)
  const dispatch = useDispatch()
  const { questionId: focusId } = useQuery()
  const user = useSelector(selectAuthUser)
  const canCreate = [USER_TYPE_KEYS.AD1, USER_TYPE_KEYS.AD2].includes(accountType(user))

  const isEditing = !!editQuestion

  const handleAddBtnClick = () => {
    setOpen(true)
  }

  const handleSuccess = () => {
    setOpen(false)
    if (!isEditing) dispatch(fetchQuestions())
    if (isEditing) dispatch(actionEditQuestion(null))
  }

  const handleModalClose = () => {
    if (isEditing) {
      dispatch(actionEditQuestion(null))
    }
    setOpen(false)
  }

  useEffect(() => {
    setOpen(isEditing)
  }, [isEditing])

  useEffect(() => {
    if (!focusId) return dispatch(actionUnfocusQuestion())

    dispatch(focusQuestion(focusId))
  }, [focusId])

  return (
    <div className="evaluation__leftMainCont">
      <div className="evaluation__leftMainFlex">
        <h2 className="evaluation__leftMainTitle evaluation__leftMainTitle-mgt0">
          {t('qAndA.title')}
          <span className="evaluation__leftMainTitle-num">{t('qAndA.numQuestions', { count: total })}</span>
        </h2>

        {canCreate && (
          <Button blockElClass="" className="qa__btn1" onClick={handleAddBtnClick} disabled={!editable}>
            {t('qAndA.addBtn')}
          </Button>
        )}

        <QuestionAndAnswerFormModal
          onSuccess={handleSuccess}
          onClose={handleModalClose}
          open={open}
          isEditing={!!editQuestion}
          initialFormValue={editQuestion}
          jobPostingId={jobPostingId}
        />
      </div>

      <QuestionAndAnswerContainer
        side={<QuestionAndAnswerSide />}
        main={<QuestionAndAnswerThread jobPostingId={jobPostingId} />}
      />
    </div>
  )
}

QuestionAndAnswerTab.propTypes = {
  jobPostingId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  editable: PropTypes.bool,
}

export default QuestionAndAnswerTab
