import React from 'react'

import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import get from 'lodash/get'

import { Avatar, Button, Textarea } from 'components/atoms'
import { sendQuestionReply } from 'services/questionAndAnswers'
import { FormProvider, useForm } from 'react-hook-form'
import { ErrorMessage, FormGroup, FormSupArea } from 'components/molecules'
import { showNotification } from 'store/notification/actionCreators'
import { assignRefs, makeFormGroupStateGetter } from 'utils/helper'
import { required } from 'utils/validation'
import InvalidFieldCount from '../InvalidFieldCount'
import AvatarIcon from 'assets/img/Avatar@3x 3.svg'

const MAX_LENGTH = 2000

const defaultValues = {
  answer: '',
  status: 'posted',
}

function QuestionAndAnswerReplyForm({ user, questionId, inputRef, disabled = false }) {
  const { t } = useTranslation()
  const methods = useForm({
    mode: 'onChange',
    defaultValues,
  })
  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
    reset,
    setError,
    getFieldState,
  } = methods
  const dispatch = useDispatch()

  const onSubmit = async (data) => {
    const res = await dispatch(sendQuestionReply(questionId, data))

    if (res.error) {
      const code = get(res.error, 'code')
      if (code === 422 && res.error.error.answer) {
        setError(t('qAndA.answerInput'), { message: res.error.error.answer[0], type: 'server' })
      } else if (typeof res.error.error === 'string') {
        dispatch(showNotification(res.error.error, { type: 'danger' }))
      }
      return
    }

    reset()

    // We need this to resize the message input
    inputRef.current.dispatchEvent(
      new Event('input', {
        bubbles: true,
        cancelable: true,
      })
    )
  }

  const answerRule = {
    validate: {
      required: required(t('validation.required')),
    },
    maxLength: { value: MAX_LENGTH, message: t('validation.maxLength', { length: MAX_LENGTH }) },
  }

  const { byName } = makeFormGroupStateGetter(getFieldState)

  const inputReg = register('answer', answerRule)

  return (
    <FormProvider {...methods}>
      <form className="qa__childLiForm" onSubmit={handleSubmit(onSubmit)}>
        <Avatar
          component="p"
          bec="qa__liBoxImgArea"
          imgProps={{ bec: 'qa__liBoxImg' }}
          src={user.avatar}
          defaultSrc={AvatarIcon}
        />
        <FormGroup error={byName('answer').error} modifier="mgb0 w100">
          <Textarea
            {...inputReg}
            bec="form__inputText"
            autoHeight
            maxAutoHeight={300}
            style={{ resize: 'none' }}
            ref={assignRefs([inputReg.ref, inputRef])}
            placeholder={t('qAndA.replyPlaceholder')}
            modifier="mgt0 w100 textarea"
            rows={1}
            disabled={disabled}
          />
          <FormSupArea>
            <ErrorMessage name="answer" />
          </FormSupArea>
        </FormGroup>
        <InvalidFieldCount
          name={['answer']}
          render={({ invalid }) => (
            <Button
              component="input"
              variant="yellowDessable"
              variantMod={clsx({ active: !(invalid || isSubmitting || disabled) })}
              modifier="mgl12 h36"
              disabled={invalid || isSubmitting || disabled}
              type="submit"
            >
              {t('qAndA.replySubmitBtn')}
            </Button>
          )}
        />
      </form>
    </FormProvider>
  )
}

QuestionAndAnswerReplyForm.propTypes = {
  user: PropTypes.object.isRequired,
  questionId: PropTypes.number.isRequired,
  inputRef: PropTypes.any,
  disabled: PropTypes.bool,
}

export default QuestionAndAnswerReplyForm
