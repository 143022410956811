import React from 'react'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'
import ReactMarkdown from 'react-markdown'

const H1 = (props) => <Typography variant="h1" {...props} />
const H2 = (props) => <Typography variant="h2" {...props} />
const Paragraph = (props) => <Typography variant="body1" {...props} />
const Img = styled('img')({
  maxWidth: 279,
})
const UnorderedList = styled('ul')({
  paddingLeft: 16,
})
const OrderedList = styled('ol')({
  paddingLeft: 16,
})
const List = styled('li')(({ theme }) => theme.typography.body1)

const Markdown = (props) => {
  return (
    <ReactMarkdown
      {...props}
      components={{
        h1: H1,
        h2: H2,
        p: Paragraph,
        li: List,
        img: Img,
        ul: UnorderedList,
        ol: OrderedList,
      }}
    />
  )
}

export default Markdown
