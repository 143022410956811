import { isParent } from 'utils/helper'

export default () =>
  ({ user }, next) =>
    next(
      user
        ? isParent(user)
          ? !user.company.first_name &&
            !user.company.last_name &&
            !user.company.year_established &&
            !user.company.account_settlement_month &&
            !user.company.employee_count
            ? true
            : false
          : false
        : false
    )
