import React from 'react'

import PropTypes from 'prop-types'
import clsx from 'clsx'

import { calculateModifiers } from 'utils/helper'
import { WorkLocations } from 'components'

const BoxHeader = ({ children }) => <div className="qa__liBoxHeader">{children}</div>

BoxHeader.propTypes = {
  children: PropTypes.node,
}

const BoxTitleArea = ({ title }) => <h2 className="otherjob__title">{title}</h2>

BoxTitleArea.propTypes = {
  title: PropTypes.string.isRequired,
}

const BoxPositionArea = ({ position }) => (
  <div className="otherjob__positionArea">
    <div className="otherjob__position">{position}</div>
  </div>
)

BoxPositionArea.propTypes = {
  position: PropTypes.string.isRequired,
}

const BoxWorkPlaceEmploymentForm = ({ work_place, employment_form, work_place_txt, employment_form_txt }) => {
  return (
    <dl className="otherjob__dl">
      <dt className="otherjob__dt">{work_place_txt}</dt>
      <dd className="otherjob__dd">
        <WorkLocations workLocations={work_place} />
      </dd>
      <dt className="otherjob__dt">{employment_form_txt}</dt>
      <dd className="otherjob__dd">{employment_form}</dd>
    </dl>
  )
}

BoxWorkPlaceEmploymentForm.propTypes = {
  work_place: PropTypes.array.isRequired,
  employment_form: PropTypes.string.isRequired,
  work_place_txt: PropTypes.string,
  employment_form_txt: PropTypes.string,
}

const BoxRecruitmentForm = ({
  recruitment_date,
  min_age,
  max_age,
  to_be_hired_count,
  gender,
  recruitment_date_txt,
  age_txt,
  to_be_hired_count_txt,
  gender_txt,
}) => (
  <div className="otherjob__tableWrap">
    <table className="otherjob__table">
      <tbody>
        <tr className="otherjob__tr">
          <th className="otherjob__th">{recruitment_date_txt}</th>
          <td className="otherjob__td">{recruitment_date}</td>
          <th className="otherjob__th">{to_be_hired_count_txt}</th>
          <td className="otherjob__td">{to_be_hired_count}</td>
        </tr>
        <tr className="otherjob__tr">
          <th className="otherjob__th">{age_txt}</th>
          <td className="otherjob__td">
            {min_age} 〜 {max_age}
          </td>
          <th className="otherjob__th">{gender_txt}</th>
          <td className="otherjob__td">{gender}</td>
        </tr>
      </tbody>
    </table>
  </div>
)

BoxRecruitmentForm.propTypes = {
  recruitment_date: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  min_age: PropTypes.number.isRequired,
  max_age: PropTypes.number.isRequired,
  to_be_hired_count: PropTypes.number.isRequired,
  gender: PropTypes.string.isRequired,
  recruitment_date_txt: PropTypes.string,
  age_txt: PropTypes.string,
  to_be_hired_count_txt: PropTypes.string,
  gender_txt: PropTypes.string,
}

const Box = ({ modifier, children }) => (
  <div className={clsx('otherjob__liLink', calculateModifiers('otherjob__liLink', modifier))}>{children}</div>
)

Box.propTypes = {
  children: PropTypes.node,
  modifier: PropTypes.string,
}

Box.Header = BoxHeader
Box.TitleArea = BoxTitleArea
Box.PositionArea = BoxPositionArea
Box.WorkPlaceEmploymentForm = BoxWorkPlaceEmploymentForm
Box.RecruitmentForm = BoxRecruitmentForm

export default Box
