import { accountType } from 'utils/helper'

export default () =>
  ({ user }, next) => {
    switch (accountType(user)) {
      case 'HR':
        return next(!user.firstname && !user.lastname ? true : false)
      case 'AD1':
      case 'AD2':
        return next(
          !user.firstname &&
            !user.lastname &&
            !user.industries.length &&
            !user.occupations.length &&
            !user.contact_number
            ? true
            : false
        )
    }
  }
