import produce from 'immer'
import { fetchSelectionStatusSteps } from 'services/referralProposals'

const initialState = {
  state: 'pending',
  items: [],
}

const reducer = produce((draft, { type, payload }) => {
  switch (type) {
    case fetchSelectionStatusSteps.pending:
      draft.state = 'pending'
      return draft
    case fetchSelectionStatusSteps.fulfilled:
      draft.state = 'fulfilled'
      draft.items = payload.map(({ id, status }) => ({ id, label: status }))
      return draft
    case fetchSelectionStatusSteps.failure:
      draft.state = 'failure'
      draft.error = payload?.message
      return draft
    default:
      return draft
  }
}, initialState)

export default reducer
