import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

const BackButton = (props) => {
  return (
    <Link to={props.link} className="header__backBtn">
      {props.label}
    </Link>
  )
}

BackButton.propTypes = {
  label: PropTypes.string,
  link: PropTypes.string,
}

export default BackButton
