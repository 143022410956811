import { useFormContext, useWatch } from 'react-hook-form'
import castArray from 'lodash/castArray'

/**
 * @typedef {Object} InvalidFieldCountOpts
 * @property {Object} [control] - The react hook form control
 * @property {Object} [defaultValue] - The form default values.
 * @property {string|string[]} name - The fields to check.
 * @property {Function} [isRequired] - An optional function that is used to determine if the field is required.
 * @property {Function} [predicate] - An optional function used to determine if the field is invalid.
 * @property {Function} [accumulator] - A function that is called for calculating the number of invalid fields.
 * @property
 */

/**
 * @typedef {Object} InvalidFieldCountResult
 * @property {string[]} invalidFields - An array of invalid fields
 * @property {number} count - Number of invalid fields.
 * @property {boolean} invalid - Indicates whether the result has invalid fields.
 */

/**
 *
 * @param {InvalidFieldCountOpts} opts
 * @returns {InvalidFieldCountResult}
 */
export default function useInvalidFieldCount(opts) {
  const { control, defaultValue, name, isRequired, predicate, accumulator } = opts
  const { getFieldState, getValues, formState } = useFormContext({ control })
  useWatch({ control, defaultValue, name })

  const isRequiredCheck = typeof isRequired === 'function' ? isRequired : () => true
  const names = castArray(name)
  const invalidFields = names.reduce((accum, curr) => {
    const fieldState = getFieldState(curr)
    formState.dirtyFields[curr] // For some reason getFieldState().isDirty is not correct
    formState.errors[curr]
    const { invalid, isDirty, isTouched } = fieldState
    const value = getValues(curr)
    const checkResult = isRequiredCheck(curr) ? !isDirty || ((isTouched || isDirty) && invalid) : invalid
    const stillInvalid =
      typeof predicate === 'function' ? predicate({ name: curr, value, fieldState, checkResult }) : checkResult

    return accum.concat(stillInvalid ? curr : [])
  }, [])
  const count = typeof accumulator === 'function' ? accumulator(invalidFields) : invalidFields.length

  return { invalidFields, count, invalid: count > 0 }
}
