import { createSelector } from 'reselect'

export const selectPagination = (state) => state.qAndA.pagination

export const selectQandATotal = (state) => selectPagination(state).total

export const selectQandAListTabReadiness = createSelector(
  (state) => state.qAndA.listState,
  (state) => state.qAndA.focusQuestion.state,
  (list, focus) => list !== 'pending' && focus !== 'pending'
)

export const selectQandAFocusItem = (state) => state.qAndA.focusQuestion.item

export const selectQandATabList = createSelector(
  (state) => state.qAndA.params.topic,
  selectQandAFocusItem,
  (state) => state.qAndA.items,
  (topics, focusItem, list) => {
    if (!focusItem || (topics.length > 0 && !topics.includes(focusItem.topic.id))) return list

    return [focusItem, ...list.filter((item) => item.id !== focusItem.id)]
  }
)

export const selectQandATabTotal = createSelector(selectPagination, ({ total }) => total)
