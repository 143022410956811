import * as ActionTypes from './actionTypes'

export function actionInitThread(threadId) {
  return {
    type: ActionTypes.INIT_THREAD,
    payload: { threadId },
  }
}

export function actionLoadThreadMessagesRequest(threadId) {
  return {
    type: ActionTypes.LOAD_THREAD_MESSAGES_REQUEST,
    payload: { threadId },
  }
}

export function actionLoadThreadMessagesSuccess(threadId, response) {
  return {
    type: ActionTypes.LOAD_THREAD_MESSAGES_SUCCESS,
    payload: { threadId, response },
  }
}

export function actionLoadThreadMessagesFailure(threadId, error) {
  return {
    type: ActionTypes.LOAD_THREAD_MESSAGES_FAILURE,
    payload: { threadId, error: error?.error || error?.message },
  }
}

export function actionCreateNewMessageRequest(threadId) {
  return {
    type: ActionTypes.CREATE_NEW_MESSAGE_REQUEST,
    payload: { threadId },
  }
}

export function actionCreateNewMessageSuccess(threadId, response) {
  return {
    type: ActionTypes.CREATE_NEW_MESSAGE_SUCCESS,
    payload: { threadId, response },
  }
}

export function actionCreateNewMessageFailure(threadId, error) {
  return {
    type: ActionTypes.CREATE_NEW_MESSAGE_FAILURE,
    payload: { threadId, error: error?.error || error?.message },
  }
}

export function actionAddMessage(threadId, message) {
  return {
    type: ActionTypes.ADD_MESSAGE,
    payload: { threadId, message },
  }
}

export function actionLoadMoreThreadMessagesRequest(threadId) {
  return {
    type: ActionTypes.LOAD_MORE_THREAD_MESSAGES_REQUEST,
    payload: { threadId },
  }
}

export function actionLoadMoreThreadMessagesSuccess(threadId, response) {
  return {
    type: ActionTypes.LOAD_MORE_THREAD_MESSAGES_SUCCESS,
    payload: { threadId, response },
  }
}

export function actionLoadMoreThreadMessagesFailure(threadId, error) {
  return {
    type: ActionTypes.LOAD_MORE_THREAD_MESSAGES_FAILURE,
    payload: { threadId, error: error?.error || error?.message },
  }
}
