import * as ActionTypes from './actionTypes'

export function loadOccupationsRequest() {
  return {
    type: ActionTypes.LOAD_OCCUPATIONS_REQUEST,
  }
}

export function loadOccupationsSuccess(data) {
  return {
    type: ActionTypes.LOAD_OCCUPATIONS_SUCCESS,
    payload: data,
  }
}

export function loadOccupationsFailure(error) {
  return {
    type: ActionTypes.LOAD_OCCUPATIONS_FAILURE,
    payload: { error },
  }
}
