import React from 'react'
import PropTypes from 'prop-types'

function FormHeader(props) {
  return <h1 className="recommendation__title">{props.label}</h1>
}

FormHeader.propTypes = {
  label: PropTypes.string,
}

export default FormHeader
