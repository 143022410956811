import { createAsyncThunk } from 'store/helpers'
import { parseAxiosResponse } from 'utils/helper'

import Http from 'utils/Http'

let request = null

export const getAboutTheMedium = createAsyncThunk('GET_MEDIUM', () => {
  if (request instanceof Promise) return request

  request = parseAxiosResponse(Http.get('aboutTheMedium')).then((res) => {
    // We want to refetch when error occurs
    if (res.error) request = null

    return res
  })

  return request
})
