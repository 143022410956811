import { createAsyncThunk } from 'store/helpers'
import { parseAxiosResponse } from 'utils/helper'
import Http from 'utils/Http'
import * as actions from 'store/job-postings/actionCreators'

export const fetchJobPosting = createAsyncThunk('FETCH_JOB_POSTING', (id, withCompanyDetails) => {
  return parseAxiosResponse(Http.get(`/common/job_postings/${id}`, { params: withCompanyDetails }))
})

export const getJobPostings = createAsyncThunk('GET_JOB_POSTINGS', (getState) => {
  const { sort, sortBy, ...params } = getState().jobPostings.params
  return parseAxiosResponse(Http.get(`/common/job_postings?sort=${sort}&sortBy=${sortBy}`, { params }))
})

export const addToFavorites = createAsyncThunk('ADD_TO_FAVORITES', (jobPostingId, status) => {
  return parseAxiosResponse(Http.post(`/common/job_postings/${jobPostingId}/bookmark`, { status }))
})

export const copyJobPosting = createAsyncThunk('COPY_JOB_POSTING', (id) => {
  return parseAxiosResponse(Http.post(`/common/job_postings/${id}/copy`))
})

export const clickJobPosting = createAsyncThunk('CLICK_JOB_POSTING', (id) => {
  return parseAxiosResponse(Http.post(`/common/job_postings/${id}/click`))
})

export const canPropose = createAsyncThunk('CAN_PROPOSE', (jobPostingId) => {
  return parseAxiosResponse(Http.get(`/common/job_postings/${jobPostingId}/can_propose`))
})

export const fetchForEditProposal = createAsyncThunk('FETCH_FOR_EDIT_PROPOSAL', (id) => {
  return parseAxiosResponse(Http.get(`/common/referral_proposals/${id}`))
})

export const getJobPostingsFavorite = createAsyncThunk('GET_JOB_POSTINGS_FAVORITE', (getState) => {
  const { sort, sortBy, ...params } = getState().jobPostings.params
  return parseAxiosResponse(
    Http.get(`/common/job_postings/?sort=${sort}&sortBy=${sortBy}`, { params: { ...params, bookmark: true } })
  )
})

export function updateJobPosting(id, jobPosting) {
  return (dispatch) => {
    dispatch(actions.updateJobPostingRequest())
    return parseAxiosResponse(Http.put(`common/job_postings/${id}`, { _method: 'PUT', ...jobPosting }))
      .then((response) => {
        return dispatch(actions.updateJobPostingSuccess(response))
      })
      .catch((error) => {
        return dispatch(actions.updateJobPostingFailure(error))
      })
  }
}

export const createJobPostings = createAsyncThunk('CREATE_JOB_POSTINGS', (data) => {
  return parseAxiosResponse(Http.post(`/common/job_postings/`, data))
})

export const searchJobPostings = createAsyncThunk('SEARCH_JOB_POSTINGS', (params) => {
  return parseAxiosResponse(Http.get(`/common/job_postings`, { params }))
})

export const fetchJobPostingTabStats = createAsyncThunk('FETCH_JOB_POSTING_TAB_STATS', ({ id, tab }) => {
  return parseAxiosResponse(Http.get(`/common/job_postings/${id}/tab_stats`)).then((response) => ({
    tab,
    stats: response,
  }))
})

export const downloadJobPostingPdfs = (ids) => {
  return parseAxiosResponse(
    Http.get('common/job_postings/pdf_download', {
      params: { ids: Array.isArray(ids) ? ids.join(',') : ids },
      responseType: 'blob',
    })
  )
}
