import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { selectJobPosting } from '../../../../store/job-postings/selectors'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { Button } from 'components'
import { JOB_POSTING_STATUSES } from 'utils/constants'

function JobVacancyDetailFooter(props) {
  const jobPosting = useSelector(selectJobPosting)
  const history = useHistory()
  const { t } = useTranslation()
  const { returnToDraft, disabled } = props
  const type = jobPosting?.job_posting_type
  if (jobPosting === null) return <></>

  const handleClick = () => {
    localStorage.setItem(
      jobPosting.job_posting_type === 'referral' ? 'job_posting' : 'job_post_proposal',
      JSON.stringify(jobPosting)
    )
    history.push({
      pathname: type === 'referral' ? '/new-job-post/step1/' : '/new-job-post-proposal/step1/',
      state: { job_posting_data: jobPosting, multipleStep: true },
    })
  }

  return (
    <footer className="footer footer-flex">
      <div className="footer__leftCont footer__leftCont-flex">
        <p className="footer__corp footer__corp-mgr">{jobPosting.company_name}</p>
        <p className="footer__corp2">{jobPosting.job_title}</p>
      </div>
      <ul className="footer__ul">
        {jobPosting.status !== JOB_POSTING_STATUSES.draft && !jobPosting.with_proposals && !jobPosting.with_referrals && (
          <li className="footer__li footer__li-mr16">
            <Button variant={'outline'} onClick={returnToDraft} disabled={disabled}>
              {t('jobPosting.footer.returnToDraft')}
            </Button>
          </li>
        )}
        <li className="footer__li">
          <Button onClick={handleClick} variant={disabled ? 'shadowDissable' : 'shadow'} disabled={disabled}>
            {t('jobPosting.footer.editJobDescription')}
          </Button>
        </li>
      </ul>
    </footer>
  )
}

JobVacancyDetailFooter.propTypes = {
  returnToDraft: PropTypes.func,
  disabled: PropTypes.bool,
}

export default JobVacancyDetailFooter
