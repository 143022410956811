const en = {
  translation: {
    // define translations below
    auth: {
      required: 'This field is required.',
      email: 'The email format is invalid.',
      password: {
        minLength: 'Password must be at least 8 characters.',
        confirm: 'Password confirmation does not match.',
        strong: 'Password must contain the following: 1 uppercase, 1 special character and a minimum of 8 characters.',
      },
    },
  },
  selectionManagement: {
    read: 'read',
    unread: 'unread',
  },
  daysOfTheWeek: {
    0: 'Sunday',
    1: 'Monday',
    2: 'Tuesday',
    3: 'Wednesday',
    4: 'Thursday',
    5: 'Friday',
    6: 'Saturday',
  },
  dateSymbols: {
    year: '',
    month: '',
  },
}

export default en
