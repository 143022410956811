import React from 'react'
import { BlockEl, Button } from 'components/atoms'
import InvalidFieldCount from '../InvalidFieldCount/InvalidFieldCount'
import partition from 'lodash/partition'
import { useTranslation } from 'react-i18next'

function Footer() {
  const { t } = useTranslation()

  return (
    <footer className="footer">
      <BlockEl modifier="w824 jce" bec="footer__inner">
        <InvalidFieldCount
          name={['firstname', 'lastname', 'user_profile', 'avatar']}
          isRequired={(field) => ['firstname', 'lastname'].includes(field)}
          accumulator={(invalidFields) => {
            const [target, others] = partition(invalidFields, (field) => ['firstname', 'lastname'].includes(field))

            return others.length + (target.length > 0 ? 1 : 0)
          }}
          render={({ invalid, count }) =>
            !invalid ? (
              <Button type="submit" variant="shadow">
                {t('common.checkContent')}
              </Button>
            ) : (
              <p className="main__footerReq" id="footerReq">
                {t('validation.invalidInputsCount', { count })}
              </p>
            )
          }
        />
      </BlockEl>
    </footer>
  )
}

export default Footer
