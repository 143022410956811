import { useEffect, useRef } from 'react'

export default function useAutoHeight({ ref, maxAutoHeight, enabled = true }) {
  const initialStyleRef = useRef(undefined)

  useEffect(() => {
    if (!ref.current) return

    const current = ref.current

    const typeOfInitialStyleRef = typeof initialStyleRef.current

    if (typeOfInitialStyleRef === 'undefined') {
      initialStyleRef.current = current.getAttribute('style') ?? undefined
    }

    if (!enabled) {
      if (typeOfInitialStyleRef === 'object') current.setAttribute('style', initialStyleRef.current)
      if (typeOfInitialStyleRef === 'undefined') current.removeAttribute('style')

      return
    }

    const listener = () => {
      if (typeof maxAutoHeight == 'number' && current.scrollHeight > maxAutoHeight) {
        current.style.height = maxAutoHeight + 'px'
        current.style.overflow = 'scroll'
      } else {
        current.style.overflow = 'initial'
        current.style.height = 'auto'
        current.style.height = current.scrollHeight + 'px'
      }
    }

    current.style.height = `${current.scrollHeight}px`
    current.style.overflowY = 'hidden'

    current.addEventListener('input', listener, false)

    // Adjust on mount
    listener()

    return () => {
      current.removeEventListener('input', listener)
    }
  }, [ref.current, initialStyleRef.current, enabled, maxAutoHeight])
}
