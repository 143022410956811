import React from 'react'

import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

function SelectionModalCompanyInfo({ jobPosting }) {
  const { t } = useTranslation()

  return (
    <ul className="selectionModal__compaUl">
      <li className="selectionModal__compaLi">
        <p className="selectionModal__compaLiTitle">{t('selection.modal.companyName')}</p>
        <p className="selectionModal__compaLiCont">{jobPosting.company_name}</p>
      </li>

      <li className="selectionModal__compaLi">
        <p className="selectionModal__compaLiTitle">{t('selection.modal.recruitmentType')}</p>
        <p className="selectionModal__compaLiCont">{jobPosting.available_position?.name}</p>
      </li>
    </ul>
  )
}

SelectionModalCompanyInfo.propTypes = {
  jobPosting: PropTypes.shape({
    company_name: PropTypes.string,
    available_position: PropTypes.object.isRequired,
  }).isRequired,
}

export default SelectionModalCompanyInfo
