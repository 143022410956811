const { default: produce } = require('immer')
const { fetchChoices } = require('services/choices')

const initialData = {}

const reducer = produce((draft, { type, payload }) => {
  switch (type) {
    case fetchChoices.fulfilled:
      Object.keys(payload).forEach((key) => (draft[key] = payload[key]))
      break
    default:
      return draft
  }
}, initialData)

export default reducer
