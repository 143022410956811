import produce from 'immer'
import { getApplicantsStatus } from 'services/applicantsStatus'
import { AUTH_SIGNOUT } from 'store/auth/actionTypes'

const initialState = {
  items: [],
  state: 'pending',
}

export default produce((draft, { type, payload }) => {
  switch (type) {
    case getApplicantsStatus.pending:
      draft.state = 'pending'
      break
    case getApplicantsStatus.fulfilled:
      draft.state = 'fulfilled'
      draft.items = payload.data
      break
    case getApplicantsStatus.failure:
      draft.state = 'failure'
      break
    case AUTH_SIGNOUT:
      return initialState
    default:
      return draft
  }
}, initialState)
