import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Checkbox from '@mui/material/Checkbox'

function CheckListItem({ item, depth, onItemClick, value, isLabelOnly, disabled }) {
  const labelId = `checkbox-list-label-${item.id}`
  const hasChildren = item?.children?.length > 0
  const isLabelOnlyVal = typeof isLabelOnly === 'function' ? isLabelOnly(item) : false
  const buttonRef = useRef(null)

  const handleClick = (e) => {
    e.target = buttonRef.current
    onItemClick(item, e)
  }

  return (
    <>
      <ListItem disablePadding>
        <ListItemButton
          ref={buttonRef}
          disabled={isLabelOnlyVal || disabled}
          role={undefined}
          onClickCapture={handleClick}
          dense
        >
          {!isLabelOnlyVal && (
            <ListItemIcon>
              <Checkbox
                edge="start"
                checked={value.indexOf(item.id) !== -1}
                tabIndex={-1}
                disableRipple
                inputProps={{ 'aria-labelledby': labelId }}
                disabled={disabled}
              />
            </ListItemIcon>
          )}
          <ListItemText id={labelId} primary={item.name} />
        </ListItemButton>
      </ListItem>

      {hasChildren &&
        item.children.map((nested) => (
          <CheckListItem
            item={nested}
            isLabelOnly={isLabelOnly}
            value={value}
            key={nested.id}
            onItemClick={onItemClick}
            depth={depth + 1}
            disabled={disabled}
          />
        ))}
    </>
  )
}

function CheckList({ onItemClick, value, items, isLabelOnly, disabled }) {
  return (
    <List>
      {items.map((item) => (
        <CheckListItem
          item={item}
          key={item.id}
          value={value}
          depth={1}
          onItemClick={onItemClick}
          isLabelOnly={isLabelOnly}
          disabled={disabled}
        />
      ))}
    </List>
  )
}

const IDType = PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
const ListItemPropType = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  name: PropTypes.string.isRequired,
  children: PropTypes.arrayOf(PropTypes.any),
})

CheckListItem.propTypes = {
  item: ListItemPropType,
  depth: PropTypes.number,
  onItemClick: PropTypes.func,
  value: PropTypes.arrayOf(IDType),
  isLabelOnly: PropTypes.func,
  disabled: PropTypes.bool,
}

CheckList.propTypes = {
  items: PropTypes.arrayOf(ListItemPropType),
  value: PropTypes.arrayOf(IDType),
  onItemClick: PropTypes.func,
  isLabelOnly: PropTypes.func,
  disabled: PropTypes.bool,
}

export default CheckList
