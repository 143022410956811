import * as types from './actionTypes'

const initialState = {
  pending: false,
  success: false,
  error: null,
  referralProposallist: [],
}

export default (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    //referrals list
    case types.GET_REFERRAL_PROPOSALS_LIST_REQUEST:
      return {
        referralProposallist: null,
        pending: true,
        success: false,
      }
    case types.GET_REFERRAL_PROPOSALS_LIST_SUCCESS:
      return {
        referralProposallist: payload.data,
        pending: false,
        success: true,
      }
    case types.GET_REFERRAL_PROPOSALS_LIST_FAILURE:
      return {
        referralProposallist: null,
        pending: false,
        success: false,
        error: payload.error,
      }

    default:
      return state
  }
}
