import React, { memo } from 'react'

import dayjs from 'dayjs'
import isEqual from 'lodash/isEqual'
import PropTypes from 'prop-types'

function DateText({ format, date, defaultText }) {
  const formatted = dayjs(date)
  return <>{formatted.isValid() ? formatted.format(format) : defaultText}</>
}

DateText.propTypes = {
  format: PropTypes.string,
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  defaultText: PropTypes.string,
}

DateText.defaultProps = {
  defaultText: '',
}

export default memo(DateText, isEqual)
