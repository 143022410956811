import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import { calculateModifiers } from 'utils/helper'

function Footer({ children, modifier, className }) {
  return <div className={clsx('footer', calculateModifiers('footer', modifier), className)}>{children}</div>
}

Footer.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  modifier: PropTypes.string,
}

export default Footer
