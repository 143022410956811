import { CustomSelect } from 'components/molecules'
import React from 'react'
import PropTypes from 'prop-types'
import { USER_STATUSES } from 'utils/constants'
import { useTranslation } from 'react-i18next'

function UserStatusSelect(props) {
  const { t } = useTranslation()
  const userStatuses = [
    {
      id: USER_STATUSES.pending,
      label: t('userStatus.pending'),
    },
    {
      id: USER_STATUSES.active,
      label: t('userStatus.active'),
    },
    {
      id: USER_STATUSES.suspended,
      label: t('userStatus.suspended'),
    },
    {
      id: USER_STATUSES.archived,
      label: t('userStatus.archived'),
    },
  ]

  return (
    <CustomSelect
      placeholder={t('userAccount.selectPlaceHolder')}
      options={userStatuses}
      onChange={props.onChange}
      selected={props.value}
    />
  )
}

UserStatusSelect.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.number,
}

export default UserStatusSelect
