import React from 'react'

import PropTypes from 'prop-types'

import { DATE_FORMAT } from 'utils/constants'
import { DateText } from 'components/atoms'

const DateItem = ({ date }) => {
  return (
    <div className="selectionModal__tabContDateWrap">
      <p className="selectionModal__tabContDate">
        <DateText format={`${DATE_FORMAT}(dd)`} date={date} />
      </p>
    </div>
  )
}

DateItem.propTypes = {
  date: PropTypes.string,
}

export default DateItem
