import React from 'react'

import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import { Pagination } from 'components/molecules'
import { actionUpdateQuestionsParams } from 'store/question-and-answers/actionCreators'
import QuestionThreadBox from './Box'
import { fetchQuestions } from 'services/questionAndAnswers'

function QuestionThreadList({ items }) {
  return (
    <ul className="question__mainUl">
      {items.map((item, index) => (
        <li key={item.id} className={clsx('question__mainLi', { 'question__mainLi-mgt0': index === 0 })}>
          <QuestionThreadBox question={item} />
        </li>
      ))}
    </ul>
  )
}

QuestionThreadList.propTypes = {
  items: PropTypes.array,
}

function QuestionThread() {
  const { lastPage, currentPage } = useSelector((state) => state.qAndA.pagination)
  const items = useSelector((state) => state.qAndA.items)
  const state = useSelector((state) => state.qAndA.listState)
  const dispatch = useDispatch()

  const handlePageChange = (page) => {
    dispatch(actionUpdateQuestionsParams({ page }))
    dispatch(fetchQuestions())
  }

  return (
    <>
      {state === 'fulfilled' && <QuestionThreadList items={items} />}
      <Pagination modifier="mgt23" page={currentPage} totalPages={lastPage} onPageChange={handlePageChange} />
    </>
  )
}

export default QuestionThread
