import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import { useHistory, Link as RouterLink } from 'react-router-dom'
import logo from 'assets/img/logo.svg'
import { useDispatch, useSelector } from 'react-redux'
import { getIndustries } from 'services/industries'
import { getOccupations } from 'services/occupations'
import { updateProfile } from 'services/users'
import { useTranslation } from 'react-i18next'
import { Label, Input, Button, Textarea, Checkbox } from 'components/atoms'
import { FormGroup, FormSupArea, FormGroupCheck } from 'components/molecules'
import { AvatarField } from 'components'
import { selectFlattenedIndustries } from 'store/industries/selectors'
import { selectFlattenedOccupations } from 'store/occupations/selectors'
import { CircularProgress, Link } from '@mui/material'
import ModalChecklist from '../ModalChecklist'
import partition from 'lodash/partition'
import AvatarDefaultImg from 'assets/img/unsplash_8YG31Xn4dSw.png'

const UserProfileForm = (props) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { user, location, mode } = props
  const [showPreview, setShowPreview] = useState(false)
  const [successFullname, setSuccessFullname] = useState('')
  const [successMostGoodIndustry, setSuccessMostGoodIndustry] = useState('')
  const [successSpecialytyIndustry, setSuccessSpecialtyIndustry] = useState('')
  const [successMostGoodJob, setSuccessMostGoodJob] = useState('')
  const [successSpecialtyJob, setSuccessSpecialtyJob] = useState('')
  const [successPhoneNumber, setSuccessPhoneNumber] = useState('')
  const [successProfile, setSuccessProfile] = useState(true)
  const [openModal, setOpenModal] = useState(false)
  const [modalTitle, setModalTitle] = useState('')
  const [modalType, setModalType] = useState('')
  const [activeModal, setActiveModal] = useState('')
  const [modalListItems, setModalListItems] = useState([])
  const [selectedAvatar, setSelectedAvatar] = useState({})
  const [bestOccupations, specializedOccupations] = partition(user.occupations || [], ['most_good', true])
  const [bestIndustries, specializedIndustries] = partition(user.industries || [], ['most_good', true])
  const [loading, setLoading] = useState(false)
  const [disabled, setDisabled] = useState(false)
  const industries = useSelector((state) => state.industries.items)
  const allIndustries = useSelector(selectFlattenedIndustries)
  const occupations = useSelector((state) => state.occupations.items)
  const allOccupations = useSelector(selectFlattenedOccupations)
  let storedData = mode == 'edit-profile' ? user : JSON.parse(localStorage.getItem('create_profile_data'))

  const sOccupations = specializedOccupations.reduce((occupation, { parent_id, id }) => {
    if (!occupation[parent_id]) {
      occupation[parent_id] = []
    }
    occupation[parent_id].push(id)
    return occupation
  }, [])

  const sIndustries = specializedIndustries.reduce((occupation, { parent_id, id }) => {
    if (!occupation[parent_id]) {
      occupation[parent_id] = []
    }
    occupation[parent_id].push(id)
    return occupation
  }, [])

  const default_new = {
    lastname: storedData.lastname ?? '',
    firstname: storedData.firstname ?? '',
    avatar: storedData.avatar ?? '',
    most_good_industry: mode == 'edit-profile' ? bestIndustries[0].id : storedData.most_good_industry ?? '',
    specialty_industry: mode == 'edit-profile' ? sIndustries : storedData.specialty_industry ?? [],
    most_good_job: mode == 'edit-profile' ? bestOccupations[0].id : storedData.most_good_job ?? '',
    specialty_job: mode == 'edit-profile' ? sOccupations : storedData.specialty_job ?? [],
    phone_number: mode == 'edit-profile' ? storedData.contact_number : storedData.phone_number ?? '',
    profile: mode == 'edit-profile' ? storedData.self_introduction : storedData.profile ?? '',
  }
  const [data] = useState(default_new)

  const { register, setValue, reset, watch } = useForm({
    defaultValues: data,
  })

  const profile = watch('profile')

  const unfilled = [
    successFullname == '',
    successMostGoodIndustry == '',
    successSpecialytyIndustry == '',
    successMostGoodJob == '',
    successSpecialtyJob == '',
    successPhoneNumber == '',
  ]
  const invalidCount = unfilled.reduce((accum, curr) => accum + (curr ? 1 : 0), 0)

  useEffect(() => {
    reset({
      avatar: data?.avatar,
      lastname: data?.lastname,
      firstname: data?.firstname,
      most_good_industry: data?.most_good_industry,
      specialty_industry: data?.specialty_industry,
      most_good_job: data?.most_good_job,
      specialty_job: data?.specialty_job,
      phone_number: data?.phone_number,
    })
    checkFilled(data)
    if (storedData.avatar && mode == 'setup-profile') {
      setSelectedAvatar(location.state.avatar_file)
    }
  }, [data])

  useEffect(() => {
    dispatch(getIndustries())
    dispatch(getOccupations())
  }, [dispatch])

  const checkFilled = (data) => {
    data?.lastname && data?.firstname ? setSuccessFullname('success') : setSuccessFullname('')
    data?.most_good_industry ? setSuccessMostGoodIndustry('success') : setSuccessMostGoodIndustry('')
    data?.specialty_industry.length ? setSuccessSpecialtyIndustry('success') : setSuccessSpecialtyIndustry('')
    data?.most_good_job ? setSuccessMostGoodJob('success') : setSuccessMostGoodJob('')
    data?.specialty_job.length ? setSuccessSpecialtyJob('success') : setSuccessSpecialtyJob('')
    data?.phone_number && data?.phone_number.length > 9 && data?.phone_number.length < 14
      ? setSuccessPhoneNumber('success')
      : setSuccessPhoneNumber('')
  }

  useEffect(() => {
    if (
      successFullname === 'success' &&
      successMostGoodIndustry === 'success' &&
      successSpecialytyIndustry === 'success' &&
      successMostGoodJob === 'success' &&
      successSpecialtyJob === 'success' &&
      successPhoneNumber === 'success' &&
      successProfile
    ) {
      setShowPreview(true)
    } else {
      setShowPreview(false)
    }
  }, [
    successFullname,
    successMostGoodIndustry,
    successSpecialytyIndustry,
    successMostGoodJob,
    successSpecialtyJob,
    successPhoneNumber,
    successProfile,
  ])

  const rules = {
    profile: {
      maxLength: { value: 5000, message: t('companyAccount.create.textAreaLimit') },
    },
  }

  const handleInput = (e) => {
    if (
      !((e.keyCode > 95 && e.keyCode < 106) || (e.keyCode > 47 && e.keyCode < 58) || e.keyCode == 189 || e.keyCode == 8)
    ) {
      e.preventDefault()
    }
  }

  const handleOnChange = (e) => {
    if (e.target) {
      const { name, value } = e.target
      setValue(name, value)
      data[name] = value
      if (mode == 'edit-profile') {
        let fieldName = name
        if (name == 'phone_number') {
          fieldName = 'contact_number'
        }
        if (name == 'profile') {
          fieldName = 'self_introduction'
        }
        finalData[fieldName] = value
      }
      if (e.target.value[0] === 0) {
        if (e.target.value.replaceAll('-', '').length > 9 && e.target.value.replaceAll('-', '').length < 14) {
          setSuccessPhoneNumber('success')
        } else {
          setSuccessPhoneNumber('')
        }
      } else {
        setSuccessPhoneNumber('')
      }
    } else {
      const value = e
      setValue('avatar', value)
      data['avatar'] = value
      setSelectedAvatar(e)
    }

    checkFilled(data)
  }

  const handleOpenModal = (title, activeModal, type, items) => {
    setModalTitle(title)
    setActiveModal(activeModal)
    setModalType(type)
    setModalListItems(items)
    setOpenModal(true)
  }

  const targetDataToRemove = (modalType, modalListItems) => {
    let dataToRemove = ''
    if (modalListItems === occupations) {
      dataToRemove = 'specialty_job'
      if (modalType == 'checklist') {
        dataToRemove = 'most_good_job'
      }
    } else {
      dataToRemove = 'specialty_industry'
      if (modalType == 'checklist') {
        dataToRemove = 'most_good_industry'
      }
    }

    return data[dataToRemove]
  }

  const handleCloseModal = () => {
    setOpenModal(false)
  }

  const decideModal = (itemsSelected, modalName) => {
    data[modalName] = itemsSelected
    checkFilled(data)
    setOpenModal(false)
  }

  const getParentName = (index, list) => {
    let parent = list.filter((e) => e.id === index)
    return parent[0] ? parent[0].name : null
  }

  const getChild = (id, list) => {
    let child = list.filter((e) => e.id === id)
    return child[0] ? child[0] : null
  }

  const finalData = {
    avatar: data.avatar ? selectedAvatar : '',
    lastname: data.lastname ?? '',
    firstname: data.firstname ?? '',
    most_good_industry: data.most_good_industry ?? '',
    specialty_industry: data.specialty_industry ?? [],
    most_good_job: data.most_good_job ?? '',
    specialty_job: data.specialty_job ?? [],
    contact_number: data.phone_number ?? '',
    self_introduction: data.profile ?? '',
    email: user.email ?? '',
  }

  const getChildren = (lists) => {
    let children = []
    for (const items in lists) {
      if (lists[items] && lists[items].length) {
        for (let i = 0; i < lists[items].length; i++) {
          children.push(lists[items][i])
        }
      }
    }
    return children
  }

  const handleSubmit = () => {
    if (mode == 'edit-profile') {
      setDisabled(true)
      setLoading(true)
      finalData.specialty_industry = getChildren(data.specialty_industry)
      finalData.specialty_job = getChildren(data.specialty_job)
      try {
        dispatch(updateProfile(finalData))
        setLoading(false)
        localStorage.setItem('create_profile_data', JSON.stringify([]))
        history.push('/profile')
      } catch (e) {
        setLoading(false)
        setDisabled(false)
      }
    } else {
      localStorage.setItem('create_profile_data', JSON.stringify(data))
      history.push({
        pathname: '/invitation/user-profile/confirm',
        state: { avatar_file: selectedAvatar },
      })
    }
  }

  return (
    <form
      className="form"
      onSubmit={() => {
        handleSubmit()
      }}
    >
      <div className="app">
        {mode == 'setup-profile' ? (
          <header className="header form__header-jc header-spTop">
            <div className="header__logoArea">
              <h1>
                <img src={logo} alt="HuuNo" className="header__logo" width="72" height="25"></img>
              </h1>
            </div>
          </header>
        ) : (
          <header className="header header-spTop">
            <Link component={RouterLink} to="/profile" className="header__backBtn">
              {t('userProfile.toEdit.backButton')}
            </Link>
          </header>
        )}
        <main className="invitation_main cm cm-pdb">
          <div className="cm__main">
            <div className="form__inner form__inner-sppd">
              <FormGroup modifier="spMgb16">
                {mode == 'setup-profile' ? (
                  <h1 className="cm__title">{t('userProfileInvitation.create.titleLabel')}</h1>
                ) : null}
                <AvatarField
                  label={t('userProfileInvitation.common.profileImageLabel')}
                  uploadLabel={t('userProfileInvitation.create.uploadImageButtonLabel')}
                  src={
                    data.avatar && mode == 'setup-profile'
                      ? URL.createObjectURL(new Blob([selectedAvatar], { type: 'images/jpeg' }))
                      : user?.avatar
                  }
                  onChange={handleOnChange}
                  disabled={true}
                  hidePencil={false}
                  defaultSrc={AvatarDefaultImg}
                />
              </FormGroup>
              {mode == 'setup-profile' ? (
                <FormGroup className="form__group-mgb0 form__group-mgt2 form__group-inSup2">
                  <Label>{t('userProfileInvitation.common.companyNameLabel')}</Label>
                  <p className="cm__formText">{user.company.company_name}</p>
                </FormGroup>
              ) : null}
              {mode == 'setup-profile' ? (
                <FormGroup className="form__group-mgb0 form__group-inSup2">
                  <Label>{t('userProfileInvitation.common.accountTypeLabel')}</Label>
                  <p className="cm__formText">{user.groups[0].name}</p>
                </FormGroup>
              ) : null}
              <FormGroup className={`form__group-mgb0 required ${successFullname}`}>
                <Label>{t('userProfileInvitation.common.fullNameLabel')}</Label>
                <FormGroup className="form__groupFlex form__group-mgt form__groupMultiCheck form__group-mgb0">
                  <FormGroup className="form__group-mgb0">
                    <Input
                      {...register('lastname', { required: 'This is required' })}
                      onChange={(e) => handleOnChange(e)}
                      className="form__inputText form__inputText-w96px form__inputText-mgr inputCheck boolCheckMulti form__group-mgb0"
                      type="text"
                    />
                    <p className="form__inputSup2">{t('userProfileInvitation.create.lastNameLabel')}</p>
                  </FormGroup>
                  <FormGroup className="form__group-mgb0">
                    <Input
                      {...register('firstname', { required: 'This is required' })}
                      onChange={(e) => handleOnChange(e)}
                      className="form__inputText form__inputText-w96px form__inputText-mgr inputCheck boolCheckMulti"
                      type="text"
                    />
                    <p className="form__inputSup2">{t('userProfileInvitation.create.firstNameLabel')}</p>
                  </FormGroup>
                </FormGroup>
                <FormGroup className="form__group-mgb0 form__supArea form__supArea-flex">
                  <p className="form__alert">{t('userProfileInvitation.create.pleaseEnter')}</p>
                </FormGroup>
              </FormGroup>
              <ModalChecklist
                open={openModal}
                onClose={handleCloseModal}
                title={modalTitle}
                modalType={modalType}
                activeModal={activeModal}
                modalListItems={modalListItems}
                submitModal={decideModal}
                data={data}
                limit={4}
                dataToRemove={targetDataToRemove(modalType, modalListItems)}
              />
              <FormGroup className={`required ${successMostGoodIndustry}`}>
                <Label>{t('userProfileInvitation.create.mostGoodIndustryLabel')}</Label>
                {data.most_good_industry > 0 ? (
                  <p className="cm__formText">
                    {allIndustries.filter((e) => e.id === data.most_good_industry)[0]
                      ? allIndustries.filter((e) => e.id === data.most_good_industry)[0].name
                      : null}
                  </p>
                ) : null}
                <FormGroup className="form__groupFlex form__group-mgt form__groupMultiCheck form__group-mgb0">
                  <Button
                    className="form__fileLabel-plus btn btn__outlineBtn popModal pc"
                    data-modal="modal"
                    onClick={() => {
                      handleOpenModal(
                        t('userProfileInvitation.create.modal.mostGoodIndustryTitle'),
                        'most_good_industry',
                        'radio',
                        industries
                      )
                    }}
                  >
                    {t('userProfileInvitation.create.selectLabel')}
                  </Button>
                  <div className="form__supArea form__supArea-flex">
                    <p className="form__alert">ご入力ください。</p>
                  </div>
                </FormGroup>
              </FormGroup>
              <FormGroup className={`required ${successSpecialytyIndustry}`}>
                <Label>{t('userProfileInvitation.create.specialtyIndustryLabel')}</Label>
                {data.specialty_industry.length > 0
                  ? data.specialty_industry.map((item, index) =>
                      data.specialty_industry[index] !== null ? (
                        data.specialty_industry[index].length ? (
                          <FormGroup className="form__group-mgb0" key={index}>
                            <h3 className="search__conditonCheckTitle">{getParentName(index, industries)}</h3>
                            {data.specialty_industry[index]
                              ? data.specialty_industry[index].map((option, idx) => (
                                  <FormGroupCheck.Item
                                    key={idx}
                                    Input={Checkbox}
                                    inputProps={{
                                      value: getChild(option, allIndustries) ? getChild(option, allIndustries).id : 0,
                                      blockElClass: `form__checkItemModal checked`,
                                    }}
                                    label={
                                      getChild(option, allIndustries) ? getChild(option, allIndustries).name : null
                                    }
                                    type="square"
                                  />
                                ))
                              : null}
                          </FormGroup>
                        ) : null
                      ) : null
                    )
                  : null}
                <FormGroup className="form__groupFlex form__group-mgt form__groupMultiCheck form__group-mgb0">
                  <Button
                    className="form__fileLabel-plus btn btn__outlineBtn popModal pc"
                    data-modal="modal"
                    onClick={() => {
                      handleOpenModal(
                        t('userProfileInvitation.create.modal.specialtyIndustryTitle'),
                        'specialty_industry',
                        'checklist',
                        industries
                      )
                    }}
                  >
                    {t('userProfileInvitation.create.selectLabel')}
                  </Button>
                  <div className="form__supArea form__supArea-flex">
                    <p className="form__alert">ご入力ください。</p>
                  </div>
                </FormGroup>
              </FormGroup>
              <FormGroup className={`required ${successMostGoodJob}`}>
                <Label>{t('userProfileInvitation.create.mostGoodJobLabel')}</Label>
                {data.most_good_job > 0 ? (
                  <p className="cm__formText">
                    {allOccupations.filter((e) => e.id === data.most_good_job)[0]
                      ? allOccupations.filter((e) => e.id === data.most_good_job)[0].name
                      : null}
                  </p>
                ) : null}
                <FormGroup className="form__groupFlex form__group-mgt form__groupMultiCheck form__group-mgb0">
                  <Button
                    className="form__fileLabel-plus btn btn__outlineBtn popModal pc"
                    data-modal="modal"
                    onClick={() => {
                      handleOpenModal(
                        t('userProfileInvitation.create.modal.mostGoodJobTitle'),
                        'most_good_job',
                        'radio',
                        occupations
                      )
                    }}
                  >
                    {t('userProfileInvitation.create.selectLabel')}
                  </Button>
                  <div className="form__supArea form__supArea-flex">
                    <p className="form__alert">ご入力ください。</p>
                  </div>
                </FormGroup>
              </FormGroup>
              <FormGroup className={`required ${successSpecialtyJob}`}>
                <Label>{t('userProfileInvitation.create.specialtyJobLabel')}</Label>
                {data.specialty_job.length > 0
                  ? data.specialty_job.map((item, index) =>
                      data.specialty_job[index] !== null ? (
                        data.specialty_job[index].length ? (
                          <FormGroup className="form__group-mgb0" key={index}>
                            <h3 className="search__conditonCheckTitle">{getParentName(index, occupations)}</h3>
                            {data.specialty_job[index]
                              ? data.specialty_job[index].map((option, idx) => (
                                  <FormGroupCheck.Item
                                    key={idx}
                                    Input={Checkbox}
                                    inputProps={{
                                      value: getChild(option, allOccupations) ? getChild(option, allOccupations).id : 0,
                                      blockElClass: `form__checkItemModal checked`,
                                    }}
                                    label={
                                      getChild(option, allOccupations) ? getChild(option, allOccupations).name : null
                                    }
                                    type="square"
                                  />
                                ))
                              : null}
                          </FormGroup>
                        ) : null
                      ) : null
                    )
                  : null}
                <FormGroup className="form__groupFlex form__group-mgt form__groupMultiCheck form__group-mgb0">
                  <Button
                    className="form__fileLabel-plus btn btn__outlineBtn popModal pc"
                    data-modal="modal"
                    onClick={() => {
                      handleOpenModal(
                        t('userProfileInvitation.create.modal.specialtyJobTitle'),
                        'specialty_job',
                        'checklist',
                        occupations
                      )
                    }}
                  >
                    {t('userProfileInvitation.create.selectLabel')}
                  </Button>
                  <div className="form__supArea form__supArea-flex">
                    <p className="form__alert">ご入力ください。</p>
                  </div>
                </FormGroup>
              </FormGroup>
              <FormGroup className={`required ${successPhoneNumber}`}>
                <Label>{t('userProfileInvitation.common.phoneNumberLabel')}</Label>
                <Input
                  className="form__inputText form__group-mgt form__inputText-w256px inputCheck boolCheck"
                  type="text"
                  onKeyDown={handleInput}
                  {...register('phone_number', { required: 'This is required' })}
                  onChange={(e) => {
                    handleOnChange(e)
                  }}
                />
              </FormGroup>
              <FormGroup
                className={successProfile ? 'form__group form__group-mgb1' : 'form__group form__group-mgb1 error'}
              >
                <label className="form__label form__label-mini">{t('userProfileInvitation.common.profileLabel')}</label>
                <Textarea
                  {...register('profile', { required: 'This is required' })}
                  onChange={(e) => {
                    handleOnChange(e)
                    e.target.value.length + (e.target.value.split(/\r\n|\r|\n/).length - 1) <=
                    rules.profile.maxLength.value
                      ? setSuccessProfile(true)
                      : setSuccessProfile(false)
                  }}
                  cols="30"
                  rows="10"
                  modifier="lh2em"
                  className="form__group-mgt inputCalc"
                ></Textarea>
                <FormSupArea modifier="flex">
                  <FormSupArea.InputSup modifier="end" inputValue={profile} max={rules.profile.maxLength.value} />
                </FormSupArea>
              </FormGroup>
            </div>
          </div>
        </main>
        <footer className="footer">
          <div className="footer__inner footer__inner-jce footer__inner-w824">
            {showPreview ? (
              <Button
                type="submit"
                id="footerBtn"
                className="btn
                btn__shadowBtn"
                hidden={true}
                disabled={disabled}
              >
                {mode == 'setup-profile'
                  ? t('userProfileInvitation.create.verifyButtonLabel')
                  : t('userProfile.toEdit.saveButton')}
                {loading && <CircularProgress size="1.5rem" />}
              </Button>
            ) : (
              <p className="main__footerReq" id="footerReq">
                {t('userProfileInvitation.create.numberofRequiredFieldsLabel', { count: invalidCount })}
              </p>
            )}
          </div>
        </footer>
      </div>
    </form>
  )
}

UserProfileForm.propTypes = {
  location: PropTypes.object,
  user: PropTypes.object,
  mode: PropTypes.string,
  finalData: PropTypes.object,
}

export default UserProfileForm
