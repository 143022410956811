import React from 'react'

import PropTypes from 'prop-types'

import { BlockEl } from 'components/atoms'

const BoxName = ({ name }) => <div className="selection__mainName">{name}</div>

BoxName.propTypes = {
  name: PropTypes.string.isRequired,
}

const BoxNameHeader = ({ name_header }) => (
  <div className="selection__mainName selection__mainName-ais selection-pdb0">{name_header}</div>
)

BoxNameHeader.propTypes = {
  name_header: PropTypes.string.isRequired,
}

const BoxJobInfo = ({ job, info }) => (
  <div className="selection__mainJobInfo">
    <span className="selection__mainLi-gray selection-dpb">{job}</span>
    {info}
  </div>
)

BoxJobInfo.propTypes = {
  job: PropTypes.string.isRequired,
  info: PropTypes.string.isRequired,
}

const BoxJobInfoHeader = ({ job_info_header }) => (
  <div className="selection__mainJobInfo selection-pdb0">{job_info_header}</div>
)

BoxJobInfoHeader.propTypes = {
  job_info_header: PropTypes.string.isRequired,
}

const BoxCompany = ({ company }) => <div className="selection__mainCompany">{company}</div>

BoxCompany.propTypes = {
  company: PropTypes.string.isRequired,
}

const BoxCompanyHeader = ({ company_header }) => (
  <div className="selection__mainCompany selection-pdb0">{company_header}</div>
)

BoxCompanyHeader.propTypes = {
  company_header: PropTypes.string.isRequired,
}

const BoxStep = ({ step, date }) => (
  <div className="selection__mainStep">
    {step}
    <span className="selection__mainLi-gray selection-dpb">{date}</span>
  </div>
)

BoxStep.propTypes = {
  step: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
}

const BoxStepHeader = ({ step_header }) => <div className="selection__mainStep selection-pdb0">{step_header}</div>

BoxStepHeader.propTypes = {
  step_header: PropTypes.string.isRequired,
}

const BoxSituation = ({ situation, situationColor }) => (
  <div className="selection__mainSituation">
    <BlockEl bec="selection__mainSituationMark" modifier={[situationColor]}>
      {situation}
    </BlockEl>
  </div>
)

BoxSituation.propTypes = {
  situation: PropTypes.string.isRequired,
  situationColor: PropTypes.string.isRequired,
}

const BoxSituationHeader = ({ situation_header }) => (
  <div className="selection__mainSituation selection-pdb0">{situation_header}</div>
)

BoxSituationHeader.propTypes = {
  situation_header: PropTypes.string.isRequired,
}

const Box = ({ children }) => <>{children}</>

Box.propTypes = {
  children: PropTypes.node,
}

Box.Name = BoxName
Box.JobInfo = BoxJobInfo
Box.Company = BoxCompany
Box.Step = BoxStep
Box.Situation = BoxSituation
Box.NameHeader = BoxNameHeader
Box.JobInfoHeader = BoxJobInfoHeader
Box.CompanyHeader = BoxCompanyHeader
Box.StepHeader = BoxStepHeader
Box.SituationHeader = BoxSituationHeader

export default Box
