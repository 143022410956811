import React from 'react'
import WhiteBatuSvg from 'assets/img/white-batu.svg'

import PropTypes from 'prop-types'

import { useTranslation } from 'react-i18next'

function Comment({ focusComment1, focusComment2 }) {
  const { t } = useTranslation()

  return (
    <div className="comment">
      {focusComment1 === true || focusComment2 === true ? (
        <div
          className={focusComment1 === true ? 'comment__inner' : 'comment__inner comment__inner-up'}
          id={focusComment1 === true ? 'exp1' : 'exp2'}
          style={{ display: 'block' }}
        >
          <p className="comment__title pc">{t('jobPosting.step2.commentTitle')}</p>
          {focusComment1 === true ? (
            <p className="comment__text">{t('jobPosting.step2.difficultyCommentText')}</p>
          ) : (
            <p className="comment__text">
              {t('jobPosting.step2.specialNotCommentText1')}
              <br />
              {t('jobPosting.step2.specialNotCommentText2')}
            </p>
          )}

          <p className="comment__close sp">
            <img src={WhiteBatuSvg} alt="" width="12" height="12" />
          </p>
        </div>
      ) : null}
    </div>
  )
}

Comment.propTypes = {
  focusComment1: PropTypes.any,
  focusComment2: PropTypes.any,
}

export default Comment
