import Http from 'utils/Http'

import {
  getPrefecturesListRequest,
  getPrefecturesListSuccess,
  getPrefecturesListFailure,
} from 'store/common/actionCreators'

const SUCCESS = 200

export function getPrefecturesList() {
  return (dispatch) => {
    dispatch(getPrefecturesListRequest())
    return Http.get(`/common/filters/prefectures`)
      .then((response) => {
        if (response.status === SUCCESS) {
          return dispatch(getPrefecturesListSuccess(response.data))
        }
      })
      .catch((error) => {
        return dispatch(getPrefecturesListFailure(error.response.data.error))
      })
  }
}
