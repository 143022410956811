import isCompanySetupInvitation from './userCompanySetupInvitation'
import isAccountSetupInvitation from './userAccountSetupInvitation'
import { orGuard } from '../RouteGuard'

export default (ctx, next) => {
  if (!ctx.user) {
    return next(false)
  }
  orGuard(isCompanySetupInvitation(), isAccountSetupInvitation())(ctx, next)
}
