import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useForm, Controller, FormProvider } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import logo from 'assets/img/logo.svg'
import { useTranslation } from 'react-i18next'
import { Input, Label, Textarea } from 'components/atoms'
import { FormGroup, FormSupArea } from 'components/molecules'
import { getIndustries } from 'services/industries'
import isEmpty from 'lodash/isEmpty'
import partition from 'lodash/partition'
import { useDispatch } from 'react-redux'
import { makeFormGroupStateGetter } from 'utils/helper'
import InvalidFieldCount from '../InvalidFieldCount'
import IndustriesField from './IndustriesField'

const defaultValues = {
  lastname: '',
  firstname: '',
  year_of_establishment: '',
  closing_month: '',
  hp: '',
  number_of_employees: '',
  company_profile: '',
  business_content: '',
  industries: [],
}

const CompanyAccountForm = (props) => {
  const history = useHistory()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { user } = props

  const methods = useForm({
    defaultValues,
    mode: 'all',
  })
  const { register, handleSubmit, reset, watch, getFieldState, trigger } = methods

  useEffect(() => {
    let storedData = JSON.parse(localStorage.getItem('company_account_data') || '{}') || {}

    const initialData = {
      lastname: storedData?.lastname ?? defaultValues.lastname,
      firstname: storedData?.firstname ?? defaultValues.firstname,
      industries: storedData?.industries ?? defaultValues.industries,
      year_of_establishment: storedData?.year_of_establishment ?? defaultValues.year_of_establishment,
      closing_month: storedData?.closing_month ?? defaultValues.closing_month,
      hp: storedData?.hp ?? defaultValues.hp,
      number_of_employees: storedData?.number_of_employees ?? defaultValues.number_of_employees,
      company_profile: storedData?.company_profile ?? defaultValues.company_profile,
      business_content: storedData?.business_content ?? defaultValues.business_content,
    }

    reset(initialData, {
      keepDefaultValues: true,
    })
  }, [])

  const company_profile = watch('company_profile')
  const business_content = watch('business_content')

  useEffect(() => {
    dispatch(getIndustries())
  }, [dispatch])

  const rules = {
    company_profile: {
      maxLength: { value: 5000, message: t('companyAccount.create.textAreaLimit') },
    },
    business_content: {
      maxLength: { value: 5000, message: t('companyAccount.create.textAreaLimit') },
    },
    industries: {
      required: t('validation.required'),
      validate: (v) => v.length > 0 && v.length <= 5,
    },
    firstname: {
      required: t('validation.required'),
    },
    lastname: {
      required: t('validation.required'),
    },
    year_of_establishment: {
      required: t('validation.required'),
      min: 1111,
      max: 9999,
    },
    closing_month: {
      required: t('validation.required'),
      min: 1,
      max: 12,
    },
    number_of_employees: {
      required: t('validation.required'),
      min: 1,
    },
    hp: {
      validate: (v) => {
        if (isEmpty(v)) return true

        const pattern = new RegExp(
          '^(https?:\\/\\/)?' + // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$',
          'i'
        )
        return pattern.test(v)
      },
    },
  }

  const { fromFieldState, byName, and } = makeFormGroupStateGetter(getFieldState)

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={handleSubmit((data) => {
          localStorage.setItem('company_account_data', JSON.stringify(data))
          history.push({
            pathname: '/invitation/company-account/confirm',
          })
        })}
      >
        <div>
          <header className="header form__header-jc header-spTop">
            <div className="header__logoArea">
              <h1 className="header__logoWrap">
                <img src={logo} alt="HuuNo" className="header__logo" width="72" height="25"></img>
              </h1>
            </div>
          </header>
          <main className="invitation_main cm cm-pdb">
            <div className="cm__main">
              <div className="form__inner form__inner-sppd">
                <h1 className="cm__title">{t('companyAccount.create.titleLabel')}</h1>
                <div className="form__groupFlex-col form__group-inSup2">
                  <label className="form__label  form__label-mgt0 form__label-mini">
                    {t('companyAccount.create.nameLabel')}
                  </label>
                  <p className="cm__formText">{user.company.company_name}</p>
                </div>
                <div className="form__group form__group-inSup2">
                  <label className="form__label form__label-mini">{t('companyAccount.common.numberLabel')}</label>
                  <p className="cm__formText">{user.company.telephone_number}</p>
                </div>
                <div className="form__group form__group-inSup2">
                  <label className="form__label form__label-mini">{t('companyAccount.common.addressLabel')}</label>
                  <p className="cm__formText">{user.company.address}</p>
                </div>
                <div className="form__group form__group-inSup2">
                  <label className="form__label form__label-mini">
                    {t('companyAccount.common.initialPublicOfferingLabel')}
                  </label>
                  <p className="cm__formText">{user.company.ipo}</p>
                </div>
                {/* representative name */}
                <FormGroup {...and(byName('lastname'), byName('firstname'))} required modifier="mgb0 spMgb16">
                  <Label>{t('companyAccount.common.respresentativeNameLabel')}</Label>
                  <div className="form__groupFlex form__group-mgt form__groupMultiCheck ">
                    <div>
                      <Input type="text" {...register('lastname', rules.lastname)} modifier="w96px mgr" />
                      <p className="form__inputSup2">{t('companyAccount.create.lastName')}</p>
                    </div>
                    <div>
                      <Input type="text" {...register('firstname', rules.firstname)} modifier="w96px mgr" />
                      <p className="form__inputSup2">{t('companyAccount.create.firstName')}</p>
                    </div>
                  </div>
                </FormGroup>
                {/* industry */}

                <IndustriesField name="industries" fromFieldState={fromFieldState} rules={rules.industries} />

                {/* years of establishment */}
                <Controller
                  name="year_of_establishment"
                  rules={rules.year_of_establishment}
                  render={({ fieldState, field }) => (
                    <FormGroup {...fromFieldState(fieldState)} required modifier="mgb0 spMgb16">
                      <Label>{t('companyAccount.common.yearOfEstablishmentLabel')}</Label>
                      <div className="form__groupFlex form__group-mgt form__groupMultiCheck">
                        <Input {...field} type="number" min="1111" max="9999" modifier="w6em" />
                        <p className="form__inputAfterText">
                          {t('companyAccount.create.yearOfEstablishmentUnitLabel')}
                        </p>
                      </div>
                      <p className="form__inputSup2">{t('companyAccount.create.yearOfEstablishmentSampleLabel')}</p>
                    </FormGroup>
                  )}
                />
                {/* closing month */}
                <Controller
                  name="closing_month"
                  rules={rules.closing_month}
                  render={({ fieldState, field }) => (
                    <FormGroup {...fromFieldState(fieldState)} required modifier="mgb0 spMgb16">
                      <Label>{t('companyAccount.common.closingMonthLabel')}</Label>
                      <div className="form__groupFlex form__group-mgt form__groupMultiCheck">
                        <Input
                          {...field}
                          onChange={(e) => {
                            field.onChange(e.target.value)
                            trigger('closing_month')
                          }}
                          type="number"
                          min="1"
                          max="12"
                          modifier="w6em"
                        />
                        <p className="form__inputAfterText">{t('companyAccount.create.closingMonthUnitLabel')}</p>
                      </div>
                      <p className="form__inputSup2">{t('companyAccount.create.closingMonthSampleLabel')}</p>
                    </FormGroup>
                  )}
                />
                {/* company hp */}
                <Controller
                  name={'hp'}
                  rules={rules.hp}
                  render={({ fieldState, field }) => (
                    <FormGroup error={fromFieldState(fieldState).error} modifier="mgb1">
                      <Label modifier="flex">{t('companyAccount.common.companyHPLabel')}</Label>
                      <Input type="text" {...field} modifier="w632px" />
                      <p className="form__inputSup2">{t('companyAccount.create.companyHPSampleLabel')}</p>
                    </FormGroup>
                  )}
                />
                {/* number of employees */}
                <FormGroup {...byName('number_of_employees')} required modifier="mgb0 spMgb16">
                  <label className="form__label form__label-mini">
                    {t('companyAccount.common.numberOfEmployeesLabel')}
                  </label>
                  <div className="form__groupFlex form__group-mgt form__groupMultiCheck">
                    <Input
                      type="number"
                      {...register('number_of_employees', rules.number_of_employees)}
                      modifier="w6em"
                    />
                    <p className="form__inputAfterText">人</p>
                  </div>
                </FormGroup>
                {/* company profile */}
                <FormGroup error={byName('company_profile').error} modifier="mgb1">
                  <Label modifier="flex">{t('companyAccount.common.companyProfileLabel')}</Label>
                  <Textarea
                    {...register('company_profile', rules.company_profile)}
                    cols="30"
                    rows="10"
                    className="form__group-mgt"
                  ></Textarea>
                  <FormSupArea modifier="flex">
                    <FormSupArea.InputSup
                      modifier="end"
                      inputValue={company_profile}
                      max={rules.company_profile.maxLength.value}
                    />
                  </FormSupArea>
                </FormGroup>
                {/* business content */}
                <FormGroup error={byName('business_content').error} modifier="mgb1">
                  <Label className="form__label form__label-mini">
                    {t('companyAccount.common.businessContentLabel')}
                  </Label>
                  <Textarea
                    {...register('business_content', rules.business_content)}
                    cols="30"
                    rows="10"
                    className="form__group-mgt"
                  ></Textarea>
                  <FormSupArea modifier="flex">
                    <FormSupArea.InputSup
                      modifier="end"
                      inputValue={business_content}
                      max={rules.business_content.maxLength.value}
                    />
                  </FormSupArea>
                </FormGroup>
              </div>
            </div>
          </main>
          <footer className="footer">
            <div className="footer__inner footer__inner-jce footer__inner-w824">
              <InvalidFieldCount
                name={Object.keys(defaultValues)}
                isRequired={(field) =>
                  [
                    'firstname',
                    'lastname',
                    'industries',
                    'year_of_establishment',
                    'closing_month',
                    'number_of_employees',
                  ].includes(field)
                }
                accumulator={(invalidFields) => {
                  const [target, others] = partition(invalidFields, (field) =>
                    ['firstname', 'lastname'].includes(field)
                  )

                  return others.length + (target.length > 0 ? 1 : 0)
                }}
                render={({ invalid, count }) =>
                  !invalid ? (
                    <input
                      type="submit"
                      id="footerBtn"
                      className="btn btn__shadowBtn"
                      hidden={true}
                      value={t('companyAccount.create.verifyButtonLabel')}
                    />
                  ) : (
                    <p className="main__footerReq" id="footerReq">
                      {t('companyAccount.create.numberofRequiredFieldsLabel', { count })}
                    </p>
                  )
                }
              />
            </div>
          </footer>
        </div>
      </form>
    </FormProvider>
  )
}

CompanyAccountForm.propTypes = {
  user: PropTypes.object,
  mode: PropTypes.string,
  finalData: PropTypes.object,
}

export default CompanyAccountForm
