import {
  actionCreateNewMessageFailure,
  actionCreateNewMessageRequest,
  actionCreateNewMessageSuccess,
  actionLoadMoreThreadMessagesFailure,
  actionLoadMoreThreadMessagesRequest,
  actionLoadMoreThreadMessagesSuccess,
  actionLoadThreadMessagesFailure,
  actionLoadThreadMessagesRequest,
  actionLoadThreadMessagesSuccess,
} from 'store/chat-threads/actionCreators'
import { selectThreadPagination } from 'store/chat-threads/selectors'
import { createAsyncThunk } from 'store/helpers'
import { parseAxiosResponse } from 'utils/helper'
import Http from 'utils/Http'

export function loadThreadMessages(threadId) {
  return (dispatch, getState) => {
    const pagination = selectThreadPagination(threadId, getState())

    dispatch(actionLoadThreadMessagesRequest(threadId))

    return parseAxiosResponse(Http.get(`threads/${threadId}/messages`, { params: pagination })).then(
      (data) => {
        dispatch(actionLoadThreadMessagesSuccess(threadId, data))
      },
      (error) => {
        dispatch(actionLoadThreadMessagesFailure(threadId, error))
      }
    )
  }
}

export const loadLatestMessages = createAsyncThunk('LOAD_LATEST_MESSAGES', (threadId) =>
  parseAxiosResponse(Http.get(`threads/${threadId}/messages`))
)

export const loadMessage = createAsyncThunk('LOAD_MESSAGE', ({ id, threadId }) =>
  parseAxiosResponse(Http.get(`threads/${threadId}/messages/${id}`)).then((data) => ({ threadId, data }))
)

export function loadMoreThreadMsesages(threadId) {
  return (dispatch, getState) => {
    const pagination = selectThreadPagination(threadId, getState())
    dispatch(actionLoadMoreThreadMessagesRequest(threadId))
    return parseAxiosResponse(
      Http.get(`threads/${threadId}/messages`, {
        params: {
          page: pagination.page + 1,
          limit: pagination.limit,
        },
      })
    ).then(
      (data) => {
        dispatch(actionLoadMoreThreadMessagesSuccess(threadId, data))
      },
      (error) => {
        dispatch(actionLoadMoreThreadMessagesFailure(threadId, error))
      }
    )
  }
}

export function createNewMessage(threadId, { message, attachments = [] }) {
  return (dispatch) => {
    dispatch(actionCreateNewMessageRequest(threadId))

    const formData = new FormData()

    formData.append('message', message)

    attachments.forEach((attachment) => formData.append('attachments[]', attachment))

    return parseAxiosResponse(Http.post(`threads/${threadId}/messages`, formData)).then(
      (data) => {
        dispatch(actionCreateNewMessageSuccess(threadId, data))

        return { threadId, data }
      },
      (error) => {
        dispatch(actionCreateNewMessageFailure(threadId, error))

        return { threadId, error }
      }
    )
  }
}

export const markAllThreadMessagesAsRead = createAsyncThunk('MARK_ALL_THREAD_MESSAGES_AS_READ', (threadId) => {
  return parseAxiosResponse(Http.post(`threads/${threadId}/mark_as_read`))
})
