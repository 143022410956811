import React from 'react'

import PropTypes from 'prop-types'
import clsx from 'clsx'

import { calculateModifiers } from 'utils/helper'
import PaginationItem from '../PaginationItem'
import { usePagination } from 'utils/hooks'

function Pagination(props) {
  const { modifier, items, totalPages } = usePagination({ ...props })

  return (
    <div className={clsx('pagenation', calculateModifiers('pagenation', modifier), { spPagenation: totalPages <= 1 })}>
      {items.map((item, i) => (
        <PaginationItem key={i} {...item} />
      ))}
    </div>
  )
}

Pagination.propTypes = {
  modifier: PropTypes.string,
  page: PropTypes.number.isRequired,
  onPageChange: PropTypes.func,
  totalPages: PropTypes.number.isRequired,
  boundaryCount: PropTypes.number,
  siblingCount: PropTypes.number,
  disabled: PropTypes.bool,
}

export default Pagination
