import React from 'react'

import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import OtherJobsCard from '../OtherJobsCard'
import { useSelector } from 'react-redux'

const OtherJobsContainer = ({ main }) => (
  <div className="otherjob__cont">
    <div className="otherjob__main">{main}</div>
  </div>
)
OtherJobsContainer.propTypes = {
  main: PropTypes.any,
}

function OtherJobsTab({ jobPostingId }) {
  const { t } = useTranslation()
  const total = useSelector((state) => state.otherJobs.pagination.total)

  return (
    <div className="evaluation__leftMainCont">
      <h2 className="evaluation__leftMainTitle">
        {t('otherJobs.title')}
        <span className="evaluation__leftMainTitle-num">{t('otherJobs.numQuestions', { count: total })}</span>
      </h2>
      <OtherJobsCard jobPostingId={jobPostingId} />
      <OtherJobsContainer />
    </div>
  )
}

OtherJobsTab.propTypes = {
  jobPostingId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}

export default OtherJobsTab
