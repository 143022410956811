import React from 'react'

import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import isEmpty from 'lodash/isEmpty'

import { BlockEl, DownloadLink, NewlineText } from 'components/atoms'
import { accountType, getUrlFilename } from 'utils/helper'
import { ReactComponent as ClipIcon } from 'assets/img/clip-icon.svg'
import WithAvatarItem from './WithAvatarItem'
import { selectAuthUser } from 'store/auth/selectors'
import { downloadPath } from 'services/download'

const RegularItem = ({ message }) => {
  const user = useSelector(selectAuthUser)
  const noMessage = isEmpty(message.message)

  return (
    <WithAvatarItem message={message}>
      <BlockEl blockElClass="selectionModal__tabContComment" modifier={{ bgy: accountType(user) === 'HR' }}>
        <NewlineText text={message.message} />

        {message.attachments?.length > 0 && (
          <BlockEl bec="selectionModal__tabContAttachmentsArea" modifier={{ mgt0: noMessage }}>
            {message.attachments?.map((attachment) => (
              <DownloadLink
                className="selectionModal__tabContAttachment"
                key={attachment.id}
                src="#"
                resolver={() => downloadPath(attachment.path)}
                text={getUrlFilename(attachment.url)}
                filename={getUrlFilename(attachment.url)}
                start={<ClipIcon className="selectionModal__tabContAttachmentIcon" />}
              />
            ))}
          </BlockEl>
        )}
      </BlockEl>
    </WithAvatarItem>
  )
}

RegularItem.propTypes = {
  message: PropTypes.object,
}

export default RegularItem
