import Http from 'utils/Http'
import {
  getAdInchargeListRequest,
  getAdInchargeListSuccess,
  getAdInchargeListFailure,
} from 'store/adIncharge/actionCreators'

const SUCCESS = 200

export function getAdInChargeList({ params } = {}) {
  return (dispatch) => {
    dispatch(getAdInchargeListRequest())
    return Http.get('/common/co_workers', { params })
      .then((response) => {
        if (response.status === SUCCESS) {
          return dispatch(getAdInchargeListSuccess(response.data))
        }
      })
      .catch((error) => {
        return dispatch(getAdInchargeListFailure(error.response.data.error))
      })
  }
}
