import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

const CandidateContentBox = ({ company_name, title, job_id }) => {
  const { t } = useTranslation()

  return (
    <li className="candidate__modalTabContBoxLi">
      <p className="candidate__modalTabContBoxName">{company_name}</p>
      <p className="candidate__modalTabContBoxTitle">{title}</p>
      <div className="candidate__modalTabContBoxFooter">
        <a href={'/job-postings/' + job_id} target="_blank" rel="noreferrer" className="candidate__modalTabContBoxLink">
          {t('candidateSlideout.checkDetails')}
        </a>
      </div>
    </li>
  )
}

CandidateContentBox.propTypes = {
  company_name: PropTypes.string,
  title: PropTypes.string,
  job_id: PropTypes.number,
}

export default CandidateContentBox
