import React, { useState } from 'react'

import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import CandidateBasicInfoTab from '../CandidateBasicInfoTab'
import CandidateRecommendationTab from '../CandidateRecommendationTab'
import CandidateResumePortfolioTab from '../CandidateResumePortfolioTab'

const CandidateInformationTabsCard = ({ open, row }) => {
  const { t } = useTranslation()
  const items = [
    {
      id: 'basicInfo',
      label: t('candidateSlideout.tabs.basicInfo'),
    },
    {
      id: 'recommendationInformation',
      label: t('candidateSlideout.tabs.recommendationInformation'),
    },
    {
      id: 'curriculumVitaeAndPortfolio',
      label: t('candidateSlideout.tabs.curriculumVitaeAndPortfolio'),
    },
  ]
  const defaultTab = items[0].id
  const [active, setActive] = useState(defaultTab)

  const handleClick = (tab) => {
    if (tab === active) return

    setActive(tab)
  }

  return (
    <>
      <ul className="candidate__modalTabs">
        {items.map((item, index) => (
          <li
            onClick={() => handleClick(item.id)}
            key={index}
            className={`candidate__modalTab ${active === item.id && 'candidate__modalTab-active'}`}
          >
            {item.label}
          </li>
        ))}
      </ul>
      <div className="candidate__modalTabContArea">
        {active === 'basicInfo' && <CandidateBasicInfoTab open={open} row={row} />}
        {active === 'recommendationInformation' && <CandidateRecommendationTab applicantId={row.id} />}
        {active === 'curriculumVitaeAndPortfolio' && <CandidateResumePortfolioTab applicantId={row.id} />}
      </div>
    </>
  )
}

CandidateInformationTabsCard.propTypes = {
  row: PropTypes.object,
  open: PropTypes.bool,
}

export default CandidateInformationTabsCard
