import produce from 'immer'
import * as ActionTypes from './actionTypes'

const initialState = {
  items: [],
  fetching: false,
  status: 'pending',
}

export default produce((draft, { type, payload }) => {
  switch (type) {
    case ActionTypes.LOAD_WORKPOINTS_FAILURE:
      draft.fetching = false
      draft.status = 'failure'
      return draft
    case ActionTypes.LOAD_WORKPOINTS_SUCCESS:
      draft.items = payload.data
      draft.fetching = false
      draft.status = 'fulfilled'
      return draft
    case ActionTypes.LOAD_WORKPOINTS_REQUEST:
      draft.fetching = true
      draft.status = 'pending'
      return draft
    default:
      return draft
  }
}, initialState)
