import React from 'react'

import PropTypes from 'prop-types'

function FormAlert({ message }) {
  return <p className="form__alert">{message}</p>
}

FormAlert.propTypes = {
  message: PropTypes.string,
}

export default FormAlert
