import React, { lazy, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { selectAuthUser } from 'store/auth/selectors'

const UnauthorizedScreen = lazy(() => import('../views/Unauthorized'))

export const orGuard = (...guards) => {
  return (ctx, next) => {
    const results = []
    const guardsLength = guards.length

    const nextNow = () => {
      next(results.some((res) => res))
    }

    const handleGuard = (result) => {
      results.push(result)

      if (results.length === guardsLength) {
        nextNow()
      }
    }
    guards.forEach((guard) => guard.apply(null, [ctx, handleGuard]))
  }
}

function RouteGuard({ guards, children, ...rest }) {
  const [initted, setInitted] = useState(false)
  const [checking, setChecking] = useState(true)
  const [isAuthorized, setIsAuthorized] = useState(false)
  const user = useSelector(selectAuthUser)

  useEffect(() => {
    if (initted) return
    setInitted(true)

    const finalizeCheck = (result) => {
      setIsAuthorized(() => result)
      setChecking(() => false)
    }

    if (typeof guards === 'undefined' || guards.length === 0) {
      finalizeCheck(true)
      return
    }

    let currentIndex = 0

    const callGuard = async (index) => {
      guards[index]({ ...rest, user }, next)
    }

    const next = (result) => {
      if (result === true) {
        currentIndex++
        if (currentIndex < guards.length) {
          callGuard(currentIndex)
        } else {
          finalizeCheck(true)
        }
      } else {
        finalizeCheck(false)
      }
    }

    callGuard(currentIndex)
  }, [guards, user, initted])

  return <>{!checking && (isAuthorized ? children : <UnauthorizedScreen />)}</>
}

RouteGuard.propTypes = {
  children: PropTypes.node,
  guards: PropTypes.arrayOf(PropTypes.func),
}

export default RouteGuard
