import { useTranslation } from 'react-i18next'
import { PROPOSAL_STATUSES, RESPONSE_TYPE, SELECTION_STATUSES } from 'utils/constants'

const statusColorMapping = {
  [RESPONSE_TYPE.proposing]: 'green',
  [RESPONSE_TYPE.noResponse]: 'red',
  [RESPONSE_TYPE.responded]: 'blue',
  [RESPONSE_TYPE.closed]: 'grey',
}

const closed = {
  proposal: [PROPOSAL_STATUSES.cancelled, PROPOSAL_STATUSES.rejected, PROPOSAL_STATUSES.contracted],
  referral: [SELECTION_STATUSES.declined, SELECTION_STATUSES.rejected, SELECTION_STATUSES.joined],
}

export default function useSituationMark(referralProposal) {
  const statusId =
    referralProposal.type === 'referral' ? referralProposal.application_status_id : referralProposal.proposal_status_id
  const isClosed = closed[referralProposal.type].includes(statusId)
  const responseState = isClosed
    ? RESPONSE_TYPE.closed
    : referralProposal.response_state || referralProposal.hr_response_state
  const { t } = useTranslation()
  const message = t(`responseState.${responseState}`)
  const color = statusColorMapping[responseState]

  return { message, color }
}
