import React, { memo, useCallback, useEffect, useMemo, useRef } from 'react'

import { FormProvider, useForm } from 'react-hook-form'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import findIndex from 'lodash/findIndex'
import { useDispatch } from 'react-redux'

import { ReactComponent as ClipIcon } from 'assets/img/clip-icon.svg'
import { BlockEl, Input, Textarea } from 'components/atoms'
import { ErrorMessage, FormGroup, FormSupArea } from 'components/molecules'
import { assignRefs, toMB } from 'utils/helper'
import { showNotification } from 'store/notification/actionCreators'
import { actionTriggerLastChanged } from 'store/referral-proposals/actionCreators'

const acceptableAttachments = [
  'image/heic',
  'image/png',
  'image/jpeg',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/pdf',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
]

const FilenameArea = memo(function FilenameArea({ files, remove }) {
  const handleClick = (id) => () => {
    remove(id)
  }

  return (
    <div className="selectionModal__fileInputFileNameArea">
      {files.map(({ id, file }) => (
        <p key={id} className="selectionModal__fileInputFileName">
          {file.name} <span onClick={handleClick(id)} className="selectionModal__fileInputFileDel"></span>
        </p>
      ))}
    </div>
  )
})

FilenameArea.propTypes = {
  remove: PropTypes.func.isRequired,
  files: PropTypes.array.isRequired,
}

const MESSAGE_MAX = 5000
const MAX_ATTACHMENTS = 10

function SelectionMessageForm(props) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { send } = props
  const methods = useForm({
    defaultValues: {
      attachments: [],
      appointments: [],
      message: '',
    },
    mode: 'onChange',
  })
  const { register, watch, handleSubmit, formState, setValue, reset } = methods
  const disabled = formState.isSubmitting || !formState.isValid
  const messageRef = useRef(null)

  const message = watch('message')
  const attachments = watch('attachments')

  const showErrorNotif = (msg) => dispatch(showNotification(msg, { type: 'danger', modifier: 'btm spBtnUp' }))

  const onSubmit = async (data) => {
    const { error } = await send({ ...data, attachments: data.attachments.map(({ file }) => file) })

    if (error) {
      let msg = error.error || error.message

      if (error.code == 422) {
        msg = error.error[Object.keys(error.error)[0]][0]
      }

      showErrorNotif(msg)

      return
    }

    reset()
    // We need this to resize the message input
    messageRef.current.dispatchEvent(
      new Event('input', {
        bubbles: true,
        cancelable: true,
      })
    )
    dispatch(actionTriggerLastChanged(new Date()))
  }

  const fileIdRef = useRef(1)

  const handleAttachmentChange = (e) => {
    if (e.target.files.length) {
      // Exclude files that are already seleted
      const files = Array.from(e.target.files).filter((file) => findIndex(attachments, { name: file.name }) === -1)
      const filteredFiles = files.filter((item) => toMB(item.size) <= 5)
      const newFiles = attachments.concat(filteredFiles.map((file) => ({ id: fileIdRef.current++, file })))

      if (filteredFiles.length !== files.length) {
        showErrorNotif(t('selection.modal.invalidAttachment'))
      }

      if (newFiles.length > MAX_ATTACHMENTS) {
        showErrorNotif(t('selection.modal.exceedAttachmentLimit'))
      }

      setValue('attachments', newFiles, {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      })
    }
  }

  const handleRemove = useCallback(
    (id) => {
      setValue(
        'attachments',
        attachments.filter((attachment) => attachment.id !== id),
        { shouldValidate: true }
      )
    },
    [attachments]
  )

  const rules = useMemo(
    () => ({
      message: {
        maxLength: {
          value: MESSAGE_MAX,
          message: t('validation.maxLength', { field: t('selection.modal.message'), length: MESSAGE_MAX }),
        },
      },
      attachments: {
        validate: (val) => {
          if (val.length > MAX_ATTACHMENTS) return t('validation.maxLength')

          return true
        },
      },
    }),
    [t]
  )

  useEffect(() => {
    register('attachments', rules.attachments)
  }, [])

  const messageReg = register('message', rules.message)

  const disableAttachment = disabled || watch('attachments').length >= MAX_ATTACHMENTS

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="selectionModal__inputAreaInner">
          <BlockEl component="p" bec="selectionModal__inputIconArea" modifier={{ active: !disableAttachment }}>
            <label className="selectionModal__inputLabel">
              <ClipIcon className="selectionModal__inputIcon" />

              <Input
                disabled={disableAttachment}
                type="file"
                accept={acceptableAttachments}
                blockElClass="selectionModal__fileInput"
                onChange={handleAttachmentChange}
                multiple
              />
            </label>
          </BlockEl>
          <FormGroup error={!!formState.errors.message} modifier="mgb0" className="selectionModal__formGroup">
            {attachments.length > 0 && <FilenameArea files={attachments} remove={handleRemove} />}
            <Textarea
              {...messageReg}
              ref={assignRefs([messageRef, messageReg.ref])}
              rows={1}
              modifier="ha"
              className="selectionModal__textArea"
              autoHeight={true}
              maxAutoHeight={300}
              style={{ resize: 'none' }}
              disabled={formState.isSubmitting || !!formState.errors.attachments}
            />
            <FormSupArea flex>
              <ErrorMessage name="message" />
              <FormSupArea.InputSup end inputValue={message} max={MESSAGE_MAX} />
            </FormSupArea>
          </FormGroup>

          <Input
            type="submit"
            disabled={disabled}
            blockElClass="selectionModal__formSubmit"
            modifier={clsx({ disabled: disabled })}
            value={t('selection.modal.submitBtn')}
          />
        </div>
      </form>
    </FormProvider>
  )
}

SelectionMessageForm.propTypes = {
  disableApppointment: PropTypes.bool,
  threadId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  send: PropTypes.func.isRequired,
  recommendation: PropTypes.object,
  loadLatest: PropTypes.func.isRequired,
}

export default SelectionMessageForm
