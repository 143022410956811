import React, { useMemo } from 'react'
import { EmptyPlaceholder, TableList } from 'components'
import { DATE_FORMAT, USER_STATUSES } from 'utils/constants'
import AccountManagementActionCell from '../AccountManagementActionCell'
import AccountManagementPermissionCell from '../AccountManagementPermissionCell'
import AccountManagementStatusCell from '../AccountManagementStatusCell'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { useUserTableList } from 'utils/hooks'

/* eslint-disable react/display-name, react/prop-types */
const makeHeadCells = (t) => [
  {
    id: 'fullname',
    label: t('accountManagement.name'),
    renderValue: ({ rowData }) =>
      rowData.status.id === USER_STATUSES.pending ? <EmptyPlaceholder /> : rowData.full_name,
    cellProps: { width: '20%' },
  },
  { id: 'email', label: t('accountManagement.emailAddress'), cellProps: { width: '20%' } },
  {
    id: 'permission',
    renderValue: (data) => <AccountManagementPermissionCell user={data.rowData} />,
    label: t('accountManagement.permission'),
    cellProps: { width: '15%' },
  },
  {
    id: 'status',
    label: t('accountManagement.status'),
    renderValue: (data) => <AccountManagementStatusCell user={data.rowData} />,
    cellProps: { width: '20%' },
  },
  {
    id: 'verified_at',
    label: t('accountManagement.verifiedAt'),
    renderValue: (data) =>
      data.rowData.verified_at ? dayjs(data.rowData.verified_at).format(DATE_FORMAT) : <EmptyPlaceholder />,
    cellProps: { width: '15%' },
  },
  {
    id: 'actions',
    renderValue: (data) => <AccountManagementActionCell user={data.rowData} />,
    label: '',
    cellProps: { width: '5%' },
  },
]
/* eslint-enable react/display-name, react/prop-types */

function AccountManagementList() {
  const tableListProps = useUserTableList()
  const { t } = useTranslation()

  const headCells = useMemo(() => makeHeadCells(t), [t])

  return <TableList title={`Users`} headCells={headCells} {...tableListProps} />
}

export default AccountManagementList
