import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { Input, Checkbox } from 'components/atoms'
import { FormGroupCheck } from 'components/molecules'
import { useFormContext } from 'react-hook-form'
import { actionUpdateJobSearchFilters } from 'store/job-postings/actionCreators'
function PrefectureModal({ lists }) {
  const { register } = useFormContext()
  const dispatch = useDispatch()
  const jobFilters = useSelector((state) => state.jobPostings.jobPostingSearch.filters)
  const activeModal = useSelector((state) => state.jobPostings.jobPostingSearch.activeModal)
  const checkedPrefectures = Array.isArray(jobFilters[activeModal])
    ? jobFilters[activeModal]
    : JSON.parse(jobFilters[activeModal])

  const checkPrefecture = (e) => {
    const val = parseInt(e.target.value)
    const filters = Object.assign({}, jobFilters)
    let selectedPrefectures = checkedPrefectures
    if (checkedPrefectures.includes(val)) {
      selectedPrefectures = checkedPrefectures.filter((item) => item !== val)
    } else {
      selectedPrefectures = [...selectedPrefectures, val]
    }
    filters[activeModal] = JSON.stringify(selectedPrefectures)
    dispatch(actionUpdateJobSearchFilters({ filters }))
  }

  const isChecked = (value) => checkedPrefectures.includes(value)

  const searchPrefecture = () => {
    if (lists) {
      const modalSearch = document.getElementById('adSearch')
      const formGroupCheckAd = document.getElementsByClassName('form__groupCheckAd')
      const formAdAreaArea = document.getElementsByClassName('form__adAreaArea')

      modalSearch.addEventListener('input', (e) => {
        const thisText = e.currentTarget.value
        if (thisText !== '') {
          for (let i = 0; formAdAreaArea.length > i; i++) {
            formAdAreaArea[i].style.display = 'none'
          }
          for (let i = 0; formGroupCheckAd.length > i; i++) {
            formGroupCheckAd[i].style.display = 'none'
            if (
              formGroupCheckAd[i].getElementsByClassName('form__checkItemModal')[0].dataset.search.indexOf(thisText) !==
              -1
            ) {
              formGroupCheckAd[i].style.display = 'inline-block'
              formGroupCheckAd[i].parentNode.style.display = 'block'
            }
          }
        } else {
          for (let i = 0; formAdAreaArea.length > i; i++) {
            formAdAreaArea[i].style.display = 'block'
          }
          for (let i = 0; formGroupCheckAd.length > i; i++) {
            formGroupCheckAd[i].style.display = 'inline-block'
          }
        }
      })
    }
  }

  useEffect(() => {
    searchPrefecture()
  }, [])
  return (
    <div id="adArea">
      <div className="form__adSearchArea form__adSearchArea-pd form__adSearchArea-bdn">
        <Input id="adSearch" className="form__adSearch form__adSearch-w100" placeholder="絞り込む" />
      </div>
      <div className="form__adAreaArea">
        <p className="form__adAreaTitle">北海道・東北</p>
        {lists
          ?.filter((category) => category.category === '北海道・東北')
          .map((prefecture, index) => (
            <FormGroupCheck.Item
              key={prefecture.id}
              Input={Checkbox}
              inputProps={{
                ...register(`prefecture.${index}.option`),
                value: prefecture.id,
                name: activeModal,
                ['data-search']: prefecture.prefecture,
                ['data-index']: prefecture.id,
                onChange: checkPrefecture,
                blockElClass: `form__checkItemModal ${isChecked(prefecture.id) ? 'checked' : ''}`,
                defaultChecked: isChecked(prefecture.id),
              }}
              label={prefecture.prefecture}
              type="square"
              blockElClass="form__groupCheckAd"
            />
          ))}
      </div>
      <div className="form__adAreaArea">
        <p className="form__adAreaTitle">北陸・甲信越</p>
        {lists
          ?.filter((category) => category.category === '北陸・甲信越')
          .map((prefecture, index) => (
            <FormGroupCheck.Item
              key={prefecture.id}
              Input={Checkbox}
              inputProps={{
                ...register(`prefecture.${index}.option`),
                value: prefecture.id,
                name: activeModal,
                ['data-search']: prefecture.prefecture,
                ['data-index']: prefecture.id,
                onChange: checkPrefecture,
                blockElClass: `form__checkItemModal ${isChecked(prefecture.id) ? 'checked' : ''}`,
                defaultChecked: isChecked(prefecture.id),
              }}
              label={prefecture.prefecture}
              type="square"
              blockElClass="form__groupCheckAd"
            />
          ))}
      </div>
      <div className="form__adAreaArea">
        <p className="form__adAreaTitle">関東</p>
        {lists
          ?.filter((category) => category.category === '関東')
          .map((prefecture, index) => (
            <FormGroupCheck.Item
              key={prefecture.id}
              Input={Checkbox}
              inputProps={{
                ...register(`${activeModal}.${index}.option`),
                value: prefecture.id,
                name: activeModal,
                ['data-search']: prefecture.prefecture,
                ['data-index']: prefecture.id,
                onChange: checkPrefecture,
                blockElClass: `form__checkItemModal ${isChecked(prefecture.id) ? 'checked' : ''}`,
                defaultChecked: isChecked(prefecture.id),
              }}
              label={prefecture.prefecture}
              type="square"
              blockElClass="form__groupCheckAd"
            />
          ))}
      </div>
      <div className="form__adAreaArea">
        <p className="form__adAreaTitle">東海</p>
        {lists
          ?.filter((category) => category.category === '東海')
          .map((prefecture, index) => (
            <FormGroupCheck.Item
              key={prefecture.id}
              Input={Checkbox}
              inputProps={{
                ...register(`${activeModal}.${index}.option`),
                value: prefecture.id,
                name: activeModal,
                onChange: checkPrefecture,
                ['data-search']: prefecture.prefecture,
                ['data-index']: prefecture.id,
                blockElClass: `form__checkItemModal ${isChecked(prefecture.id) ? 'checked' : ''}`,
                defaultChecked: isChecked(prefecture.id),
              }}
              label={prefecture.prefecture}
              type="square"
              blockElClass="form__groupCheckAd"
            />
          ))}
      </div>
      <div className="form__adAreaArea">
        <p className="form__adAreaTitle">関西</p>
        {lists
          ?.filter((category) => category.category === '関西')
          .map((prefecture, index) => (
            <FormGroupCheck.Item
              key={prefecture.id}
              Input={Checkbox}
              inputProps={{
                ...register(`${activeModal}.${index}.option`),
                value: prefecture.id,
                name: activeModal,
                ['data-search']: prefecture.prefecture,
                ['data-index']: prefecture.id,
                onChange: checkPrefecture,
                blockElClass: `form__checkItemModal ${isChecked(prefecture.id) ? 'checked' : ''}`,
                defaultChecked: isChecked(prefecture.id),
              }}
              label={prefecture.prefecture}
              type="square"
              blockElClass="form__groupCheckAd"
            />
          ))}
      </div>
      <div className="form__adAreaArea">
        <p className="form__adAreaTitle">中国・四国</p>
        {lists
          ?.filter((category) => category.category === '中国・四国')
          .map((prefecture, index) => (
            <FormGroupCheck.Item
              key={prefecture.id}
              Input={Checkbox}
              inputProps={{
                ...register(`${activeModal}.${index}.option`),
                value: prefecture.id,
                name: activeModal,
                ['data-search']: prefecture.prefecture,
                ['data-index']: prefecture.id,
                onChange: checkPrefecture,
                blockElClass: `form__checkItemModal ${isChecked(prefecture.id) ? 'checked' : ''}`,
                defaultChecked: isChecked(prefecture.id),
              }}
              label={prefecture.prefecture}
              type="square"
              blockElClass="form__groupCheckAd"
            />
          ))}
      </div>
      <div className="form__adAreaArea">
        <p className="form__adAreaTitle">九州・沖縄</p>
        {lists
          ?.filter((category) => category.category === '九州・沖縄')
          .map((prefecture, index) => (
            <FormGroupCheck.Item
              key={prefecture.id}
              Input={Checkbox}
              inputProps={{
                ...register(`${activeModal}.${index}.option`),
                value: prefecture.id,
                name: activeModal,
                ['data-search']: prefecture.prefecture,
                ['data-index']: prefecture.id,
                onChange: checkPrefecture,
                blockElClass: `form__checkItemModal ${isChecked(prefecture.id) ? 'checked' : ''}`,
                defaultChecked: isChecked(prefecture.id),
              }}
              label={prefecture.prefecture}
              type="square"
              blockElClass="form__groupCheckAd"
            />
          ))}
      </div>
    </div>
  )
}

PrefectureModal.propTypes = {
  lists: PropTypes.array,
}

export default PrefectureModal
