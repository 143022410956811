import Happy from './Happy'
import Sad from './Sad'
import ArrowRight from './ArrowRight'

const SvgIcon = {
  Happy,
  Sad,
  ArrowRight,
}
export default SvgIcon
