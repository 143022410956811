import { useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'

import { getAdInChargeList } from 'services/adIncharge'
import { USER_STATUSES } from 'utils/constants'

/**
 * @typedef {Object} CoWorkersResult
 * @property {Object[]} users - An array of user object
 * @property {boolean} fetching - Indicates whether the list is fetching
 */

/**
 * @returns {CoWorkersResult}
 */
export default function useCoWorkers() {
  const dispatch = useDispatch()
  const { pending: fetching, adInchargelist } = useSelector((state) => state.adInCharge)

  useEffect(() => {
    dispatch(getAdInChargeList({ params: { statuses: USER_STATUSES.active } }))
  }, [])

  return { users: adInchargelist?.data || [], fetching }
}
