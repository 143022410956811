import React, { useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import range from 'lodash/range'
import lodashGet from 'lodash/get'
import { useHistory } from 'react-router-dom'
import { useForm, FormProvider, Controller } from 'react-hook-form'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'

import { ErrorMessage, CustomSelect, FormGroup, FormGroupCheck, FormSupArea } from 'components/molecules'
import { Label, Radio, Textarea, Input, FormAlert } from 'components/atoms'
import EducationalBackground from 'components/organisms/common/EducationalBackground'
import EnglishLevel from 'components/organisms/common/EnglishLevel'
import ChineseLevel from 'components/organisms/common/ChineseLevel'
import { FormInterlock } from 'components'
import WorkingPoints from './WorkingPoints'
import { getWorkPoints } from 'services/workPoints'
import Footer from './Footer'
import StoreSync from '../StoreSync'
import { saveAsDraft, saveProposalAsDraft } from 'services/jobPostingForm'
import { makeFormGroupStateGetter, makeMinMaxValidators } from 'utils/helper'

export const defaultData = {
  working_points: [],
  skills_experience: null,
  min_acceptable_age: null,
  max_acceptable_age: null,
  gender: null,
  changed_job_count: null,
  changed_job_count_opt: null,
  nationality: null,
  other_info_working_conditions: null,
  education_background: null,
  chinese_level: null,
  english_level: null,
}

export const step3Rules = ({ t, getValues }) => {
  const ageValdiator = makeMinMaxValidators({
    maxField: 'max_acceptable_age',
    minField: 'min_acceptable_age',
    getValues,
  })

  return {
    education_background: {
      required: t('required'),
    },
    english_level: {
      required: t('required'),
    },
    chinese_level: {
      required: t('required'),
    },
    skills_experience: {
      required: t('required'),
      maxLength: { value: 2000, message: t('maxLength', { length: 2000 }) },
    },
    other_info_working_conditions: {
      maxLength: { value: 2000, message: t('maxLength') },
    },
    min_acceptable_age: {
      required: t('required'),
      validate: ageValdiator.min,
      min: 18,
      max: 70,
      deps: ['max_acceptable_age'],
    },
    max_acceptable_age: {
      required: t('required'),
      validate: ageValdiator.max,
      min: 18,
      max: 70,
      deps: ['min_acceptable_age'],
    },
  }
}

function mapToStore(formData) {
  const get = (key) => lodashGet(formData, key)

  return {
    working_points: get('working_points'),
    skills_experience: get('skills_experience'),
    min_acceptable_age: get('min_acceptable_age'),
    max_acceptable_age: get('max_acceptable_age'),
    gender: get('gender'),
    changed_job_count: get('changed_job_count'),
    changed_job_count_opt: get('changed_job_count_opt'),
    nationality: get('nationality'),
    other_info_working_conditions: get('other_info_working_conditions'),
    education_background: get('education_background'),
    chinese_level: get('chinese_level'),
    english_level: get('english_level'),
  }
}

export const mapFromStore = (data) => {
  const get = (key) => lodashGet(data, key, defaultData[key])

  const workingPoints =
    typeof lodashGet(data, 'working_points.0.id') !== 'undefined'
      ? data.working_points.map(({ id }) => id)
      : get('working_points')

  return {
    ...Object.keys(defaultData).reduce((acc, key) => ({ ...acc, [key]: get(key) }), {}),
    working_points: workingPoints,
  }
}

export function shouldRegister({ fieldName, type }) {
  return type === 'referral' || !['education_background'].includes(fieldName)
}

const Form3 = (props) => {
  const { handleShowTip, initialData, jobVacancyData, type } = props
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { t: tv } = useTranslation('translation', { keyPrefix: 'validation' })
  const history = useHistory()
  const job_posting_data = jobVacancyData?.job_posting_data
  const multipleStep = jobVacancyData?.multipleStep
  const singleStep = jobVacancyData?.singleStep
  const [jobCount, setJobCount] = useState('')
  const [initWorkingPoints, setInitWorkingPoints] = useState({})
  const [showSpecific, setShowSpecific] = useState(false)
  const [initted, setInitted] = useState(false)
  const methods = useForm({ defaultValues: defaultData, mode: 'all' })
  const {
    formState: { errors, dirtyFields, isValid },
    handleSubmit,
    reset,
    watch,
    register,
    setValue,
    getValues,
    getFieldState,
  } = methods

  const rules = step3Rules({ t: tv, getValues: methods.getValues })

  const skills_experience = watch('skills_experience')
  const other_info_working_conditions = watch('other_info_working_conditions')

  useEffect(() => {
    if (!isEmpty(initialData) && !initted) {
      setInitWorkingPoints(initialData.working_points)
      const data = mapFromStore(initialData)
      if (parseInt(data?.education_background?.length) > 0) {
        var educational = []
        if (typeof data.education_background[0] === 'object') {
          data.education_background.map((educationalLevel) => {
            educationalLevel?.id && educational.push(educationalLevel?.id)
          })
        } else {
          data.education_background.map((educationalLevel) => educational.push(educationalLevel))
        }
        data.education_background = educational
      } else {
        data.education_background = null
      }
      if (data?.english_level?.length === 1) {
        var english = []
        if (typeof data.english_level[0] === 'object') {
          data.english_level.map((englishLevel) => (englishLevel?.id ? english.push(englishLevel?.id) : englishLevel))
        } else {
          data.english_level.map((englishLevel) => english.push(englishLevel))
        }
        data.english_level = english
      } else {
        data.english_level = null
      }
      if (data?.chinese_level?.length === 1) {
        var chinese = []
        if (typeof data.chinese_level[0] === 'object') {
          data.chinese_level.map((chineseLevel) => (chineseLevel?.id ? chinese.push(chineseLevel?.id) : chineseLevel))
        } else {
          data.chinese_level.map((chineseLevel) => chinese.push(chineseLevel))
        }
        data.chinese_level = chinese
      } else {
        data.chinese_level = null
      }
      data.changed_job_count_opt =
        data?.changed_job_count !== null && data?.changed_job_count !== undefined
          ? data?.changed_job_count > -1
            ? '*'
            : '-1'
          : null
      reset(data, { keepDefaultValues: true })

      setJobCount(data?.changed_job_count_opt)
      parseInt(initialData?.changed_job_count) > -1 ? setShowSpecific(true) : setShowSpecific(false)
      setInitted(true)
    }
  }, [initialData, initted])

  useEffect(() => {
    dispatch(getWorkPoints())
  }, [])

  const handleChangedJobCount = (e) => {
    const { value } = e.target
    setJobCount(value)
    value === '-1' ? setShowSpecific(false) : value === '*' ? setShowSpecific(true) : ''
    value === '-1' && setValue('changed_job_count', -1)
  }

  const handleSetJobCount = (count) => {
    setValue('changed_job_count', +count, { shouldDirty: true })
  }

  const onSubmit = async (data) => {
    const nextPath = type === 'proposal' ? '/new-job-post-proposal/step3' : '/new-job-post/step4'
    const nextPathConf = type === 'referral' ? '/new-job-post/step-conf' : '/new-job-post-proposal/step-conf'

    const saveDraft = () => {
      if (singleStep) {
        history.push({
          pathname: nextPathConf,
          state: { singleStep: true },
        })
      } else if (multipleStep) {
        history.push({
          pathname: nextPath,
          state: { job_posting_data: job_posting_data, multipleStep: multipleStep },
        })
      } else {
        history.push(nextPath)
      }
    }
    const forStorage = mapToStore(data)
    type === 'proposal'
      ? dispatch(saveProposalAsDraft(forStorage, 'saveToLocal')).then(() => {
          saveDraft()
        })
      : dispatch(saveAsDraft(forStorage, 'saveToLocal')).then(() => {
          saveDraft()
        })
  }

  const onBack = () => {
    type === 'referral'
      ? dispatch(saveAsDraft(getValues(), 'saveToLocal')).then(() => {
          history.push('/new-job-post/step2')
        })
      : type === 'proposal'
      ? dispatch(saveProposalAsDraft(getValues(), 'saveToLocal')).then(() => {
          history.push('/new-job-post-proposal/step1')
        })
      : null
  }

  const changeJobCountOptions = [
    {
      id: 0,
      label: t('jobPosting.step3.none'),
    },
  ].concat(range(1, 7).map((id) => ({ id, label: t('jobPosting.step3.changeJobCountItem', { count: id }) })))

  const { byName, and } = makeFormGroupStateGetter(getFieldState)

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)} className="form">
        <div className="form__inner">
          <div className="form__group">
            <label htmlFor="" className="form__label">
              {t('jobPosting.step3.workingStyle')}
            </label>
            <WorkingPoints initialData={initWorkingPoints} />
          </div>
          <div className="form__groupWrap">
            <label htmlFor="" className="form__label">
              {t('jobPosting.step3.required')}
            </label>
            <FormGroup
              success={!errors?.skills_experience && dirtyFields?.skills_experience}
              error={!!errors?.skills_experience}
              modifier="mgb0 mgt2"
              required
            >
              <Label>{t('jobPosting.step3.essentialSkills')}</Label>
              <Textarea
                className="form__group-mgt"
                {...methods.register('skills_experience', rules.skills_experience)}
                cols="30"
                rows="10"
              />
              <FormSupArea modifier="flex">
                <ErrorMessage errors={methods.formState.errors} name="skills_experience" />
                <FormSupArea.InputSup
                  modifier="end"
                  inputValue={skills_experience}
                  max={rules.skills_experience.maxLength.value}
                />
              </FormSupArea>
            </FormGroup>
          </div>
          <FormGroup {...and(byName('min_acceptable_age'), byName('max_acceptable_age'))} modifier="mgb0 mgt2" required>
            <Label>{t('jobPosting.step3.ageRequired')}</Label>
            <div className="form__groupFlex form__group-mgt form__groupMultiCheck">
              <Input
                type="number"
                {...register('min_acceptable_age', rules.min_acceptable_age)}
                min={18}
                max={70}
                modifier="w4em"
              />
              <p className="form__inputMiddleText">～</p>
              <Input
                type="number"
                {...register('max_acceptable_age', rules.max_acceptable_age)}
                min={18}
                max={70}
                modifier="w4em"
              />
              <p className="form__inputAfterText"> {t('jobPosting.step3.required')}</p>
            </div>
            <p className="form__sup">{t('jobPosting.step3.ageHint')}</p>
            <FormSupArea flex>
              <FormAlert>{t('hints.enterWithinRange')}</FormAlert>
            </FormSupArea>
          </FormGroup>
          <FormGroup {...byName('gender')} modifier="mgb0 mgt2" required>
            <Label>{t('common.assumedGender')}</Label>
            <div className="form__groupCheckArea">
              <FormGroupCheck>
                <FormGroupCheck.Item
                  label={t('formValues.others')}
                  Input={Radio}
                  type="round"
                  inputProps={{
                    ...register('gender', { required: t('validation.required') }),
                    value: t('formValues.others'),
                  }}
                />
                <FormGroupCheck.Item
                  label={t('formValues.male')}
                  Input={Radio}
                  type="round"
                  inputProps={{
                    ...register('gender', { required: t('validation.required') }),
                    value: t('formValues.male'),
                  }}
                />
                <FormGroupCheck.Item
                  label={t('formValues.female')}
                  Input={Radio}
                  type="round"
                  inputProps={{
                    ...register('gender', { required: t('validation.required') }),
                    value: t('formValues.female'),
                  }}
                />
                <FormSupArea flex>
                  <FormAlert>{t('jobPosting.step3.plsEnter')}</FormAlert>
                </FormSupArea>
              </FormGroupCheck>
            </div>
          </FormGroup>
          {type === 'referral' && (
            <FormGroup {...byName('education_background')} modifier="mgb0 mgt2" required>
              <div className="form__groupCheckArea">
                <Label>{t('jobPosting.step3.academicBackground')}</Label>
                <EducationalBackground rules={rules.education_background} />
                <FormSupArea flex>
                  <FormAlert>{t('common.pleaseSelect')}</FormAlert>
                </FormSupArea>
              </div>
            </FormGroup>
          )}
          <FormGroup
            success={!errors?.changed_job_count_opt && dirtyFields?.changed_job_count_opt}
            error={!!errors?.changed_job_count_opt}
            modifier="mgb0 mgt2"
            required
          >
            <div className="form__groupCheckArea">
              <Label>{t('jobPosting.step3.jobChangeCount')}</Label>
              <div className="form__groupCheckArea form__groupCheckArea-mgb">
                <FormGroupCheck>
                  <FormGroupCheck.Item
                    label={t('optionLabels.regardless')}
                    Input={Radio}
                    type="round"
                    inputProps={{
                      ...register('changed_job_count_opt'),
                      onClick: handleChangedJobCount,
                      value: '-1',
                      checked: jobCount === '-1',
                    }}
                  />

                  <FormGroupCheck.Item
                    label={t('optionLabels.specify')}
                    Input={Radio}
                    type="round"
                    defaultChecked={jobCount === '*'}
                    inputProps={{
                      ...register('changed_job_count_opt'),
                      value: '*',
                      onClick: handleChangedJobCount,
                      checked: jobCount === '*',
                    }}
                  />
                </FormGroupCheck>
                <FormInterlock active={showSpecific}>
                  <Controller
                    control={methods.control}
                    name="changed_job_count"
                    render={({ field }) => (
                      <CustomSelect
                        placeholder={t('hints.pleaseSpecify')}
                        modifier="w296 mgt5"
                        options={changeJobCountOptions}
                        selected={field.value}
                        onChange={handleSetJobCount}
                      />
                    )}
                  />
                </FormInterlock>
              </div>
              <FormSupArea flex>
                <FormAlert>{t('common.pleaseSelect')}</FormAlert>
              </FormSupArea>
            </div>
          </FormGroup>
          <FormGroup {...byName('english_level')} modifier="mgb0 mgt2" required>
            <div className="form__groupCheckArea">
              <Label>{t('jobPosting.step3.englishLevel')}</Label>
              <EnglishLevel rules={rules.english_level} />
              <FormSupArea flex>
                <FormAlert>{t('common.pleaseSelect')}</FormAlert>
              </FormSupArea>
            </div>
          </FormGroup>
          <FormGroup
            success={!errors?.chinese_level && dirtyFields?.chinese_level}
            error={!!errors?.chinese_level}
            modifier="mgb0 mgt2"
            required
          >
            <div className="form__groupCheckArea">
              <Label>{t('jobPosting.step3.chineseLevel')}</Label>
              <ChineseLevel rules={rules.chinese_level} />
              <FormSupArea flex>
                <FormAlert>{t('common.pleaseSelect')}</FormAlert>
              </FormSupArea>
            </div>
          </FormGroup>
          <FormGroup
            success={!errors?.nationality && dirtyFields?.nationality}
            error={!!errors?.nationality}
            modifier="mgb0 mgt2"
            required
          >
            <div className="form__groupCheckArea">
              <Label>{t('jobPosting.step3.nationality')}</Label>
              <FormGroupCheck>
                <FormGroupCheck.Item
                  label={t('formGroupLabels.regardlessNationality')}
                  Input={Radio}
                  type="round"
                  inputProps={{
                    ...register('nationality', { required: t('validation.required') }),
                    value: t('formGroupLabels.regardlessNationality'),
                  }}
                />
                <FormGroupCheck.Item
                  label={t('formGroupLabels.foreign')}
                  Input={Radio}
                  type="round"
                  inputProps={{
                    ...register('nationality', { required: t('validation.required') }),
                    value: t('formGroupLabels.foreign'),
                  }}
                />
                <FormGroupCheck.Item
                  label={t('formGroupLabels.japanese')}
                  Input={Radio}
                  type="round"
                  inputProps={{
                    ...register('nationality', { required: t('validation.required') }),
                    value: t('formGroupLabels.japanese'),
                  }}
                />
                <FormSupArea flex>
                  <FormAlert>{t('common.pleaseSelect')}</FormAlert>
                </FormSupArea>
              </FormGroupCheck>
            </div>
          </FormGroup>
          {type === 'referral' && (
            <FormGroup error={byName('other_info_working_conditions').error} modifier="mgb0 mgt2">
              <Label>{t('jobPosting.step3.recommendationRequirement')}</Label>
              <Textarea
                className="form__inputTextArea form__group-mgt inputExp inputCalc"
                {...register('other_info_working_conditions', rules.other_info_working_conditions)}
                cols="30"
                rows="10"
                data-exp="exp1"
                onFocus={() => handleShowTip(true)}
                onBlur={() => handleShowTip(false)}
              />
              <FormSupArea modifier="flex">
                <FormSupArea.InputSup
                  errors={errors}
                  modifier="end"
                  inputValue={other_info_working_conditions}
                  max={rules.other_info_working_conditions.maxLength.value}
                />
              </FormSupArea>
            </FormGroup>
          )}
        </div>
        <Footer
          onSubmit={handleSubmit(onSubmit)}
          onBack={onBack}
          isValid={isValid}
          jobVacancyData={jobVacancyData}
          type={type}
        />
        <StoreSync defaultValues={defaultData} mapToStore={mapToStore} type={type} />
      </form>
    </FormProvider>
  )
}

Form3.propTypes = {
  initialData: PropTypes.object,
  handleShowTip: PropTypes.func,
  jobVacancyData: PropTypes.any,
  type: PropTypes.string,
}

export default Form3
