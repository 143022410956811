import produce from 'immer'
import { fetchQuestioners } from 'services/questionAndAnswers'

const initialState = {
  state: 'pending',
  error: null,
  items: [],
}

const reducer = produce((draft, { type, payload }) => {
  switch (type) {
    case fetchQuestioners.pending:
      draft.state = 'pending'
      return draft
    case fetchQuestioners.fulfilled:
      draft.state = 'fulfilled'
      draft.items = payload.data
      return draft
    case fetchQuestioners.failure:
      draft.state = 'failure'
      draft.error = payload.message
      return draft
    default:
      return draft
  }
}, initialState)

export default reducer
