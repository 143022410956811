import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { calculateModifiers } from 'utils/helper'
import CandidateContentBox from './CandidateContentBox'
import clsx from 'clsx'
import { CircularProgress } from '@mui/material'

const CandidateRecommendationsList = ({ title, recommendations, loading, modifier = '' }) => {
  const { t } = useTranslation()
  const theMods = calculateModifiers('candidate__modalTabContInner', modifier)
  const collapseArea = useRef(null)

  useEffect(() => {
    if (!loading) {
      collapse(collapseArea.current)
    }
  }, [loading])

  const collapse = (collapseAreaBtn) => {
    //if node exists, do this
    if (collapseAreaBtn) {
      let targetSelectionH = 0
      const targetSelection =
        collapseAreaBtn?.parentNode?.parentNode?.getElementsByClassName('candidate__modalTabContBoxUl')[0]
      const targetSelectionLi = targetSelection?.querySelectorAll('.candidate__modalTabContBoxLi')
      targetSelectionH =
        targetSelectionLi[0]?.offsetHeight +
        targetSelectionLi[1]?.offsetHeight +
        targetSelectionLi[2]?.offsetHeight +
        24

      collapseAreaBtn.classList.remove('open')
      collapseAreaBtn.textContent = t('candidateSlideout.seeAll')

      targetSelection.style.height = `${targetSelectionH}px`
    }
  }

  const handleOpen = async (e) => {
    const target = e.currentTarget.parentNode.parentNode.getElementsByClassName('candidate__modalTabContBoxUl')[0]
    const targetLi = target.querySelectorAll('.candidate__modalTabContBoxLi')
    let targetH = 0
    if (e.currentTarget.classList.contains('open')) {
      targetH = targetLi[0].offsetHeight + targetLi[1].offsetHeight + targetLi[2].offsetHeight + 24
      e.currentTarget.classList.remove('open')
      e.currentTarget.textContent = t('candidateSlideout.seeAll')
    } else {
      targetLi.forEach((element) => {
        targetH += element.offsetHeight + 8
      })
      e.currentTarget.classList.add('open')
      e.currentTarget.textContent = t('candidateSlideout.close')
    }

    target.style.height = `${targetH}px`
  }

  return (
    <div className={clsx('candidate__modalTabContInner', theMods)}>
      <p className="candidate__modalTabContTitle">
        {title}&nbsp;&nbsp;&nbsp;&nbsp;{recommendations.length}
        {t('candidateSlideout.item')}
      </p>
      <ul className="candidate__modalTabContBoxUl">
        {loading ? (
          <CircularProgress color="primary" size="3rem" />
        ) : (
          recommendations.map((item) => (
            <CandidateContentBox
              key={item.id}
              title={item?.job_posting?.job_title}
              company_name={item?.job_posting?.company_name}
              job_id={item?.job_posting?.id}
            />
          ))
        )}
      </ul>
      <div className="candidate__modalTabContBoxBtnWrap">
        {recommendations.length > 3 && (
          <p ref={collapseArea} className="candidate__modalTabContBoxBtn" onClick={handleOpen}>
            {t('candidateSlideout.seeAll')}
          </p>
        )}
      </div>
    </div>
  )
}

CandidateRecommendationsList.propTypes = {
  recommendations: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  modifier: PropTypes.string,
  title: PropTypes.string.isRequired,
}

export default CandidateRecommendationsList
