import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

const CandidateTestimonial = ({ testimonial }) => {
  const { t } = useTranslation()

  return (
    <div className="candidate__modalTabContInner">
      <p className="candidate__modalTabContTitle candidate__modalTabContTitle-mgb24">
        {t('candidateSlideout.testimonial')}
      </p>
      <p className="candidate__modalTabTestimonial">{testimonial}</p>
    </div>
  )
}

CandidateTestimonial.propTypes = {
  testimonial: PropTypes.string,
}

export default CandidateTestimonial
