import React from 'react'

import PropTypes from 'prop-types'
import { useFormContext } from 'react-hook-form'

import { Modal, Button } from 'components'
import { useInvalidFieldCount } from 'utils/hooks'

const fields = ['question', 'topic']

function Footer({ onCancel, confirmation, t }) {
  const {
    formState: { isSubmitting },
    control,
  } = useFormContext()

  const { count: errorCount } = useInvalidFieldCount({ control, name: fields })

  return (
    <Modal.Footer>
      <Button type="button" variant="outline" onClick={onCancel}>
        {t('qAndA.cancelBtn')}
      </Button>
      {errorCount > 0 ? (
        <Modal.Button component="p" className="main__footerReq">
          {t('validation.invalidInputsCount', { count: errorCount })}
        </Modal.Button>
      ) : (
        <Button
          disabled={isSubmitting}
          type="submit"
          modifier="mgl16"
          variant={isSubmitting ? 'shadowDissable' : 'shadow'}
        >
          {t('qAndA.' + (confirmation ? 'submitBtn' : 'confirmBtn'))}
        </Button>
      )}
    </Modal.Footer>
  )
}

Footer.propTypes = {
  onCancel: PropTypes.func.isRequired,
  confirmation: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
}

export default Footer
