import produce from 'immer'
import { fetchJobPostingFilter, createJobPostingFilter, fetchJobPostingFilterSearch } from 'services/jobFilter'
import { AUTH_SIGNOUT } from 'store/auth/actionTypes'
import * as ActionTypes from './actionTypes'

const initialState = {
  items: [],
  listState: 'pending',
  addFilterState: 'pending',
  filterCriteria: {},
  editMode: false,
}

export default produce((draft, { type, payload }) => {
  switch (type) {
    case ActionTypes.SET_EDIT_MODE:
      draft.editMode = payload
      break
    case ActionTypes.ADD_FILTER_CRITERIA:
      draft.filterCriteria = { ...draft.filterCriteria, ...payload }
      break
    case fetchJobPostingFilter.pending:
      draft.listState = 'pending'
      break
    case fetchJobPostingFilter.fulfilled:
      draft.listState = 'fulfilled'
      draft.items = payload.data
      break
    case fetchJobPostingFilter.failure:
      draft.listState = 'failure'
      break
    case fetchJobPostingFilterSearch.pending:
      draft.listState = 'pending'
      break
    case fetchJobPostingFilterSearch.fulfilled:
      draft.listState = 'fulfilled'
      draft.items = payload.data
      break
    case fetchJobPostingFilterSearch.failure:
      draft.listState = 'failure'
      break
    case createJobPostingFilter.pending:
      draft.addFilterState = 'pending'
      break
    case createJobPostingFilter.fulfilled:
      draft.addFilterState = 'fulfilled'
      break
    case createJobPostingFilter.failure:
      draft.addFilterState = 'failure'
      break
    case AUTH_SIGNOUT:
      return initialState
    default:
      return draft
  }
}, initialState)
