import React, { useEffect, useReducer, useState } from 'react'

import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import find from 'lodash/find'
import findIndex from 'lodash/findIndex'
import { useSelector } from 'react-redux'

import { FormGroup, FormGroupCheck } from 'components/molecules'
import AboutTheMediumModal from 'components/organisms/hr/NewJobPost/step1/AboutTheMediumModal'
import SearchCondition from './SearchCondition'
import SearchSideSection from './SearchSideSection'
import { selectFlattenedMedium } from 'store/aboutTheMedium/selectors'
import isEmpty from 'lodash/isEmpty'

const initialOnboardingState = {
  current_media: [],
  past_publication_media: [],
  media_of_interest: [],
}

function onboardingReducer(state, { type, payload }) {
  switch (type) {
    case 'update':
      return {
        ...state,
        [payload.key]: payload.changes,
      }
    case 'reset':
      return {
        ...initialOnboardingState,
        ...(payload || {}),
      }
    default:
      throw new Error(`Unhandled action type: ${type}`)
  }
}

function PublicationMediumSection({ filterCriteria, setFilterCriteria, resetToState }) {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const [activeKey, setActiveKey] = useState(null)
  const [state, dispatch] = useReducer(onboardingReducer, initialOnboardingState)
  const aboutTheMedium = useSelector(selectFlattenedMedium)

  const handlePresenceOrAbsenceChange = () => {
    setFilterCriteria((old) => ({ ...old, presence_absence_authorizer: old.presence_absence_authorizer ? 0 : 1 }))
  }

  const handleSelectClick = (key) => () => {
    setOpen(true)
    setActiveKey(key)
  }

  const handleSelect = (changes) => {
    dispatch({ type: 'update', payload: { key: activeKey, changes } })
    setFilterCriteria((old) => ({ ...old, [activeKey]: changes }))
  }

  const handleClose = () => {
    setActiveKey(null)
    setOpen(null)
  }

  const selected = filterCriteria[activeKey] || []

  const getChecklist = (stateItem) =>
    stateItem
      .map((id) => find(aboutTheMedium, { id }))
      .reduce((accum, curr) => {
        const parentId = curr.parent_id
        const parent = find(aboutTheMedium, { id: parentId })
        let index = findIndex(accum, { id: parentId })

        if (index === -1) {
          accum.push({ id: parentId, label: parent.name, children: [] })
          index = accum.length - 1
        }

        accum[index].children.push({ value: curr.id, label: curr.name })

        return accum
      }, [])

  const currentMediaChecklist = getChecklist(state.current_media)
  const pastPublicationChecklist = getChecklist(state.past_publication_media)
  const mediaOfInterestChecklist = getChecklist(state.media_of_interest)

  useEffect(() => {
    if (!isEmpty(resetToState)) {
      dispatch({
        type: 'reset',
        payload: {
          current_media: filterCriteria.current_media,
          past_publication_media: filterCriteria.past_publication_media,
          media_of_interest: filterCriteria.media_of_interest,
        },
      })
    }
  }, [resetToState])

  const handleChecklistChange = (key) => {
    return (changes) => {
      setFilterCriteria((old) => ({ ...old, [key]: changes }))
    }
  }

  const modalTitle =
    {
      current_media: t('newJobPostProposal.step1.currentPostMedia'),
      past_publication_media: t('jobPosting.search.pastPublicationMedium'),
      media_of_interest: t('jobPosting.search.mediaOfInterest'),
    }[activeKey] || ''

  return (
    <SearchSideSection title={t('jobPosting.search.publicationMedium')}>
      <SearchCondition
        label={t('newJobPostProposal.step1.currentPostMedia')}
        onClick={handleSelectClick('current_media')}
        selected={filterCriteria.current_media}
        checklist={currentMediaChecklist}
        onChecklistChange={handleChecklistChange('current_media')}
        getValue={parseInt}
      />
      <SearchCondition
        label={t('jobPosting.search.pastPublicationMedium')}
        onClick={handleSelectClick('past_publication_media')}
        selected={filterCriteria.past_publication_media}
        checklist={pastPublicationChecklist}
        onChecklistChange={handleChecklistChange('past_publication_media')}
        getValue={parseInt}
      />
      <SearchCondition
        label={t('jobPosting.search.mediaOfInterest')}
        onClick={handleSelectClick('media_of_interest')}
        selected={filterCriteria.media_of_interest}
        checklist={mediaOfInterestChecklist}
        onChecklistChange={handleChecklistChange('media_of_interest')}
        getValue={parseInt}
      />

      <AboutTheMediumModal
        title={modalTitle}
        open={open}
        selected={selected}
        onSelect={handleSelect}
        onClose={handleClose}
      />

      <FormGroup modifier="mgb0">
        <p className="search__sideSecTitle2">{t('newJobPostProposal.step1.presenceOrAbsenceOfAuthorizer')}</p>
        <FormGroupCheck>
          <FormGroupCheck.Checkbox
            label={t('jobPosting.search.presenceOrAbsenceOfApprover')}
            value={1}
            checked={+filterCriteria.presence_absence_authorizer === 1}
            onChange={handlePresenceOrAbsenceChange}
          />
        </FormGroupCheck>
      </FormGroup>
    </SearchSideSection>
  )
}

PublicationMediumSection.propTypes = {
  filterCriteria: PropTypes.object,
  setFilterCriteria: PropTypes.func,
  resetToState: PropTypes.any,
}

export default PublicationMediumSection
