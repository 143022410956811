import React from 'react'

import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { generatePath } from 'react-router-dom'
import qs from 'query-string'

import { Button, DownloadLink, NewlineText } from 'components/atoms'
import FileIconWhite from 'assets/img/file-icon-white.svg'
import { downloadPath } from 'services/download'
import { selectAuthUser } from 'store/auth/selectors'
import { inAccountType } from 'utils/helper'
import { PROPOSAL_STATUSES } from 'utils/constants'

function ProposalItem({ referralProposal }) {
  const { t } = useTranslation()
  const user = useSelector(selectAuthUser)
  const canEdit =
    !referralProposal.hr_has_viewed &&
    inAccountType(user, 'AD2') &&
    referralProposal.referred_by === user.id &&
    referralProposal.proposal_status_id !== PROPOSAL_STATUSES.contracted
  const attachments = referralProposal.attachments ?? []

  const handleEditClick = () => {
    const path = qs.parseUrl(
      generatePath('/job-postings/:id/:tab?', {
        id: referralProposal.job_posting.id,
        tab: 'jobvacancies',
      })
    )
    path.query.editProposal = referralProposal.id

    window.open(qs.stringifyUrl(path))
  }

  return (
    <div className="selectionModal__tabContCommentArea">
      <div className="selectionModal__tabContSuggestionMainArea">
        <p className="selectionModal__tabContSuggestionTitle">{t('jobPosting.step4.businessContent')}</p>
        <p className="selectionModal__tabContSuggestionComment">
          <NewlineText text={referralProposal.proposal} />
        </p>

        {referralProposal.proposal_updated_at && (
          <p className="selectionModal__tabContSuggestionEdited">{t('common.edit')}</p>
        )}

        {attachments.length > 0 && (
          <div className="selectionModal__tabContSuggestionFlex">
            {attachments.map((attachment, index) => (
              <div key={index} className="selectionModal__tabContSuggestionItem">
                <DownloadLink
                  src={attachment}
                  resolver={() => downloadPath(attachment)}
                  className="selectionModal__tabContSuggestionLink"
                  start={<img src={FileIconWhite} className="selectionModal__tabContSuggestionIcon" />}
                  renderText={({ text }) => <span className="selectionModal__tabContSuggestionName">{text}</span>}
                />
              </div>
            ))}
          </div>
        )}

        {canEdit && (
          <div className="selectionModal__tabContSuggestionEditArea">
            <Button onClick={handleEditClick} bec="selectionModal__tabContSuggestionEditButton">
              {t('common.edit')}
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}

ProposalItem.propTypes = {
  referralProposal: PropTypes.object.isRequired,
}

export default ProposalItem
