import React from 'react'

import PropTypes from 'prop-types'
import clsx from 'clsx'
import presets from './presets'

function Nav({ classes, preset, items, active, onClick, isMulti = false }) {
  const theClasses = typeof classes === 'object' ? classes : presets[preset]
  const handleItemClick = (item) => (e) => {
    e.preventDefault()
    if (item.disabled) return

    onClick(item.id)
  }

  const isActive = (item) => (isMulti ? active.includes(item.id) : item.id === active)

  return (
    <ul className={theClasses.root}>
      {items.map((item) => (
        <li
          key={item.id}
          className={clsx(theClasses.item, {
            [theClasses.item_unread]: item.unread,
            [theClasses.item_disabled]: item.disabled,
          })}
        >
          <a
            href="#"
            onClick={handleItemClick(item)}
            className={clsx(theClasses.link, {
              [theClasses.active]: isActive(item),
            })}
          >
            {item.label}
            {Number.isFinite(parseFloat(item.number)) && <span className={theClasses.number}>{item.number}</span>}
          </a>
        </li>
      ))}
    </ul>
  )
}

const IDType = PropTypes.oneOfType([PropTypes.string, PropTypes.number])

const ClassesType = PropTypes.shape({
  root: PropTypes.string.isRequired,
  item: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  active: PropTypes.string.isRequired,
  number: PropTypes.any,
})

const ItemShape = PropTypes.shape({
  id: IDType.isRequired,
  label: PropTypes.string.isRequired,
  icon: PropTypes.node,
  number: PropTypes.number,
  disabled: PropTypes.bool,
  unread: PropTypes.bool,
})

Nav.propTypes = {
  preset: PropTypes.oneOf(Object.keys(presets)),
  classes: ClassesType,
  items: PropTypes.arrayOf(ItemShape.isRequired).isRequired,
  active: PropTypes.oneOfType([IDType, PropTypes.arrayOf(IDType)]),
  onClick: PropTypes.func,
  filterStatus: PropTypes.string,
  selectionStatusFilter: PropTypes.any,
  onSelectionManagementPage: PropTypes.bool,
  filterChecker: PropTypes.bool,
  isMulti: PropTypes.bool,
}

Nav.defaultProps = {
  preset: 'evaluation',
}

export default Nav
