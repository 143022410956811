import { useCallback } from 'react'
import isEmpty from 'lodash/isEmpty'
import dayjs from 'dayjs'

import { parseDateTime } from 'utils/helper'

const useAppointmentScheduleResolver = ({ t, shouldValidate, validateSchedule }) =>
  useCallback(
    async (data) => {
      if (!shouldValidate) return { values: data, errors: {} }
      const errors = {}
      const mapDates = (item, index) => {
        const { date, start, end } = item.value
        const startPath = `dates.${index}.value.start`
        const endPath = `dates.${index}.value.end`

        const startDate = parseDateTime(date, start)
        const endDate = parseDateTime(date, end)

        if (startDate.isSameOrAfter(endDate)) {
          errors[startPath] = t('startGreaterThanEnd')
        }

        if (endDate.isSameOrBefore(startDate)) {
          errors[endPath] = t('endLessThanStart')
        }

        if (startDate.isSameOrBefore(dayjs())) {
          errors[startPath] = t('dateInPast')
        }

        if (endDate.isSameOrBefore(dayjs())) {
          errors[endPath] = t('dateInPast')
        }

        return {
          index,
          date,
          start,
          end,
          startDate,
          endDate,
          startPath,
          endPath,
        }
      }
      const isDateRangeConflict = (startA, endA, startB, endB) =>
        !(startA.isAfter(endB) || startA.isSame(endB) || endA.isBefore(startB) || endA.isSame(startB))

      const modified = data.dates.map(mapDates)

      // Check conflicting date ranges
      const modifiedLength = modified.length
      if (isEmpty(errors) && modifiedLength > 1) {
        modified.forEach((dateA, indexA) => {
          // No need to validate a field that has already been invalidated
          if (dateA.startPath in errors || dateA.endPath in errors) return

          modified.forEach((dateB, indexB) => {
            // No need to validate a field that has already been invalidated
            if (dateB.startPath in errors || dateB.startPath in errors) return

            // Don't compare a field to itself
            if (indexB === indexA) return

            if (isDateRangeConflict(dateA.startDate, dateA.endDate, dateB.startDate, dateB.endDate)) {
              errors[dateA.startPath] = t('dateRangeConflict')
              errors[dateA.endPath] = t('dateRangeConflict')
              errors[dateB.startPath] = t('dateRangeConflict')
              errors[dateB.endPath] = t('dateRangeConflict')
            }
          })
        })
      }

      // If we still don't have an error till now lets validate the conflict from backend
      if (isEmpty(errors)) {
        const promises = modified.map(({ date, start, end }) => validateSchedule({ date, start, end }))
        const results = await Promise.all(promises)
        results.forEach((result, index) => {
          // No conflict from backend
          if (result === true) return

          if (typeof result.error === 'string') {
            errors[`dates.${index}.value.start`] = result.error
            return
          }

          Object.keys(result.error).forEach((key) => {
            errors[`dates.${index}.value.${key}`] = result.error[key]
          })
        })
      }

      return {
        values: isEmpty(errors) ? data : {},
        errors: isEmpty(errors) ? {} : errors,
      }
    },
    [t, shouldValidate, validateSchedule]
  )

export default useAppointmentScheduleResolver
