import React from 'react'

import PropTypes from 'prop-types'

function ListTruncate({ items, max, separator = ', ', truncate }) {
  const shouldDisplayTruncate = items.length > max
  const txt = items.slice(0, max).join(separator)

  return (
    <>
      {txt}
      {shouldDisplayTruncate && truncate(items.length - max)}
    </>
  )
}

ListTruncate.propTypes = {
  items: PropTypes.array.isRequired,
  max: PropTypes.number.isRequired,
  separator: PropTypes.string,
  truncate: PropTypes.func.isRequired,
}

export default ListTruncate
