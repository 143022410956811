import React from 'react'

import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Step2Form from './Step2Form'

function index() {
  const { t } = useTranslation()
  const location = useLocation()
  return (
    <>
      <div className="step">
        <p className="step__text">STEP2/4&nbsp;&nbsp;{t('jobPosting.step2.barLabel')}</p>
        <div className="step__bar step__bar-50"></div>
      </div>
      <Step2Form job_vacancy_data={location.state} />
    </>
  )
}

export default index
