import React, { useEffect, useState } from 'react'

import PropTypes from 'prop-types'
import { useController } from 'react-hook-form'
import find from 'lodash/find'
import flatten from 'lodash/flatten'
import groupBy from 'lodash/groupBy'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { Label, Button, FormAlert, Checkbox, BlockEl } from 'components/atoms'
import { FormGroup, FormSupArea } from 'components/molecules'
import { selectFlattenedIndustries } from 'store/industries/selectors'
import ModalChecklist from '../ModalChecklist'
import { useSet } from 'utils/hooks'

const notEmpty = (v) => !!v

function IndustriesField({ name, rules, fromFieldState }) {
  const { t } = useTranslation()
  const [openModal, setOpenModal] = useState(false)
  const { field, fieldState } = useController({
    name,
    rules,
  })
  const modalSet = useSet([])

  const industries = useSelector((state) => state.industries.items)
  const allIndustries = useSelector(selectFlattenedIndustries)

  useEffect(() => {
    if (!fieldState.isTouched) {
      modalSet.reset(field.value)
    }
  }, [fieldState.isTouched, field.value])

  const handleOpenModal = () => {
    setOpenModal(true)
  }

  const handleCloseModal = () => {
    setOpenModal(false)
  }

  const handleSubmitModal = (e) => {
    const selected = flatten(e).filter(notEmpty)
    field.onChange(selected)
    modalSet.reset(selected)
    setOpenModal(false)
  }

  const valueObjects = modalSet.items.map((id) => find(allIndustries, { id })).filter(notEmpty)
  const groupedObjects = groupBy(valueObjects, 'parent_id')
  const normalizedData = Object.keys(groupedObjects).reduce((accum, curr) => {
    accum[curr] = groupedObjects[curr].map(({ id }) => id)
    return accum
  }, [])

  return (
    <FormGroup {...fromFieldState(fieldState)} modifier="mgb1 spMgb16" required>
      <ModalChecklist
        open={openModal}
        onClose={handleCloseModal}
        title={t('userProfileInvitation.create.modal.industryTitle')}
        modalType="checklist"
        activeModal={name}
        modalListItems={industries}
        limit={5}
        submitModal={handleSubmitModal}
        data={{ [name]: normalizedData }}
      />
      <Label>{t('userProfileInvitation.create.modal.industryTitle')}</Label>
      <BlockEl bec="form__groupFlex" modifier="col" className="form__group-mgt">
        {Object.keys(groupedObjects).map((parentId) => (
          <div className="cm__conditonCheckItemWrap" style={{ display: 'block' }} key={parentId}>
            <h3 className="search__conditonCheckTitle">{find(allIndustries, { id: +parentId })?.name}</h3>
            {groupedObjects[parentId].map((industry) => (
              <p className="search__conditonCheckItem" key={industry.id}>
                <Checkbox readOnly checked={true} bec="form__checkItem2" />
                <Label blockElClass="form__checkLabel2" modifier="mg10">
                  {find(allIndustries, { id: industry.id })?.name}
                </Label>
              </p>
            ))}
          </div>
        ))}
        <Button variant="outline" className="form__fileLabel-plus" onClick={handleOpenModal}>
          {t('userProfileInvitation.create.selectLabel')}
        </Button>
      </BlockEl>

      <FormSupArea flex>
        <FormAlert name="industries" />
      </FormSupArea>
    </FormGroup>
  )
}

IndustriesField.propTypes = {
  name: PropTypes.string.isRequired,
  rules: PropTypes.object.isRequired,
  fromFieldState: PropTypes.func.isRequired,
}

export default IndustriesField
