import React from 'react'
import CorporateInfoCard from '../CorporateInfoCard'

import PropTypes from 'prop-types'

function CorporateInfoTab({ jobPostingId }) {
  return (
    <div className="evaluation__leftMainCont evaluation__leftMainCont-spPdb">
      <CorporateInfoCard jobPostingId={jobPostingId} />
    </div>
  )
}

CorporateInfoTab.propTypes = {
  jobPostingId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default CorporateInfoTab
