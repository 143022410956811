import Button from '@mui/material/Button'
import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
// TODO: Remove this component, if unused
const BaseButton = React.forwardRef(({ navigate, ...props }, ref) => {
  const handleClick = (e) => {
    e.preventDefault()
    navigate(props.href)
  }
  return (
    <Button ref={ref} {...props} onClick={handleClick} variant={props.variant || 'contained'}>
      {props.children}
    </Button>
  )
})

BaseButton.displayName = 'BaseButton'

BaseButton.propTypes = {
  navigate: PropTypes.any,
  children: PropTypes.node,
  variant: PropTypes.string,
  href: PropTypes.string,
}

function ButtonLink(props) {
  return <Link {...props} to={props.to} component={BaseButton} />
}

ButtonLink.propTypes = {
  color: PropTypes.string,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  children: PropTypes.string,
  variant: PropTypes.string,
}

export default ButtonLink
