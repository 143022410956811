import * as types from './actionTypes'

const initialState = {
  pending: false,
  success: false,
  error: null,
  prefectureslist: [],
}

export default (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    //prefectures list
    case types.GET_PREFECTURES_LIST_REQUEST:
      return {
        ...state,
        prefectureslist: [],
        pending: true,
        success: false,
      }
    case types.GET_PREFECTURES_LIST_SUCCESS:
      return {
        ...state,
        prefectureslist: payload.data,
        pending: false,
        success: true,
      }
    case types.GET_PREFECTURES_LIST_FAILURE:
      return {
        ...state,
        prefectureslist: null,
        pending: false,
        success: false,
        error: payload.error,
      }
    default:
      return state
  }
}
