import React from 'react'
import PropTypes from 'prop-types'
import { FormItemLabel } from 'components'
import { useForm } from 'react-hook-form'

const ApplicantFullname = (props) => {
  const { successFullname, successKanaFullname, editmode, handleOnChange, candidateSelected } = props

  const {
    register,
    control,
    formState: { errors },
  } = useForm()

  return (
    <>
      <div
        className={
          editmode ? `form__group form__group-mgb0 required ${successFullname}` : `form__group form__group-mgb0`
        }
      >
        <FormItemLabel label="氏名" />
        {editmode && !candidateSelected ? (
          <>
            <div className="form__groupFlex form__group-mgt form__groupMultiCheck">
              <input
                type="text"
                name="lastname"
                {...register('lastname', { required: 'This is required' })}
                defaultValue={props?.applicant?.lastname}
                onChange={(e) => handleOnChange(e)}
                className="form__inputText form__inputText-w96px form__inputText-mgr inputCheck boolCheckMulti"
              />
              <input
                type="text"
                name="firstname"
                {...register('firstname', { required: '入力してください。' })}
                defaultValue={props?.applicant?.firstname}
                onChange={(e) => handleOnChange(e)}
                className="form__inputText form__inputText-w96px inputCheck boolCheckMulti"
              />
            </div>
            <div className="form__supArea form__supArea-flex">
              <p className="form__alert">{errors?.firstname}</p>
              <p className="form__alert">{errors?.lastname}</p>
              <p className="form__alert">入力してください。</p>
            </div>
          </>
        ) : (
          <div className="form__groupFlex form__group-mgt form__groupMultiCheck">
            <p className="recommendation__dt">
              {candidateSelected ? props?.applicant?.lastname + ' ' : props?.finalData.lastname}{' '}
              {candidateSelected ? props?.applicant?.firstname : props?.finalData?.firstname}
            </p>
          </div>
        )}
      </div>

      <div
        className={
          editmode ? `form__group form__group-mgb0 required ${successKanaFullname}` : `form__group form__group-mgb0`
        }
      >
        <FormItemLabel label="フリガナ" />
        {editmode && !candidateSelected ? (
          <>
            <div className="form__groupFlex form__group-mgt form__groupMultiCheck">
              <input
                type="text"
                name="kana_lastname"
                control={control}
                {...register('kana_lastname', { required: '入力してください。' })}
                defaultValue={props ? props?.applicant?.kana_lastname : ''}
                onChange={(e) => handleOnChange(e)}
                className="form__inputText form__inputText-w128px form__inputText-mgr inputCheck boolCheckMulti"
              />

              <input
                type="text"
                name="kana_firstname"
                control={control}
                {...register('kana_firstname', { required: '入力してください。' })}
                defaultValue={props ? props?.applicant?.kana_firstname : ''}
                onChange={(e) => handleOnChange(e)}
                className="form__inputText form__inputText-w128px inputCheck boolCheckMulti"
              />
            </div>
            <div className="form__supArea form__supArea-flex">
              <p className="form__alert">{errors.kana_firstname?.message}</p>
              <p className="form__alert">{errors.kana_lastname?.message}</p>
            </div>
          </>
        ) : (
          <div className="form__groupFlex form__group-mgt form__groupMultiCheck">
            <p className="recommendation__dt">
              {candidateSelected ? props?.applicant?.kana_lastname + ' ' : props?.finalData.kana_lastname}
              {candidateSelected ? props?.applicant?.kana_firstname : props?.finalData?.kana_firstname}
            </p>
          </div>
        )}
      </div>
    </>
  )
}

ApplicantFullname.propTypes = {
  applicant: PropTypes.object,
  finalData: PropTypes.object,
  successKanaFullname: PropTypes.string,
  successFullname: PropTypes.string,
  editmode: PropTypes.bool,
  handleOnChange: PropTypes.func,
  candidateSelected: PropTypes.bool,
}
export default ApplicantFullname
