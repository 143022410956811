import React from 'react'

import PropTypes from 'prop-types'

function Slim({ children }) {
  return <>{children}</>
}

Slim.propTypes = {
  children: PropTypes.node,
}

export default Slim
