import React from 'react'
import { ErrorMessage as BaseErrorMessage } from '@hookform/error-message'
import { FormAlert } from 'components/atoms'

/**
 * Renders an error message for a field
 *
 * @param {*} props @see https://react-hook-form.com/api/useformstate/errormessage#main
 */
export default function ErrorMessage(props) {
  return <BaseErrorMessage {...props} render={({ message }) => <FormAlert message={message} />} />
}
