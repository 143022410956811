import React from 'react'
import PropTypes from 'prop-types'

function Sad({ className }) {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33.33 33.33">
      <g data-name="レイヤー 2">
        <path
          className="cls-1"
          d="M16.67,30A13.34,13.34,0,1,0,3.33,16.67,13.37,13.37,0,0,0,16.67,30Zm0,3.33A16.67,16.67,0,1,0,0,16.67,16.71,16.71,0,0,0,16.67,33.33Zm-3.34-20A1.57,1.57,0,0,1,11.67,15,1.58,1.58,0,0,1,10,13.33a1.57,1.57,0,0,1,1.67-1.66A1.56,1.56,0,0,1,13.33,13.33ZM21.67,15a1.57,1.57,0,0,0,1.66-1.67,1.67,1.67,0,0,0-3.33,0A1.58,1.58,0,0,0,21.67,15Zm-5,3.33c4.6,0,8.33,3,8.33,6.67H22.5c0-2.3-2.61-4.17-5.83-4.17S10.83,22.7,10.83,25H8.33C8.33,21.32,12.06,18.33,16.67,18.33Z"
        ></path>
      </g>
    </svg>
  )
}

Sad.propTypes = {
  className: PropTypes.string,
}

export default Sad
