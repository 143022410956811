/**
 * Creates an async thunk that dispatches pending, fullfiled/failure types
 *
 * @param {String} type
 * @param {Function} cb
 */
export function createAsyncThunk(type, cb) {
  const fn = (...args) => {
    return (dispatch, getState, extraArgs) => {
      dispatch({ type: fn.pending, payload: undefined, meta: { args } })
      return cb(...args, getState, extraArgs).then(
        (data) => {
          dispatch({ type: fn.fulfilled, payload: data, meta: { args } })
          return { data }
        },
        (error) => {
          dispatch({ type: fn.failure, payload: error, meta: { args } })
          return { error }
        }
      )
    }
  }

  fn.pending = `${type}_PENDING`
  fn.fulfilled = `${type}_FULFILLED`
  fn.failure = `${type}_FAILURE`

  return fn
}
