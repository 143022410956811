import Http from 'utils/Http'
import get from 'lodash/get'

import { parseAxiosResponse, parseDateTime } from '../utils/helper'
import { createAsyncThunk } from 'store/helpers'

/**
 * Set multiple appointments
 *
 * @param {Number} referralId
 * @param {Array} schedules
 * @returns
 */
export const setMultipleAppointments = (referralId, schedules) => {
  return parseAxiosResponse(Http.post('/common/appointment/propose', { referral_proposal_id: referralId, schedules }))
}

export const validateSchedule = ({ referral_proposal_id, date, start, end }) => {
  return Http.get('/common/appointment/validate', {
    params: {
      referral_proposal_id,
      start_datetime: parseDateTime(date, start).format(),
      end_datetime: parseDateTime(date, end).format(),
    },
  })
    .then((res) => (res.data.data ? true : { error: res.data.message }))
    .catch((e) => {
      const error = get(e, 'response.data.error', get(e, 'message'))
      const status = get(e, 'response.status', 500)

      if (status === 422) {
        const errorMap = {
          start_datetime: 'start',
          end_datetime: 'end',
        }

        return {
          error: Object.keys(error).reduce(
            (accum, key) => ({
              ...accum,
              [errorMap[key]]: error[key],
            }),
            {}
          ),
          status,
        }
      }

      return { error, status }
    })
}

export const chooseAppointment = createAsyncThunk('CHOOSE_APPOINTMENT', (appointment) =>
  parseAxiosResponse(Http.post(`/common/appointment/${appointment}/choose`))
)

export const cancelAppointment = createAsyncThunk('CANCEL_APPOINTMENT', (appointment) =>
  parseAxiosResponse(Http.post(`/common/appointment/${appointment}/cancel`))
)

export const fetchLatestAppointmentSchedules = createAsyncThunk(
  'FETCH_LATEST_APPOINTMENT_SCHEDULES',
  (referralPropsalId) =>
    parseAxiosResponse(Http.get(`/common/referral_proposals/${referralPropsalId}/recent_appointments`))
)
