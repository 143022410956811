import React, { Fragment, useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as GarbageIcon } from 'assets/img/garbage-icon2.svg'
import { ReactComponent as PenIcon } from 'assets/img/edit-icon2.svg'
import { ReactComponent as PlusIcon } from 'assets/img/plus-icon2.svg'
import {
  createApplicantsNote,
  getApplicantNotes,
  updateApplicantNotes,
  deleteApplicantNotes,
} from 'services/applicantsNote'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { CircularProgress } from '@mui/material'
import { Link } from 'react-router-dom'
import { showNotification } from 'store/notification/actionCreators'
import { FormGroup, FormSupArea } from 'components/molecules'
import { Textarea, FormAlert, Button } from 'components/atoms'
import clsx from 'clsx'

const CandidateMemo = ({ open, applicant_id }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [notes, setNotes] = useState([])
  const [editMemos, setEditMemos] = useState([])
  const [showDeleteIndex, setShowDeleteIndex] = useState()
  const [showEditor, setShowEditor] = useState(false)
  const [memo, setMemo] = useState('')
  const [loading, setLoading] = useState(false)
  const [initialLoading, setInitialLoading] = useState(false)
  const deleteDialogArea = useRef(null)

  const createNote = async () => {
    setLoading(true)
    dispatch(
      createApplicantsNote({
        applicant_id,
        notes: memo,
      })
    ).then((res) => {
      if (res?.error?.code > 200) {
        dispatch(showNotification(t('candidateSlideout.failedToCreateMemo'), { type: 'danger' }))
        setLoading(false)
        resetStates()
      } else {
        dispatch(showNotification(t('candidateSlideout.addedMemo'), { type: 'success' }))
        resetStates()
        stackNotes(res.data.data)
        setLoading(false)
      }
    })
  }

  const updateNote = (id, data, index) => {
    setLoading(true)
    dispatch(updateApplicantNotes(id, data)).then((res) => {
      if (res?.error?.code > 200) {
        dispatch(showNotification(t('candidateSlideout.failedToUpdateMemo'), { type: 'danger' }))
        setLoading(false)
        resetStates()
      } else {
        dispatch(showNotification(t('candidateSlideout.updatedMemo'), { type: 'success' }))
        const tempNotes = [...notes]
        tempNotes[index] = data
        setLoading(false)
        resetStates()
        setNotes(tempNotes)
        stackEditNotes(tempNotes)
      }
    })
  }

  const deleteNote = (id, index, event) => {
    event.preventDefault()
    setInitialLoading(true)
    setShowDeleteIndex(null)
    dispatch(deleteApplicantNotes(id))
      .then((res) => {
        if (res?.error?.code > 200) {
          dispatch(showNotification(t('candidateSlideout.failedToDeleteMemo'), { type: 'danger' }))
          setInitialLoading(false)
          resetStates()
        } else {
          dispatch(showNotification(t('candidateSlideout.deletedMemo'), { type: 'success' }))
          const tempNotes = [...notes]
          tempNotes.splice(index, 1)
          setNotes(tempNotes)
          stackEditNotes(tempNotes)
          resetStates()
          setInitialLoading(false)
        }
      })
      .catch(() => {})
  }

  const resetStates = () => {
    setShowEditor(false)
    setMemo('')
    setShowDeleteIndex(null)
  }

  const stackNotes = (newNote) => {
    const currentNotes = [...notes]
    const newArray = [newNote].concat(currentNotes)
    setNotes(newArray)
    stackEditNotes(newArray)
  }

  const stackEditNotes = (stackEditNotes) => {
    const tempEditNotes = []
    stackEditNotes.map((item) => {
      tempEditNotes.push({ ...item, isOpen: false })
    })
    setEditMemos(tempEditNotes)
  }

  const getApplicationNotes = () => {
    setInitialLoading(true)
    dispatch(getApplicantNotes(applicant_id))
      .then((res) => {
        setNotes(res.data.data)
        stackEditNotes(res.data.data)
        setInitialLoading(false)
      })
      .catch(() => {
        setInitialLoading(false)
      })
  }

  useEffect(() => {
    if (open) {
      resetStates()
      if (applicant_id) getApplicationNotes()
    }
  }, [open])

  useEffect(() => {
    //Assign click handler to listen when clicked outside the delete dialog
    document.addEventListener('click', handleClickOutside)

    return () => {
      //Remove the listener on unmount
      document.removeEventListener('click', handleClickOutside)
    }
  }, [])

  const handleClickOutside = (event) => {
    const path = event.path || (event.composedPath && event.composedPath())

    if (!path.includes(deleteDialogArea.current)) {
      setShowDeleteIndex(null)
    }
  }

  const handleOnEditButtonPress = (index) => {
    const copyData = [...editMemos]

    const editData = copyData[index]

    editData.notes = notes[index].notes

    if (editData.isOpen) editData.isOpen = false
    else editData.isOpen = true

    setEditMemos(copyData)
  }

  const handleNoteChange = (value, index) => {
    const copyData = [...editMemos]

    copyData[index].notes = value
    setEditMemos(copyData)
  }

  const rules = {
    notes: {
      required: t('validation.required'),
      maxLength: { value: 500, message: t('validation.maxLength', { length: 500 }) },
    },
  }

  return (
    <div className="candidate__modalTabContInner">
      <p className="candidate__modalTabContTitle">
        {t('candidateSlideout.memo')}&nbsp;&nbsp;&nbsp;&nbsp;{notes.length}
        {t('candidateSlideout.item')}
      </p>
      {initialLoading ? (
        <CircularProgress color="primary" size="3rem" />
      ) : (
        notes.map((item, index) => {
          let dateUpdated = new Date(item.created_at)
          let formattedDate =
            dateUpdated.getFullYear() + '/' + (Number(dateUpdated.getMonth()) + 1) + '/' + dateUpdated.getDate()
          return (
            <Fragment key={index}>
              <div className="candidate__modalTabMemoArea">
                <span className="candidate__modalTabMemo">{item.notes}</span>
                <div className="candidate__modalTabMemoFooter">
                  <div className="candidate__modalTabMemoFooterL">
                    {/* <p className="candidate__modalTabMemoText1">{item.registrantName}</p> */}
                    <span className="candidate__modalTabMemoText1">{formattedDate}</span>
                  </div>
                  <div className="candidate__modalTabMemoFooterR">
                    <div className="candidate__modalTabMemoIconWrap candidateGab">
                      <div
                        className="candidate__modalTabMemoIcon"
                        onClick={(e) => {
                          e.stopPropagation()
                          setShowDeleteIndex(index)
                        }}
                      >
                        <GarbageIcon />
                      </div>
                      <div
                        ref={deleteDialogArea}
                        className={clsx('candidate__modalTabMemoDelArea', {
                          'candidate__modalTabMemoDelArea-open': showDeleteIndex === index,
                        })}
                      >
                        <span className="candidate__modalTabMemoText">{t('common.confirmDeleteQuestion')}</span>
                        <Link
                          to="#"
                          onClick={(e) => deleteNote(item.id, index, e)}
                          className="candidate__modalTabMemoDelLink"
                        >
                          {t('common.delete')}
                        </Link>
                      </div>
                    </div>
                    <span
                      className="candidate__modalTabMemoIconWrap candidatePen"
                      onClick={() => handleOnEditButtonPress(index)}
                    >
                      <div className="candidate__modalTabMemoIcon">
                        <PenIcon />
                      </div>
                    </span>
                  </div>
                </div>
              </div>
              <div
                className={clsx('candidate__modalTabMemoTextarea', {
                  'candidate__modalTabMemoTextarea-open': editMemos[index]?.isOpen,
                })}
              >
                <FormGroup modifier="mgt">
                  <Textarea
                    value={editMemos[index]?.notes}
                    cols="30"
                    rows="10"
                    maxLength={500}
                    onChange={(e) => handleNoteChange(e.target.value, index)}
                    placeholder={t('candidateSlideout.fillInYourNotes')}
                    className="form__inputTextArea inputCalc toInterlockBtn2"
                  />
                  <FormSupArea modifier="flex jcfe">
                    <FormAlert>{t('candidate.fieldErrorMessage')}</FormAlert>
                    <FormSupArea.InputSup
                      modifier="num"
                      inputValue={editMemos[index]?.notes}
                      max={rules.notes.maxLength.value}
                    />
                  </FormSupArea>
                  <Button
                    disabled={editMemos[index]?.notes.length === 0 || loading}
                    onClick={() => updateNote(editMemos[index]?.id, editMemos[index], index)}
                    variant="shadowDissable"
                    className={clsx('candidate__modalTabMemoTextareaBtn', {
                      'btn__shadowDissableBtn-active': editMemos[index]?.notes.length > 0 && !loading,
                    })}
                  >
                    {loading ? <CircularProgress size="1.5rem" /> : t('candidate.updateButton')}
                  </Button>
                </FormGroup>
              </div>
            </Fragment>
          )
        })
      )}
      <div className="candidate__modalTabMemoBtnArea">
        {!showEditor && (
          <Button modifier="iconL" onClick={() => setShowEditor(true)} variant="shadow">
            <span className="btn__btnIcon">
              <PlusIcon />
            </span>
            {t('candidateSlideout.addNotes')}
          </Button>
        )}
      </div>
      <div className={clsx('candidate__modalTabMemoTextarea', { 'candidate__modalTabMemoTextarea-open': showEditor })}>
        <FormGroup modifier="mgt">
          <Textarea
            value={memo}
            cols="30"
            rows="10"
            maxLength={500}
            onChange={(e) => setMemo(e.target.value)}
            placeholder={t('candidateSlideout.fillInYourNotes')}
            className="form__inputTextArea inputCalc toInterlockBtn2"
          />
          <FormSupArea modifier="flex jcfe">
            <FormAlert>{t('candidate.fieldErrorMessage')}</FormAlert>
            <FormSupArea.InputSup modifier="num" inputValue={memo} max={rules.notes.maxLength.value} />
          </FormSupArea>
          <Button
            className={clsx('candidate__modalTabMemoTextareaBtn interlockBtn2', {
              'btn__shadowDissableBtn-active': memo.length > 0 && !loading && !initialLoading,
            })}
            onClick={createNote}
            variant="shadowDissable"
          >
            {loading || initialLoading ? <CircularProgress size="1.5rem" /> : t('candidateSlideout.add')}
          </Button>
        </FormGroup>
      </div>
    </div>
  )
}

CandidateMemo.propTypes = {
  applicant_id: PropTypes.number,
  open: PropTypes.bool,
}

export default CandidateMemo
