import produce from 'immer'
import { createJobPostings } from 'services/jobPostings'
import * as ActionTypes from './actionTypes'
import {
  UPDATE_JOB_POSTING_FAILURE,
  UPDATE_JOB_POSTING_SUCCESS,
  UPDATE_JOB_POSTING_REQUEST,
} from '../job-postings/actionTypes'
import { AUTH_SIGNOUT } from 'store/auth/actionTypes'

const initialValues = {
  data: null,
  formData: null,
  syncData: null,
  proposalData: null,
  syncProposalData: null,
  withReferrals: false,
  withProposals: false,
  error: null,
  success: false,
  state: 'idle',
}

const reducer = produce((draft, { type, payload }) => {
  switch (type) {
    case ActionTypes.SYNC_JOB_POSTING_FORM:
      draft.syncData = Object.assign(draft.syncData || {}, payload)
      draft.formData = payload
      break
    case ActionTypes.SET_JOB_POSTING_FORM:
      draft.data = payload
      draft.withReferrals = payload?.id !== '' && payload?.with_referrals
      break
    case ActionTypes.SYNC_JOB_POSTING_PROPOSAL_FORM:
      draft.syncProposalData = Object.assign(draft.syncProposalData || {}, payload)
      draft.formData = payload
      break
    case ActionTypes.SET_JOB_POSTING_PROPOSAL_FORM:
      draft.proposalData = payload
      draft.withProposals = payload?.id !== '' && payload?.with_proposals
      break
    case createJobPostings.pending:
    case UPDATE_JOB_POSTING_REQUEST:
      draft.state = 'pending'
      draft.error = null
      break
    case createJobPostings.fulfilled:
      draft.state = 'fulfilled'
      break
    case createJobPostings.failure:
      draft.state = 'failure'
      draft.error = payload.error || payload.message
      break
    case UPDATE_JOB_POSTING_FAILURE:
      draft.state = 'failure'
      draft.error = payload.error.error || payload.error.message
      break
    case UPDATE_JOB_POSTING_SUCCESS:
      draft.state = 'fulfilled'
      break
    case AUTH_SIGNOUT:
    case ActionTypes.RESET_JOB_POSTING_FORM:
      return initialValues
    default:
      return draft
  }
}, initialValues)

export default reducer
