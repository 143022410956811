import React from 'react'
import PropTypes from 'prop-types'
import BlockEl from '../BlockEl'

function Label(inProps) {
  const { component, blockElClass, mini, modifier, children, ...props } = inProps
  return (
    <BlockEl {...props} component={component} modifier={[{ mini }, modifier]} blockElClass={blockElClass}>
      {children}
    </BlockEl>
  )
}

Label.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  modifier: PropTypes.any,
  mini: PropTypes.bool,
}

Label.defaultProps = {
  mini: true,
  blockElClass: 'form__label',
  component: 'label',
}

export default Label
