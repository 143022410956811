import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { getApplicantsList } from 'services/applicant'
import { useTranslation } from 'react-i18next'
import CandidateInformationSidebar from '../CandidateInformationSidebar'

const CandidateRecord = (props) => {
  const { row } = props
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const recordUpdated = useSelector((state) => state.applicantsNote.candidateRecordIsUpdated)

  const handleClose = () => {
    if (recordUpdated) {
      dispatch(getApplicantsList())
    }
    setOpen(false)
  }

  return (
    <>
      <li
        className="candidate__mainLi popModal"
        onClick={() => setOpen(true)}
        data-modal="modal"
        data-modalcont="cont1"
      >
        <div className="candidate__mainName candidate__mainName-ais">{row.fullName}</div>
        <div className="candidate__mainName2" data-title={t('tableHeaders.personIncharge')}>
          {row.ad_person_incharge ? row.ad_person_incharge.full_name : row.created_by.full_name}
        </div>
        <div className="candidate__mainAge" data-title={t('tableHeaders.age')}>
          {row?.age}
          {t('suffixes.age')}
        </div>
        <div className="candidate__mainSituation" data-title={t('tableHeaders.status')}>
          {row?.status}
        </div>
        <div className="candidate__mainApplications" data-title={t('tableHeaders.applicationCount')}>
          {row?.recommendation_count}
          {t('suffixes.subject')}
        </div>
        <div className="candidate__mainMemo" data-title={t('tableHeaders.memo')}>
          {row?.notes}
        </div>
      </li>
      <CandidateInformationSidebar open={open} row={row} handleClose={handleClose} />
    </>
  )
}

CandidateRecord.propTypes = {
  row: PropTypes.object,
}

export default CandidateRecord
