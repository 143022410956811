export default {
  evaluation: {
    root: 'evaluation__leftMainHeader',
    item: 'evaluation__leftMainHeaderItem',
    link: 'evaluation__leftMainHeaderLink',
    active: 'evaluation__leftMainHeaderLink-active',
    number: 'evaluation__leftMainHeaderLink-num',
  },
  question: {
    root: 'question__headerUl',
    item: 'question__headerLi',
    link: 'question__headerLink',
    active: 'question__headerLink-active',
    number: 'question__headerLink-num',
  },
  selection: {
    root: 'selection__sideUl',
    active: 'selection__sideLink-active',
    number: 'selection__sideLink-num',
    item: 'selection__sideLi',
    item_unread: 'selection__sideLi-attention',
    item_disabled: 'selection__sideLi-disableLink',
    link: 'selection__sideLink',
  },
}
