import React from 'react'

import PropTypes from 'prop-types'
import clsx from 'clsx'
import noop from 'lodash/noop'

import { calculateModifiers } from 'utils/helper'
import BlockEl from '../BlockEl'

const Button = React.forwardRef((props, ref) => {
  const {
    className,
    children,
    variant,
    variantMod,
    blockElClass = 'btn',
    modifier = '',
    component = 'button',
    value,
    onChange,
    text,
    type = 'button',
    noVariantSuffix = false,
    disabled,
    onClick,
    ...other
  } = props
  const variantClass = `${blockElClass}__${variant}${noVariantSuffix ? '' : 'Btn'}`
  const isInput = component == 'input'
  const hasVariant = typeof variant !== 'undefined'

  const theProps = {
    ...other,
    blockElClass,
    modifier,
    className: clsx(className, {
      [variantClass]: hasVariant,
      ...calculateModifiers(variantClass, variantMod),
    }),
    value: isInput ? children : value,
    onChange: isInput ? onChange ?? noop : onChange,
    onClick: (e) => {
      if (disabled) {
        e.preventDefault()
        return
      }
      onClick && onClick(e)
    },
    componentRef: ref,
    component,
    disabled,
    type: component !== 'a' ? type : undefined,
  }

  const theChildren = !isInput ? text ?? children : undefined

  return <BlockEl {...theProps}>{theChildren}</BlockEl>
})

Button.displayName = 'Button'

Button.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  // Known variants 'shadow', 'outline', 'yellowDessable', 'text', 'outlineBtn'
  variant: PropTypes.string,
  variantMod: PropTypes.any,
  blockElClass: PropTypes.string,
  modifier: PropTypes.any,
  component: BlockEl.propTypes.component,
  value: PropTypes.any,
  onChange: PropTypes.func,
  text: PropTypes.string,
  type: PropTypes.string,
  noVariantSuffix: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
}

export default Button
