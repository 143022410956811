import { loadOccupationsFailure, loadOccupationsRequest, loadOccupationsSuccess } from 'store/occupations/actionCreator'
import { parseAxiosResponse } from 'utils/helper'
import Http from 'utils/Http'

export function getOccupations() {
  return (dispatch) => {
    loadOccupationsRequest()

    return parseAxiosResponse(Http.get('occupations')).then((res) =>
      dispatch(loadOccupationsSuccess(res), (err) => loadOccupationsFailure(err))
    )
  }
}
