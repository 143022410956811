import React from 'react'

import PropTypes from 'prop-types'

function SearchSideSection({ title, children }) {
  return (
    <section className="search__sideSec">
      <h2 className="search__sideSecTitle">{title}</h2>
      {children}
    </section>
  )
}

SearchSideSection.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
}

export default SearchSideSection
