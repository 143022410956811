import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

const RecommendationFooter = (props) => {
  const { unfilled, isValid, isSubmitting } = props
  const { t } = useTranslation()

  return (
    <footer className="footer footer-flex">
      <div className="recommendation__footer">
        {isValid && unfilled === 0 ? (
          <input
            type="submit"
            className="btn btn__shadowBtn btn__shadowBtn-wide recommendation__footerBtn main__footerBtn-disabled"
            id="footerBtn"
            value={t('common.checkContent')}
            hidden={true}
            disabled={isSubmitting}
          />
        ) : (
          <p className="main__footerReq" id="footerReq">
            {t('validation.invalidInputsCount', { count: unfilled })}
          </p>
        )}
      </div>
    </footer>
  )
}

RecommendationFooter.propTypes = {
  errors: PropTypes.object,
  showPreview: PropTypes.bool,
  unfilled: PropTypes.number,
  isValid: PropTypes.bool,
  isSubmitting: PropTypes.bool,
}

export default RecommendationFooter
