import React from 'react'
import PropTypes from 'prop-types'

function FormItemLabel(props) {
  return (
    <label htmlFor="" className="form__label form__label-mgt9 form__label-mini">
      {props.label}
    </label>
  )
}

FormItemLabel.propTypes = {
  label: PropTypes.string,
}

export default FormItemLabel
