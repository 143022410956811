import React from 'react'

import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useLocation, useHistory, Link } from 'react-router-dom'

import { Button, Input } from 'components/atoms'
import { ErrorMessage, FormGroup, FormSupArea } from 'components/molecules'
import { sendForgotPasswordEmail } from 'services/auth'
import { makeFormGroupStateGetter } from 'utils/helper'

function ForgotPasswordForm() {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()
  const success = !!location.state?.success
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
    getFieldState,
  } = useForm()

  const validationRules = {
    email: {
      required: {
        value: String,
        message: t('auth.required'),
      },
      pattern: {
        value: /\S+@\S+\.\S+/, // Regex Email Validation
        message: t('auth.email'),
      },
    },
  }

  const handleSuccess = () => {
    history.replace({
      pathname: location.pathname,
      state: { success: true },
    })
  }

  const handleError = (errorRes) => {
    const { code, error } = errorRes
    // handle API validation error
    if (code === 422 && error) {
      if ('email' in error) {
        setError('email', { message: error.email })
      }
    }
  }

  const onSubmit = (data) => dispatch(sendForgotPasswordEmail(data)).then(handleSuccess, handleError)

  const { byName } = makeFormGroupStateGetter(getFieldState)

  return (
    <div className="adminLogin__mainWrap">
      <div className="selection__mainTitleFlex">
        <h3 className="selection__mainTitle"> {t('forgot.forgotPassword')}</h3>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        {!success && (
          <div className="adminLogin__inputWrap">
            <p className="adminLogin__inputTtl">{t('common.emailAddress')}</p>
            <Input type="email" {...register('email', validationRules.email)} />

            <FormGroup modifier="mgb0 mgt" {...byName('email')}>
              <FormSupArea>
                <ErrorMessage name="email" errors={errors} />
              </FormSupArea>
            </FormGroup>
          </div>
        )}

        <div className="adminLogin__btnWrap">
          {success ? (
            <>
              <p className="mgb">{t('forgot.submitted')}</p>
              <p className="mgb">{t('forgot.success')}</p>
              <br />
            </>
          ) : (
            <Button
              type="submit"
              className="mgb"
              disabled={isSubmitting}
              variant={isSubmitting ? 'shadowDissable' : 'shadow'}
            >
              {t('forgot.setIt')}
            </Button>
          )}

          <p className="pwForget">
            <Link to="/sign-in">{t('common.backToSignIn')}</Link>
          </p>
        </div>
      </form>
    </div>
  )
}

export default ForgotPasswordForm
