import produce from 'immer'
import { getChineseLevel } from 'services/chineseLevel'

const initialState = {
  items: [],
  state: 'pending',
}

export default produce((draft, { type, payload }) => {
  switch (type) {
    case getChineseLevel.pending:
      draft.state = 'pending'
      break
    case getChineseLevel.fulfilled:
      draft.state = 'fulfilled'
      draft.items = payload.data
      break
    case getChineseLevel.failure:
      draft.state = 'failure'
      break
    default:
      return draft
  }
}, initialState)
