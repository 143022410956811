import * as ActionTypes from './actionTypes'

export function getPrefecturesListRequest() {
  return {
    type: ActionTypes.GET_PREFECTURES_LIST_REQUEST,
  }
}

export function getPrefecturesListSuccess(data) {
  return {
    type: ActionTypes.GET_PREFECTURES_LIST_SUCCESS,
    payload: data,
  }
}

export function getPrefecturesListFailure(error) {
  return {
    type: ActionTypes.GET_PREFECTURES_LIST_FAILURE,
    payload: error,
  }
}
