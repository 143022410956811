import { useState, useCallback } from 'react'
import isArray from 'lodash/isArray'
import castArray from 'lodash/castArray'
import partition from 'lodash/partition'

export default function useSet(initialItems = []) {
  const [items, setItems] = useState(initialItems)
  const reset = useCallback((val) => setItems(() => (isArray(val) ? val : [])), [])
  const toggle = useCallback((id) => {
    const ids = castArray(id)
    setItems((oldItems) => {
      const tmp = oldItems.slice()
      const [toBeRemoved, toBeAdded] = partition(ids, (itemId) => tmp.includes(itemId))
      return tmp.filter((id) => !toBeRemoved.includes(id)).concat(toBeAdded)
    })
  }, [])
  const isSelected = useCallback((val) => items.includes(val), [items])

  return { items, reset, toggle, isSelected }
}
