import React from 'react'
import { useSelector } from 'react-redux'
import { selectJobPosting } from '../../../store/job-postings/selectors'
import { useTranslation } from 'react-i18next'
import { Button } from 'components/atoms'
import { useHistory } from 'react-router-dom'
import AgentSvg from 'assets/img/agent-icon.svg'
import PropTypes from 'prop-types'

function AgentOnly({ accountTypeHR, editable = true }) {
  const job_posting = useSelector(selectJobPosting)
  const { t } = useTranslation('translation', { keyPrefix: 'jobVacancies.agentOnly' })
  const history = useHistory()

  const handleClick = () => {
    localStorage.setItem(
      job_posting.job_posting_type === 'referral' ? 'job_posting' : 'job_post_proposal',
      JSON.stringify(job_posting)
    )
    history.push({ pathname: '/new-job-post/step2/', state: { job_posting_data: job_posting, multipleStep: true } })
  }

  return (
    <section className="jobvacancies__cont">
      <h3 className="jobvacancies__title jobvacancies__title-jcsb">
        <p>
          <img src={AgentSvg} alt="" className="jobvacancies__titleImg" width="20" height="20" />
          {t('title')}
        </p>
        {accountTypeHR && (
          <Button variant="text" onClick={handleClick} disabled={!editable}>
            {t('editButton')}
          </Button>
        )}
      </h3>
      <p className="jobvacancies__titleSup">{t('subTitle')}</p>
      <dl className="jobvacancies__dl">
        <dt className="jobvacancies__dt">{t('selectionDifficulty')}</dt>
        <dd className="jobvacancies__dd">{job_posting.difficulty_level}</dd>
        <dt className="jobvacancies__dt">{t('latestInformalCandidateInformation')}</dt>
        <dd className="jobvacancies__dd">{job_posting.recent_job_info}</dd>
        <dt className="jobvacancies__dt">{t('interviewInformation')}</dt>
        <dd className="jobvacancies__dd">{job_posting.interview_info}</dd>
        <dt className="jobvacancies__dt">{t('notices')}</dt>
        <dd className="jobvacancies__dd">{job_posting.special_note}</dd>
      </dl>
    </section>
  )
}

AgentOnly.propTypes = {
  accountTypeHR: PropTypes.bool,
  editable: PropTypes.bool,
}

export default AgentOnly
