import React from 'react'

import PropTypes from 'prop-types'

import { BlockEl } from 'components/atoms'
import { useSituationMark } from 'utils/hooks'

function SituationMark({ item }) {
  const { message, color } = useSituationMark(item)

  return (
    <BlockEl bec="selection__mainSituation">
      <BlockEl bec="selection__mainSituationMark" modifier={color}>
        {message}
      </BlockEl>
    </BlockEl>
  )
}

SituationMark.propTypes = {
  item: PropTypes.object.isRequired,
}

export default SituationMark
