import React, { memo } from 'react'

import PropTypes from 'prop-types'

function Line({ line, addLine, renderLine }) {
  const renderTheLine = renderLine || (({ line }) => <>{line}</>)

  return (
    <>
      {renderTheLine({ line })}
      {addLine && <br />}
    </>
  )
}

Line.propTypes = {
  line: PropTypes.string.isRequired,
  addLine: PropTypes.bool,
  renderLine: PropTypes.func,
}

function NewlineText({ text, noBreak = false, renderLine }) {
  const newLineSplitText = (text || '').split('\n')
  const length = newLineSplitText.length

  return (
    <>
      {newLineSplitText.map((line, index) => (
        <Line key={index} line={line} addLine={length - 1 !== index && !noBreak} renderLine={renderLine} />
      ))}
    </>
  )
}

NewlineText.propTypes = {
  text: PropTypes.string,
  renderLine: PropTypes.func,
  noBreak: PropTypes.bool,
}

export default memo(NewlineText)
