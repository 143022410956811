import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Button } from 'components/atoms'
import PropTypes from 'prop-types'

const AccountManagementEmptyList = (props) => {
  const { t } = useTranslation()
  const { countOnLoad, openInvitationModal } = props
  const company = useSelector((state) => state.companies.details)

  return (
    <>
      {countOnLoad === 0 ? (
        <>
          <p className="invited__subTitle">{t('accountList.emptyList.subTitle')}</p>
          <p className="invited__txt">
            {t('accountList.emptyList.invitedTxt', { companyName: company?.company_name })}
          </p>
          <div className="invited__btnArea">
            <Button component="a" href="" variant="shadow" modifier="iconL" onClick={openInvitationModal()}>
              <span className="btn__btnIcon">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16.67 16.67">
                  <g id="レイヤー_2" data-name="レイヤー 2">
                    <g id="レイヤー_1-2" data-name="レイヤー 1">
                      <path
                        className="cls-1"
                        d="M7.5,15.83a.84.84,0,0,0,.83.84.85.85,0,0,0,.84-.84V9.17h6.66a.85.85,0,0,0,.84-.84.84.84,0,0,0-.84-.83H9.17V.83A.84.84,0,0,0,8.33,0,.83.83,0,0,0,7.5.83V7.5H.83A.83.83,0,0,0,0,8.33a.84.84,0,0,0,.83.84H7.5Z"
                      ></path>
                    </g>
                  </g>
                </svg>
              </span>
              {t('accountList.emptyList.inviteBtnTxt')}
            </Button>
          </div>
        </>
      ) : (
        <>
          <p className="selection__mainNoneText">{t('accountList.userList.listRetrievalUnsuccesfulMessage')}</p>
        </>
      )}
    </>
  )
}

AccountManagementEmptyList.propTypes = {
  countOnLoad: PropTypes.number,
  openInvitationModal: PropTypes.func,
}

export default AccountManagementEmptyList
