import React from 'react'

import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import StopIcon from 'assets/img/stop-icon.svg'
import { DATE_FORMAT, JOB_POSTING_STATUSES } from 'utils/constants'

function QuestionThreadBox({ question }) {
  const { t } = useTranslation()
  const repliesCount = question.number_of_replies
  const hasSeen = question.number_of_unseen_replies === 0
  const repliesText = t('qAndA.numReplies', { count: repliesCount })
  const isClosed = question.job_posting?.status === JOB_POSTING_STATUSES.closed

  return (
    <div className="question__mainLiBox">
      <div className="question__mainLiHeader">
        <p className="question__mainLiTitle">{question.topic?.topic}</p>
        <p className="question__mainLiDate">
          {t('qAndA.questionDate')}: {dayjs(question.created_at).format(DATE_FORMAT)}
        </p>
      </div>

      <div className="question__mainLiTextArea">
        <p className="question__mainLiText">{question.question}</p>
      </div>

      <div className="question__mainLiToJob">
        <div className="question__mainLiToJobIn">
          <p className="question__mainLiToJobTitle">{t('qAndA.jobYouAsk')}</p>
          <Link
            to={{
              pathname: `/job-postings/${question.job_posting_id}/qanda`,
              search: `?questionId=${question.id}`,
            }}
            className="question__mainLiToJobLink"
          >
            <p className="question__mainLiToJobName">{question.job_posting?.job_title}</p>
            <p className="question__mainLiToJobComp">{question.job_posting?.company_name}</p>
          </Link>
        </div>
        {isClosed && (
          <p className="question__mainLiToJobFin">
            <img src={StopIcon} alt="" className="question__mainLiToJobFinIcon" width="17" height="17" />
            {t('jobPosting.status.closed')}
          </p>
        )}
      </div>

      <div className="question__mainLiReplyArea">
        {!hasSeen ? (
          <Link
            className="question__mainLiReply question__mainLiReply-link"
            to={{
              pathname: `/job-postings/${question.job_posting_id}/qanda`,
              search: `?questionId=${question.id}`,
            }}
          >
            {repliesText}
          </Link>
        ) : (
          <p className="question__mainLiReply">{repliesText}</p>
        )}
      </div>
    </div>
  )
}

QuestionThreadBox.propTypes = {
  question: PropTypes.object,
}

export default QuestionThreadBox
