import React from 'react'

import PropTypes from 'prop-types'
import { useBEM } from 'utils/hooks'

function BlockEl({ blockElClass: propBlockElClass, bec, ...inProps }) {
  const theBlockElClass = bec ?? propBlockElClass
  const { component = 'div', componentRef, ...props } = useBEM({ props: { ...inProps, blockElClass: theBlockElClass } })

  if (typeof component === 'string') {
    return React.createElement(component, { ...props, ref: componentRef }, inProps.children)
  }

  const Component = component

  return (
    <Component {...props} ref={componentRef}>
      {inProps.children}
    </Component>
  )
}

BlockEl.propTypes = {
  component: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType]),
  blockElClass: function (props) {
    if (typeof props.blockElClass !== 'string' && typeof props.bec !== 'string') {
      return new Error('Please provide blockElClass')
    }
  },
  /**
   * Alias of blockElClass prop
   */
  bec: PropTypes.string,
  modifier: PropTypes.any,
  noBlockEl: PropTypes.bool,
  componentRef: PropTypes.any,
}

export default BlockEl
