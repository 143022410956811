import React, { useEffect } from 'react'

import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import findIndex from 'lodash/findIndex'

import { Stepper } from 'components/molecules'
import { fetchReferralProposalSteps } from 'services/referralProposals'
import { PROPOSAL_STATUSES } from 'utils/constants'

const rejectedCancelled = [PROPOSAL_STATUSES.rejected, PROPOSAL_STATUSES.cancelled]

function SuggestionStatusStepper({ id, proposal_status_id }) {
  const steps = useSelector((state) => state.referralProposals.steps.items)
  const state = useSelector((state) => state.referralProposals.steps.state)
  const dispatch = useDispatch()

  useEffect(() => {
    if (id) {
      dispatch(fetchReferralProposalSteps(id))
    }
  }, [id, proposal_status_id])

  const getType = (step) => {
    switch (step.id) {
      case PROPOSAL_STATUSES.cancelled:
      case PROPOSAL_STATUSES.rejected:
        return 'error'
      case PROPOSAL_STATUSES.contracted:
        return proposal_status_id === PROPOSAL_STATUSES.contracted ? 'success' : 'normal'
      default:
        return undefined
    }
  }

  const currentIndex = findIndex(steps, { id: proposal_status_id })

  const theSteps = steps.map((step, index) => ({
    ...step,
    label: step.status,
    date: index <= currentIndex ? step.recent_transaction?.created_at : null,
    type: getType(step),
    borderBlockElClass: rejectedCancelled.includes(proposal_status_id)
      ? 'selectionModal__stepBorder2'
      : 'selectionModal__stepBorder',
  }))

  return <Stepper steps={state === 'fulfilled' ? theSteps : []} current={proposal_status_id} />
}

SuggestionStatusStepper.propTypes = {
  id: PropTypes.number,
  proposal_status_id: PropTypes.number,
}

export default SuggestionStatusStepper
