import React from 'react'
import { styled } from '@mui/material/styles'
import { Link } from 'react-router-dom'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { AppBar, Toolbar, Typography } from '@mui/material'

const PREFIX = 'Topbar'

const classes = {
  root: `${PREFIX}-root`,
  link: `${PREFIX}-link`,
  title: `${PREFIX}-title`,
}

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  [`&.${classes.root}`]: {
    boxShadow: 'none',
  },

  [`& .${classes.link}`]: {
    textDecoration: 'none',
  },

  [`& .${classes.title}`]: {
    flexGrow: 1,
    color: theme.palette.white,
  },
}))

function Topbar(props) {
  const { className, ...rest } = props

  return (
    <StyledAppBar {...rest} className={clsx(classes.root, className)} color="primary" position="fixed">
      <Toolbar>
        <Link to="/" className={classes.link}>
          <Typography variant="h4" className={classes.title}></Typography>
        </Link>
      </Toolbar>
    </StyledAppBar>
  )
}

Topbar.propTypes = {
  className: PropTypes.string,
}

export default Topbar
