import React from 'react'

import PropTypes from 'prop-types'
import groupBy from 'lodash/groupBy'

import WorkPlaceCard from './WorkPlaceCard'

function GroupItem({ items }) {
  const groupName = items[0].prefecture.prefecture

  return (
    <>
      <p className="jobvacancies__ddTitle">{groupName}</p>

      {items.map((item) => (
        <WorkPlaceCard
          key={item.id}
          zipcode={item.postal_code}
          address={item.address}
          nearestStation={item.nearest_station}
        />
      ))}
    </>
  )
}

GroupItem.propTypes = {
  items: PropTypes.array,
}

function WorkPlaces({ workLocations }) {
  const groupedWorkLocations = groupBy(workLocations, 'prefecture.id')

  return (
    <>
      {Object.keys(groupedWorkLocations).map((prefectureId) => (
        <GroupItem key={prefectureId} prefectureId={prefectureId} items={groupedWorkLocations[prefectureId]} />
      ))}
    </>
  )
}

WorkPlaces.propTypes = {
  workLocations: PropTypes.array,
}

export default WorkPlaces
