import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { BlockEl, Checkbox, Label as BaseLabel } from 'components/atoms'

let compId = 0

const labelTypes = {
  round: 'form__checkLabel',
  square: 'form__checkLabel2',
}

const Label = ({ type, htmlFor, start, end, text, disabled, renderText, ...other }) => (
  <BaseLabel blockElClass={labelTypes[type]} modifier={{ disabled }} mini={false} htmlFor={htmlFor} {...other}>
    {start}
    {typeof renderText === 'function' ? renderText({ text }) : text}
    {end}
  </BaseLabel>
)

Label.propTypes = {
  ...BaseLabel.propTypes,
  type: PropTypes.oneOf(['round', 'square']).isRequired,
  text: PropTypes.string,
  renderText: PropTypes.func,
  start: PropTypes.node,
  end: PropTypes.node,
}

function FormGroupCheckItem(props) {
  const {
    slim = false,
    Input,
    id,
    label,
    inputProps,
    modifier,
    type,
    supElement,
    blockElClass = 'form__groupCheckItem',
    disabled = false,
    labelProps = {},
    ...other
  } = props
  const [inputId, setInputId] = useState('')

  useEffect(() => {
    if (id) {
      setInputId(inputId)
    } else {
      setInputId(`form-check-item-${++compId}`)
    }
  }, [id])

  const renderChildren = () => (
    <>
      <Input id={inputId} {...inputProps} disabled={disabled || inputProps.disabled} />
      <Label disabled={disabled} type={type} text={label} htmlFor={inputId} {...labelProps} />
      {supElement}
    </>
  )

  return (
    <>
      {slim ? (
        renderChildren()
      ) : (
        <BlockEl {...other} blockElClass={blockElClass} modifier={modifier}>
          {renderChildren()}
        </BlockEl>
      )}
    </>
  )
}

FormGroupCheckItem.propTypes = {
  ...BlockEl.propTypes,
  blockElClass: PropTypes.string,
  Input: PropTypes.any,
  id: PropTypes.string,
  inputProps: PropTypes.object,
  labelProps: PropTypes.object,
  label: function (props) {
    if (typeof props.label === 'undefined' && typeof props.labelProps === 'undefined') {
      return new Error('Please provide `label` prop if `labelProp` is not provided')
    }
  },
  type: Label.propTypes.type,
  slim: PropTypes.bool,
  supElement: PropTypes.node,
  disabled: PropTypes.bool,
}

function FormGroupCheckboxItem({ value, onChange, checked, label, labelProps, ...props }) {
  return (
    <FormGroupCheckItem
      inputProps={{ ...props, value, onChange, checked }}
      label={label}
      labelProps={labelProps}
      Input={Checkbox}
      type="square"
    />
  )
}
FormGroupCheckboxItem.propTypes = {
  value: PropTypes.any,
  label: FormGroupCheckItem.propTypes.label,
  labelProps: FormGroupCheckItem.propTypes.labelProps,
  onChange: PropTypes.func,
  checked: PropTypes.bool,
}

function FormGroupCheck(props) {
  const { children, options, checkItemProps, blockElClass = 'form__groupCheck', ...other } = props
  const optionsLength = options?.length || 0
  const hasOptions = optionsLength > 0

  const renderOption = (option, index) => (
    <FormGroupCheckItem
      key={option.id || index}
      {...(typeof checkItemProps === 'function'
        ? checkItemProps({ option, index, length: optionsLength })
        : checkItemProps)}
    />
  )

  return (
    <BlockEl {...other} blockElClass={blockElClass}>
      {hasOptions ? options.map(renderOption) : children}
    </BlockEl>
  )
}

FormGroupCheck.propTypes = {
  ...BlockEl.propTypes,
  blockElClass: PropTypes.string,
  options: PropTypes.array,
  checkItemProps: PropTypes.oneOfType([PropTypes.func, PropTypes.shape(FormGroupCheckItem.propTypes)]),
}

FormGroupCheck.Item = FormGroupCheckItem
FormGroupCheck.Checkbox = FormGroupCheckboxItem

export default FormGroupCheck
