import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Topbar } from 'components/organisms'

const USER_TYPES = {
  admin: {
    id: 1,
    type: 'admin',
  },
  hrParent: {
    id: 2,
    type: 'hrParent',
  },
  hrChild: {
    id: 3,
    type: 'hrChild',
  },
  ad1Parent: {
    id: 4,
    type: 'ad1Parent',
  },
  ad1Child: {
    id: 5,
    type: 'ad1Child',
  },
  ad2Parent: {
    id: 6,
    type: 'ad2Parent',
  },
  ad2Child: {
    id: 7,
    type: 'ad2Child',
  },
}

function getUserTypeFromId(id) {
  let userTypeKey = Object.keys(USER_TYPES).find((key) => USER_TYPES[key].id === id)
  return userTypeKey
}

const Main = (props) => {
  const user = useSelector((state) => state.auth.user?.data)
  const type = getUserTypeFromId(user?.groups[0].id)

  const { children } = props

  return (
    <>
      {location.pathname.includes('/invitation') ? null : <Topbar userType={type} user={user} />}
      {children}
    </>
  )
}

Main.propTypes = {
  children: PropTypes.node,
}

export default Main
