import React from 'react'

import PropTypes from 'prop-types'
import { getUrlFilename } from 'utils/helper'
import { saveAs } from 'file-saver'

const guessFilename = (url) => getUrlFilename(url) ?? 'unknown'

function DownloadLink({ src, className, text, renderText: renderTextProp, filename, start, end, resolver }) {
  const downloadFilename = filename ?? guessFilename(src)
  const theText = text || downloadFilename

  const handleOnClick = async (e) => {
    if (typeof resolver === 'function') {
      e.preventDefault()
      const data = await resolver(src)

      data && saveAs(data, downloadFilename)
    }
  }

  const renderText = typeof renderTextProp === 'function' ? renderTextProp : ({ text }) => text

  return (
    <a
      href={src}
      target="_blank"
      rel="noreferrer"
      download={downloadFilename}
      onClick={handleOnClick}
      className={className}
    >
      {start}
      {renderText({ text: theText })}
      {end}
    </a>
  )
}

DownloadLink.propTypes = {
  className: PropTypes.string,
  src: PropTypes.string,
  text: PropTypes.string,
  renderText: PropTypes.func,
  filename: PropTypes.string,
  start: PropTypes.node,
  end: PropTypes.node,
  resolver: PropTypes.func,
}

export default DownloadLink
