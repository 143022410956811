import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { objectFlip } from 'utils/helper'
import { USER_STATUSES } from 'utils/constants'

const flipped = objectFlip(USER_STATUSES)

function AccountManagementStatusCell({ user }) {
  const { t } = useTranslation()

  const statusId = user.status.id
  const statusKey = statusId === USER_STATUSES.pending ? 'pendingInviter' : flipped[statusId]
  const text = statusId in flipped ? t(`userStatus.${statusKey}`, { inviter_name: user.invited_by?.full_name }) : ''

  return <div>{text}</div>
}

AccountManagementStatusCell.propTypes = {
  user: PropTypes.object.isRequired,
}

export default AccountManagementStatusCell
