import React from 'react'

import PropTypes from 'prop-types'
import { PROPOSAL_STATUSES } from 'utils/constants'
import SelectionMessageForm from '../SelectionMessageForm'
import { useTranslation } from 'react-i18next'
import { useRefPropPermission } from 'utils/hooks'

const disabledAppointmentStatuses = [
  PROPOSAL_STATUSES.rejected,
  PROPOSAL_STATUSES.cancelled,
  PROPOSAL_STATUSES.contracted,
]

const SuggestionInputArea = React.forwardRef((props, ref) => {
  const { threadId, recommendation, show, send, loadLatest } = props
  const { t } = useTranslation()
  const { canSendMessage } = useRefPropPermission()
  const proposalStatus = recommendation?.proposal_status_id
  const isMessageDisabled = [
    PROPOSAL_STATUSES.rejected,
    PROPOSAL_STATUSES.cancelled,
    PROPOSAL_STATUSES.contracted,
  ].includes(proposalStatus)
  const disableApppointment = disabledAppointmentStatuses.includes(proposalStatus)
  const noThread = typeof threadId === 'undefined'
  const showUnauthorized = !canSendMessage || noThread
  const showResume = !showUnauthorized && canSendMessage && isMessageDisabled

  const message = (() => {
    if (proposalStatus === PROPOSAL_STATUSES.contracted) return t('suggestion.modal.completedMsg')

    if (showResume) return t('suggestion.modal.resumeMsg')

    if (showUnauthorized) return t('selection.modal.unauthorizedMsg')
  })()

  return (
    <div ref={ref} style={{ display: show ? 'block' : 'none' }} className="selectionModal__inputArea">
      {canSendMessage && !isMessageDisabled && !noThread ? (
        <SelectionMessageForm
          disableApppointment={disableApppointment}
          threadId={recommendation?.chat_thread?.id}
          send={send}
          loadLatest={loadLatest}
          recommendation={recommendation}
        />
      ) : (
        <p className="selectionModal__inputAreaText">{message}</p>
      )}
    </div>
  )
})

SuggestionInputArea.displayName = 'SuggestionInputArea'

SuggestionInputArea.propTypes = {
  threadId: PropTypes.number,
  recommendation: PropTypes.object,
  user: PropTypes.object,
  show: PropTypes.bool,
  send: PropTypes.func.isRequired,
  loadLatest: PropTypes.func.isRequired,
}

export default SuggestionInputArea
