import React, { useState } from 'react'

import Grid from '@mui/material/Grid'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import FullscreenIcon from '@mui/icons-material/Fullscreen'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import Button from '@mui/material/Button'
import { Document, Page as PdfPage } from 'react-pdf/dist/esm/entry.webpack'
import { FullScreen } from 'react-full-screen'
import clsx from 'clsx'

import { Page } from 'components'

const PREFIX = 'ApplicantFile'
const classes = {
  pdfViewer: `${PREFIX}-pdfViewer`,
  pdf: `${PREFIX}-pdf`,
  hidden: `${PREFIX}-hidden`,
}
const StyledPage = styled(Page)(({ theme }) => ({
  [`& .${classes.pdfViewer}`]: {
    height: '100%',
    textAlign: 'right',
    '& Button': {
      backgroundColor: '#FFFFFF',
      borderRadius: '50%',
      zIndex: '10',
      height: '4em',
      width: '.25em',
      padding: '0px',
    },
  },
  [`& .${classes.pdf}`]: {
    padding: theme.spacing(1, 2),
    margin: theme.spacing(3),
  },
  [`& .${classes.hidden}`]: {
    display: 'none',
  },
}))

const PdfViewer = (props) => {
  const {
    onClick,
    pageNumber,
    filePath,
    onDocumentLoadSuccess,
    handle,
    title,
    previousPage,
    nextPage,
    numPages,
    ready,
  } = props
  const [maximize, setMaximize] = useState(true)

  const handleMaximize = () => {
    setMaximize(true)
  }
  const handleMinimize = () => {
    setMaximize(false)
  }

  const nextPrevButton = {
    prev: {
      color: pageNumber <= 1 ? '#585757' : 'white',
    },
    next: {
      color: pageNumber === numPages ? '#585757' : 'white',
    },
  }

  return (
    <StyledPage title={title ? title : 'ApplicantFile'} className={classes.root}>
      <Grid container className={classes.pdfViewer} style={{ justifyContent: 'center' }}>
        <FullScreen handle={handle} className="btn__fullScreen">
          <Grid item className={classes.pdf}>
            <div className={clsx({ [classes.hidden]: !maximize || !ready })}>
              <Document file={filePath} onLoadSuccess={onDocumentLoadSuccess}>
                <PdfPage pageNumber={pageNumber} />
              </Document>
              <div style={{ textAlign: 'center' }}>
                <button
                  className="btn__nextPrevBtn"
                  style={nextPrevButton.prev}
                  disabled={pageNumber <= 1}
                  onClick={previousPage}
                  type="button"
                >
                  <span style={{ marginLeft: '35px' }}>{'< Previous'}</span>
                </button>
                <button
                  className="btn__nextPrevBtn"
                  style={nextPrevButton.next}
                  disabled={pageNumber === numPages}
                  onClick={nextPage}
                  type="button"
                >
                  <span style={{ marginRight: '35px' }}>{'Next >'}</span>
                </button>
              </div>
            </div>
          </Grid>
        </FullScreen>
        <div style={{ width: '100%', position: 'fixed', bottom: 0 }}>
          <Grid item xl={12}>
            <Button onClick={onClick} title={'Full Screen'}>
              <FullscreenIcon />
            </Button>
          </Grid>
          <Grid item xl={12}>
            <Button onClick={handleMaximize} title={'Maximize'}>
              <AddIcon />
            </Button>
          </Grid>
          <Grid item xl={12}>
            <Button onClick={handleMinimize} title={'Minimize'}>
              <RemoveIcon />
            </Button>
          </Grid>
        </div>
      </Grid>
    </StyledPage>
  )
}

PdfViewer.propTypes = {
  onClick: PropTypes.func.isRequired,
  pageNumber: PropTypes.any,
  filePath: PropTypes.object,
  onDocumentLoadSuccess: PropTypes.func.isRequired,
  handle: PropTypes.object.isRequired,
  title: PropTypes.string,
  previousPage: PropTypes.any,
  nextPage: PropTypes.any,
  numPages: PropTypes.any,
  ready: PropTypes.bool.isRequired,
}

export default PdfViewer
