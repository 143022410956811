import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { getOpenGraphData } from 'services/openGraph'
import { CircularProgress } from '@mui/material'
import BlankImage from 'assets/img/blank_image.png'
import clsx from 'clsx'

const LinkPreview = ({ url, isForForms = true }) => {
  const [cardData, setCardData] = useState({
    image: '',
    title: '',
    description: '',
    hostname: '',
  })
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  useEffect(() => {
    getWebMetadata(url)
      .then((res) => {
        const dataObj = {
          title: res?.data?.title ? res?.data?.title : res?.data?.site_name ? res?.data?.site_name : url,
          hostname: res?.data?.url,
          description: res?.data?.description,
          image: res?.data?.image ? res?.data?.image : res?.data?.thumbnail,
        }
        setCardData(dataObj)
        setLoading(false)
      })
      .catch(() => setLoading(false))
  }, [url])

  const getWebMetadata = async (site) => {
    setLoading(true)
    let urlString = site?.replace(/^http(s?):\/\//, '')
    if (urlString) return await dispatch(getOpenGraphData(urlString))
  }

  const truncate = (input) => {
    if (input) {
      return input.length > 50 ? `${input.substring(0, 50)}...` : input
    }
  }

  return (
    <div
      className={clsx('form__portfolioOg', {
        'form__portfolioOg-2': !isForForms,
      })}
    >
      <div className="form__portfolioOgImgArea">
        {loading ? (
          <CircularProgress color="primary" size="5rem" />
        ) : (
          <img src={cardData?.image ?? BlankImage} alt="" className="form__portfolioOgImg" width="152" height="80" />
        )}
      </div>
      <div className="form__portfolioOgTextArea">
        <p className="form__portfolioOgTitle">{truncate(cardData?.title)}</p>
        <p className="form__portfolioOgLink">{cardData?.hostname ? cardData.hostname : url}</p>
        <p className="form__portfolioOgText">{truncate(cardData?.description)}</p>
      </div>
    </div>
  )
}

LinkPreview.propTypes = {
  url: PropTypes.string,
  isForForms: PropTypes.bool,
}

export default LinkPreview
