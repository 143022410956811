import { useEffect, useState } from 'react'

import useResizeObserver from '@react-hook/resize-observer'

export default function useFitElement({ src, anchor }) {
  const [srcTop, setSrcTop] = useState(null)
  const [anchorBottom, setAnchorBottom] = useState(null)

  useResizeObserver(anchor?.current, () => {
    setAnchorBottom(anchor.current?.getBoundingClientRect()?.bottom)
  })

  useResizeObserver(src?.current, () => {
    setSrcTop(src.current?.getBoundingClientRect()?.top)
  })

  useEffect(() => {
    if (typeof srcTop === 'number' && typeof anchorBottom === 'number') {
      src.current.style.minHeight = `${anchorBottom - 289}px`
    }
  }, [srcTop, anchorBottom])
}
