import React from 'react'

import PropTypes from 'prop-types'

import CheckIcon from 'assets/img/check-icon.svg'
import RedBatuIcon from 'assets/img/red-batu.svg'
import { BlockEl } from 'components/atoms'
import { SELECTION_STATUSES } from 'utils/constants'

const ERROR_STATUSES = [SELECTION_STATUSES.declined, SELECTION_STATUSES.rejected]

const StatusIcon = (props) => <BlockEl {...props} component="span" blockElClass="selectionModal__tabContStatusIcon" />

const StatusIconImg = (props) => (
  <BlockEl {...props} component="img" blockElClass="selectionModal__tabContStatusIconImg" />
)

const StatusItem = ({ message }) => {
  const isError = ERROR_STATUSES.includes(message.meta.status)

  return (
    <BlockEl blockElClass="selectionModal__tabContStatus" modifier={{ red: isError }}>
      {isError ? (
        <StatusIcon modifier="nbgc">
          <StatusIconImg src={RedBatuIcon} alt="" modifier="w" width="12" height="12" />
        </StatusIcon>
      ) : (
        <StatusIcon>
          <StatusIconImg src={CheckIcon} alt="" width="13" height="11" />
        </StatusIcon>
      )}
      {message.message}
    </BlockEl>
  )
}

StatusItem.propTypes = {
  message: PropTypes.object,
}
export default StatusItem
