import React, { useEffect, useState } from 'react'

import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import { useHistory, useParams, generatePath, useRouteMatch, Redirect } from 'react-router-dom'
import isEmpty from 'lodash/isEmpty'

import { Nav } from 'components/molecules'
import QuestionAndAnswerTab from '../QuestionAndAnswerTab'
import OtherJobsTab from '../OtherJobsTab'
import { selectQandATabTotal } from 'store/question-and-answers/selectors'
import { selectOtherJobsTotal } from 'store/other-jobs/selectors'
import { selectEvaluationTotal } from 'store/evaluation/selectors'
import Evaluations from 'views/evaluation'
import JobVacancyTab from '../JobVacancyTab'
import CorporateInfoTab from '../CorporateInfoTab'

const selectJobPostingTabsStats = createSelector(
  selectQandATabTotal,
  selectOtherJobsTotal,
  selectEvaluationTotal,
  (qanda, otherjobs, evaluation) => ({
    qanda,
    otherjobs,
    evaluation,
  })
)

// This is to minimize CLS on mount
const getStat = (stats, name, defaulVal = 0) => stats[name] ?? defaulVal

function JobDetailTabsCard({ id, ready, useRedirect = false, onTabChange, editable = true }) {
  const stats = useSelector(selectJobPostingTabsStats)
  const { t } = useTranslation()
  const [items, setItems] = useState([
    {
      id: 'jobvacancies',
      label: t('jobPosting.tabs.jobVacancies'),
      number: null,
      disabled: false,
    },
    {
      id: 'qanda',
      label: t('jobPosting.tabs.qANdA'),
      number: getStat(stats, 'qanda'),
      disabled: false,
    },
    {
      id: 'corporateinfo',
      label: t('jobPosting.tabs.corporateInfo'),
      number: null,
      disabled: false,
    },
    {
      id: 'evaluation',
      label: t('jobPosting.tabs.evaluation'),
      number: getStat(stats, 'evaluation'),
      disabled: false,
    },
    {
      id: 'otherjobs',
      label: t('jobPosting.tabs.otherJobs'),
      number: getStat(stats, 'otherjobs'),
      disabled: false,
    },
  ])
  const defaultTab = items[0].id
  const { tab: tabParam } = useParams()
  const { path } = useRouteMatch()
  const currentTab = useRedirect && !isEmpty(tabParam) ? tabParam : defaultTab
  const [active, setActive] = useState(currentTab)
  const history = useHistory()
  const handleClick = (tab) => {
    if (tab === active) return

    if (useRedirect) {
      history.push({
        pathname: generatePath(path, { id, tab }),
      })
      return
    }

    setActive(tab)
    onTabChange && onTabChange(tab)
  }

  const isActive = (tab, needsJobInfo = false) => tab === active && ((needsJobInfo && ready) || !needsJobInfo)

  useEffect(() => {
    setItems(
      (old) =>
        old.slice().map((item) => ({
          ...item,
          number: item.number === null ? null : stats[item.id] ?? item.number,
          disabled: stats[item.id] === 0 && item.id !== 'qanda',
        })),
      []
    )
  }, [stats])

  if (useRedirect && isEmpty(tabParam)) {
    const toPath = path.includes('/:tab?') ? path : `${path}/:tab?`

    return (
      <Redirect
        to={generatePath(toPath, {
          id,
          tab: defaultTab,
        })}
      />
    )
  }

  return (
    <div className="evaluation__leftMain">
      <Nav preset="evaluation" items={items} active={active} onClick={handleClick} />
      {isActive('jobvacancies') && <JobVacancyTab jobPostingId={id} editable={editable} />}
      {isActive('qanda') && <QuestionAndAnswerTab jobPostingId={id} editable={editable} />}
      {isActive('corporateinfo') && <CorporateInfoTab jobPostingId={id} />}
      {isActive('evaluation') && <Evaluations jobPostingId={id} />}
      {isActive('otherjobs') && <OtherJobsTab jobPostingId={id} />}
    </div>
  )
}

JobDetailTabsCard.propTypes = {
  id: PropTypes.number,
  ready: PropTypes.bool.isRequired,
  useRedirect: PropTypes.bool,
  onTabChange: PropTypes.func,
  editable: PropTypes.bool,
}

export default JobDetailTabsCard
