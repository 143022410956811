import { createAsyncThunk } from 'store/helpers'
import { loadUserLogsFailure, loadUserLogsRequest, loadUserLogsSuccess } from 'store/user-logs/actionCreator'
import { selectUserLogsSearchParams } from 'store/user-logs/selectors'
import { parseAxiosResponse } from 'utils/helper'
import Http from 'utils/Http'

export function searchUserLogs() {
  return (dispatch, getState) => {
    dispatch(loadUserLogsRequest())

    return parseAxiosResponse(Http.get('admin/user_logs', { params: selectUserLogsSearchParams(getState()) })).then(
      (res) => dispatch(loadUserLogsSuccess(res)),
      (err) => dispatch(loadUserLogsFailure(err))
    )
  }
}

export const fetchUserProfile = createAsyncThunk('FETCH_USER_PROFILE', (userId) => {
  return parseAxiosResponse(Http.get(`/common/profile/${userId}`))
})
