import React from 'react'
import PropTypes from 'prop-types'
import { ReactComponent as GarbageIcon } from 'assets/img/garbage-icon2.svg'
import { replaceFileName } from 'utils/helper'

const CandidatePortfolioPdfItem = ({ item, index, handleOpenModal, otherDocsPdf }) => {
  return (
    <div className="candidate__modalTabContPortArea">
      <a
        href={item}
        className="recommendation__ddFile recommendation__ddFile-pdf"
        target="_blank"
        rel="noopener noreferrer"
      >
        {replaceFileName(otherDocsPdf[index])}
      </a>
      <p className="candidate__modalTabContPortIconArea">
        <span className="candidate__modalTabContPortIcon" onClick={() => handleOpenModal(index, 'pdf')}>
          <GarbageIcon width="17" height="17" />
        </span>
      </p>
    </div>
  )
}

CandidatePortfolioPdfItem.propTypes = {
  item: PropTypes.string,
  index: PropTypes.number,
  handleOpenModal: PropTypes.func,
  otherDocsPdf: PropTypes.array,
}

export default CandidatePortfolioPdfItem
