import React from 'react'

import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import BlockEl from '../BlockEl'
import NewlineText from '../NewlineText'

function Notification({ message, type, showButton, ...props }) {
  const { t } = useTranslation()
  return (
    <BlockEl {...props} bec="aleart" modifier={[{ [type]: true }, props.modifier]}>
      <p className="aleart__text">
        <NewlineText text={message} />
      </p>
      {showButton && <a className="aleart__link">{t('qAndA.confirmBtn')}</a>}
    </BlockEl>
  )
}

Notification.propTypes = {
  message: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['success', 'danger']),
  showButton: PropTypes.bool,
  modifier: PropTypes.any,
}

export default Notification
