import { styled } from '@mui/material/styles'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { NavMenuLink } from 'components/atoms/'

const PREFIX = 'Subbar'

const classes = {
  root: `${PREFIX}-root`,
}

const Root = styled('div')(() => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    border: '1px solid rgb(100, 100, 100, 0.5)',
    borderRadius: '5px',
    margin: '5px auto',
  },
}))

const Subbar = (props) => {
  const { userType, ...rest } = props
  const { t } = useTranslation()
  const pages = [
    {
      title: t('header.subbar.jobOffer'),
      href: '/joboffer',
      access: ['hrParent', 'hrChild'],
    },
    {
      title: t('header.subbar.jobPosting'),
      href: '/jobposting',
      access: ['admin', 'ad1Parent', 'ad1Child', 'ad2Parent', 'ad2Child'],
    },
    {
      title: t('header.subbar.selectionManagement'),
      href: '/selectionmanagement',
      access: ['admin', 'ad1Parent', 'ad1Child', 'hrParent', 'hrChild'],
    },
    {
      title: t('header.subbar.jobSeekerManagement'),
      href: '/jobseekermanagement',
      access: ['admin', 'ad1Parent', 'ad1Child'],
    },
    {
      title: t('header.subbar.proposalManagement'),
      href: '/proposalmanagement',
      access: ['admin', 'ad2Parent', 'ad2Child', 'hrParent', 'hrChild'],
    },
  ]

  return (
    <Root {...rest} className={clsx(classes.root)}>
      <NavMenuLink
        key={t('header.subbar.dashboard')}
        title={t('header.subbar.dashboard')}
        href="/"
        isExact={true}
        exact
      />
      {pages
        .filter((page) => page.access.includes(userType))
        .map((page) => (
          <NavMenuLink key={page.title} title={page.title} href={page.href} isExact={false} />
        ))}
    </Root>
  )
}

Subbar.propTypes = {
  userType: PropTypes.string,
}

export default Subbar
