import Http from 'utils/Http'
import fileDownload from 'js-file-download'
import JSZip from 'jszip'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { formatID } from 'utils/helper'
import { showNotification } from 'store/notification/actionCreators'

export function downloadPath(path, viewPdf) {
  return Http.get('/common/download', { params: { path }, responseType: 'blob' }).then(
    (res) => (viewPdf ? viewPdf(res.data) : res.data),
    () => null
  )
}

export const handleDownload = (ids, t) => (dispatch) => {
  const docName = formatDocName(ids)
  if (!isNaN(+ids)) {
    Http.get('/job_postings/' + ids + '/pdf', {
      responseType: 'blob',
    })
      .then((res) => {
        fileDownload(res.data, docName)
      })
      .catch(() => dispatch(showNotification(t('download.error'), { type: 'danger', modifier: 'btm' })))
  } else {
    const zip = new JSZip()
    let x = 0
    ids.forEach(async (id) => {
      await Http.get('/job_postings/' + id + '/pdf', {
        responseType: 'blob',
      })
        .then((res) => {
          x++
          if (res.data.size) {
            zip.file(formatDocName(id), res.data, { binary: true })
          }
        })
        .catch(() => dispatch(showNotification(t('download.error'), { type: 'danger', modifier: 'btm' })))
      if (x === ids.length) {
        zip?.generateAsync({ type: 'blob' }).then(function (content) {
          fileDownload(content, docName)
        })
      }
    })
  }
}

/**
 * Expected Output
 * Zip: 20220810_163803.zip
 * PDF: ID000023_20220703.pdf
 * @returns
 */
const formatDocName = (ids) => {
  dayjs.extend(utc)
  dayjs.extend(timezone)
  const now = dayjs().tz('Asia/Tokyo')
  const dateString = now.format('YYYYMMDD')
  const timeString = now.format('HHmmss')

  return !isNaN(+ids) ? formatID(ids) + '_' + dateString + '.pdf' : dateString + '_' + timeString + '.zip'
}
