import React from 'react'

import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import { Avatar, DateText } from 'components/atoms'
import AvatarIcon from 'assets/img/Avatar@3x 3.svg'
function WithAvatarItem({ message, children }) {
  const link = '/profile/' + message.sender?.id
  return (
    <div className="selectionModal__tabContCommentArea">
      <Avatar
        component={Link}
        to={link}
        target="_blank"
        bec="selectionModal__tabContCommentImgArea"
        imgProps={{ bec: 'selectionModal__tabContCommentImg' }}
        src={message.sender?.avatar}
        defaultSrc={AvatarIcon}
      />
      <div className="selectionModal__tabContCommentMainArea">
        <div className="selectionModal__tabContCommentNameArea">
          <p className="selectionModal__tabContCommentName">{message.sender?.full_name}</p>
          <p className="selectionModal__tabContCommentCompa">{message.sender?.company?.company_name}</p>
        </div>
        <div className="selectionModal__tabContCommentWrap">
          {children}
          <p className="selectionModal__tabContCommentTime">
            <DateText format="HH:mm" date={message.created_at} />
          </p>
        </div>
      </div>
    </div>
  )
}

WithAvatarItem.propTypes = {
  message: PropTypes.object,
  children: PropTypes.node,
}

export default WithAvatarItem
