import {
  actionSetJobPostingForm,
  actionSyncJobPostingForm,
  actionSetJobPostingProposalForm,
  actionSyncJobPostingProposalForm,
} from 'store/job-posting-form/actionCreators'
import { createJobPostings, updateJobPosting } from 'services/jobPostings'
import { JOB_POSTING_STATUSES } from 'utils/constants'

export const getJobPostingDataFromDraft = () => {
  return (dispatch) => {
    const draft = JSON.parse(localStorage.getItem('job_posting') ?? '{}') || {}
    dispatch(actionSetJobPostingForm(draft))
    dispatch(actionSyncJobPostingForm(draft))
  }
}

export const getJobPostingProposalDataFromDraft = () => {
  return (dispatch) => {
    const draft = JSON.parse(localStorage.getItem('job_post_proposal') ?? '{}') || {}
    dispatch(actionSetJobPostingProposalForm(draft))
    dispatch(actionSyncJobPostingProposalForm(draft))
  }
}

export const syncToStore = (data) => {
  return (dispatch) => {
    dispatch(actionSyncJobPostingForm(data))
  }
}

export const syncProposalToStore = (data) => {
  return (dispatch) => {
    dispatch(actionSyncJobPostingProposalForm(data))
  }
}

export const saveAsDraft = (data = {}, mode) => {
  return async (dispatch, getState) => {
    if (mode === 'saveToDB') {
      const { jobPostingForm } = getState()
      const id = jobPostingForm.data?.id
      if (id === undefined) {
        dispatch(
          createJobPostings({
            ...jobPostingForm.syncData,
            status: JOB_POSTING_STATUSES.draft,
            job_posting_type: 'referral',
          })
        )
      } else {
        dispatch(
          updateJobPosting(id, {
            ...jobPostingForm.syncData,
            status: JOB_POSTING_STATUSES.draft,
          })
        )
      }
      localStorage.removeItem('job_posting')
      return
    }

    dispatch(syncToStore(data))
    const { jobPostingForm } = getState()
    localStorage.setItem('job_posting', JSON.stringify({ ...jobPostingForm.data, ...jobPostingForm.syncData }))
  }
}

export const saveProposalAsDraft = (data = {}, mode) => {
  return async (dispatch, getState) => {
    const { jobPostingForm } = getState()
    const id = jobPostingForm.proposalData?.id

    if (mode === 'saveToDB') {
      if (id === undefined) {
        dispatch(
          createJobPostings({
            ...jobPostingForm.syncProposalData,
            status: JOB_POSTING_STATUSES.draft,
            job_posting_type: 'proposal',
          })
        )
      } else {
        dispatch(
          updateJobPosting(id, {
            ...jobPostingForm.syncProposalData,
            status: JOB_POSTING_STATUSES.draft,
          })
        )
      }
      localStorage.removeItem('job_post_proposal')
    } else {
      dispatch(syncProposalToStore(data))
      const { jobPostingForm } = getState()
      localStorage.setItem(
        'job_post_proposal',
        JSON.stringify({ ...jobPostingForm.proposalData, ...jobPostingForm.syncProposalData })
      )
    }
  }
}
