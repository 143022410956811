import produce from 'immer'
import { fetchEditHistories } from 'services/referralProposals'

const initialState = {
  status: 'pending',
  fetching: false,
  items: [],
}

export default produce((draft, { type, payload }) => {
  switch (type) {
    case fetchEditHistories.pending:
      draft.status = 'pending'
      break
    case fetchEditHistories.fulfilled:
      draft.status = 'fulfilled'
      draft.items = payload.data
      break
    case fetchEditHistories.failure:
      draft.status = 'failure'
      break
    default:
      return draft
  }
}, initialState)
