import { AUTH_SIGNOUT } from 'store/auth/actionTypes'
import * as types from './actionTypes'

const initialState = {
  pending: false,
  success: false,
  error: null,
  adInchargelist: [],
}

export default (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    //ad Incharge list
    case types.GET_AD_INCHARGE_LIST_REQUEST:
      return {
        ...state,
        adInchargelist: null,
        pending: true,
        success: false,
      }
    case types.GET_AD_INCHARGE_LIST_SUCCESS:
      return {
        ...state,
        adInchargelist: payload.data,
        pending: false,
        success: true,
      }
    case types.GET_AD_INCHARGE_LIST_FAILURE:
      return {
        ...state,
        adInchargelist: null,
        pending: false,
        success: false,
        error: payload.error,
      }
    case AUTH_SIGNOUT:
      return initialState
    default:
      return state
  }
}
