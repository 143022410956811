import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

function AccountManagementPermissionCell({ user }) {
  const { t } = useTranslation()
  const isParent = user.groups[0]?.is_parent
  const text = isParent ? t('userPermission.parent') : t('userPermission.child')

  return <div>{text}</div>
}

AccountManagementPermissionCell.propTypes = {
  user: PropTypes.object.isRequired,
}

export default AccountManagementPermissionCell
