import React from 'react'
import Modal from 'components/molecules/Modal'
import PropTypes from 'prop-types'
import EvaluationSlideOut from 'components/molecules/EvaluationSlideOut'
import { useTranslation } from 'react-i18next'

function SlideModal({
  handleClose,
  handleSubClose,
  open,
  openSub,
  item,
  handleApproved,
  handleNonApproved,
  handleRevertApproval,
  handleSubNonApproval,
}) {
  const { t } = useTranslation('translation', { keyPrefix: 'evaluation' })

  return (
    <>
      <Modal
        blockElClass="selectionModal"
        containerBlockElClass="selectionModal__cont"
        containerType="contSlideCont"
        innerProps={{ modifier: 'selectionModal__inner selectionModal__inner-rt' }}
        onClose={handleClose}
        open={open}
      >
        <Modal.Header blockElClass="selectionModal__contHeader">
          <Modal.Title blockElClass="selectionModal__contHeaderTitle">
            {t('listing.headings.evaluationDetails')}
          </Modal.Title>
          <Modal.CloseButton onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          <EvaluationSlideOut
            item={item}
            open={open}
            openSub={openSub}
            handleApproved={handleApproved}
            handleNonApproved={handleNonApproved}
            handleRevertApproval={handleRevertApproval}
            handleSubNonApproval={handleSubNonApproval}
            handleClose={handleClose}
            handleSubClose={handleSubClose}
          />
        </Modal.Body>
      </Modal>
    </>
  )
}

SlideModal.propTypes = {
  handleClose: PropTypes.func,
  handleSubClose: PropTypes.func,
  open: PropTypes.bool,
  item: PropTypes.object,
  handleApproved: PropTypes.func,
  handleNonApproved: PropTypes.func,
  handleRevertApproval: PropTypes.func,
  handleSubNonApproval: PropTypes.func,
  openSub: PropTypes.bool,
}
export default SlideModal
