import React, { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'

import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'

import { selectAuthUser } from 'store/auth/selectors'
import {
  actionEditQuestion,
  actionResetQuestionsParams,
  actionUpdateQuestionsParams,
} from 'store/question-and-answers/actionCreators'
import { deleteAnswer, deleteQuestion, fetchQuestions } from 'services/questionAndAnswers'
import GarbageIcon2 from 'assets/img/garbage-icon2.svg'
import AvatarIcon from 'assets/img/Avatar@3x 3.svg'
import PenIcon from 'assets/img/pen-icon.svg'
import { DATE_FORMAT } from 'utils/constants'
import { ConfirmModal, Pagination } from 'components/molecules'
import QandABox from './Box'
import QuestionAndAnswerThreadChild from './QuestionAndAnswerThreadChild'
import {
  selectQandAListTabReadiness,
  selectQandAFocusItem,
  selectQandATabList,
} from 'store/question-and-answers/selectors'
import { showNotification } from 'store/notification/actionCreators'
import { isSuspended } from 'utils/helper'

const QuestionAndAnswerThreadItem = ({ question, user, onDelete, onEdit, onDeleteAnswer }) => {
  const { t } = useTranslation()
  const focusItem = useSelector(selectQandAFocusItem)
  const containerRef = useRef(null)
  const [collapsed, setCollapsed] = useState(true)
  const userId = get(user, 'id')
  const name = get(question, 'user.full_name')
  const isOwn = get(question, 'user.id') === userId
  const sameCompany = get(question, 'user.company.id') === get(user, 'company.id')
  const jobPostOwn = user?.id === question?.job_posting?.user_id
  const date = dayjs(question.date_posted).format(DATE_FORMAT)
  const numReplies = question.number_of_replies
  const answers = question.answers || []
  const [hasScroll, setHasScroll] = useState(false)
  const headerHeight = useSelector((state) => state.app.header.blockSize)
  const inputRef = useRef(null)
  const isParticipant = [get(question, 'user.id'), get(question, 'job_posting.user_id')].includes(userId)

  const handleIconClick = (icon) => {
    if (isSuspended(user)) return
    switch (icon) {
      case GarbageIcon2:
        return onDelete(question.id)
      case PenIcon:
        return onEdit(question.id)
      default:
        null
    }
  }

  useLayoutEffect(() => {
    if (focusItem && focusItem.id === question.id && !hasScroll && containerRef.current) {
      const timeout = setTimeout(() => {
        setHasScroll(true)
        window.scroll({ behavior: 'smooth', top: containerRef.current.getBoundingClientRect().top - headerHeight })
      }, 500)

      return () => {
        clearTimeout(timeout)
      }
    }
  }, [focusItem?.id, question.id, headerHeight, hasScroll])

  const handleReplyClick = () => {
    setCollapsed(false)
    setTimeout(() => inputRef.current.focus(), 0)
  }

  const userAvatar = question.user?.avatar || AvatarIcon

  return (
    <>
      <li ref={containerRef} className="qa__li">
        <QandABox>
          <QandABox.Header>
            <QandABox.NameArea
              avatar={sameCompany ? userAvatar : AvatarIcon}
              name={sameCompany ? name : t('qAndA.agent')}
            />

            {isOwn && <QandABox.IconArea icons={[GarbageIcon2, PenIcon]} onClick={handleIconClick} />}
          </QandABox.Header>
          <QandABox.CatArea topic={question.topic?.topic} />
          <QandABox.TextArea text={question.question} />
          <QandABox.SupArea date={t('qAndA.date', { date })} />
          <QandABox.Footer replyText={t('qAndA.numReplies', { count: numReplies })}>
            {jobPostOwn && (
              <div className="qa__liBoxReply" onClick={handleReplyClick}>
                返信する
              </div>
            )}
          </QandABox.Footer>
        </QandABox>
        <QuestionAndAnswerThreadChild
          isOwn={isOwn}
          sameCompany={sameCompany}
          total={numReplies}
          answers={answers}
          user={user}
          onDelete={onDeleteAnswer}
          questionId={question.id}
          jobPostOwn={jobPostOwn}
          inputRef={inputRef}
          collapsed={collapsed}
          setCollapsed={setCollapsed}
          isParticipant={isParticipant}
          disabled={isSuspended(user)}
        />
      </li>
    </>
  )
}

QuestionAndAnswerThreadItem.propTypes = {
  user: PropTypes.object,
  question: PropTypes.object,
  t: PropTypes.func,
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDeleteAnswer: PropTypes.func.isRequired,
}

function QuestionAndAnswerThread({ jobPostingId }) {
  const items = useSelector(selectQandATabList)
  const state = useSelector((state) => state.qAndA.listState)
  const ready = useSelector(selectQandAListTabReadiness)
  const deleting = useSelector((state) => state.qAndA.deleting)
  const [deleteInfo, setDeleteInfo] = useState(null)
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const { t } = useTranslation()
  const currentUser = useSelector(selectAuthUser)
  const { currentPage, lastPage } = useSelector((state) => state.qAndA.pagination)

  useEffect(() => {
    dispatch(actionResetQuestionsParams({ job_posting_id: jobPostingId, page: 1, with: ['answers'] }))
    dispatch(fetchQuestions())
  }, [jobPostingId])

  const handlePageChange = (page) => {
    if (state === 'pending') return

    dispatch(actionUpdateQuestionsParams({ page }))
    dispatch(fetchQuestions())
  }

  const buttons = useMemo(
    () => [
      { type: 'cancel', text: t('common.cancel') },
      {
        type: 'confirm',
        text: t('common.delete'),
        props: { modifier: 'b' },
        onClick: async (cb) => {
          if (deleting) return

          const { id, type } = deleteInfo

          const method = { question: deleteQuestion, answer: deleteAnswer }[type]

          await dispatch(method(id)).then((res) => {
            if (res.error) {
              const erorr = res.error
              dispatch(showNotification(erorr.error || erorr.message || 'Unknown Error', { type: 'danger' }))
            }
          })
          cb()
        },
      },
    ],
    [deleting, deleteInfo, t]
  )

  const handleClose = () => {
    if (deleting) return

    setOpen(false)
    setDeleteInfo(null)
  }

  const handleOnDelete = (id) => {
    setDeleteInfo({ id, type: 'question' })
    setOpen(true)
  }

  const handleOnDeleteAnswer = (id) => {
    setDeleteInfo({ id, type: 'answer' })
    setOpen(true)
  }

  const handleOnEdit = (id) => {
    dispatch(actionEditQuestion(id))
  }

  return (
    <>
      {ready && (
        <ul className="qa__ul">
          {items.map((item) => (
            <QuestionAndAnswerThreadItem
              key={item.id}
              question={item}
              user={currentUser}
              onDelete={handleOnDelete}
              onDeleteAnswer={handleOnDeleteAnswer}
              onEdit={handleOnEdit}
            />
          ))}
        </ul>
      )}

      <ConfirmModal open={open} onClose={handleClose} buttons={buttons} title={t('common.confirmDeleteQuestion')} />
      <Pagination onPageChange={handlePageChange} page={currentPage} totalPages={lastPage} />
    </>
  )
}

QuestionAndAnswerThread.propTypes = {
  jobPostingId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

export default QuestionAndAnswerThread
