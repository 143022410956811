import React from 'react'
import PropTypes from 'prop-types'

function FormMainButton(props) {
  return <button className="btn btn__shadowBtn">{props.label}</button>
}

FormMainButton.propTypes = {
  label: PropTypes.string,
}

export default FormMainButton
