import React from 'react'

import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { Label } from 'components/atoms'
import { FormGroup, FormGroupCheck } from 'components/molecules'
import QuestionAndAnswerTopicsChecklist from '../QuestionAndAnswerTopicsChecklist'
import { fetchQuestions } from 'services/questionAndAnswers'
import { actionUpdateQuestionsParams } from 'store/question-and-answers/actionCreators'
import QuestionerSelect from '../QuestionerSelect'
import { selectAuthUser } from 'store/auth/selectors'
import { accountType } from 'utils/helper'

function QuestionFilters() {
  const { topic, user_id } = useSelector((state) => state.qAndA.params)
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const handleSelectedChange = (newSelected) => {
    dispatch(actionUpdateQuestionsParams({ topic: newSelected, page: 1 }))
    dispatch(fetchQuestions())
  }

  const handleSelectedQuestionerchange = (newSelected) => {
    dispatch(actionUpdateQuestionsParams({ user_id: newSelected, page: 1 }))
    dispatch(fetchQuestions())
  }

  const userType = useSelector(selectAuthUser)
  const questionType = accountType(userType) === 'HR'

  return (
    <>
      {!questionType && (
        <FormGroup modifier="mgb0">
          <Label modifier="mgb8 mgt0 mini">{t('qAndA.questioner')}</Label>
          <QuestionerSelect selected={user_id} onChange={handleSelectedQuestionerchange} />
        </FormGroup>
      )}

      <FormGroup modifier="mgt4 mgb0">
        <Label>{t('qAndA.topic')}</Label>

        <FormGroupCheck>
          <QuestionAndAnswerTopicsChecklist selected={topic} onChange={handleSelectedChange} />
        </FormGroupCheck>
      </FormGroup>
    </>
  )
}

export default QuestionFilters
