import React, { useEffect } from 'react'

import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { fetchQuestioners } from 'services/questionAndAnswers'
import { CustomSelect } from 'components/molecules'

function QuestionerSelect({ selected, onChange }) {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const questioners = useSelector((state) =>
    state.questioners.items.map(({ id, full_name }) => ({ id, label: full_name }))
  )
  useEffect(() => {
    dispatch(fetchQuestioners())
  }, [])

  return (
    <CustomSelect
      options={questioners}
      selected={selected}
      onChange={onChange}
      placeholder={t('common.pleaseSelect')}
    />
  )
}

QuestionerSelect.propTypes = {
  selected: PropTypes.number,
  onChange: PropTypes.func,
}

export default QuestionerSelect
