import React from 'react'
import TitleArea from './TitleArea'
import { SvgIcon } from 'components'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

function AllFilterItem({ stats, active, onFilterItemClick }) {
  const { t } = useTranslation()
  return (
    <TitleArea.FilterItem active={active} onFilterItemClick={onFilterItemClick}>
      <span className="evaluation__leftEvaHeaderLink-before">{t('common.all')}</span>
      {stats.all}
      <span className="evaluation__leftEvaHeaderLink-mini">{t('suffixes.subject')}</span>
    </TitleArea.FilterItem>
  )
}

AllFilterItem.propTypes = {
  stats: PropTypes.object.isRequired,
  active: PropTypes.bool.isRequired,
  onFilterItemClick: PropTypes.func.isRequired,
}

function LikedFilterItem({ stats, active, onFilterItemClick }) {
  const { t } = useTranslation()
  return (
    <TitleArea.FilterItem active={active} onFilterItemClick={onFilterItemClick}>
      <span className="evaluation__leftEvaHeaderLink-before">
        <SvgIcon.Happy className={'evaluation__leftEvaHeaderLinkImg evaluation__leftEvaHeaderLinkImg-1'} />
      </span>
      {stats.like}
      <span className="evaluation__leftEvaHeaderLink-mini">{t('suffixes.subject')}</span>
    </TitleArea.FilterItem>
  )
}
LikedFilterItem.propTypes = {
  stats: PropTypes.object.isRequired,
  active: PropTypes.bool.isRequired,
  onFilterItemClick: PropTypes.func.isRequired,
}

function DislikedFilterItem({ stats, active, onFilterItemClick }) {
  const { t } = useTranslation()
  return (
    <TitleArea.FilterItem active={active} onFilterItemClick={onFilterItemClick}>
      <span className="evaluation__leftEvaHeaderLink-before">
        <SvgIcon.Sad className={'evaluation__leftEvaHeaderLinkImg evaluation__leftEvaHeaderLinkImg-2'} />
      </span>
      {stats.dislike}
      <span className="evaluation__leftEvaHeaderLink-mini">{t('suffixes.subject')}</span>
    </TitleArea.FilterItem>
  )
}
DislikedFilterItem.propTypes = {
  stats: PropTypes.object.isRequired,
  active: PropTypes.bool.isRequired,
  onFilterItemClick: PropTypes.func.isRequired,
}

function EvaluationTitleArea({ stats, onFilterItemClick, activeFilter }) {
  const { t } = useTranslation()
  const filterItems = [
    {
      id: 'all',
      component: AllFilterItem,
      active: true,
    },
    {
      id: 'like',
      component: LikedFilterItem,
      active: false,
    },
    {
      id: 'dislike',
      component: DislikedFilterItem,
      active: false,
    },
  ]
  const handleFilterItemClick = (e, filterItem) => {
    e.preventDefault()
    onFilterItemClick(filterItem)
  }

  return (
    <TitleArea>
      <TitleArea.MainTitle title={t('evaluation.listing.title')} resultCount={stats.all} unit={t('suffixes.subject')} />
      <TitleArea.Filter>
        {filterItems.map((filterItem) => (
          <filterItem.component
            key={filterItem.id}
            stats={stats}
            active={activeFilter === filterItem.id}
            onFilterItemClick={(e) => handleFilterItemClick(e, filterItem.id)}
          />
        ))}
      </TitleArea.Filter>
    </TitleArea>
  )
}
EvaluationTitleArea.propTypes = {
  stats: PropTypes.shape({
    all: PropTypes.number,
    like: PropTypes.number,
    dislike: PropTypes.number,
  }),
  activeFilter: PropTypes.string.isRequired,
  onFilterItemClick: PropTypes.func.isRequired,
}

export default EvaluationTitleArea
