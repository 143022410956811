import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

function SearchListOverlay({ height, editMode }) {
  const [isShow, setIsShow] = useState(editMode)

  useEffect(() => setIsShow(editMode), [editMode])

  return isShow ? <div className="search__mainCover" id="searchMainCover" style={{ height }}></div> : <></>
}

SearchListOverlay.propTypes = {
  height: PropTypes.number,
  editMode: PropTypes.bool,
}

export default SearchListOverlay
