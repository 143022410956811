import React from 'react'

import PropTypes from 'prop-types'
import ReactMarkdown from 'react-markdown'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { Slim } from 'components/atoms'
import CheckIcon from 'assets/img/check-icon.svg'
import { actionSetSlideoutActiveTab } from 'store/referral-proposals/actionCreators'

function determineLink(linkItem) {
  switch (linkItem.type) {
    case 'applicant':
      return `/applicant/${linkItem.id}`
    default:
      return '/'
  }
}

const LinkItem = ({ href, children, links = [] }) => {
  const dispatch = useDispatch()
  const index = (href?.match(/\d+/) || [])[0] || -1
  const linkItem = links[index]
  const link = typeof linkItem !== 'undefined' ? determineLink(linkItem) : '/'

  const showRecommenderTab = (e) => {
    e.preventDefault()
    dispatch(actionSetSlideoutActiveTab('recommender'))
  }

  if (linkItem.type === 'applicant') {
    return (
      <a href="#" onClick={showRecommenderTab} className="selectionModal__tabContStatus-tdu">
        {children}
      </a>
    )
  }

  return (
    <Link to={link} className="selectionModal__tabContStatus-tdu" target="_blank">
      {children}
    </Link>
  )
}

LinkItem.propTypes = {
  href: PropTypes.string,
  children: PropTypes.node,
  links: PropTypes.array,
}

LinkItem.propTypes = {}

const LinkableItem = ({ message }) => {
  return (
    <div className="selectionModal__tabContStatus">
      <span className="selectionModal__tabContStatusIcon">
        <img src={CheckIcon} alt="" className="selectionModal__tabContStatusIconImg" width="13" height="11" />
      </span>
      <ReactMarkdown
        components={{
          p: Slim,
          a: (props) => <LinkItem {...props} links={message.meta?.links || []} />,
        }}
      >
        {message.message}
      </ReactMarkdown>
    </div>
  )
}

LinkableItem.propTypes = {
  message: PropTypes.object,
}

export default LinkableItem
