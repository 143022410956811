import React from 'react'
import { FormGroup } from 'components/molecules'
import { useTranslation } from 'react-i18next'
import king from 'assets/img/king.svg/'
import check from 'assets/img/check3.svg/'
import PropTypes from 'prop-types'

function IndustryOccupationCard({ bestIndustries, specializedIndustries, bestOccupations, specializedOccupations }) {
  const { t } = useTranslation()

  return (
    <FormGroup className="form_group form__group-mgb0 cm__mainHeader">
      <dl className="cm__mainHeaderDl">
        <dt className="cm__mainHeaderDt">
          <img src={king} width="20" height="16"></img>
          {t('userProfile.basicInformation.mostGoodJobLabel')}
        </dt>
        {bestOccupations.length > 0 ? <dd className="cm__mainHeaderDd">{bestOccupations[0].name}</dd> : null}
        {specializedOccupations.length > 0 ? (
          <dt className="cm__mainHeaderDt">
            <img src={check} width="20" height="16"></img>
            {t('userProfile.basicInformation.specialtyJobLabel')}
          </dt>
        ) : null}
        <dd className="cm__mainHeaderDd">
          {specializedOccupations.length > 0
            ? specializedOccupations.map((occupation, index) => (index ? '\n' + occupation.name : occupation.name))
            : null}
        </dd>
      </dl>
      <dl className="cm__mainHeaderDl cm__mainHeaderDl-none">
        <dt className="cm__mainHeaderDt">
          <img src={king} width="20" height="16"></img>
          {t('userProfile.basicInformation.mostGoodIndustryLabel')}
        </dt>
        {bestIndustries.length > 0 ? <dd className="cm__mainHeaderDd">{bestIndustries[0].name}</dd> : null}
        {specializedIndustries.length > 0 ? (
          <dt className="cm__mainHeaderDt">
            <img src={check} width="20" height="16"></img>
            {t('userProfile.basicInformation.specialtyIndustryLabel')}
          </dt>
        ) : null}
        <dd className="cm__mainHeaderDd cm__mainHeaderDd-last">
          {specializedIndustries.length > 0
            ? specializedIndustries.map((occupation, index) => (index ? '\n' + occupation.name : occupation.name))
            : null}
        </dd>
      </dl>
    </FormGroup>
  )
}

IndustryOccupationCard.propTypes = {
  bestIndustries: PropTypes.array.isRequired,
  specializedIndustries: PropTypes.array.isRequired,
  bestOccupations: PropTypes.array.isRequired,
  specializedOccupations: PropTypes.array.isRequired,
}

export default IndustryOccupationCard
