import Typography from '@mui/material/Typography'
import React from 'react'
import PropTypes from 'prop-types'

function Title(props) {
  return (
    <Typography color="primary" component="h1" variant="h3" fontWeight="bold">
      {props.children}
    </Typography>
  )
}

Title.propTypes = {
  children: PropTypes.string.isRequired,
}

export default Title
