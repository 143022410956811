import React from 'react'
import PropTypes from 'prop-types'

const SelectListItem = (props) => {
  const { selectApplicant, label, id, disabled, buttonLabel } = props
  return (
    <li className="recommendation__modalLi">
      <p className="recommendation__modalLiName">{label}</p>
      <button
        className={disabled ? 'btn btn__shadowBtn-inactive' : 'btn btn__shadowBtn'}
        onClick={() => {
          selectApplicant(id)
          props.checkCandidateSelected()
        }}
      >
        {buttonLabel}
      </button>
    </li>
  )
}

SelectListItem.propTypes = {
  selectApplicant: PropTypes.func.isRequired,
  label: PropTypes.string,
  id: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
  buttonLabel: PropTypes.string.isRequired,
  checkCandidateSelected: PropTypes.func,
}

export default SelectListItem
