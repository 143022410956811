import React from 'react'
import { styled } from '@mui/material/styles'
import { Link } from 'react-router-dom'
import Topbar from 'layouts/minimal/components/Topbar'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { useSelector } from 'react-redux'
import { selectAuthUser } from 'store/auth/selectors'
import { getHomePage } from 'utils/helper'

const PREFIX = 'NotFound'

const classes = {
  contentBox: `${PREFIX}-contentBox`,
  heading: `${PREFIX}-heading`,
  link: `${PREFIX}-link`,
}

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.contentBox}`]: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100vh',
    flexDirection: 'column',
    fontFamily: 'Roboto',
  },

  [`& .${classes.heading}`]: {
    fontSize: '10em',
  },

  [`& .${classes.link}`]: {
    fontWeight: '700',
    paddingTop: theme.spacing(5),
  },
}))

const NotFound = () => {
  const user = useSelector(selectAuthUser)
  const link = user ? getHomePage(user) : '/sign-in'

  return (
    <Root>
      <Topbar />

      <div className={classes.contentBox}>
        <h1 className={classes.heading}>404</h1>
        <p>The page you were looking for does not exist.</p>
        <Link to={link} className={classes.link}>
          Back to Home &#8594;
        </Link>
      </div>

      <HelmetProvider>
        <Helmet>
          <title>404 Not Found - {process.env.REACT_APP_SITE_TITLE}</title>
        </Helmet>
      </HelmetProvider>
    </Root>
  )
}

export default NotFound
