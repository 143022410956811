import produce from 'immer'
import { getIndustries } from 'services/industries'

const initialState = {
  items: [],
  fetching: false,
}

export default produce((draft, { type, payload }) => {
  switch (type) {
    case getIndustries.pending:
      draft.fetching = true
      return draft
    case getIndustries.fulfilled:
      draft.items = payload.data
      draft.fetching = false
      return draft
    case getIndustries.failure:
      draft.fetching = false
      return draft
    default:
      return draft
  }
}, initialState)
