import React from 'react'

import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import SatisfactionIcon from 'assets/img/satisfaction-icon.svg'
import CuriousIcon from 'assets/img/curious-icon.svg'
import { NewlineText } from 'components/atoms'

function Confirmation() {
  const { t } = useTranslation()
  const { watch } = useFormContext()

  return (
    <>
      <div className="ev__contHeader">{t('evaluationForm.confirmInput')}</div>
      <div className="ev__type">
        <div className="ev__typeHead ev__typeHead-grey">{t('evaluationForm.typeLabel')}</div>
        <ul className="rv__typeUl">
          <li className="ev__typeLi">
            <div className="ev__typeWrap">
              {watch('type') === 'like' ? (
                <>
                  <img className="ev__typeIcon" src={SatisfactionIcon} alt="" width="24" height="24" />
                  <span className="ev__typeText">{t('common.like')}</span>
                </>
              ) : (
                <>
                  <img className="ev__typeIcon" src={CuriousIcon} alt="" width="24" height="24" />
                  <span className="ev__typeText">{t('common.dislike')}</span>
                </>
              )}
            </div>
          </li>
        </ul>
      </div>
      <div className="ev__comment">
        <div className="ev__commentHead ev__commentHead-grey">{t('evaluationForm.contentLabel')}</div>
        <div className="ev__commentArea">
          <p className="ev__commentTextareaP">
            <NewlineText text={watch('message')} />
          </p>
        </div>
      </div>
    </>
  )
}

export default Confirmation
