import React from 'react'

import PropTypes from 'prop-types'
import BlockEl from '../BlockEl'

function Avatar(inProps) {
  const { src, defaultSrc, imgProps, imgClass, component = 'span', bec = 'cm__headerIconArea', ...props } = inProps

  return (
    <BlockEl component={component} {...props} bec={bec}>
      <BlockEl
        component="img"
        {...imgProps}
        bec={imgProps?.bec || imgProps?.blockElClass || 'cm__headerIcon'}
        className={imgClass}
        src={src || defaultSrc}
      />
    </BlockEl>
  )
}

Avatar.propTypes = {
  className: PropTypes.string,
  // oneOf: mgr12
  modifier: PropTypes.string,
  blockElClass: PropTypes.string,
  src: PropTypes.string,
  imgClass: PropTypes.string,
  defaultSrc: PropTypes.string,
  imgProps: PropTypes.object,
}

Avatar.defaultProps = {
  blockElClass: 'avatar',
  imgClass: 'avatar__img',
}

export default Avatar
