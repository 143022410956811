import React from 'react'

import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import get from 'lodash/get'

import { formatID } from 'utils/helper'
import { DATE_FORMAT, DATE_FORMAT_JAPANESE } from 'utils/constants'
import { selectJobPosting } from 'store/job-postings/selectors'
import { DateText } from 'components/atoms'

function JobDetailCard() {
  const jobPosting = useSelector(selectJobPosting)
  const { t } = useTranslation()
  const workingPoints = get(jobPosting, 'working_points', []).map(({ name }) => name)

  return (
    <div className="evaluation__leftHeader">
      <div className="evaluation__headerBlueTags">
        {jobPosting?.presence_absence_authorizer === 1 && (
          <p className="evaluation__headerBlueTag">{t('formLabels.withAuthorizer')}</p>
        )}
        <p className="evaluation__headerId">
          {t('prefixes.jobOffer')} {formatID(jobPosting?.id)}
        </p>
      </div>
      <h1 className="evaluation__title">{jobPosting?.job_title}</h1>
      <div className="evaluation__headerDlWrap">
        <dl className="evaluation__headerDl">
          <dt className="evaluation__headerDt">{t('jobPosting.desiredTimeHires')}</dt>
          <dd className="evaluation__headerDd">
            {jobPosting?.desired_period_of_employment === 'year' ? (
              t('newJobPost.step1.desiredTimeToHire_recruitedAllYear')
            ) : (
              <DateText format={DATE_FORMAT_JAPANESE} date={jobPosting?.desired_period_of_employment} />
            )}
          </dd>
          <dt className="evaluation__headerDt">{t('jobPosting.numHires')}</dt>
          <dd className="evaluation__headerDd">
            {t('jobPosting.numPeople', { count: jobPosting?.number_of_positions })}
          </dd>
        </dl>
      </div>
      <div className="evaluation__headerTagArea">
        {workingPoints.map((name, index) => (
          <p key={index} className="evaluation__headerTag">
            {name}
          </p>
        ))}
      </div>
      <div className="evaluation__supArea">
        <p className="evaluation__supCorp">
          <span className="evaluation__supCorp-bunrui">{t('jobPosting.companyName')}</span>
          {jobPosting?.company_name}
        </p>
        <div className="evaluation__supTimeArea">
          <p className="evaluation__supTime">
            {t('jobPosting.createdAt')}: <DateText format={DATE_FORMAT} date={jobPosting?.created_at} />
          </p>
          <p className="evaluation__supTime">
            {t('jobPosting.updatedAt')}: <DateText format={DATE_FORMAT} date={jobPosting?.updated_at} />
          </p>
        </div>
      </div>
    </div>
  )
}

JobDetailCard.propTypes = {
  id: PropTypes.number,
}

export default JobDetailCard
