import produce from 'immer'
import { fetchProposalStatuses } from 'services/referralProposals'

const initialData = {
  state: 'pending',
  items: [],
  error: null,
}

const reducer = produce((draft, { payload, type }) => {
  switch (type) {
    case fetchProposalStatuses.pending:
      draft.state = 'pending'
      draft.error = null
      break
    case fetchProposalStatuses.fulfilled:
      draft.state = 'fulfilled'
      draft.items = payload.data
      draft.error = null
      break
    case fetchProposalStatuses.failure:
      draft.state = 'failure'
      draft.error = payload
      break
    default:
      return draft
  }
}, initialData)

export default reducer
