import React, { useState, useEffect } from 'react'
import { SelectListItem, SearchArea } from 'components'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { getApplicants } from 'services/applicant'
import { Modal } from 'components/molecules'

const RecommendModal = (props) => {
  const [userList, setUserList] = useState()
  const [selectedApplicant, setSelectedApplicant] = useState('')
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getApplicants()).then((response) => {
      if (response.type === 'GET_APPLICANTS_LIST_SUCCESS') {
        let list = response.payload.data.data

        setUserList(list)
      }
    })
  }, [])

  const selectApplicant = (id) => {
    setSelectedApplicant(id)
    props.handleSelectApplicant(id)
  }

  return (
    <Modal onClose={props.handleCloseModal} open={props.showModal} containerBlockElClass="recommendation__modal">
      <SearchArea label="氏名" placeholder="絞り込む" handleCloseModal={props.handleCloseModal} />
      <ul className="recommendation__modalUl">
        {userList?.map((row, index) => (
          <SelectListItem
            key={index}
            label={row.lastname + ' ' + row.firstname}
            id={row.id}
            selectApplicant={() => selectApplicant(row.id)}
            disabled={row.id === selectedApplicant}
            buttonLabel={row.id === selectedApplicant ? '推薦済み' : '選択する'}
            checkCandidateSelected={props.checkCandidateSelected}
          />
        ))}
      </ul>
    </Modal>
  )
}

RecommendModal.propTypes = {
  handleSelectApplicant: PropTypes.func,
  handleCloseModal: PropTypes.func,
  showModal: PropTypes.bool,
  checkCandidateSelected: PropTypes.func,
}

export default RecommendModal
