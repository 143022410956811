import React from 'react'
import { useSelector } from 'react-redux'
import { selectJobPosting } from '../../../store/job-postings/selectors'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { Button } from 'components/atoms'

function TreatmentSalaryWelfare({ accountTypeHR, editable = true }) {
  const job_posting = useSelector(selectJobPosting)
  const { t } = useTranslation('translation', { keyPrefix: 'jobVacancies.treatmentSalaryWelfare' })
  const history = useHistory()
  const referralType = job_posting?.job_posting_type === 'referral'
  const overtimeWork = +job_posting?.overtime_work === 1 ? t('canBe') : t('none')

  const handleClick = () => {
    localStorage.setItem(
      job_posting.job_posting_type === 'referral' ? 'job_posting' : 'job_post_proposal',
      JSON.stringify(job_posting)
    )
    history.push({
      pathname: referralType ? '/new-job-post/step4/' : '/new-job-post-proposal/step3/',
      state: { job_posting_data: job_posting, multipleStep: true },
    })
  }

  return (
    <section className="jobvacancies__cont">
      <h3 className="jobvacancies__title jobvacancies__title-jcsb">
        {t('title')}
        {accountTypeHR && (
          <Button variant="text" onClick={handleClick} disabled={!editable}>
            {t('editButton')}
          </Button>
        )}
      </h3>
      <dl className="jobvacancies__dl">
        <dt className="jobvacancies__dt">{t('workingStyle')}</dt>
        <dd className="jobvacancies__dd">{job_posting.type_of_work}</dd>
        <dt className="jobvacancies__dt">{t('supWorkingStyle')}</dt>
        <dd className="jobvacancies__dd">{job_posting.supp_info_type_of_work}</dd>
        <dt className="jobvacancies__dt">{t('workingHours')}</dt>
        <dd className="jobvacancies__dd">{job_posting.work_hours}</dd>
        <dt className="jobvacancies__dt">{t('breakTime')}</dt>
        <dd className="jobvacancies__dd">
          {job_posting.rest_time}
          {t('minutes')}
        </dd>
        <dt className="jobvacancies__dt">{t('overtimeWork')}</dt>
        <dd className="jobvacancies__dd">{overtimeWork}</dd>
        <dt className="jobvacancies__dt">{t('averageOvertimeHours')}</dt>
        <dd className="jobvacancies__dd">
          {job_posting.average_overtime_hours}
          {t('hour')}
        </dd>
        <dt className="jobvacancies__dt">{t('wageSystem')}</dt>
        <dd className="jobvacancies__dd">{job_posting.wage_system}</dd>
        <dt className="jobvacancies__dt">{t('supInforOnWageSystem')}</dt>
        <dd className="jobvacancies__dd">{job_posting.wage_system_other_info}</dd>
        <dt className="jobvacancies__dt">{t('overtimePay')}</dt>
        <dd className="jobvacancies__dd">{job_posting.overtime_pay}</dd>
        <dt className="jobvacancies__dt">{t('treatmentCond')}</dt>
        <dd className="jobvacancies__dd">{job_posting.compensation_salary_benefits}</dd>
        <dt className="jobvacancies__dt">{t('welfare')}</dt>
        <dd className="jobvacancies__dd">{job_posting.benefits}</dd>
        <dt className="jobvacancies__dt">{t('holidayVacation')}</dt>
        <dd className="jobvacancies__dd">{job_posting.vacation_leaves}</dd>
      </dl>
    </section>
  )
}

TreatmentSalaryWelfare.propTypes = {
  accountTypeHR: PropTypes.bool,
  editable: PropTypes.bool,
}

export default TreatmentSalaryWelfare
