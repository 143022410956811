import React from 'react'
import { MenuItem, ListItemIcon, Typography } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'
import PropTypes from 'prop-types'

const NavMenuItem = (props) => {
  const { title, icon, href, size, color, variant, onClick } = props

  return (
    <MenuItem primary={color} key={title} component={RouterLink} to={href} onClick={onClick}>
      <ListItemIcon color={color} size={size}>
        {icon}
      </ListItemIcon>
      <Typography variant={variant}>{title}</Typography>
    </MenuItem>
  )
}

NavMenuItem.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.element,
  href: PropTypes.string,
  size: PropTypes.string,
  color: PropTypes.string,
  variant: PropTypes.string,
  onClick: PropTypes.func,
}

export default NavMenuItem
