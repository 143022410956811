import React from 'react'
import PropTypes from 'prop-types'

function Happy({ className }) {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33.33 33.33">
      <g data-name="レイヤー 2">
        <path
          className="cls-1"
          d="M0,16.67A16.67,16.67,0,1,1,16.67,33.33,16.72,16.72,0,0,1,0,16.67Zm3.33,0A13.34,13.34,0,1,0,16.67,3.33,13.37,13.37,0,0,0,3.33,16.67Zm20-3.34a1.67,1.67,0,1,1-1.66-1.66A1.66,1.66,0,0,1,23.33,13.33ZM11.67,15A1.67,1.67,0,1,0,10,13.33,1.66,1.66,0,0,0,11.67,15Zm5,10c4.6,0,8.33-3,8.33-6.67H22.5c0,2.31-2.61,4.17-5.83,4.17s-5.84-1.86-5.84-4.17H8.33C8.33,22,12.06,25,16.67,25Z"
        ></path>
      </g>
    </svg>
  )
}

Happy.propTypes = {
  className: PropTypes.string,
}

export default Happy
