import produce from 'immer'
import {
  getApplicantNotes,
  createApplicantsNote,
  deleteApplicantNotes,
  updateApplicantNotes,
} from 'services/applicantsNote'
import { AUTH_SIGNOUT } from 'store/auth/actionTypes'

const initialState = {
  state: 'pending',
  error: null,
  items: [],
  candidateRecordIsUpdated: false,
}

const reducer = produce((draft, { type, payload }) => {
  switch (type) {
    //get
    case getApplicantNotes.pending:
      draft.state = 'pending'
      return draft
    case getApplicantNotes.fulfilled:
      draft.state = 'fulfilled'
      draft.items = payload.data
      draft.candidateRecordIsUpdated = false
      return draft
    case getApplicantNotes.failure:
      draft.state = 'failure'
      draft.error = payload.message
      return draft
    //create
    case createApplicantsNote.pending:
      draft.state = 'pending'
      return draft
    case createApplicantsNote.fulfilled:
      draft.state = 'fulfilled'
      draft.items = payload.data
      draft.candidateRecordIsUpdated = true
      return draft
    case createApplicantsNote.failure:
      draft.state = 'failure'
      draft.error = payload.message
      return draft
    //update
    case updateApplicantNotes.pending:
      draft.state = 'pending'
      return draft
    case updateApplicantNotes.fulfilled:
      draft.state = 'fulfilled'
      draft.items = payload.data
      draft.candidateRecordIsUpdated = true
      return draft
    case updateApplicantNotes.failure:
      draft.state = 'failure'
      draft.error = payload.message
      return draft
    //delete
    case deleteApplicantNotes.pending:
      draft.state = 'pending'
      return draft
    case deleteApplicantNotes.fulfilled:
      draft.state = 'fulfilled'
      draft.items = payload.data
      return draft
    case deleteApplicantNotes.failure:
      draft.state = 'failure'
      draft.error = payload.message
      return draft
    case AUTH_SIGNOUT:
      return initialState
    default:
      return draft
  }
}, initialState)

export default reducer
