import React, { useEffect } from 'react'
import { getChineseLevel } from 'services/chineseLevel'
import { useDispatch, useSelector } from 'react-redux'
import { FormGroupCheck } from 'components/molecules'
import { useController } from 'react-hook-form'
import { Radio } from 'components/atoms'
import PropTypes from 'prop-types'

const ChineseLevel = (props) => {
  const { rules } = props
  const dispatch = useDispatch()
  const { field } = useController({ name: 'chinese_level', rules })
  const chineseLevelState = useSelector((state) => state.chineseLevel.state)
  const items = useSelector((state) => state.chineseLevel.items)

  useEffect(() => {
    dispatch(getChineseLevel()).then(() => {})
  }, [])
  const value = field?.value
  return (
    <FormGroupCheck modifier="flex">
      {chineseLevelState === 'fulfilled' &&
        items?.map((chineseLevel, index) => (
          <FormGroupCheck.Item
            key={index}
            Input={Radio}
            inputProps={{
              ...field,
              onChange: () => {
                field.onChange([chineseLevel.id])
              },
              checked: +value === chineseLevel.id,
              value: chineseLevel.id,
              className: 'form__checkItem2',
            }}
            modifier={{ w50: index % 2 === 0, w502: index % 2 !== 0, sp100: true }}
            type="round"
            label={chineseLevel.name}
          />
        ))}
    </FormGroupCheck>
  )
}

ChineseLevel.propTypes = {
  rules: PropTypes.object,
}

export default ChineseLevel
