import * as ActionTypes from './actionTypes'

/**
 * @typedef {Object} QuestionsParams
 * @property {number} [page]
 * @property {number} [limit]
 * @property {string|number|null} [job_posting_id]
 * @property {array} [topic]
 * @property {string|number|null} [company_id]
 * @property {string|null} [answer_state]
 */

/**
 *
 * @param {QuestionsParams} patch
 */
export const actionUpdateQuestionsParams = (patch) => {
  return {
    type: ActionTypes.UPDATE_QUESTION_PARAMS,
    payload: patch,
  }
}

/**
 *
 * @param {QuestionsParams} patch
 */
export const actionResetQuestionsParams = (patch) => {
  return {
    type: ActionTypes.RESET_QUESTION_PARAMS,
    payload: patch,
  }
}

export const actionEditQuestion = (id) => {
  return {
    type: ActionTypes.EDIT_QUESTION,
    payload: id,
  }
}

export const actionUnfocusQuestion = () => {
  return {
    type: ActionTypes.UNFOCUS_QUESTION,
  }
}
