import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import Link from '@mui/material/Link'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { CheckList } from 'components'
import PropTypes from 'prop-types'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import { getIndustries } from 'services/industries'
import find from 'lodash/find'
import get from 'lodash/get'
import noop from 'lodash/noop'

function IndustryCheckList({ onItemClick, checked, items }) {
  const isLabelOnly = (item) => 'children' in item && item.children.length > 0

  return <CheckList isLabelOnly={isLabelOnly} items={items} value={checked} onItemClick={onItemClick} />
}

IndustryCheckList.propTypes = {
  checked: PropTypes.array.isRequired,
  onItemClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  items: PropTypes.array.isRequired,
}

function getName(item, items) {
  const title = get(find(items, { id: item.parent_id }), 'name')
  const description = item.name

  return `${title}/${description}`
}

export default function IndustryInputField(props) {
  const [open, setOpen] = useState(false)
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const items = useSelector((state) => state.industries.items)

  useEffect(() => {
    dispatch(getIndustries())
  }, [dispatch])

  const handleToggle = (value, e) => {
    const currentIndex = props.value.indexOf(value.id)
    const newChecked = [...props.value]

    if (currentIndex === -1) {
      newChecked.push(value.id)
    } else {
      newChecked.splice(currentIndex, 1)
    }

    e.target.value = newChecked

    props.onChange(e)
  }

  const handleOpemModalClick = (e) => {
    e.preventDefault()
    setOpen(true)
  }
  const propValue = props.value

  const value = useMemo(() => {
    const filtered = items
      .reduce((accum, curr) => accum.concat([curr, ...curr.children]), [])
      .filter((item) => propValue?.indexOf(item.id) !== -1 || false)
    return filtered.map((item) => getName(item, items))
  }, [items, propValue])

  return (
    <>
      <TextField
        margin="dense"
        label={t('userProfile.industryLabel')}
        onChange={noop}
        value={value}
        helperText={props.helperText}
        error={props.error}
        fullWidth
        disabled={props.disabled}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Link onClick={handleOpemModalClick}>{t('userProfile.industryLink')}</Link>
            </InputAdornment>
          ),
        }}
      />

      <Dialog open={open} onClose={() => setOpen(false)} maxWidth="md">
        <DialogContent>
          <IndustryCheckList items={items} checked={props.value} onItemClick={handleToggle} />
        </DialogContent>
      </Dialog>
    </>
  )
}

IndustryInputField.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.array.isRequired,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  disabled: PropTypes.bool,
}
