import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { isParent } from 'utils/helper'
import AvatarField from 'components/molecules/AvatarField'
import { useLocation } from 'react-router-dom'

const UserAccountInfo = (props) => {
  const { t } = useTranslation()
  const { user, data } = props
  const userType = isParent(user) ? t('userPermission.parent') : t('userPermission.child')
  const location = useLocation()

  return (
    <main className="main cm cm-w">
      <div className="recommendation__inner">
        <dl className="recommendation__dl">
          <dt className="recommendation__dt">{t('createUserAccount.profileImage')}</dt>
          <dd className="recommendation__dt">
            <AvatarField
              src={location.state?.avatar}
              imgClass="cm__headerIconArea cm__headerIconArea-mgb16"
              hidePencil={true}
            />
          </dd>
          <dt className="recommendation__dt">{t('createUserAccount.company')}</dt>
          <dd className="recommendation__dd">{user.company?.company_name}</dd>
          <dt className="recommendation__dt">{t('createUserAccount.accountType')}</dt>
          <dd className="recommendation__dd">{userType}</dd>
          <dt className="recommendation__dt">{t('createUserAccount.fullName')}</dt>
          <dd className="recommendation__dd">
            {data.lastname} {data.firstname}
          </dd>
          <dt className="recommendation__dt">{t('createUserAccount.profile')}</dt>
          <dd className="recommendation__dd">{data?.user_profile}</dd>
        </dl>
      </div>
    </main>
  )
}

UserAccountInfo.propTypes = {
  user: PropTypes.object,
  data: PropTypes.object,
}

export default UserAccountInfo
