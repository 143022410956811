import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { calculateModifiers } from 'utils/helper'

const StepBar = (props) => {
  const { step, end, label, modifier } = props
  return (
    <div className="step">
      <p className="step__text">
        STEP{step}/{end}&nbsp;&nbsp;{label}
      </p>
      <div className={clsx('step__bar', calculateModifiers('step__bar', modifier))} />
    </div>
  )
}

StepBar.propTypes = {
  step: PropTypes.number,
  end: PropTypes.number,
  label: PropTypes.string,
  modifier: PropTypes.string,
}

export default StepBar
