import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Select } from 'components/molecules'
import { useTranslation } from 'react-i18next'
import merge from 'lodash/merge'
import { useDispatch, useSelector } from 'react-redux'
import { getCompanySelect } from 'services/companies'
import { selectCompanySelect } from 'store/companies/selectors'

function CompanySelect({ formControlProps, ...props }) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const options = useSelector(selectCompanySelect)

  useEffect(() => {
    dispatch(getCompanySelect())
  }, [dispatch])

  return (
    <Select
      {...props}
      label={props.label ?? t('adminUser.companyName')}
      options={options}
      formControlProps={merge({ fullWidth: true }, formControlProps)}
    />
  )
}

CompanySelect.propTypes = {
  label: PropTypes.string,
  formControlProps: PropTypes.object,
}

export default CompanySelect
