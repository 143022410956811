import React from 'react'

import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import get from 'lodash/get'
import { selectJobPosting } from 'store/job-postings/selectors'
import { Button } from 'components/atoms'
function MandatoryRequirements({ accountTypeHR, editable = true }) {
  const job_posting = useSelector(selectJobPosting)
  const { t } = useTranslation('translation', { keyPrefix: 'jobVacancies.mandatoryRequirements' })
  const history = useHistory()
  const referralType = job_posting?.job_posting_type === 'referral'

  const handleClick = () => {
    localStorage.setItem(
      job_posting.job_posting_type === 'referral' ? 'job_posting' : 'job_post_proposal',
      JSON.stringify(job_posting)
    )
    history.push({
      pathname: referralType ? '/new-job-post/step3/' : '/new-job-post-proposal/step2/',
      state: { job_posting_data: job_posting, multipleStep: true },
    })
  }

  return (
    <section className="jobvacancies__cont">
      <h3 className="jobvacancies__title jobvacancies__title-jcsb">
        {t('title')}
        {accountTypeHR && (
          <Button variant="text" onClick={handleClick} disabled={!editable}>
            {t('editButton')}
          </Button>
        )}
      </h3>
      <dl className="jobvacancies__dl">
        <dt className="jobvacancies__dt">{t('skillsExperience')}</dt>
        <dd className="jobvacancies__dd">{job_posting.skills_experience}</dd>
        <dt className="jobvacancies__dt">{t('assumedGender')}</dt>
        <dd className="jobvacancies__dd">{job_posting.gender}</dd>
        <dt className="jobvacancies__dt">{t('ageAtWhichYouCanApply')}</dt>
        <dd className="jobvacancies__dd">
          {job_posting.min_acceptable_age} - {job_posting.max_acceptable_age}
        </dd>
        {referralType && <dt className="jobvacancies__dt">{t('finalAcademicBackgroundThatCanBeApplied')}</dt>}

        <dd className="jobvacancies__dd">{get(job_posting, 'education_background.0.name')}</dd>
        <dt className="jobvacancies__dt">{t('numberOfJobChanges')}</dt>
        <dd className="jobvacancies__dd">
          {job_posting.changed_job_count === -1 ? '回数を問わない' : job_posting.changed_job_count}
        </dd>
        <dt className="jobvacancies__dt">{t('englishLevel')}</dt>

        <dd className="jobvacancies__dd">{get(job_posting, 'english_level[0].name')}</dd>
        <dt className="jobvacancies__dt">{t('chineseLevel')}</dt>

        <dd className="jobvacancies__dd">{get(job_posting, 'chinese_level[0].name')}</dd>
        <dt className="jobvacancies__dt">{t('countryOfCitizenship')}</dt>
        <dd className="jobvacancies__dd">{job_posting.nationality}</dd>
        {referralType && <dt className="jobvacancies__dt">{t('requiredItemsToFillInWhenRecommending')}</dt>}
        <dd className="jobvacancies__dd">{job_posting.other_info_working_conditions}</dd>
      </dl>
    </section>
  )
}

MandatoryRequirements.propTypes = {
  accountTypeHR: PropTypes.bool,
  editable: PropTypes.bool,
}

export default MandatoryRequirements
