import React from 'react'

import PropTypes from 'prop-types'
import clsx from 'clsx'

function TabCont({ Component, active, tabProps = {} }) {
  return (
    <div className={clsx('selectionModal__tabCont', { 'selectionModal__tabCont-active': active })}>
      {<Component {...tabProps} active={active} />}
    </div>
  )
}

TabCont.propTypes = {
  Component: PropTypes.any,
  active: PropTypes.bool,
  tabProps: PropTypes.object,
}

function Tab({ id, label, active, onClick }) {
  const handleClick = () => {
    onClick(id)
  }

  return (
    <li className={clsx('selectionModal__tab', { 'selectionModal__tab-active': active })} onClick={handleClick}>
      {label}
    </li>
  )
}

Tab.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  active: PropTypes.bool,
  onClick: PropTypes.func,
}

function SelectionModalTabs(props) {
  const { tabs, active, onChange, tabContAreaRef } = props
  const isActive = (tab) => tab.id === active

  const handleTabChange = (id) => {
    if (active === id) return

    if (onChange) onChange(id)
  }

  return (
    <>
      <ul className="selectionModal__tabs">
        {tabs.map((tab) => (
          <Tab key={tab.id} {...tab} active={isActive(tab)} onClick={handleTabChange} />
        ))}
      </ul>

      <div ref={tabContAreaRef} className="selectionModal__tabContArea">
        {tabs.map((tab) => (
          <TabCont key={tab.id} {...tab} active={isActive(tab)} />
        ))}
      </div>
    </>
  )
}

SelectionModalTabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
      Component: PropTypes.any,
      tabProps: PropTypes.object,
    })
  ),
  active: PropTypes.string,
  onChange: PropTypes.func,
  tabContAreaRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })]),
}

export default SelectionModalTabs
