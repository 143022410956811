import { useSelector } from 'react-redux'

export default function useStoreStateVar(selector) {
  const state = useSelector(selector)

  const loading = state === 'pending'
  const ready = state === 'fulfilled'
  const failed = state === 'failure'

  return { loading, ready, failed }
}
