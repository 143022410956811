import React from 'react'

import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import { BlockEl } from 'components/atoms'

function Header({ isAD2 }) {
  const { t } = useTranslation()

  return (
    <BlockEl component="li" bec="selection__mainLi" modifier="gray">
      <BlockEl bec="selection__mainOwner" modifier="ais" className="selection-pdb0">
        {isAD2 ? t('suggestion.employingCompany') : t('suggestion.proposingCompany')}
      </BlockEl>
      <BlockEl bec="selection__mainPosition" modifier={{ hr: !isAD2 }} className="selection-pdb0">
        {t('suggestion.position')}/{t('common.company')}
      </BlockEl>
      {isAD2 && (
        <BlockEl bec="selection__mainPartner" className="selection-pdb0">
          {t('suggestion.salesRep')}
        </BlockEl>
      )}
      <BlockEl bec="selection__mainStatus" className="selection-pdb0">
        {t('common.status')}
      </BlockEl>
      <BlockEl bec="selection__mainSituation" className="selection-pdb0">
        {t('suggestion.correspondingSituation')}
      </BlockEl>
    </BlockEl>
  )
}

Header.propTypes = {
  isAD2: PropTypes.bool.isRequired,
}

export default Header
