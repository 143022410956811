import * as ActionTypes from './actionTypes'

export const actionSetEvaluationFormData = (data) => ({
  type: ActionTypes.SET_EVAULATION_FORM_DATA,
  payload: data,
})

export const actionOpenEvaluationForm = () => ({
  type: ActionTypes.OPEN_EVAULATION_FORM,
})

export const actionCloseEvaluationForm = (success) => ({
  type: ActionTypes.CLOSE_EVAULATION_FORM,
  payload: { success },
})
