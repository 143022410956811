import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
//import clsx from 'clsx'
import { Controller, useForm, FormProvider, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { inviteUser } from 'services/users'
import { useInvalidFieldCount } from 'utils/hooks'
import { syncErrorsToForm, makeFormGroupStateGetter } from 'utils/helper'
//import AccountPermissionSelect from '../AccountPermissionSelect'
import { selectCompanyId } from 'store/auth/selectors'
import { Button, Input } from 'components/atoms'
import { FormGroup, FormSupArea, ErrorMessage } from 'components/molecules'

function SubmitButton() {
  const { t } = useTranslation()
  const {
    control,
    formState: { isSubmitting },
  } = useFormContext()
  const { count } = useInvalidFieldCount({ control, name: ['email', 'permission'] })

  const disabled = count > 0 || isSubmitting
  return (
    <Button type="submit" disabled={disabled} variant="yellowDessable" variantMod={{ active: !disabled }}>
      {t('userAccount.modalInviteBtn')}
    </Button>
  )
}

function InviteUserForm(props) {
  const { isOpen } = props
  const methods = useForm({
    defaultValues: {
      email: '',
      permission: null,
    },
    mode: 'all',
  })
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
    fieldState,
    setError,
    reset,
  } = methods
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const companyId = useSelector(selectCompanyId)

  const { fromFieldState } = makeFormGroupStateGetter(fieldState)
  const handleSubmitCb = async (data) => {
    data['permission'] = 'child'
    const { error, data: responseData } = await dispatch(inviteUser({ ...data, company_id: companyId }))

    if (error) {
      if (error?.code === 422) {
        syncErrorsToForm(error.error, setError)
      }
    } else {
      props.onInviteSuccess(responseData)
      resetFields()
    }
  }

  const validationRules = {
    email: {
      required: t('auth.required'),
      pattern: {
        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        message: t('auth.email'),
      },
    },
    group_id: {
      required: t('auth.required'),
    },
  }

  const resetFields = () => {
    reset(
      {
        email: '',
        permission: '',
      },
      {
        keepDefaultValues: true,
        keepDirtyValues: false,
        keepValues: false,
      }
    )
  }

  useEffect(() => {
    resetFields()
  }, [isOpen])

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(handleSubmitCb)} noValidate>
        <div className="member__modalHeader member__modalHeader-pdt16 member__modalHeader-nobd">
          <p className="modal__contTitle modal__contTitle2">{t('userAccount.modaltitle')}</p>
        </div>
        <div className="modal__textArea form-pdrlSp16">
          <p className="modal__text modal__text-wn">{t('userAccount.modalSubLbl')}</p>
        </div>

        <Controller
          control={control}
          name="email"
          rules={validationRules.email}
          render={({ field, fieldState }) => (
            <FormGroup {...fromFieldState(fieldState)} modifier="mgb0 mgt2" required>
              <div className="form__group form__group-mgb0 form-pdrlSp16 form__group-spMgb0">
                <div className="form__groupCheckArea form__groupCheckArea-mgt24 form__groupCheckArea-mgb">
                  <Input {...field} className="form__inputText form__group-mgt0 form__inputText-w100" />
                  <FormSupArea>
                    <ErrorMessage name="email" />
                  </FormSupArea>
                </div>
              </div>
            </FormGroup>
          )}
        />

        {/* uncomment when admin is implemented to allow selection of permission
         <Controller
          control={control}
          name="permission"
          rules={validationRules.group_id}
          render={({ field: { onChange, value }, fieldState }) => (
            <FormGroup {...fromFieldState(fieldState)} modifier="mgb0 mgt2" required>
              <div className="form__group form__group-mgb0 form-pdrlSp16 form__group-spMgb0">
                <div className="form__groupCheckArea form__groupCheckArea-mgt24 form__groupCheckArea-mgb">
                  <AccountPermissionSelect onChange={onChange} value={value} />
                  <FormSupArea>
                    <ErrorMessage name="permission" />
                  </FormSupArea>
                </div>
              </div>
            </FormGroup>
          )}
        /> */}

        <div className="modal__btnArea modal__btnArea-fe member__modalBtnArea">
          <Button
            onClick={props.onCancel}
            disabled={isSubmitting}
            className="btn btn-mgr btn__outlineDissable modalClose"
          >
            {t('userAccount.modalCancelBtn')}
          </Button>
          <SubmitButton />
        </div>
      </form>
    </FormProvider>
  )
}

InviteUserForm.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func,
  onInviteSuccess: PropTypes.func,
}

export default InviteUserForm
