import React from 'react'
import { ReactComponent as HappyIcon } from 'assets/img/happy-icon.svg'
import { ReactComponent as ArrowIcon } from 'assets/img/arrow4.svg'
import { ReactComponent as SadIcon } from 'assets/img/sad.svg'
import { Button } from 'components/atoms'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { EVALUATION_STATUSES } from 'utils/constants'
import EvaluationSubModal from './Modal'

function EvaluationSlideOut({
  item,
  handleApproved,
  handleRevertApproval,
  handleNonApproved,
  handleSubNonApproval,
  handleSubClose,
  openSub,
}) {
  const { t } = useTranslation('translation', { keyPrefix: 'evaluation' })

  const customStyles = {
    marginTop: '10px',
  }

  return (
    <>
      <div className="adminEvaluationModal__contWrap">
        <div className="adminEvaluationModal__ttlArea">
          <h3 className="adminEvaluationModal__ttlAreaTitle">{t('listing.headings.mutualEvaluation')}</h3>
          <p className="adminEvaluationModal__ttlAreaTxt" style={customStyles}>
            {t('listing.headings.mutuallyEvaluated')}
          </p>
        </div>

        <div className="adminEvaluationModal__valueDetail">
          <h3 className="adminEvaluationModal__valueDetailTitle">{t('listing.headings.evaluationDetails')}</h3>
          <ul className="adminEvaluationModal__valueDetailPtoP" style={{ marginTop: '20px' }}>
            <li className="adminEvaluationModal__valueDetailPtoPList">
              {item?.evaluator_id.full_name}
              <span className="adminEvaluationModal__valueDetailPtoP-gray" style={customStyles}>
                {item?.evaluator_id.company.company_name}
              </span>
            </li>
            <li className="adminEvaluationModal__valueDetailPtoPList-arrow">
              <ArrowIcon />
            </li>
            <li className="adminEvaluationModal__valueDetailPtoPList">
              {item?.evaluated_id.full_name}
              <span className="adminEvaluationModal__valueDetailPtoP-gray" style={{ marginTop: '10px' }}>
                {item?.evaluated_id.company.company_name}
              </span>
            </li>
          </ul>

          <h3 className="adminEvaluationModal__valueDetailTitle" style={{ marginTop: '25px' }}>
            {t('listing.headings.evaluationDetails')}
          </h3>
          <div className="adminEvaluationModal__valueDetailValue" style={{ marginTop: '15px' }}>
            {item?.like > item?.dislike ? (
              <>
                <HappyIcon />
                {t('iconText.like')}
              </>
            ) : (
              <>
                <SadIcon />
                {t('iconText.dislike')}
              </>
            )}
          </div>
          <div className="adminEvaluationModal__valueDetailTxt" style={{ marginTop: '10px', marginBottom: '15px' }}>
            <p>{item?.message}</p>
          </div>
          {item?.status === EVALUATION_STATUSES.approved ? (
            <div className="adminEvaluationModal__valueStatus" style={customStyles}>
              <p className="adminEvaluationModal__linkBack" onClick={handleRevertApproval}>
                <a>{t('status.revertApproval')}</a>
              </p>
              <div className="adminEvaluationModal__statusDetail status-ok">{t('status.approval')}</div>
            </div>
          ) : item?.status === EVALUATION_STATUSES.disapproved ? (
            <div className="adminEvaluationModal__valueStatus" style={customStyles}>
              <p className="adminEvaluationModal__linkBack" onClick={handleRevertApproval}>
                <a>{t('status.revertApproval')}</a>
              </p>
              <div className="adminEvaluationModal__statusDetail status-no">{t('status.nonApproval')}</div>
            </div>
          ) : (
            <div className="selectionModal__contHeaderRight" style={customStyles}>
              <Button blockElClass="btn__approval" onClick={handleApproved}>
                {t('status.approved')}
              </Button>
              <Button blockElClass="btn__approval-no popModal withoutScroll" onClick={handleSubNonApproval}>
                {t('status.disapproved')}
              </Button>
            </div>
          )}
        </div>
      </div>
      <EvaluationSubModal open={openSub} handleSubClose={handleSubClose} handleNonApproved={handleNonApproved} />
    </>
  )
}

EvaluationSlideOut.propTypes = {
  item: PropTypes.object,
  handleApproved: PropTypes.func,
  handleNonApproved: PropTypes.func,
  handleRevertApproval: PropTypes.func,
  handleSubNonApproval: PropTypes.func,
  handleSubClose: PropTypes.func,
  openSub: PropTypes.bool,
}

export default EvaluationSlideOut
