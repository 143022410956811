import React from 'react'

import PropTypes from 'prop-types'

import { BlockEl } from 'components/atoms'

function TooltipText({ children, bec = 'tooltip__text', component = 'p', ...props }) {
  return (
    <BlockEl component={component} bec={bec} {...props}>
      {children}
    </BlockEl>
  )
}

TooltipText.propTypes = {
  ...BlockEl.propTypes,
  children: PropTypes.node,
}

function TooltipLink({ children, bec = 'tooltip__link', component = 'p', ...props }) {
  return (
    <BlockEl component={component} bec={bec} {...props}>
      {children}
    </BlockEl>
  )
}

TooltipLink.propTypes = {
  ...BlockEl.propTypes,
  children: PropTypes.node,
}

function Tooltip({ open, blockElClass = 'tooltip__main', modifier, children, onClose, ...props }) {
  const handleBackdropClick = () => {
    onClose && onClose('backdrop')
  }

  return (
    <>
      <BlockEl blockElClass={blockElClass} modifier={[modifier, { open }]} {...props}>
        {children}
      </BlockEl>
      <div onClick={handleBackdropClick} className="tooltip__closeBg" style={{ display: open ? 'flex' : 'none' }}></div>
    </>
  )
}

Tooltip.propTypes = {
  ...BlockEl.propTypes,
  open: PropTypes.bool,
  children: PropTypes.node,
  onClose: PropTypes.func,
}

Tooltip.Text = TooltipText
Tooltip.Link = TooltipLink

export default Tooltip
