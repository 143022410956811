import { selectChoice } from 'store/choices/selectors'
import { createAsyncThunk } from 'store/helpers'
import { parseAxiosResponse } from 'utils/helper'
import Http from 'utils/Http'

export const fetchChoices = createAsyncThunk('FETCH_CHOICES', async ({ choices }, getState) => {
  const state = getState()
  const fetchChoices = choices.filter((item) => typeof selectChoice(state, item) === 'undefined')

  try {
    if (fetchChoices.length > 0) {
      const response = await parseAxiosResponse(Http.get('/common/choices', { params: { choices: fetchChoices } }))

      return response.choices
    }
    return {}
  } catch (e) {
    return Promise.reject(e)
  }
})
