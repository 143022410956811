import Http from 'utils/Http'
import { createAsyncThunk } from 'store/helpers'
import { parseAxiosResponse } from 'utils/helper'

export const createApplicantsNote = createAsyncThunk('CREATE_APPLICANT_NOTE', (data) => {
  return parseAxiosResponse(Http.post(`/common/applicants_notes/`, data))
})

export const getApplicantNotes = createAsyncThunk('GET_APPLICANT_NOTES', (applicantId) =>
  parseAxiosResponse(Http.get(`/common/applicants_notes/${applicantId}`))
)

export const updateApplicantNotes = createAsyncThunk('UPDATE_APPLICANT_NOTE', (id, data) =>
  parseAxiosResponse(Http.put(`/common/applicants_notes/${id}`, data))
)

export const deleteApplicantNotes = createAsyncThunk('UPDATE_APPLICANT_NOTE', (id) =>
  parseAxiosResponse(Http.delete(`/common/applicants_notes/${id}`))
)
