import React, { useEffect, useRef } from 'react'

import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import get from 'lodash/get'
import { useDispatch, useSelector } from 'react-redux'

import { Modal } from 'components/molecules'
import SelectionStatusStepper from '../SelectionStatusStepper'
import { SELECTION_STATUSES } from 'utils/constants'
import RedBatuIcon from 'assets/img/red-batu.svg'
import SelectionModalCompanyInfo from '../SelectionModalCompanyInfo'
import { selectAuthUser } from 'store/auth/selectors'
import { accountType } from 'utils/helper'
import SelectionModalMessageTab from '../MessageList'
import SelectionModalRecommenderInfoTab from './SelectionModalRecommenderInfoTab'
import { selectRecommendation } from 'store/referral-proposals/selectors'
import { useFitElement, useThread, useBodyScroll } from 'utils/hooks'
import SelectionButtons from './SelectionButtons'
import SelectionInputArea from './SelectionInputArea'
import SelectionModalTabs from '../SelectionModalTabs'
import { actionSetSlideoutActiveTab } from 'store/referral-proposals/actionCreators'

function getAlertMessage({ status, t }) {
  switch (status) {
    case SELECTION_STATUSES.declined:
      return t('selection.modal.declinedAlert')
    case SELECTION_STATUSES.rejected:
      return t('selection.modal.rejectedAlert')
    default:
      return null
  }
}

// const showResumeBtnStatuses = [SELECTION_STATUSES.rejected, SELECTION_STATUSES.declined]
const hideDeclineBtnStatuses = [SELECTION_STATUSES.rejected, SELECTION_STATUSES.declined, SELECTION_STATUSES.joined]

const canResume = (user = null, recommendation = null) => {
  const isAD1 = accountType(user) === 'AD1'
  const isOwner = +recommendation?.referred_by === +user?.id
  const applicationStatus = recommendation?.application_status_id
  const shouldShowResume = SELECTION_STATUSES.declined === applicationStatus

  return isAD1 && shouldShowResume && isOwner
}

const canDecline = (user = null, recommendation = null) => {
  const applicationStatus = recommendation?.application_status_id
  const isAD1 = accountType(user) === 'AD1'
  const shouldShowDecline = !hideDeclineBtnStatuses.includes(applicationStatus)
  const isOwner = +recommendation?.referred_by === +user?.id

  return isAD1 && shouldShowDecline && isOwner
}

function SelectionModal({ open, onClose, setProposalIsUpdated }) {
  const { t } = useTranslation()
  const user = useSelector(selectAuthUser)
  const recommendation = useSelector(selectRecommendation)
  const dispatch = useDispatch()

  const handleClose = () => {
    if (onClose) {
      onClose()
    }
  }

  const applicant = get(recommendation, 'applicant.fullName')
  const applicationStatus = recommendation?.application_status_id
  const dangerMessage = getAlertMessage({ status: applicationStatus, t })
  const showResumeBtn = canResume(user, recommendation)
  const showDeclineBtn = canDecline(user, recommendation)
  const chatThread = useThread({ threadId: recommendation?.chat_thread?.id })

  const tabs = [
    {
      id: 'message',
      label: t('selection.modal.message'),
      Component: SelectionModalMessageTab,
      tabProps: { ...chatThread, referralProposal: recommendation, user, open, scrollToBottom },
    },
    {
      id: 'recommender',
      label: t('selection.modal.recommenderInfo'),
      Component: SelectionModalRecommenderInfoTab,
    },
  ]

  const active = useSelector((state) => state.referralProposals.slideOut.tab)
  const setActive = (tab) => {
    dispatch(actionSetSlideoutActiveTab(tab))
  }

  useEffect(() => {
    setActive(tabs[0].id)
  }, [])

  const containerRef = useRef(null)
  const tabContAreaRef = useRef(null)
  const inputAreaRef = useRef(null)

  useFitElement({ src: tabContAreaRef, anchor: containerRef })

  // Set tab cont area padding depending on the input area height
  useEffect(() => {
    if (tabContAreaRef.current === null || inputAreaRef.current === null) return

    const inputH = 16
    tabContAreaRef.current.style.paddingBottom = `${inputH}px`
  }, [tabContAreaRef.current, inputAreaRef.current, inputAreaRef.current?.clientHeight, active])

  function scrollToBottom() {
    if (tabContAreaRef.current === null) return
    // Scroll to bottom
    tabContAreaRef.current.scroll(0, tabContAreaRef.current.scrollHeight)
  }

  useBodyScroll({ disable: open })

  return (
    <Modal
      blockElClass="selectionModal"
      containerBlockElClass="selectionModal__cont"
      containerType="contSlideCont"
      innerProps={{ blockElClass: 'selectionModal__inner selectionModal__inner-rt' }}
      onClose={handleClose}
      open={open}
      containerRef={containerRef}
    >
      {recommendation && (
        <>
          <Modal.Header blockElClass="selectionModal__contHeader">
            <Modal.Title blockElClass="selectionModal__contHeaderTitle">
              {t('selection.modal.title', { applicant })}
            </Modal.Title>
            <div className="selectionModal__contHeaderRight">
              <SelectionButtons
                recommendationId={recommendation?.id}
                showDeclineBtn={showDeclineBtn}
                showResumeBtn={showResumeBtn}
                setProposalIsUpdated={setProposalIsUpdated}
              />
              <Modal.CloseButton onClick={handleClose} blockElClass="selectionModal__close" />
            </div>
          </Modal.Header>
          {dangerMessage && (
            <div className="selectionModal__alertArea">
              <img src={RedBatuIcon} alt="" className="selectionModal__alertIcon" width="12" height="12" />
              {dangerMessage}
            </div>
          )}
          <SelectionStatusStepper {...(recommendation || {})} />
          <SelectionModalCompanyInfo jobPosting={recommendation.job_posting} />
          <div className="selectionModal__main">
            <SelectionModalTabs active={active} onChange={setActive} tabs={tabs} tabContAreaRef={tabContAreaRef} />
          </div>
          <SelectionInputArea
            {...chatThread}
            show={active === 'message'}
            recommendation={recommendation}
            user={user}
            ref={inputAreaRef}
            setProposalIsUpdated={setProposalIsUpdated}
          />
        </>
      )}
    </Modal>
  )
}

SelectionModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  recommendation: PropTypes.object,
  setProposalIsUpdated: PropTypes.func,
}

export default SelectionModal
