import React, { useCallback, useEffect } from 'react'

import { useWatch } from 'react-hook-form'
import PropTypes from 'prop-types'
import debounce from 'lodash/debounce'
import { useDispatch } from 'react-redux'
import { syncProposalToStore, syncToStore } from 'services/jobPostingForm'

function StoreSync({ control, defaultValues, mapToStore, type }) {
  const values = useWatch({
    control,
    defaultValue: defaultValues,
  })
  const dispatch = useDispatch()

  const handleValue = useCallback(
    debounce((formData) => {
      const data = typeof mapToStore === 'function' ? mapToStore(formData) : formData
      type === 'referral' ? dispatch(syncToStore(data)) : dispatch(syncProposalToStore(data))
    }, 300),
    [mapToStore]
  )

  useEffect(() => {
    handleValue(values)
  }, [values, handleValue])

  return <></>
}

StoreSync.propTypes = {
  control: PropTypes.any,
  defaultValues: PropTypes.object,
  mapToStore: PropTypes.func,
  type: PropTypes.string.isRequired,
}

export default StoreSync
