import { USER_TYPE_KEYS } from 'utils/constants'
import { inAccountType, isParent } from 'utils/helper'

export default ({ bypassAdmin = false } = {}) =>
  ({ user }, next) => {
    if (!user) return next(false)

    if (bypassAdmin && inAccountType(user, USER_TYPE_KEYS.ADMIN)) return next(true)

    next(
      isParent(user)
        ? !!(
            user.company.first_name &&
            user.company.last_name &&
            user.company.year_established &&
            user.company.account_settlement_month &&
            user.company.employee_count
          )
        : true
    )
  }
