import { createAsyncThunk } from 'store/helpers'
import { parseAxiosResponse } from 'utils/helper'
import Http from 'utils/Http'

export const fetchJobPostingFilter = createAsyncThunk('FETCH_JOB_POSTING_FILTER', () => {
  return parseAxiosResponse(Http.get(`/common/job_filters`))
})

export const fetchJobPostingFilterSearch = createAsyncThunk('FETCH_JOB_POSTING_FILTER_SEARCH', (searchCriteria) => {
  return parseAxiosResponse(Http.get(`/common/job_filters/?search_criteria=${searchCriteria}`))
})

export const createJobPostingFilter = createAsyncThunk('CREATE_JOB_POSTING_FILTER', (formData) => {
  const stringifyForm = Object.keys(formData).reduce((acc, key) => {
    if (Array.isArray(formData[key]) || typeof formData[key] === 'object') {
      acc[key] = formData[key] !== null ? JSON.stringify(formData[key]) : formData[key]
    } else {
      acc[key] = formData[key]
    }
    return acc
  }, {})

  return parseAxiosResponse(Http.post('/common/job_filters', stringifyForm))
})

export const deleteJobPostingFilter = createAsyncThunk('DELETE_JOB_POSTING_FILTER', (id) =>
  parseAxiosResponse(Http.delete(`/common/job_filters/${id}`))
)

export const updateJobPostingFilter = createAsyncThunk('UPDATE_JOB_POSTING_FILTER', (formData) => {
  const stringifyForm = Object.keys(formData).reduce((acc, key) => {
    if ((Array.isArray(formData[key]) || typeof formData[key] === 'object') && formData[key] !== null) {
      acc[key] = JSON.stringify(formData[key])
    } else {
      acc[key] = formData[key]
    }
    return acc
  }, {})

  return parseAxiosResponse(Http.patch(`/common/job_filters/${formData['id']}`, stringifyForm))
})
