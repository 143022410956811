import clsx from 'clsx'

import { calculateModifiers } from 'utils/helper'

export default function useBEM({ initial = '', props = {}, omitUnknownAttribs = true }) {
  const { className = '', blockElClass = initial, noBlockEl = false, modifier = '', ...other } = props

  return {
    ...other,
    ...(omitUnknownAttribs ? {} : { blockElClass: blockElClass, modifier }),
    className: clsx(noBlockEl ? '' : blockElClass, calculateModifiers(blockElClass, modifier), className),
  }
}
