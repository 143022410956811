import React, { lazy, Suspense, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'

import Main from 'layouts/main'
import { Loader } from 'components'
import { getUser } from 'services/auth'
import RouteGuard from './RouteGuard'

function Private(inProps) {
  const { component, layout, ...rest } = inProps
  const auth = useSelector((state) => state.auth)
  const dispatch = useDispatch()

  useEffect(() => {
    if (auth.state === 'idle') {
      dispatch(getUser())
    }
  }, [auth.state])

  if (auth.state !== 'ready') {
    return <Loader />
  }

  if (!auth.isAuthenticated) {
    return (
      <Route
        {...rest}
        render={(props) => (
          <Redirect
            to={{
              pathname: '/sign-in',
              state: { from: props.location },
            }}
          />
        )}
      />
    )
  }

  const Layout = layout ? lazy(() => import(`../layouts/${layout}`)) : Main
  const Component = lazy(() => import(`../${component}`))

  return (
    <Route
      {...rest}
      render={(props) => (
        <Suspense fallback={<Loader />}>
          <RouteGuard {...{ ...props, ...rest }}>
            <Layout>
              <Component {...props} />
            </Layout>
          </RouteGuard>
        </Suspense>
      )}
    />
  )
}

Private.propTypes = {
  component: PropTypes.any,
  layout: PropTypes.any,
  location: PropTypes.object,
}

export default Private
