import React from 'react'

import PropTypes from 'prop-types'

import { useBEM } from 'utils/hooks'

const Select = React.forwardRef((inProps, ref) => {
  const { options, ...props } = useBEM({ initial: 'form__select', props: inProps })

  return (
    <select {...props} ref={ref}>
      {options.map((option) => (
        <option key={option.id || option} value={option.id || option}>
          {option?.label || option}
        </option>
      ))}
    </select>
  )
})

Select.displayName = 'Select'

const OptionID = PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired

const OptionProp = PropTypes.shape({
  id: OptionID,
  label: PropTypes.string,
})

Select.propTypes = {
  options: PropTypes.arrayOf(PropTypes.oneOfType([OptionID, OptionProp])),
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.arrayOf(OptionID), OptionID]),
}

export default Select
