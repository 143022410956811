import React from 'react'

import PropTypes from 'prop-types'
import { BlockEl } from 'components/atoms'

const FormInputSup = ({ max, inputValue, modifier, end }) => (
  <BlockEl bec="form__inputSup" modifier={[modifier, { end }]}>
    <span className="form__inputSup-num">{inputValue?.length ?? 0}</span>/{max}
  </BlockEl>
)

FormInputSup.propTypes = {
  children: PropTypes.node,
  max: PropTypes.number,
  inputValue: PropTypes.any,
  modifier: PropTypes.any,
  end: PropTypes.bool,
}

const FormSupArea = ({ className, component = 'span', modifier, children, flex, ...rest }) => (
  <BlockEl
    blockElClass="form__supArea"
    component={component}
    modifier={[modifier, { flex }]}
    className={className}
    {...rest}
  >
    {children}
  </BlockEl>
)

FormSupArea.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  error: PropTypes.node,
  inputSup: PropTypes.node,
  component: PropTypes.string,
  modifier: PropTypes.any,
  flex: PropTypes.bool,
}

FormSupArea.InputSup = FormInputSup

export default FormSupArea
