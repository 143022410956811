import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import LinkPreview from 'components/molecules/LinkPreview'
import { useForm } from 'react-hook-form'
import garbage from 'assets/img/garbage.svg/'
import { FormGroupCheck, FormInterlock } from 'components/molecules'
import { Radio } from 'components/atoms'

const OtherUrlPdfDialog = (props) => {
  const { field } = props
  const [url, setUrl] = useState('')
  const [preview, setPreview] = useState(false)
  const { register } = useForm()

  const handleRadioClick = (e) => {
    props.update(props.index, { ...field, option: e.target.value })
  }

  const handleFileInput = (e) => {
    const { files } = e.target

    if (files?.length > 0) {
      props.update(props.index, { ...field, option: 'pdf', pdf: files[0].name })
      var filelist = props.selectedOtherPdf
      filelist[props.index] = e.target.files[0]
      props.handleOnChange(e)
      props.handleSelectedOtherPdfs(props.index, e.target.files[0])
    }
  }

  const handleRemoveFile = (index) => {
    props.update(index, { option: 'pdf', pdf: null })
    props.handleRemoveFile(index, 'other pdf')
  }

  const handleChangeDocsUrl = (e) => {
    e.preventDefault()
    setPreview(false)
    const { value } = e.target
    const url = value
    setUrl(url)
    props.handleOnChange(e)
  }

  const handleBlurDocsUrl = (e) => {
    e.preventDefault()
    setPreview(true)
  }

  useEffect(() => {
    if (field.url) {
      setUrl(field.url)
      setPreview(true)
    }
  }, [])

  return (
    <div className="form__group form__group-mgb0 form__group-mgt0">
      <div id="portfilioCheckDom">
        <div className="form__portfolioArea">
          <div className="form__portfolioAreaHeader">
            <img
              src={garbage}
              alt="remove"
              className="form__portfolioAreaHeaderImg"
              width="20"
              height="20"
              onClick={() => props.handleRemovePortfolio(props.index)}
            />
          </div>
          <div className="form__groupCheckArea">
            <FormGroupCheck>
              <FormGroupCheck.Item
                Input={Radio}
                inputProps={{
                  ...register(`other_docs.${props.index}.option`),
                  value: 'url',
                  onClick: handleRadioClick,
                  checked: field.option === 'url',
                }}
                label="URLで指定する"
                type="round"
              />
            </FormGroupCheck>

            <FormInterlock active={field.option === 'url'}>
              <div className="form__groupFlex form__group-mgt">
                <input
                  type="text"
                  {...register(`other_docs[${props.index}].url`)}
                  defaultValue={field?.url}
                  onChange={(e) => handleChangeDocsUrl(e)}
                  onBlur={handleBlurDocsUrl}
                  className="form__inputText form__inputText-w100 inputCalc inputCalcParent inputCheck portfilioCheck"
                />
              </div>
              {preview && (
                <>
                  <div className="form__supArea form__supArea-flex">
                    <p className="form__sup form__sup-mgt0">https://から始まる形式で入力してください</p>
                  </div>
                  <div className="form__portfolioOg1">
                    <p className="form__portfolioOgText1">{url && <LinkPreview url={url} />}</p>
                  </div>
                </>
              )}
            </FormInterlock>

            <div className="form__supArea form__supArea-flex">
              <p className="form__alert">ご選択の上、ご入力ください。</p>
            </div>

            <FormGroupCheck>
              <FormGroupCheck.Item
                Input={Radio}
                inputProps={{
                  ...register(`other_docs.${props.index}.option`),
                  value: 'pdf',
                  onClick: handleRadioClick,
                  checked: field.option === 'pdf',
                }}
                label="PDFをアップロードする"
                type="round"
              />
            </FormGroupCheck>

            <FormInterlock active={field.option === 'pdf'}>
              <div className="form__groupFlex form__group-mgt">
                {field?.pdf === null ? (
                  <label className="form__fileLabel form__fileLabel-plus">
                    PDFをアップロードする
                    <input
                      type="file"
                      accept="application/pdf"
                      {...register(`other_docs.${props.index}.pdf`)}
                      onChange={handleFileInput}
                      className="form__file form__file-dpn inputFile portfilioCheck"
                    />
                  </label>
                ) : (
                  <p className="form__fileText">
                    <span className="form__fileText-text">
                      {field?.pdf?.replace('C:\\fakepath\\', '')?.replace(/^[^_]+(?=_)_/, '')}
                    </span>
                    <span
                      className="form__fileTextDel portfolioDel "
                      onClick={() => handleRemoveFile(props.index)}
                    ></span>
                  </p>
                )}
              </div>
              <div className="form__supArea form__supArea-flex">
                <p className="form__alert">入力してください。</p>
              </div>
            </FormInterlock>
          </div>
        </div>
      </div>
    </div>
  )
}

OtherUrlPdfDialog.propTypes = {
  update: PropTypes.func.isRequired,
  index: PropTypes.number,
  field: PropTypes.object.isRequired,
  finalData: PropTypes.object,
  handleFileInput: PropTypes.func,
  handleOnChange: PropTypes.func,
  handleChangeDocsUrl: PropTypes.func,
  url: PropTypes.string,
  preview: PropTypes.bool,
  handleRemovePortfolio: PropTypes.func,
  handleSelectedOtherPdfs: PropTypes.func,
  selectedOtherPdf: PropTypes.array,
  handleRemoveFile: PropTypes.func,
}

export default OtherUrlPdfDialog
