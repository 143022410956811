import * as types from './actionTypes'

//Applicants List
export function getAdInchargeListRequest(fullname) {
  return {
    type: types.GET_AD_INCHARGE_LIST_REQUEST,
    payload: { fullname },
  }
}

export function getAdInchargeListSuccess(data) {
  return {
    type: types.GET_AD_INCHARGE_LIST_SUCCESS,
    payload: { data },
  }
}

export function getAdInchargeListFailure(error) {
  return {
    type: types.GET_AD_INCHARGE_LIST_FAILURE,
    payload: { error },
  }
}
