import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import Typography, { typographyClasses } from '@mui/material/Typography'
import { styled } from '@mui/material/styles'
import React, { useState } from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import { useThread } from 'utils/hooks'
import { useSelector } from 'react-redux'
import {
  selectThreadError,
  selectThreadFetching,
  selectThreadItems,
  selectThreadPagination,
} from 'store/chat-threads/selectors'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import { DATE_FORMAT } from 'utils/constants'
import Card from '@mui/material/Card'
import LightModeIcon from '@mui/icons-material/LightMode'
import { selectAuthUser } from 'store/auth/selectors'
import Avatar from '@mui/material/Avatar'
import Grid from '@mui/material/Grid'
import { Waypoint } from 'react-waypoint'
import { Markdown } from '../../../molecules'

const MessageBox = styled('div')({
  height: 400,
  width: 600,
  padding: '10px',
  backgroundColor: '#dddddd',
  marginBottom: '10px',
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
})

const MessageBoxNotification = styled('div')(({ theme, type }) => {
  const pallete = theme.palette[type]

  return {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    padding: theme.spacing(1),
    backgroundColor: pallete.main,
    color: pallete.contrastText,
    textAlign: 'center',
    [`& .${typographyClasses.root}`]: {
      color: pallete.contrastText,
    },
  }
})

const MessageContent = styled('div')(({ theme }) => ({
  height: 360,
  flexGrow: 1,
  overflowY: 'scroll',
  display: 'flex',
  flexDirection: 'column-reverse',
  padding: theme.spacing(1),
}))

const StyledCircularProgress = styled(CircularProgress)(() => ({
  position: 'absolute',
  top: '15px',
  right: '50%',
}))

const RegularMessageItemContainer = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(2),
}))

const StatusMessageItemContainer = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(2),
  textAlign: 'center',
}))

const EvaluationItemContainer = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(2),
  textAlign: 'center',
  maxWidth: 300,
  marginRight: 'auto',
  marginLeft: 'auto',
}))

const StyledForm = styled('form')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
})

const MessageItemTypeProps = {
  message: PropTypes.object,
  user: PropTypes.object,
}

const RegularMessageCard = styled(Card)(({ theme }) => ({
  paddingRight: theme.spacing(3),
  paddingLeft: theme.spacing(3),
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  maxWidth: 327,
}))

const SenderAvatar = styled(Avatar)(({ theme }) => ({
  marginRight: theme.spacing(2),
}))

const RegularMessageItem = (props) => {
  const isSelf = props.user?.id === props.message.sender.id

  return (
    <RegularMessageItemContainer>
      <Grid container justifyContent={isSelf ? 'end' : 'start'}>
        {!isSelf && (
          <Grid item>
            <SenderAvatar src={props.user?.avatar} />
          </Grid>
        )}
        <Grid item>
          <Typography textAlign={isSelf ? 'right' : 'left'}>
            {!isSelf && props.message.sender?.full_name}&nbsp; {dayjs(props.message.created_at).format(DATE_FORMAT)}
          </Typography>
          <RegularMessageCard>
            <Markdown>{props.message.message}</Markdown>
          </RegularMessageCard>
        </Grid>
      </Grid>
    </RegularMessageItemContainer>
  )
}

RegularMessageItem.propTypes = MessageItemTypeProps

const MessageLine = styled('div')({
  display: 'flex',
  justifyContent: 'center',
})

const StatusMessageItem = (props) => {
  return (
    <StatusMessageItemContainer>
      <Typography>{dayjs(props.message.created_at).format(DATE_FORMAT)}</Typography>
      <MessageLine>
        <LightModeIcon />
        <Typography component="u">{props.message.message}</Typography>
      </MessageLine>
    </StatusMessageItemContainer>
  )
}

StatusMessageItem.propTypes = MessageItemTypeProps

const EvaluationCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(3),
  backgroundColor: theme.palette.primary.light,
}))

const EvaluationText = styled(Typography)(({ theme }) => ({
  fontWeight: '600',
  marginBottom: theme.spacing(2),
}))

const EvaluationMessageItem = (props) => {
  return (
    <EvaluationItemContainer>
      <EvaluationCard variant="outlined">
        <EvaluationText>{props.message.message}</EvaluationText>
        {/* TODO: Disable button when the current user has already evaluated */}
        <Button variant="contained">評価コメントを入力する</Button>
      </EvaluationCard>
    </EvaluationItemContainer>
  )
}

EvaluationMessageItem.propTypes = MessageItemTypeProps

const getComponentByMessageType = (type) => {
  switch (type) {
    case 'evaluation':
      return EvaluationMessageItem
    case 'status':
      return StatusMessageItem
    case 'regular':
    default:
      return RegularMessageItem
  }
}

const MessageItem = (props) => {
  const Component = getComponentByMessageType(props.message.type)

  return <Component {...props} />
}

MessageItem.propTypes = {
  message: PropTypes.object,
  user: PropTypes.object,
}

function ChatBox({ threadId }) {
  const [message, setMessage] = useState('')
  const { send, loadMore } = useThread({ threadId })
  const messages = useSelector(selectThreadItems(threadId))
  const fetching = useSelector(selectThreadFetching(threadId))
  const error = useSelector(selectThreadError(threadId))
  const user = useSelector(selectAuthUser)
  const { hasMore } = useSelector(selectThreadPagination(threadId))

  const sendMessage = (e) => {
    e.preventDefault()

    if (message.trim().length > 0) {
      send({ message })
      setMessage('')
    }
  }

  const handleLoadMore = () => {
    if (fetching || !hasMore) return
    loadMore()
  }

  return (
    <div>
      <MessageBox>
        {error && (
          <MessageBoxNotification onClick={error} type="error">
            <Typography>{error}</Typography>
          </MessageBoxNotification>
        )}
        <MessageContent>
          {fetching && <StyledCircularProgress />}
          {messages.map((item) => (
            <MessageItem key={item.id} user={user} message={item} />
          ))}
          <Waypoint onEnter={handleLoadMore} />
        </MessageContent>
        <StyledForm onSubmit={sendMessage}>
          <TextField
            multiline
            sx={{ flexGrow: 1 }}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          ></TextField>
          <Button type="submit" variant="contained">
            Send
          </Button>
        </StyledForm>
      </MessageBox>
    </div>
  )
}

ChatBox.propTypes = {
  threadId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default ChatBox
