import React, { lazy, Suspense } from 'react'
import PropTypes from 'prop-types'
import { accountType } from 'utils/helper'
import { useAuth } from 'utils/hooks'

const components = {
  HR: lazy(() => import(`components/organisms/hr/SelectionButtons`)),
  AD1: lazy(() => import(`components/organisms/ad1/SelectionButtons`)),
}

function SelectionButtons(props) {
  const { user } = useAuth()
  const userType = accountType(user)

  const Component = components[userType]

  if (Component) {
    return (
      <Suspense>
        <Component {...props} />
      </Suspense>
    )
  }

  return <></>
}

SelectionButtons.propTypes = {
  showResumeBtn: PropTypes.bool.isRequired,
  showDeclineBtn: PropTypes.bool.isRequired,
  recommendationId: PropTypes.number,
  setProposalIsUpdated: PropTypes.func,
}

export default SelectionButtons
