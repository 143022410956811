import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'

import { BlockEl, Radio, FormGroupCheck } from 'components'
import { selectPrefectures } from 'store/common/selectors'
import { getPrefecturesList } from 'services/common'
import { Input } from 'components/atoms'

const PrefectureModal = (props) => {
  const {
    handleOnChange,
    handlePrefectureClick,
    handleSelectPrefecture,
    handleShowPrefecture,
    showPrefecture,
    prefectureId,
    onClose,
    onSelect,
    disabledItems = [],
  } = props
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const { register } = useForm()
  const { pending, success } = useSelector((state) => state.common)
  const [searchTerm, setSearchTerm] = useState('')
  const prefectures = useSelector(selectPrefectures)

  useEffect(() => {
    if (pending) {
      setLoading(true)
    } else {
      setLoading(false)
    }
  }, [pending])

  useEffect(() => {
    if (success) return
    showPrefecture && dispatch(getPrefecturesList())
  }, [showPrefecture, success])

  const handlePrefectureSearch = (e) => {
    setSearchTerm(e.target.value)
    handleOnChange && handleOnChange(e)
  }

  const groupedPrefectures = prefectures
    .filter((item) => searchTerm.trim().length === 0 || item.prefecture.indexOf(searchTerm) > -1)
    .reduce((acc, cur) => {
      const group = cur.category

      if (!acc[group]) {
        acc[group] = []
      }

      acc[group].push(cur)
      return acc
    }, {})

  const handleOnClose = (...args) => {
    handleShowPrefecture && handleShowPrefecture(...args)
    onClose(...args)
  }

  const handleChange = (item) => (e) => {
    handlePrefectureClick && handlePrefectureClick(e)
    handleSelectPrefecture && handleSelectPrefecture(e, item.prefecture)
    onSelect && onSelect(item.id)
  }

  return (
    <BlockEl blockElClass="form__adInputArea" modifier={{ active: showPrefecture }}>
      <div className="form__adInputAreaBack" onClick={handleOnClose}></div>
      {!loading && (
        <div className="form__adInputAreaInner">
          <div className="form__adSearchArea">
            <p className="form__adSearchText">都道府県名</p>
            <Input {...register('prefectureSearch')} blockElClass="form__adSearch" onChange={handlePrefectureSearch} />
          </div>
          {Object.keys(groupedPrefectures).map((groupKey) => (
            <div key={groupKey} className="form__adAreaArea">
              <p className="form__adAreaTitle">{groupKey}</p>
              {groupedPrefectures[groupKey].map((item) => (
                <FormGroupCheck.Item
                  key={item.id}
                  blockElClass="form__groupCheckAd"
                  name="prefecture"
                  inputProps={{
                    checked: parseInt(prefectureId) === parseInt(item.id),
                    value: item.id,
                    onChange: handleChange(item),
                  }}
                  disabled={disabledItems.includes(+item.id)}
                  Input={Radio}
                  type="round"
                  label={item.prefecture}
                />
              ))}
            </div>
          ))}
        </div>
      )}
    </BlockEl>
  )
}

PrefectureModal.propTypes = {
  handlePrefectureClick: PropTypes.func,
  handleShowPrefecture: PropTypes.func,
  handleSelectPrefecture: PropTypes.func,
  prefecture: PropTypes.string,
  showPrefecture: PropTypes.bool,
  prefectureId: PropTypes.number,
  handleOnChange: PropTypes.func,
  disabledItems: PropTypes.arrayOf(PropTypes.number),
  onClose: PropTypes.func,
  onSelect: PropTypes.func,
}

export default PrefectureModal
