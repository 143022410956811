import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { signOutUser } from 'services/auth'
import { selectAuthUser } from 'store/auth/selectors'

export default function useAuth() {
  const user = useSelector(selectAuthUser)
  const dispatch = useDispatch()

  const signOut = useCallback(() => {
    dispatch(signOutUser())
  }, [dispatch, signOutUser])

  return { user, signOut }
}
