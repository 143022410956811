import React, { useEffect, useState } from 'react'

import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { FormProvider, useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'

import { Modal } from 'components'
import { createQuestion, updateQuestion } from 'services/questionAndAnswers'
import { syncErrorsToForm } from 'utils/helper'
import FormFields from './FormFields'
import Confirmation from './Confirmation'
import { showNotification } from 'store/notification/actionCreators'
import Footer from './Footer'

function QuestionAndAnswerFormModal(props) {
  const { open, onClose, initialFormValue, jobPostingId, onSuccess, isEditing } = props
  const dispatch = useDispatch()
  const methods = useForm({ defaultValues: initialFormValue, mode: 'all' })
  const { handleSubmit, setError, reset } = methods
  const { t } = useTranslation()
  const [confirmation, setConfirmation] = useState(false)

  const onSubmit = async ({ id, ...data }) => {
    if (!confirmation) {
      setConfirmation(true)
      return
    }

    const res = isEditing
      ? await dispatch(updateQuestion(id, data))
      : await dispatch(createQuestion({ job_posting_id: jobPostingId, ...data }))

    if (res.error) {
      if (res.error?.code == 422) {
        syncErrorsToForm(res.error.error, setError)
      }

      dispatch(showNotification(res.error.error, { type: 'danger' }))
      return
    }

    // If there are no error then signal success
    onSuccess()
    if (!isEditing) {
      dispatch(showNotification(t('qAndA.successAlert')))
    }
  }

  useEffect(() => {
    if (open) {
      setConfirmation(false)
    }

    if (!open) {
      reset({ question: '', topic: null }, { keepValues: false })
    }
  }, [open])

  useEffect(() => {
    if (isEditing && open) {
      reset(
        { id: initialFormValue.id, question: initialFormValue.question, topic: initialFormValue.topic?.toString() },
        { keepDefaultValues: true }
      )
    }
  }, [isEditing, open, initialFormValue])

  const handleOnClose = (e) => {
    if (confirmation) {
      setConfirmation(false)
      return
    }
    onClose(e)
  }

  return (
    <FormProvider {...methods}>
      <Modal onClose={handleOnClose} open={open}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header>
            <Modal.Title>{t('qAndA.modalTitle')}</Modal.Title>
            <Modal.CloseButton onClick={handleOnClose} />
          </Modal.Header>
          <Modal.Body>{confirmation ? <Confirmation /> : <FormFields />}</Modal.Body>
          <Footer confirmation={confirmation} onCancel={handleOnClose} t={t} />
        </form>
      </Modal>
    </FormProvider>
  )
}

QuestionAndAnswerFormModal.propTypes = {
  open: PropTypes.bool,
  initialFormValue: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  jobPostingId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onSuccess: PropTypes.func.isRequired,
  isEditing: PropTypes.bool,
}

QuestionAndAnswerFormModal.defaultProps = {
  initialFormValue: {
    topic: null,
    question: '',
  },
}

export default QuestionAndAnswerFormModal
