import { range } from 'utils/helper'

export default function usePagination(props) {
  const { page, onPageChange, totalPages, boundaryCount = 1, siblingCount = 1, disabled = false, ...others } = props
  const handleClick = (e, p) => {
    e.preventDefault()

    if (onPageChange) {
      onPageChange(p)
    }
  }

  const startPages = range(1, Math.min(boundaryCount, totalPages))
  const endPages = range(Math.max(totalPages - boundaryCount + 1, boundaryCount + 1), totalPages)

  const siblingsStart = Math.max(
    Math.min(
      // Natural start
      page - siblingCount,
      // Lower boundary when page is high
      totalPages - boundaryCount - siblingCount * 2 - 1
    ),
    // Greater than startPages
    boundaryCount + 2
  )
  const siblingsEnd = Math.min(
    Math.max(
      // Natural end
      page + siblingCount,
      // Upper boundary when page is low
      boundaryCount + siblingCount * 2 + 2
    ),
    // Less than endPages
    endPages.length > 0 ? endPages[0] - 2 : totalPages - 1
  )

  const itemList = [
    'previous',
    ...startPages,

    // Start ellipsis
    // eslint-disable-next-line no-nested-ternary
    ...(siblingsStart > boundaryCount + 2
      ? ['start-ellipsis']
      : boundaryCount + 1 < totalPages - boundaryCount
      ? [boundaryCount + 1]
      : []),

    // Sibling pages
    ...range(siblingsStart, siblingsEnd),

    // End ellipsis
    // eslint-disable-next-line no-nested-ternary
    ...(siblingsEnd < totalPages - boundaryCount - 1
      ? ['end-ellipsis']
      : totalPages - boundaryCount > boundaryCount
      ? [totalPages - boundaryCount]
      : []),

    ...endPages,
    'next',
  ]

  // Map the button type to its page number
  const buttonPage = (type) => {
    switch (type) {
      case 'first':
        return 1
      case 'previous':
        return page - 1
      case 'next':
        return page + 1
      case 'last':
        return totalPages
      default:
        return null
    }
  }

  // Convert the basic item list to PaginationItem props objects
  const items = itemList.map((item) => {
    return typeof item === 'number'
      ? {
          onClick: (event) => {
            if (disabled) return
            handleClick(event, item)
          },
          type: 'page',
          page: item,
          selected: item === page,
          disabled,
          'aria-current': item === page ? 'true' : undefined,
        }
      : {
          onClick: (event) => {
            if (disabled || (item.indexOf('ellipsis') === -1 && (item === 'next' ? page >= totalPages : page <= 1)))
              return
            handleClick(event, buttonPage(item))
          },
          type: item,
          page: buttonPage(item),
          selected: false,
          disabled: disabled || (item.indexOf('ellipsis') === -1 && (item === 'next' ? page >= totalPages : page <= 1)),
        }
  })

  return { items, totalPages, ...others }
}
