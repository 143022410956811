import React, { useRef } from 'react'

import PropTypes from 'prop-types'

import { useAutoHeight } from 'utils/hooks'
import { assignRefs } from 'utils/helper'
import BlockEl from '../BlockEl'

const Textarea = React.forwardRef(function Textarea(inProps, ref) {
  const { autoHeight = false, maxAutoHeight, children, ...props } = inProps
  const inputRef = useRef(null)
  const bec = props.bec || props.blockElClass || 'form__inputTextArea'

  useAutoHeight({ ref: inputRef, enabled: autoHeight, maxAutoHeight })

  return (
    <BlockEl {...props} component="textarea" bec={bec} componentRef={assignRefs([ref, inputRef])}>
      {children}
    </BlockEl>
  )
})

Textarea.propTypes = {
  ...BlockEl.propTypes,
  blockElClass: PropTypes.string,
  children: PropTypes.any,
  autoHeight: PropTypes.bool,
  maxAutoHeight: PropTypes.number,
}

export default Textarea
