import React, { useEffect } from 'react'

import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import findIndex from 'lodash/findIndex'

import { Stepper } from 'components/molecules'
import { fetchReferralProposalSteps } from 'services/referralProposals'
import { SELECTION_STATUSES } from 'utils/constants'

const isRejectedDeclined = [SELECTION_STATUSES.rejected, SELECTION_STATUSES.declined]

function SelectionStatusStepper({ id, application_status_id }) {
  const steps = useSelector((state) => state.referralProposals.steps.items)
  const state = useSelector((state) => state.referralProposals.steps.state)
  const dispatch = useDispatch()

  useEffect(() => {
    if (id) {
      dispatch(fetchReferralProposalSteps(id))
    }
  }, [id, application_status_id])

  const getType = (step) => {
    switch (step.id) {
      case SELECTION_STATUSES.declined:
      case SELECTION_STATUSES.rejected:
        return 'error'
      case SELECTION_STATUSES.joined:
        return application_status_id === SELECTION_STATUSES.joined ? 'success' : 'normal'
      default:
        return undefined
    }
  }

  const currentIndex = findIndex(steps, { id: application_status_id })

  const theSteps = steps.map((step, index) => ({
    ...step,
    label: step.status,
    date: index <= currentIndex ? step.recent_transaction?.created_at : null,
    type: getType(step),
    borderBlockElClass: isRejectedDeclined.includes(application_status_id)
      ? 'selectionModal__stepBorder2'
      : 'selectionModal__stepBorder',
  }))

  return <Stepper steps={state === 'fulfilled' ? theSteps : []} current={application_status_id} />
}

SelectionStatusStepper.propTypes = {
  id: PropTypes.number,
  application_status_id: PropTypes.number,
}

export default SelectionStatusStepper
