import React from 'react'
import Evaluations from 'views/evaluation'
import PropTypes from 'prop-types'

function UserProfileEvaluation({ userId }) {
  return <Evaluations userId={userId} />
}

export default UserProfileEvaluation

UserProfileEvaluation.propTypes = {
  userId: PropTypes.number,
}
