import React, { useEffect } from 'react'

import { useSelector, useDispatch } from 'react-redux'

import includes from 'lodash/includes'
import PropTypes from 'prop-types'

import { Checkbox } from 'components/atoms'
import FormGroupCheck from 'components/molecules/FormGroupCheck'
import { fetchTopics } from 'services/topics'
import { selectTopics } from 'store/topics/selectors'

function QuestionAndAnswerTopicsChecklist({ selected, onChange }) {
  const dispatch = useDispatch()
  const topics = useSelector(selectTopics)

  useEffect(() => {
    dispatch(fetchTopics())
  }, [])

  const handleClick = (id) => {
    return (e) => {
      const { checked } = e.target
      const isSelected = includes(selected, id)

      if (checked && !isSelected) {
        onChange(selected.concat(id))
      } else if (!checked && isSelected) {
        onChange(selected.filter((item) => item !== id))
      }
    }
  }

  return (
    <>
      {topics.map((topic) => (
        <FormGroupCheck.Item
          key={topic.id}
          Input={Checkbox}
          inputProps={{
            value: topic.id,
            checked: includes(selected, topic.id),
            onChange: handleClick(topic.id),
          }}
          label={topic.topic}
          type="square"
        />
      ))}
    </>
  )
}

QuestionAndAnswerTopicsChecklist.propTypes = {
  selected: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
  onChange: PropTypes.func,
}

export default QuestionAndAnswerTopicsChecklist
