import { BlockEl } from 'components/atoms'
import React from 'react'
import PropTypes from 'prop-types'

function FormInterlock({ children, active = false, ...others }) {
  return (
    <BlockEl blockElClass="form__interlock" modifier={{ active }} {...others}>
      {children}
    </BlockEl>
  )
}

FormInterlock.propTypes = {
  children: PropTypes.node,
  active: PropTypes.bool,
}

export default FormInterlock
