import { USER_TYPE_KEYS } from 'utils/constants'
import { accountType } from 'utils/helper'

export default ({ bypassAdmin = false } = {}) =>
  ({ user }, next) => {
    switch (accountType(user)) {
      case USER_TYPE_KEYS.HR:
        return next(user.firstname && user.lastname ? true : false)
      case USER_TYPE_KEYS.AD1:
      case USER_TYPE_KEYS.AD2:
        return next(
          user.firstname &&
            user.lastname &&
            (user.industries?.length || 0) > 0 &&
            (user.occupations?.length || 0) > 0 &&
            user.contact_number
            ? true
            : false
        )
      case USER_TYPE_KEYS.ADMIN:
        return next(bypassAdmin)
      default:
        return false
    }
  }
