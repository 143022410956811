import * as ActionTypes from './actionTypes'

export function loadWorkPointsRequest() {
  return {
    type: ActionTypes.LOAD_WORKPOINTS_REQUEST,
  }
}

export function loadWorkPointsSuccess(data) {
  return {
    type: ActionTypes.LOAD_WORKPOINTS_SUCCESS,
    payload: data,
  }
}

export function loadWorkPointsFailure(error) {
  return {
    type: ActionTypes.LOAD_WORKPOINTS_FAILURE,
    payload: { error },
  }
}
