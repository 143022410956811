import { useCallback, useEffect, useState } from 'react'
import toString from 'lodash/toString'

export default function useOtherOption({ value, findIndex, otherOptionIndex }) {
  const [selected, setSelected] = useState(-1)
  const [hasValue, setHasValue] = useState(false)

  useEffect(() => {
    let index = findIndex(value)

    const valueLength = toString(value)

    if (index === -1 && hasValue) {
      index = otherOptionIndex
    }

    !hasValue && valueLength && setHasValue(true)

    setSelected(index)
  }, [value, selected, hasValue, otherOptionIndex, findIndex])

  const isOthers = useCallback((index) => otherOptionIndex === index || index === -1, [otherOptionIndex])

  const selectedOthers = isOthers(selected) && hasValue

  return { selected, isOthers, setHasValue, selectedOthers }
}
