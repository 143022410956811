import React from 'react'

import PropTypes from 'prop-types'

import Modal from '../Modal'
import ConfirmModalContent from './ConfirmModalContent'

function ConfirmModal(props) {
  const { buttons, onClose, open, title, text, disabled = false } = props

  const handleClose = () => {
    onClose({ type: 'backdrop' })
  }

  return (
    <Modal containerType="contA" open={open} onClose={handleClose}>
      <ConfirmModalContent onClick={onClose} title={title} text={text} buttons={buttons} disabled={disabled} />
    </Modal>
  )
}

ConfirmModal.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  buttons: ConfirmModalContent.propTypes.buttons,
  open: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
}

export default ConfirmModal
