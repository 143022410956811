import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { ReactComponent as GarbageIcon } from 'assets/img/garbage-icon2.svg'
import { getOpenGraphData } from 'services/openGraph'
import { CircularProgress } from '@mui/material'
import BlankImage from 'assets/img/blank_image.png'

const CandidatePortfolioUrlItem = ({ item, index, handleOpenModal, handleOpenLink }) => {
  const [cardData, setCardData] = useState({
    url: '',
    image: '',
    title: '',
    description: '',
    hostname: '',
  })
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  useEffect(async () => {
    if (item?.option === 'url') {
      getWebMetadata(item.url)
        .then((res) => {
          const dataObj = {
            title: res?.data?.site_name ?? res?.data?.title ?? item.url,
            hostname: res?.data?.url,
            description: res?.data?.description,
            image: res?.data?.image,
            option: 'url',
          }
          setCardData(dataObj)
          setLoading(false)
        })
        .catch(() => setLoading(false))
    }
  }, [item])

  const getWebMetadata = async (site) => {
    setLoading(true)
    let urlString = site?.replace(/^http(s?):\/\//, '')
    if (urlString) return await dispatch(getOpenGraphData(urlString))
  }

  const truncate = (input) => {
    if (input) {
      return input.length > 50 ? `${input.substring(0, 50)}...` : input
    }
  }

  if (item?.option === 'url') {
    return (
      <div className="form__portfolioOg form__portfolioOg-inLink form__portfolioOg-w582 candidate__modalTabContOg">
        <a
          href=""
          onClick={(e) => handleOpenLink(e, cardData?.hostname ? cardData.hostname : item?.url)}
          className="form__portfolioOgLinkArea"
          rel="noopener"
        >
          <div className="form__portfolioOgImgArea">
            {loading ? (
              <CircularProgress color="primary" size="5rem" />
            ) : (
              <img
                src={cardData?.image ?? BlankImage}
                alt=""
                className="form__portfolioOgImg"
                width="152"
                height="80"
              />
            )}
          </div>
          {!loading && (
            <div className="form__portfolioOgTextArea">
              <p className="form__portfolioOgTitle form__portfolioOgTitle-mgb0">{cardData.title}</p>
              <p className="form__portfolioOgLink">{cardData?.hostname ? cardData.hostname : item?.url}</p>
              <p className="form__portfolioOgText">{truncate(cardData.description)}</p>
            </div>
          )}
        </a>
        <div className="form__portfolioOgIconAreaWrap">
          <p className="form__portfolioOgIconArea">
            <span className="form__portfolioOgIcon" onClick={() => handleOpenModal(index, 'url')}>
              <GarbageIcon width="17" height="17" />
            </span>
          </p>
        </div>
      </div>
    )
  } else return null
}

CandidatePortfolioUrlItem.propTypes = {
  item: PropTypes.object,
  index: PropTypes.number,
  truncate: PropTypes.func,
  handleOpenModal: PropTypes.func,
  handleOpenLink: PropTypes.func,
}

export default CandidatePortfolioUrlItem
