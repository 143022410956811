import produce from 'immer'
import { AUTH_SIGNOUT } from 'store/auth/actionTypes'
import * as ActionTypes from './actionTypes'

const initialState = {
  open: false,
  data: null,
  success: false,
}

const reducer = produce((draft, { type, payload }) => {
  switch (type) {
    case ActionTypes.OPEN_EVAULATION_FORM:
      draft.open = true
      draft.success = false
      break
    case ActionTypes.CLOSE_EVAULATION_FORM:
      draft.open = false
      draft.success = !!payload.success
      break
    case ActionTypes.SET_EVAULATION_FORM_DATA:
      draft.data = payload
      break
    case AUTH_SIGNOUT:
      return initialState
    default:
      return draft
  }
}, initialState)

export default reducer
