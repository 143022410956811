import React from 'react'
import { Menu, Divider, Button, Typography } from '@mui/material'
import Logout from '@mui/icons-material/Logout'
import AccountCircleRounded from '@mui/icons-material/AccountCircleRounded'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { signOutUser } from 'services/auth'
import { useTranslation } from 'react-i18next'
import { NavMenuItem } from 'components/atoms/'

const MenuDropdown = (props) => {
  const { subPages, userType, user } = props

  const dispatch = useDispatch()

  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const { t } = useTranslation()

  return (
    <div>
      <Button
        endIcon={<AccountCircleRounded />}
        size="large"
        variant="text"
        color="inherit"
        style={{ textTransform: 'none' }}
        id="basic-button"
        aria-controls="basic-menu"
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <Typography color="inherit" variant="body1">
          {user?.lastname} {user?.firstname}
        </Typography>
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {subPages
          .filter((page) => page.access.includes(userType))
          .map((page) => (
            <NavMenuItem key={page.title} title={page.title} href={page.href} />
          ))}
        <Divider sx={{ my: 0.5 }} />
        {/* TODO: Refactor */}
        <button onClick={() => dispatch(signOutUser())} key={t('header.dropdown.logout')}>
          <Logout /> {t('header.dropdown.logout')}
        </button>
      </Menu>
    </div>
  )
}

MenuDropdown.propTypes = {
  subPages: PropTypes.array,
  userType: PropTypes.string,
  user: PropTypes.object,
}

export default MenuDropdown
