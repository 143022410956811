import produce from 'immer'
import sortBy from 'lodash/sortBy'
import get from 'lodash/get'
import * as ActionTypes from './actionTypes'
import findIndex from 'lodash/findIndex'
import { loadLatestMessages, loadMessage } from 'services/chatThreads'

export const initialThreadState = {
  fetching: true,
  sending: false,
  error: null,
  items: [],
  pagination: {
    page: 1,
    limit: 20,
    hasMore: true,
  },
}

const initialState = {
  threads: {},
}

export default produce((draft, { type, payload, meta }) => {
  const threadId = payload?.threadId || get(meta, 'args.0')
  const thread = draft.threads[threadId] || { ...initialThreadState }

  switch (type) {
    case ActionTypes.LOAD_MORE_THREAD_MESSAGES_REQUEST:
    case ActionTypes.LOAD_THREAD_MESSAGES_REQUEST:
      thread.fetching = true
      break
    case ActionTypes.LOAD_THREAD_MESSAGES_SUCCESS:
      updateItems(thread, payload.response.data)
      updatePagination(thread.pagination, payload)
      thread.fetching = false
      break
    case ActionTypes.LOAD_MORE_THREAD_MESSAGES_SUCCESS:
      updateItems(thread, payload.response.data)
      updatePagination(thread.pagination, payload)
      thread.fetching = false
      break
    case ActionTypes.LOAD_MORE_THREAD_MESSAGES_FAILURE:
    case ActionTypes.LOAD_THREAD_MESSAGES_FAILURE:
      thread.error = payload.error
      thread.fetching = false
      break
    case ActionTypes.CREATE_NEW_MESSAGE_REQUEST:
      thread.sending = true
      break
    case ActionTypes.CREATE_NEW_MESSAGE_SUCCESS:
      updateItems(thread, [payload.response.data])
      thread.sending = false
      break
    case ActionTypes.CREATE_NEW_MESSAGE_FAILURE:
      thread.error = payload.error
      thread.sending = false
      break
    case ActionTypes.ADD_MESSAGE:
      updateItems(thread, [payload.message])
      break
    case ActionTypes.INIT_THREAD:
      draft.threads[threadId] = thread
      break
    case loadLatestMessages.fulfilled:
      updateItems(thread, payload.data)
      break
    case loadMessage.fulfilled:
      updateItems(thread, [payload.data.data])
      break
    default:
      return draft
  }
}, initialState)

/**
 * Updates or adds the additions to the items whichever is necessary.
 *
 * @param {Object} thread The thread state
 * @param {Array} additions The additional items
 * @returns {Array}
 */
function updateItems(thread, additions) {
  additions.forEach((addition) => {
    const index = findIndex(thread.items, { id: addition.id })
    if (index !== -1) {
      thread.items[index] = addition
    } else {
      thread.items.unshift(addition)
    }
  })

  thread.items = sortItems(thread.items)
}

/**
 * Sort the items by created_at, and id in ascending order.
 *
 * @param {*} items
 */
function sortItems(items) {
  return sortBy(items, ['created_at', 'id'])
}

/**
 * Updates the pagination state based on the given payload.
 *
 * @param {any} draft
 * @param {any} payload
 */
function updatePagination(draft, payload) {
  draft.page = +payload.response.meta.current_page
  draft.limit = +payload.response.meta.per_page
  draft.hasMore = payload.response.meta.last_page !== draft.page
}
